import React, { useState, useEffect, useContext } from "react";
import { MyTextField, MyTypography } from "../../../../assets/styles/styledComponents";
import { Grid, Button, Accordion, AccordionSummary, AccordionDetails, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import { SimpleSnackMessage, YearTable } from "../../..";
import useStyles from "./styles";
import api from "../../../../api";
import { StoreContext } from "../../../../utils";

export default function ManageYear(props) {
	const { mount, setMount } = props;
  const { token } = useContext(StoreContext);
	const classes = useStyles();
	const initialYearState = {
		dataInicio: new Date(),
		dataFim: new Date(),
		contagem: 0,
		dataModificacao: new Date(),
    autorModificacao: token.userID,
	};
	const [anoLetivo, setAnoLetivo] = useState(initialYearState);
	const [anoID, setAnoID] = useState(null);
	const [listaTurma, setListaTurma] = useState([]);
  const [listaAnos, setListaAnos] = useState([]);
	const [noError, setNoError] = useState(true);
	const [message, setMessage] = useState("");
	const [open, setOpen] = useState(false);

	// Lista Ano Letivo por Turma
	async function fetchYearAPI() {
		const response = await api.listarAnoLetivo();
		setListaAnos(response.data.data);
	}

	// Caso haja mudança em anoLetivo
	useEffect(() => {
		const abortController = new AbortController();
		if (mount.anoLetivo) {
			if (anoLetivo.dataInicio && anoLetivo.dataFim) {
				getTheWeek();
			}
			fetchYearAPI();
			setMount((preValue) => ({ ...preValue, anoLetivo: false }));
		}
		return abortController.abort();
		// eslint-disable-next-line
	}, [mount]);

	// Calcula o número de semanas
	const getTheWeek = () => {
		var dataInicio = new Date(moment(anoLetivo.dataInicio).format("YYYY-MM-DDT07:00:00.000Z"));
		var dataFim = new Date(moment(anoLetivo.dataFim).format("YYYY-MM-DDT07:00:00.000Z"));

		setAnoLetivo((preValue) => ({
			...preValue,
			dataInicio: dataInicio,
			dataFim: dataFim,
			numSemanas: Math.trunc((dataFim.valueOf() - dataInicio.valueOf()) / 1000 / 3600 / 24 / 7),
			contagem: Math.trunc(dataInicio.valueOf()),
		}));
	};

	// Carregamento Inicial - Ano Letivo
	const initialLoad = () => {
		if (mount.isMountedYear) {
			// Lista Turmas Ativas
			async function fetchTurmaAPI() {
				const response = await api.listarTurma();
				setListaTurma(response.data.data);
			}

			fetchTurmaAPI();
			fetchYearAPI();
			setMount((preValue) => ({ ...preValue, isMountedYear: false }));
		}
	};

	// Função para pegar os valores de data do formulário
	const handleDateChange = (date, name) => {
		setAnoLetivo((preValue) => ({
			...preValue,
			[name]: date,
		}));
		setMount((preValue) => ({ ...preValue, anoLetivo: true }));
	};

	// Função para pegar os valores do formulário
  const handleChange = (event) => {
    const { name, value } = event.target;
    setAnoLetivo((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

	// Guarda ano letivo no banco
	async function saveYearChange() {
		const validate = listaAnos.filter(x => {return x.turmaID._id === anoLetivo.turmaID});

		if (validate.length === 0) {
			await api
				.inserirAnoLetivo(anoLetivo)
				.then(res => {
					setNoError(true);
					setMessage(res.data.message);
					setMount((preValue) => ({ ...preValue, anoLetivo: true }));
				})
				.catch(err => {
					setNoError(false);
					setMessage("Houve um erro ao inserir o ano letivo.");
				});
		} else {
			setNoError(false);
			setMessage("Esta turma já possui um ano letivo definido.");
		}
    setOpen(true);
	}

	// Guarda ano letivo atualizado no banco
	async function editYearChange() {
		const validate = listaAnos.filter(x => { return x._id !== anoLetivo._id && x.turmaID._id === anoLetivo.turmaID });

		if (validate.length === 0) {
			var novoAnoLetivo = anoLetivo;
			novoAnoLetivo.autorModificacao = token.userID;
			novoAnoLetivo.dataModificacao = new Date();

			await api
				.atualizarAnoLetivo(anoID, novoAnoLetivo)
				.then(res => {
					setNoError(true);
					setMessage(res.data.message);
					setMount((preValue) => ({ ...preValue, anoLetivo: true }));
				})
				.catch(err => {
					setNoError(false);
					setMessage("Houve um erro ao atualizar o ano letivo.");
				});
		} else {
			setNoError(false);
			setMessage("Esta turma já possui um ano letivo definido.");
		}
    setOpen(true);
	}

	return (
		<>
			<Accordion
				square
				elevation={2}
				style={{ borderBottom: `0.2rem solid #4dbc8e` }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					onClick={() => initialLoad()}
					aria-controls="panel1a-content"
					id="panel3-settings"
				>
					<h2 className="heading-page">Gerenciar Ano Letivo</h2>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container={true} className={classes.root} spacing={1}>
						<Grid item xs={12} sm={5}>
							<Grid container={true} spacing={2}>
								<Grid item={true} xs={6}>
									<MuiPickersUtilsProvider
										locale="pt-br"
										libInstance={moment}
										utils={MomentUtils}
									>
										<KeyboardDatePicker
											autoOk={true}
											disableToolbar={true}
											variant="inline"
											format="DD/MM/YYYY"
											margin="normal"
											label="Data de Início"
											name="dataInicio"
											fullWidth
											value={anoLetivo.dataInicio}
											onChange={date => handleDateChange(date, "dataInicio")}
											maxDate={anoLetivo.dataFim}
											KeyboardButtonProps={{ "aria-label": "change date" }}
										/>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item={true} xs={6}>
									<MuiPickersUtilsProvider
										locale="pt-br"
										libInstance={moment}
										utils={MomentUtils}
									>
										<KeyboardDatePicker
											autoOk={true}
											disableToolbar={true}
											format="DD/MM/YYYY"
											variant="inline"
											margin="normal"
											label="Data de Fim"
											name="dataFim"
											fullWidth
											value={anoLetivo.dataFim}
											onChange={date => handleDateChange(date, "dataFim")}
											minDate={anoLetivo.dataInicio}
											KeyboardButtonProps={{ "aria-label": "change date" }}
										/>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item={true} xs={12}>
									<MyTextField
										variant="outlined"
										select={true}
										label="Turma"
										name="turmaID"
										onChange={handleChange}
										value={anoLetivo.turmaID ? anoLetivo.turmaID : ""}
									>
										{
											listaTurma.map((row, index) => {
												return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
											})
										}
									</MyTextField>
								</Grid>

								<Grid className={classes.dateGrid} item={true} xs={12}>
									<MyTypography variant="h6">
										Número de Semanas
									</MyTypography>
									<MyTypography variant="h4">
										{anoLetivo.numSemanas ? anoLetivo.numSemanas : ""}
									</MyTypography>
								</Grid>
										
								<Grid className={classes.group} item xs={12}>
									<Button
										className={classes.buttons}
										variant="outlined"
										type="submit"
										color="secondary"
										onClick={() => setAnoLetivo(initialYearState)}
									>
										Limpar
									</Button>
									<Button
										className={classes.buttons}
										variant="outlined"
										type="submit"
										color="primary"
										onClick={anoLetivo._id ? editYearChange : saveYearChange}
									>
										Salvar
									</Button>
								</Grid>
							</Grid>
						</Grid>
						
						<Grid item xs={12} sm={7}>
							<YearTable
								data={listaAnos} 
								setAno={setAnoLetivo} 
								setAnoID={setAnoID} 
								setMount={setMount}/>
						</Grid>
					
					</Grid>
				</AccordionDetails>
			</Accordion>
			<SimpleSnackMessage 
				open={open}
				setOpen={setOpen}
				message={message}
				error={noError}/>
		</>
	);
}
