import Axios from 'axios'
import React, { useState } from 'react'
import api from '../../api'
import { UserForm, SimpleSnackMessage } from "../../components"
import validate from "../../components/Form/Validation/FormValidateUser"

export default function UsersInsert() {
    const initialState = {
        nome: "",
        email: "",
        acesso: "",
        alunoID: "",
        disciplina: [],
        erros: [],
        url: "",
        foto: {},
        turma: [],
        plano: "Free"
    }
    const [usuario, setUsuario] = useState(initialState);
    const [noError, setNoError] = useState(true);
    const [message, setMessage] = useState("Houve um erro ao inserir o usuário.");
    const [open, setOpen] = useState(false);
    const [profDisciplinas, setProfDisciplinas] = useState([{ disciplinaID: '' }]);
    const [turmas, setTurmas] = useState([{ turmaID: '' }]);

    async function handleIncludeUser () {
        const error = await validate(usuario, false);
        setUsuario(preValue => ({
            ...preValue,
            erros: error
        }));

        if (error.validated) {
            
            var novoUsuario = {
                nome: usuario.nome,
                email: usuario.email,
                acesso: usuario.acesso,
                senha: usuario.senha,
                turma: usuario.turma,
                url: usuario.url,
                contador: 0
            }

            if(usuario.acesso !== "Aluno" && usuario.acesso !== "Responsável") 
                novoUsuario.disciplina = usuario.disciplina;
            else if(usuario.acesso !== "Aluno")
                novoUsuario.alunoID = usuario.alunoID;
            else
                novoUsuario.plano = usuario.plano;

            try {
                await api
                    .inserirUsuario(novoUsuario)
                    .then(async res => {
                        if (usuario.foto?.name !== undefined) {
                            const data = new FormData();
                            data.append("foto", usuario.foto);
                            try {
                                await Axios
                                .post(`${process.env.REACT_APP_API_URL}upload-profile/${res.data.id}`, data)
                                .then(async res => {
                                    novoUsuario.url = res.data.url
                                    await api.atualizarUsuario(res.data.id, novoUsuario);
                                });

                            } catch (error) {
                                console.log(error);
                            }
                        }
                        setNoError(true);
                        setMessage(res.data.message);
                        setUsuario(initialState);
                });
            } catch (error) {
                setNoError(false);
            }
            setOpen(true);
        }
    }  

    return (
        <>
            <UserForm
                data={usuario}
                onSubmit={handleIncludeUser}
                setUsuario={setUsuario}
                typeForm="Registrar"
                edit={false}
                profDisciplinas={profDisciplinas}
                setProfDisciplinas={setProfDisciplinas}
                turmas={turmas}
                setTurmas={setTurmas}
            /> 

            <SimpleSnackMessage 
                open={open}
                setOpen={setOpen}
                message={message}
                error={noError}
            />
        </>
    );
}