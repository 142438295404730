import React, { useContext } from 'react'
import { StoreContext } from "../../utils"
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Dialog, DialogContent, Fade } from '@material-ui/core';

import { GeneralText, GeneralTitle } from '../../assets/styles/styledComponents';
import * as inactive from "../../assets/animations/inactive.json";
import Lottie from "react-lottie";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: inactive.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};


// -- Local Styles
const useStyles = makeStyles(() => ({
    button: {
        textAlign: "center"
    },
    card: {
        maxWidth: "18.75rem",
        paddingBottom: "1.5rem"
    },
    title: {
        padding: "1rem 0 0 1rem",
        marginBottom: "-0.3rem",
        textAlign: "center"
    },
    text: {
        textAlign: "center",
        fontSize: "1.2rem",
        padding: "0.5rem 0"
    }
}));

const userName = (nomeAluno) => {
    var name = nomeAluno.split(' ').map(name => {
        return name;
    })
    return name[0];
}

export default function InactiveDialog(props) {
    const classes = useStyles();
    const { away, setAway, setOnBlur } = props;
    const {token} = useContext(StoreContext);
    const nomeAluno = userName(token.userName);

    const handleClick = () => {
        if(!localStorage.getItem("TimestampGeral")) 
            localStorage.setItem("TimestampGeral", JSON.stringify(new Date().toString()));
        setOnBlur(false);
        setAway(false);
    }

    return (
        <Dialog
            open={away}
            keepMounted={true}>
            <GeneralTitle className={classes.title}>{`Hey, ${nomeAluno}!`}</GeneralTitle>
            <DialogContent className={classes.card}>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Fade in={true} style={{transitionDelay: "300ms"}}>
                            <Lottie options={defaultOptions} height={200} width={200} isStopped={false} isPaused={false}/>
                        </Fade>
                    </Grid>
                    <Grid item xs={12}>
                        <GeneralText className={classes.text}>Você ainda está aí?</GeneralText>
                    </Grid>
                    <Grid item className={classes.button} xs={12}>
                        <Button variant="contained" onClick={handleClick} color="primary">
                            Sim
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
