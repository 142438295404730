import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  group: {
    textAlign: "center",
  },
  section: {
    marginTop: theme.spacing(2),
  },
  dateGrid: {
    textAlign: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default useStyles;