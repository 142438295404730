import React, { useState, useEffect, useContext, useCallback } from 'react';
import { GeneralSubtitle, GeneralText, MyCard } from '../../../assets/styles/styledComponents';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import { Button, Grid, Slide } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CircularStatic from '../../ProgressBar/CircularStatic';
import DescriptionIcon from '@material-ui/icons/Description';
import api from '../../../api';
import { StoreContext } from '../../../utils';
import moment from "moment";
import useStyles from './styles';

export default function StudentResultsAD(props) {
  const classes = useStyles();
  const { msg1, msg2, msg3, listagem, mediaAcumulada, title1, title2, title3 } = props;
  const { token } = useContext(StoreContext);
  const [wasLoaded, setWasLoaded] = useState(false);
  const [dataRealizacao, setDataRealizacao] = useState('');
  const [notaAluno, setNotaAluno] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [atividadeSelecionada, setAtividadeSelectionada] = useState(listagem[0]);
  const [numTotal, setNumTotal] = useState(0);
  const [numRealizado, setNumRealizado] = useState(0);
  const [check, setCheck] = useState({ revisao: false });
  const [progresso, setProgresso] = useState({
    topicoID: "",
    alunoID: "",
    progresso: true
  });
  const { setDataProvider } = useContext(StoreContext);
  
  //--Pega nota da AD do aluno
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const PegaNotaAluno = useCallback(async () => {
    let alunoID = atividadeSelecionada!== undefined ? atividadeSelecionada?.alunoID : listagem[0].alunoID;
    let revisaoID = atividadeSelecionada!== undefined ? atividadeSelecionada?.revisaoID?._id : listagem[0].revisaoID._id;

    await api.listarRAPorAlunoID(alunoID)
      .then(res => {
        let results = res.data.data.filter(x => { return x.revisaoID === revisaoID });
        if (results.length > 0) {
          if (results[0].nota === undefined) {
            setNotaAluno("Pendente");
          } else {
            setNotaAluno(results[0].nota > 100 ? 100 : results[0].nota);
          }
        }
      })
      .catch(err => {
        console.log(msg2, check, progresso);
      })
  })

  //--Função para visualizar redação ou AD
  const VisualizarCorrecao = () => {
    if (!atividadeSelecionada) {
      setAtividadeSelectionada(listagem[0]);
      setDataProvider(x => ({ 
        ...x, 
        activity: listagem[0].revisaoID, 
        progress: progresso, 
        type: "avaliacaoDiagnostica"
      }));
    }
    else {
      setDataProvider(x => ({ 
        ...x, 
        activity: atividadeSelecionada.revisaoID, 
        progress: progresso, 
        type: "avaliacaoDiagnostica"
      }));
    }
  }

  //--Renderiza linhas da tabela virtualizada
  const RenderizaLinhasTabela = (props) => {
    const { index, style } = props;
    let dataFormatada = new Intl.DateTimeFormat('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(listagem[index].dataConclusao));
    let titleList = `AD ${index+1} - ${dataFormatada}`;

    function MostraAtividade(index) {
      //--Salva atividade selecionada
      setAtividadeSelectionada(listagem[index]);

      //--Atualiza data de realização da atividade
      let dataConclusao = new Intl.DateTimeFormat('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(listagem[index].dataConclusao));
      setDataRealizacao(dataConclusao);

      //--Pega nota do aluno
      PegaNotaAluno();
    }

    return (
      <ListItem button style={style} key={index} onClick={() => MostraAtividade(index)}>
        <ListItemText primary={titleList}  />
      </ListItem>
    );
  }

  //--Carregamento inicial
  useEffect(() => {
    if (!isLoaded && listagem.length) {
      //--Carrega data inicial da redação ou AD
      var dataConclusao = new Intl.DateTimeFormat('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(listagem[0].dataConclusao));
      setDataRealizacao(dataConclusao);

      
      //--Carrega número total de ADs
      async function CarregaDadosGeraisRevisao() {
        await api.listarRevisao()
          .then(res => {
            var today = `${moment(new Date()).format("YYYY-MM-DD")}T08:00:00.000Z`;
            var atividades = res.data.data.filter(item => {
              return item.turmaID === token.turmaID && item.dataLiberacao.dataFim <= today;
            })
            setNumTotal(atividades.length);
          })
          .catch(err => console.log(err));
        }
      CarregaDadosGeraisRevisao();
      
      setAtividadeSelectionada(listagem[0]);
      setNumRealizado(listagem.length);
      PegaNotaAluno();

      //--Marca como já carregado
      setIsLoaded(true);
    }
  }, [PegaNotaAluno, isLoaded, listagem, token.turmaID]);

  //--Carrega progresso após selecionar uma atividade
  useEffect(() => {
    if (!atividadeSelecionada) {
      setAtividadeSelectionada(listagem[0]);
    } else {
      //--Carrega progresso da atividade do banco
      async function fetchProgressoRevisaoAPI() {
        await api.encProgressoPorRevisaoID(atividadeSelecionada.alunoID, atividadeSelecionada._id)
          .then(res => {
            const value = res.data.data;
            setProgresso(value);
            setCheck({ avaliacaoDiagnostica: value.progresso });
            setProgresso(value.progresso ? 1 : 0);
          })
          .catch(err => console.log(err));
      }
      fetchProgressoRevisaoAPI();
    }
  }, [atividadeSelecionada, listagem])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '250ms'}}>
          <MyCard className={classes.contentCard}>
            <GeneralSubtitle>{title1}</GeneralSubtitle>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <GeneralSubtitle className={classes.areaConhecimento}>{atividadeSelecionada?.revisaoID?.areaConhecimento}</GeneralSubtitle>
                <GeneralText className={classes.subtitle}>{msg1}</GeneralText>
              </Grid>

              <Grid item xs={12} sm={4}>
                <div className={classes.caixaRedacoes}>
                  <GeneralText className={classes.rightText}>{dataRealizacao}</GeneralText>
                  <GeneralText className={classes.rightSubtitle}>Data Realização</GeneralText>
                  <GeneralText className={classes.rightText} style={{paddingTop: "0.7rem"}}>{Math.round(notaAluno)}</GeneralText>
                  <GeneralText className={classes.rightSubtitle}>Resultado Obtido</GeneralText>
                </div>
              </Grid>

              <Grid item xs={12}style={{paddingTop: "0.5rem"}}>
                <Link to={`/atividade/`} style={{ textDecoration: 'none' }}>
                  <Button 
                    fullWidth={true} 
                    variant="contained" 
                    color="primary" 
                    component="span"
                    onClick={() => VisualizarCorrecao()} 
                    startIcon={<DescriptionIcon />}>Visualizar Correção</Button>
                </Link>
              </Grid>
            </Grid>
          </MyCard>
        </Slide>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '300ms'}}>
          <MyCard className={classes.contentCard}>
            <GeneralSubtitle>{title2}</GeneralSubtitle>
            <Grid container spacing={1} justifyContent="center">
              <Grid id='nota' item xs={6} sm={6} className={classes.sumario}>
                <div className={classes.caixaNota}>
                  <GeneralText className={classes.resultadoNota}>{Math.round(mediaAcumulada)}</GeneralText>
                  <GeneralText className={classes.legenda}>Resultado Médio</GeneralText>
                </div>
              </Grid>
              
              <Grid id='progresso' item xs={6} sm={6} className={classes.sumario}>
                <div className={classes.caixa}>
                  <div className={numTotal !== numRealizado ? "none" : classes.success}>
                    <CircularStatic 
                      wasLoaded={wasLoaded} 
                      setWasLoaded={setWasLoaded} 
                      numTasks={numTotal} 
                      progresso={numRealizado}/>
                  </div>
                  <GeneralText className={classes.resultado}>{`${numRealizado}/${numTotal}`}</GeneralText>
                  <GeneralText className={classes.legenda}>Realizadas/Total</GeneralText>
                </div>
              </Grid>
              
              <Grid id='observacao' item xs={12}>
                <GeneralText className={classes.observacao}>{msg3}</GeneralText>
              </Grid>
            </Grid>
          </MyCard>
        </Slide>
      </Grid>
      
      <Grid item xs={12} sm={4}>
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '350ms'}}>
          <MyCard className={classes.listCard}>
            <GeneralSubtitle>{title3}</GeneralSubtitle>
            <FixedSizeList height={200} itemSize={46} itemCount={listagem.length}>
              { RenderizaLinhasTabela }
            </FixedSizeList>
          </MyCard>
        </Slide>
      </Grid>
    </Grid>
  );
}
