import React, { useEffect, useState } from "react";
import {Link as RouterLink} from 'react-router-dom';
import api from '../../api'
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import "moment/locale/pt-br";
import { Grid, lighten, MenuItem, Toolbar } from "@material-ui/core";
import { MyTextField } from "../../assets/styles/styledComponents";

const useStyles = makeStyles({
    table: {
        color: "#606161"
    },
    row: {
        color: "#606161"
    },
    head: {
        color: "#606161",
        fontWeight: "bold"
    }
});

// -- Atualização
function UpdateDateAds(props) {
    const {setDataAd, dataAd, setDataID, setMount} = props;

    function AtualizandoDateAds() {
        setDataID(dataAd._id)
        setDataAd(dataAd);
        setMount(preValue => ({...preValue, dataAd: true}));
    }

    return (
        <IconButton aria-label="update" color="primary" size="small" onClick={AtualizandoDateAds}>
            <EditIcon/>
        </IconButton>
    )
}

// -- Remoção
function DeleteDateAds(props) {
    const {id, setMount} = props;

    function RemovendoDateAds() {
        if (window.confirm(`Quer remover esta data permanentemente?`)) {
            api.removerDataAd(id)
            setMount(preValue => ({...preValue, tag: true}));
        }
    }

    return (
        <RouterLink to={"/configuracoes"}>
            <IconButton
                aria-label="delete"
                color="secondary"
                size="small"
                onClick={RemovendoDateAds}>
                <DeleteIcon/>
            </IconButton>
        </RouterLink>
    )
}

// -- Toolbar Styles
const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
      color: "#606161",
    },
  }));

// -- Toolbar
const EnhancedTableToolbar = (props) => {
    const { listaTurma, filtro, setFiltro } = props;
    const classes = useToolbarStyles();

    const handleChange = (event) => {
        const { value } = event.target;
        setFiltro(value);
    };

    return (
    <Toolbar className={clsx(classes.root)}>
        <Grid container spacing={2} style={{margin: "1rem 0.5rem 0.5rem 0"}} justifyContent="center">
            <Grid item xs={12}>
                <MyTextField
                    variant="outlined"
                    select={true}
                    label="Turma"
                    name="turma"
                    onChange={handleChange}
                    value={filtro ? filtro : ""}>
                    {
                        listaTurma.map((row, index) => {
                            return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
                        })
                    }
                </MyTextField>
            </Grid>
        </Grid>
    </Toolbar>
    );
};

export default function DateAdsTable(props) {
    const {data, setDataAd, setDataID, setMount, turmas} = props;
    const [filtro, setFiltro] = useState('')
    const [datas, setDatas] = useState([])
    const classes = useStyles();

    useEffect(() => {
        const abortController = new AbortController();
        if (filtro !== "") {
            setDatas(data.filter(x => {return x.turmaID === filtro}));
        } else {
            setDatas(data);
        }
        return abortController.abort();
        // eslint-disable-next-line
    }, [data, filtro]);

    return (
        <Paper className={classes.paper}>
            <EnhancedTableToolbar 
                listaTurma={turmas}
                filtro={filtro} 
                setFiltro={setFiltro}/>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.head}>Área</TableCell>
                            <TableCell className={classes.head}>Data Início</TableCell>
                            <TableCell className={classes.head}>Data Fim</TableCell>
                            <TableCell className={classes.head}>Funções</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            datas.map((row, index) => {
                                const {areaConhecimento, dataInicio, dataFim} = row;
                                return (
                                    <TableRow key={index}>
                                        <TableCell className={classes.row} component="th" scope="row">{areaConhecimento}</TableCell>
                                        <TableCell className={classes.row} component="th" scope="row">{moment(dataInicio).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell className={classes.row} component="th" scope="row">{moment(dataFim).format("DD/MM/YYYY")}</TableCell>

                                        <TableCell className={classes.row} component="th" scope="row">
                                            <UpdateDateAds dataAd={row} setDataAd={setDataAd} setMount={setMount} setDataID={setDataID}/>
                                            <DeleteDateAds id={row._id} nome={row.nome} setMount={setMount}/>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}