import React from 'react'
import { Link } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

// -- Material UI
import { withStyles, styled } from '@material-ui/core/styles';
import { Avatar, Typography, Container, TextField, Button, CardContent, Card, Backdrop } from '@material-ui/core';

// Ícones: Material-UI
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { useHistory } from 'react-router-dom';

// -- Button Styles
const AddButton = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        },
    }
}))(Button);
  
const DeleteButton = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        },
    }
}))(Button);

const YellowButton = withStyles((theme) => ({
    root: {
        color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
        },
    },
}))(Button);

const GreenButton = withStyles((theme) => ({
    root: {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.dark,
        },
    },
}))(Button);

const CreateButton = (props) => {
    const {title, url} = props;
    return (
        <Link to={url} style={{ textDecoration: 'none' }}>
            <Button variant="outlined" color="primary" startIcon={<AddIcon/>}>
                {title}
            </Button>
        </Link>
    )
}  

const BackButton = (rest) => {
    const history = useHistory();
    return (
        <Button
            {...rest}
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIcon />}>
            Voltar
        </Button>
    );
}

const ExportButton = ({csvData, fileName, tabName}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, tabName);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={(e) => exportToCSV(csvData,fileName)}
            startIcon={<ImportExportIcon />}>
            Exportar
        </Button>
    )
}

// -- Title Styles
const GeneralTitle = withStyles((theme) => ({
    root: {
        color: "#606161",
        fontStyle: 'normal',
        fontWeight: '500',
        marginBottom: '1rem',
        fontSize: "2rem",
    },
}))(Typography);

const GeneralSubtitle = withStyles((theme) => ({
    root: {
        color: "#606161",
        fontStyle: 'normal',
        fontWeight: '500',
        marginBottom: '1rem',
        fontSize: "1.5rem"
    },
}))(Typography);

const GeneralText = withStyles((theme) => ({
    root: {
        color: "#606161",
    },
}))(Typography);

// -- Others
const MyTextField = styled(TextField)({
    marginBottom: "0.625rem", 
    backgroundColor: "#fff", 
    display: "flex", 
    color: "primary",
    minWidth: 0
});

const MyContainer = styled(Container)({
    justifyContent: "center",
    justifySelf: "center",
    justifyItems: "center",
    padding: "1.2rem 1.875rem 1.2rem 1.875rem",
    margin: "0",
    maxWidth: "none",
    maxHeight: "none",
    minWidth: "18.75rem",
});

const MyTypography = styled(Typography)({ color: "#606161" });

const LoginCard = styled(CardContent)({
    padding: "2rem", 
    alignContent: "center", 
    textAlign: "center",
    backgroundColor: "#fff",
    borderRadius: "1rem",
});

const MyCard = styled(Card)({
    padding: "0", 
    margin: "0", 
    backgroundColor: "#fff"
});

const MyCardContent = styled(CardContent)({
    margin: "0",
    padding: "0", 
    alignContent: "left", 
    textAlign: "left"
});

const MyAvatar = styled(Avatar) ({
    width: "9.375rem",
    height: "9.375rem"
})

const QuestionNumeration = withStyles((theme) => ({
    root: {
        color: "#606161",
        fontStyle: 'normal',
        fontWeight: '700',
        marginTop: '1rem',
        fontSize: "1rem"
    },
}))(Typography);

const OnboardingBackdrop = withStyles((theme) => ({
    root: {
        zIndex: 1500
    }
}))(Backdrop);

export {
    AddButton,
    CreateButton,
    YellowButton,
    GreenButton,
    DeleteButton,
    BackButton,
    ExportButton,
    MyContainer,
    MyTextField,
    MyTypography,
    LoginCard,
    MyCard,
    MyCardContent,
    MyAvatar,
    GeneralTitle,
    GeneralSubtitle,
    GeneralText,
    QuestionNumeration,
    OnboardingBackdrop
}