import React, { useEffect, useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { optionConfig, defaultConfig, uploadAdapterPlugin } from "./config.jsx";
import OdisseiaEditor from 'ckeditor5-no-math/build/ckeditor';
// import OdisseiaEditor from 'ckeditor-odisseia/build/ckeditor'; 

export default function TextEditor ({ text, setText, optionType, position, initialMessage }) {
    const [defaultMessage, setDefaultMessage] = useState(initialMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => optionType ? setText(position, text) : setText(text), [text]); // Garante atualização do texto capturado do editor
    return (
        <CKEditor
            editor={ OdisseiaEditor }
            name="ckeditor"
            data={ text === '' ? defaultMessage : text }
            onReady={ editor => {
                editor.ui.focusTracker.on('change:isFocused', (evt, name, value) => {
                    value && setDefaultMessage(text);
                });
                uploadAdapterPlugin(editor);
            }}
            config={ optionType ? optionConfig : defaultConfig }
            onError={ err => console.log(err) }
            onChange={(ev, editor) => {
                optionType ? setText(position, editor.getData()) : setText(editor.getData());
            }}
        />
    )
}