import React, {memo} from 'react';
import { GeneralText, MyCard, MyCardContent } from '../../../assets/styles/styledComponents';
import { Grid, Divider } from "@material-ui/core";
import { WirisIframe, GeneralCircular } from "../../../components";

const optionsLetter = ["A)", "B)", "C)", "D)", "E)", "F)", "G)"]

function CardRAbyQuestion(props) {
  const { classes, discursiva, data, tipo, questoes, acertos, erros, media, noAnswer, alternativas, respostas, indice } = props;

  return (
    <>
      {/* Acordeão contendo dados dos alunos */}
      <Grid item={true} xs={12} sm={5}>
        <MyCard className={classes.card}>
          <MyCardContent className={classes.question}>
            <Grid container>
              <Grid item xs={12}>
                <GeneralText className={classes.title}>Informações da Questão</GeneralText>
              </Grid>

              <Grid item xs={12} sm={discursiva ? 12 : (data.respostaAtividade.length === 0 ? 12 : 6)} className={classes.centering}>
                <GeneralText className={classes.subTitle}>{tipo}</GeneralText>

                <div hidden={data.respostaAtividade.length === 0}>
                  <GeneralText className={classes.textStudent}>Nº de Respostas: {data.respostaAtividade.length}</GeneralText>
                  <GeneralText className={classes.textStudent} hidden={discursiva}>Nº de Acertos: {acertos}</GeneralText>
                  <GeneralText className={classes.textStudent} hidden={discursiva}>Nº de Erros: {erros}</GeneralText>
                  <div style={{marginTop: "0.5rem"}}>
                    <GeneralCircular progress={media} />
                  </div>
                  <GeneralText className={classes.textStudent}>Média</GeneralText>
                </div>

                <div hidden={data.respostaAtividade.length > 0}>
                  <GeneralText className={classes.textStudent}>{noAnswer}</GeneralText>
                </div>

              </Grid>
              
              <Grid item xs={12} sm={6} hidden={discursiva || (data.respostaAtividade.length === 0)} className={classes.circularStatic}>
                <GeneralText className={classes.subTitle}>Frequência das Alternativas</GeneralText>
                { 
                  (!discursiva) &&
                    alternativas.map((a, index) => {
                      var count = 0;
                      respostas.forEach(v => v === a._id && count++);
                      var frequencia = Math.round((count*100)/respostas.length);
                      return (
                        <GeneralText 
                          key={a._id} 
                          className={a.gabarito ? classes.correct : classes.textStudent}>
                            {optionsLetter[index]} {count} | {Number.isNaN(frequencia)? 0 : frequencia}%
                        </GeneralText>
                      );
                    })
                }
              </Grid>
            </Grid>
          </MyCardContent>
        </MyCard>
      </Grid>

      {/* Enunciado da questão */}
      <Grid item={true} xs={12} sm={4}>
        <MyCard className={classes.card}>
          <MyCardContent className={classes.question}>
            <h2 className={classes.title}  id="questaoNumeracao">{`Questão ${(indice ? indice : 0)+1}`}</h2>
            <Grid container>
              <Grid item xs={12}>
                <Divider/>
                <WirisIframe text={questoes[indice ? indice : 0].enunciado}/>
              </Grid>
              <Grid item xs={12}>
                {
                  questoes[indice ? indice : 0]?.resposta?.map((x, pos) => {
                    return (
                      <div key={x._id} className={x.gabarito ? classes.correctOption : classes.optionSection}>
                        <Divider/>
                        <Grid key={pos} container={true} justifyContent="center">
                          <Grid item={true} xs={1} sm={1} lg={1}>
                            <p className={classes.optionsLetter}>{optionsLetter[pos]}</p>
                          </Grid>
                          <Grid item={true} xs={11} sm={11} lg={11}>
                            <WirisIframe text={x.opcao} />
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })
                }
              </Grid>
            </Grid>
          </MyCardContent>
        </MyCard>
      </Grid>
    </>
  )
}

export default memo(CardRAbyQuestion);