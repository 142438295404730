import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end"
    },
    content: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1),
        },
        transition: theme
            .transitions
            .create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
        // marginLeft: -240
    },
    contentShift: {
        [theme.breakpoints.up('sm')]: {
            transition: theme
            .transitions
            .create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginLeft: 0,
        }
    },
    footer: {
        float: 'none',
        display: 'block',
        position: 'relative',
        overflow: 'hidden',
        width: '99%',
        padding: '0.5rem 1.875rem',
        borderTop: '1px solid #ddd',
        [theme.breakpoints.down('sm')]: {
            padding: '0.5rem 0.7rem',
        },
    },
    footerText: {
        fontSize: '0.9rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        },
    },
    mainContent: {
        paddingBottom: "1rem"
    }
}));

export default useStyles;