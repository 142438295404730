import React from "react";
import ItemsDrawer from "./ItemsDrawer"
import ProfileMenu from "./ProfileMenu"
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { makeStyles, useTheme, Drawer, IconButton, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 240,
        flexShrink: 0
    },
    drawerPaper: {
        width: 240,
        backgroundColor: theme.palette.secondary.main
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end"
    }
}));

export default function SideBar(props) {
    const {open, setOpen} = props;
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Drawer className={classes.drawer} anchor="left" open={open} onClose={() => setOpen(false)} classes={{ paper: classes.drawerPaper }}>
            <Grid container={true} justifyContent="center">
                <Grid item={true} xs={6} sm={6}> 
                    <ProfileMenu/>
                </Grid>

                <Grid item={true} xs={6} sm={6} className={classes.drawerHeader}>
                    <IconButton onClick={() => setOpen(!open)}>
                        { theme.direction === "ltr"
                            ? (<ChevronLeftIcon/>)
                            : (<ChevronRightIcon/>)
                        }
                    </IconButton>
                </Grid>
            </Grid>
            <ItemsDrawer setOpen={setOpen} />
        </Drawer>
    );
}