import React, { useContext, useEffect, useState } from "react";
import { GeneralTitle, GeneralSubtitle, MyContainer, GeneralText, MyCard } from "../../../assets/styles/styledComponents"
import { Button, Grid } from "@material-ui/core";
import api from '../../../api'
import { StoreContext } from "../../../utils";
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import SchoolIcon from '@material-ui/icons/School';
import useStyle from "./styles";

export default function Classroom () {
  const [classLink, setClassLink] = useState('')
  const [classRoomLink, setClassRoomLink] = useState('')
  const { token } = useContext(StoreContext)
  const turmaID = token.turmaID;
  const classes = useStyle();
  
  useEffect(() => {
    async function fetchClassLinkAPI() {
      await api.encClassLinkPorTurma(turmaID)
      .then(res => {
        const valAulaLink = res.data.data.aulaLink;
        const valClassRoomLink = res.data.data.classRoomLink;
        (valAulaLink.includes("https://")) ? setClassLink(valAulaLink) : setClassLink("https://" + valAulaLink);
        (valClassRoomLink.includes("https://")) ? setClassRoomLink(valClassRoomLink) : setClassRoomLink("https://" + valClassRoomLink);
      })
      .catch(err => console.log(err));
    }
    fetchClassLinkAPI();
  }, [turmaID]);

  return (
    <MyContainer>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} sm={12}>
          <GeneralTitle>Sala de Aula</GeneralTitle>
        </Grid>
      </Grid>

      <MyCard className={classes.root} style={{borderRight: `0.7rem solid #a283bc`}}>
        <Grid container={true} justifyContent="center" spacing={1}>
          <Grid item={true} xs={12} sm={9}>
            <GeneralSubtitle>Bora estudar?</GeneralSubtitle>
            <GeneralText>
              Para acessar ao link da aula ao vivo, é só clicar no botão ao lado e você será redirecionado para a sala de aula.
            </GeneralText>
          </Grid>
          <Grid item={true} xs={12} sm={3} className={classes.button}>
            <Button 
              color="primary" 
              variant="outlined" 
              onClick={() => { window.open(classLink,'_blank') }} 
              startIcon={<SchoolIcon />}>Participar</Button>
          </Grid>
        </Grid>
      </MyCard>

      <MyCard className={classes.root} style={{marginTop: "1rem", borderRight: `0.7rem solid #94c93d`}}>
        <Grid container={true} justifyContent="center" spacing={1}>
          <Grid item={true} xs={12} sm={9}>
            <GeneralSubtitle>Procurando o link para o Classroom da sua Turma?</GeneralSubtitle>
            <GeneralText>
              Para acessar ao link do seu ClassRoom é só clicar no botão ao lado e você será redirecionado.
            </GeneralText>
          </Grid>
          <Grid item={true} xs={12} sm={3} className={classes.button}>
            <Button 
              color="primary" 
              variant="outlined" 
              onClick={() => { window.open(classRoomLink,'_blank') }} 
              startIcon={<LocalLibraryIcon />}>ClassRoom</Button>
          </Grid>
        </Grid>
      </MyCard>
    </MyContainer>
  );
};
