import React, { useEffect, useState } from 'react';
import { GeneralText, MyCard, MyCardContent } from '../../../assets/styles/styledComponents';
import { Grid, Typography, Divider, Button } from "@material-ui/core";
import { WirisIframe, GeneralCircular, GeneralAccordion } from "../..";
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function CardRAbyStudent(props) {
  const { classes, alunoID, corrigido, questoes, respostaQuestaoIDs, notaRedacao, isEssay, correcaoURL } = props;
  const [realizadas, setRealizadas] = useState([]);
  const [nota, setNota] = useState(0);
  const optionsLetter = ["A)", "B)", "C)", "D)", "E)", "F)", "G)"]
  const mostraCorrecaoRedacao = () => window.open(correcaoURL,'_blank')
  const corrigeNota = (nota)=> (Number.isNaN(nota) || nota === undefined) ? 0 : Math.round(nota);

  useEffect(() => {
    var resEncontrada = [];
    var notaFinal = 0;

    if (!isEssay) {
      // Filtra mensagens repetidas ou vazias
      questoes.map(questao => {
        var resQuestao = respostaQuestaoIDs.filter(x => { return x.questaoID === questao._id });
  
        // Caso haja mais de uma resposta para a mesma questão
        if (resQuestao.length) {
          var resNaoVazia = resQuestao.filter(x => { return x.resposta !== "" });
  
          // Caso haja mais de uma resposta não vazia para a questão
          // pega a última resposta enviada pelo aluno
          if (resNaoVazia.length) {
            resQuestao = [];
            var res = resNaoVazia[resNaoVazia.length-1];
            resQuestao.push(res);
            notaFinal += res?.nota === 1 ? 100 : res.nota;
          }
          // Do contrário, salva a única resposta não vazia que houver
          else {
            resQuestao = resQuestao[0];
            if (resQuestao[0]) {
              notaFinal += resQuestao[0]?.nota === 1 ? 100 : resQuestao[0].nota;
            }
            else {
              notaFinal += 0;
            }
          }
        }
  
        // Insere num array auxiliar a resposta encontrada
        return resEncontrada = resEncontrada.length ? resEncontrada.concat(resQuestao) : resQuestao;
      });

      setNota(notaFinal/questoes.length);
    }
    else {
      setNota(notaRedacao);
    }

    // Define respostas realizadas
    setRealizadas(resEncontrada.length ? resEncontrada : respostaQuestaoIDs)

  },[isEssay, notaRedacao, questoes, respostaQuestaoIDs])

  function RetornaRespostaQuestao() {
    return questoes.map((questao, index) => {
      // Encontra a resposta que o aluno deu à questão
      var respostaAtividade = (isEssay) ? false : realizadas.filter(rq => {return rq.questaoID === questao._id})[0];

      // Tenta verificar se a resposta dada pelo aluno é a resposta correta
      var encontraResposta = (isEssay) 
        ? true 
        : ((respostaAtividade) 
          ? questao.resposta.filter(x => { return x._id === respostaAtividade.resposta })[0]?.gabarito 
          : false);

      // Define título do acordeão da questão
      var text = `Questão ${questao.index+1}`;

      // Se resposta do aluno for impossível
      if (questao.tipoResposta !== 'discursiva' && encontraResposta === undefined)  text += " - Sem resposta";

      // Se resposta do aluno for vazia ou não estiver corrigida
      else if (respostaAtividade?.nota !== undefined) {
        var status = (respostaAtividade.resposta === "") ? " - Sem resposta" : " - Não corrigida"; // Verifica se resposta é vazia
        text += respostaAtividade.corrigido ? ` - Nota: ${respostaAtividade.nota}` : status;
      }

      // Define título e status da questão
      var tituloAcordeao = (isEssay) ? text : ((respostaAtividade) ? text : `Questão ${questao.index+1} - Não realizado`);

      return (
        <GeneralAccordion 
          key={index}
          noClass={true}
          accordionSummary={
            <Typography className={!encontraResposta ? classes.incorrect : ""}>{tituloAcordeao}</Typography>
          }
          accordionDetails={
            <Grid container>
              <Grid item xs={12}>
                <Divider/>
                <WirisIframe text={questao.enunciado}/>
              </Grid>
              <Grid item hidden={isEssay} xs={12}>
                {
                  !isEssay && questao.resposta.map((res, pos) => {
                    var selecionado = (respostaAtividade) ? respostaAtividade.resposta === res._id : false;
                    return (
                      <div key={res._id} className={res.gabarito ? classes.correctOption : (selecionado ? classes.wrongAnswer : classes.optionSection)}>
                        <Divider/>
                        <Grid key={index} container={true} justifyContent="center">
                          <Grid item={true} xs={1} sm={1} lg={1}>
                            <p className={classes.optionsLetter}>{optionsLetter[pos]}</p>
                          </Grid>
                          <Grid item={true} xs={11} sm={11} lg={11}>
                            <WirisIframe text={res.opcao} />
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })
                }
              </Grid>
            </Grid>
          }
        />
      );
    })
  }

  return (
    <>
      <Grid item xs={12} sm={5}>
        <MyCard className={classes.cardStudent}>
          <MyCardContent className={classes.question}>
            <Grid container>
              <Grid item xs={12}>
                <GeneralText className={classes.title}>Informações do Aluno</GeneralText>
              </Grid>
              <Grid item xs={12} sm={8}>
                <GeneralText className={classes.subTitle}>{alunoID.nome}</GeneralText>
                <GeneralText className={classes.textStudent}>Nota da Atividade: {corrigeNota(nota)}</GeneralText>
                <GeneralText className={corrigido ? classes.textStudent : classes.toDo}>Status da Correção: {corrigido ? "Finalizada" : "Em andamento"}</GeneralText>
                <GeneralText className={classes.textStudent} hidden={isEssay}>Questões Realizadas: {realizadas.length} / {questoes.length}</GeneralText>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.circularStatic}>
                <GeneralCircular progress={nota} />
                <GeneralText className={classes.textStudent}>Média</GeneralText>
                <div style={{padding: "1rem", display: (correcaoURL === "" || correcaoURL === undefined) ? "none" : "block"}}>
                  <Button 
                    fullWidth
                    variant="outlined"
                    color="primary"
                    hidden={correcaoURL === ""} 
                    startIcon={<VisibilityIcon />}
                    onClick={mostraCorrecaoRedacao}>
                    Correção
                  </Button>
                </div>
              </Grid>
            </Grid>
          </MyCardContent>
        </MyCard>
      </Grid>
        
      <Grid item xs={12} sm={4} className={classes.card}>
        <RetornaRespostaQuestao />
      </Grid>
    </>
  )
}