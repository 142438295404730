import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../utils"
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Slide, Button, Dialog, DialogContent, Fade } from '@material-ui/core';
import Dashboard from '@material-ui/icons/Dashboard';
import StudyPlan from '@material-ui/icons/LibraryBooks';

import * as studentBooks from "../../assets/animations/studentBooks.json";
import Lottie from "react-lottie";
import { GeneralText, GeneralTitle } from '../../assets/styles/styledComponents';
import api from '../../api';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: studentBooks.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

// -- Local Styles
const useStyles = makeStyles(() => ({
    button: {
        textAlign: "center"
    },
    card: {
        maxWidth: "18.75rem",
        paddingBottom: "1.5rem"
    },
    onboardingCard: {
        maxWidth: "25rem",
        paddingBottom: "1.5rem"
    },
    title: {
        padding: "1rem 0 0 1rem",
        marginBottom: "-0.3rem",
        textAlign: "center"
    },
    text: {
        textAlign: "center",
        fontSize: "1.2rem",
        padding: "0.5rem 0"
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const userName = (nomeAluno) => {
    var name = nomeAluno.split(' ').map(name => {
        return name;
    })
    return name[0];
}

export default withRouter(function SlideDialog(props) {
    const classes = useStyles();
    const {history} = props;
    const {token, setToken} = useContext(StoreContext);
    const nomeAluno = userName(token.userName);
    const parent = token.parent;
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (parent) {
            history.push("/dashboard");
        } else {
            setTimeout(() => {
                setIsLoaded(true);
            }, 400);
        }
    }, [history, parent]);

    const skipTutorial = () => {
        setToken((prevValue) => ({
            ...prevValue,
            firstTime: false
        }));
        api.concluirTutorial(token.userID);
        history.push("/dashboard");
    }
    
    const startTutorial = () => {
        document.getElementById("drawerButton").click();
        setInterval(history.push("/dashboard"), 2000);
    }

    if (token.firstTime) {
        return (
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted={true}>
                <GeneralTitle className={classes.title}>{`Olá, ${nomeAluno}!`}</GeneralTitle>
                <DialogContent className={classes.onboardingCard}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                            {
                                isLoaded &&
                                <Fade in={isLoaded} style={{transitionDelay: "300ms"}}>
                                    <Lottie options={defaultOptions} height={160} width={160} isStopped={!isLoaded} isPaused={!isLoaded}/>
                                </Fade>
                            }
                        </Grid>

                        <Grid item xs={12} alignItems="center">
                            <GeneralText className={classes.text}>
                                Nós sabemos o quão difícil pode ser a vida de vestibulando. É MUITA coisa para estudar, o tempo passa correndo e a sensação de que tem sempre algo pendente é constante.
                                <br/>
                                Pensando nisso, criamos esse ambiente para que você saiba exatamente o que precisa estudar diariamente para cumprir toda a jornada de preparação rumo ao ENEM sem precisar se desesperar.
                            </GeneralText>
                        </Grid>
                        
                        <Grid item className={classes.button} xs={6}>
                            <Button variant="contained" fullWidth onClick={skipTutorial} color="secondary">
                                Pular
                            </Button>
                        </Grid>
                        <Grid item className={classes.button} xs={6}>
                            <Button variant="contained" fullWidth onClick={startTutorial} color="primary">
                                Fazer Tour
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted={true}>
            <GeneralTitle className={classes.title}>{`Olá, ${nomeAluno}!`}</GeneralTitle>
            <DialogContent className={classes.card}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} style={{height: 160, width: 160}}>
                        {
                            isLoaded &&
                            <Fade in={isLoaded} style={{transitionDelay: "300ms"}}>
                                <Lottie options={defaultOptions} height={160} width={160} isStopped={!isLoaded} isPaused={!isLoaded}/>
                            </Fade>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <GeneralText className={classes.text}>Por onde gostaria de começar hoje?</GeneralText>
                    </Grid>
                    
                    <Grid item className={classes.button} xs={6}>
                        <Button variant="contained" onClick={() => history.push("/plano-estudo")} color="primary" startIcon={<StudyPlan />}>
                            Estudar
                        </Button>
                    </Grid>
                    <Grid item className={classes.button} xs={6}>
                        <Button variant="contained" onClick={() => history.push("/dashboard")} color="primary" startIcon={<Dashboard />}>
                            Navegar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
});
