import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import api from '../../api';
import { SimpleSnackMessage, ContentForm } from "../../components";
import validate from "../../components/Form/Validation/FormValidateContent";
import { StoreContext } from "../../utils";

function initialState(props) {
  return {
    id: props.match.params.id,
    disciplinaID: "", 
    turmaID: "", 
    topico: "",
    classificacao: "",
    numeracao: "",
    videoAulaURL:  "",
    videoAulaExtraURL: '',
    autor: "",
    conteudo: {
      file: {},
      url: ''
    },
    conteudoURL: "",
    erros: []
  }
}

export default function ContentUpdate(props) {  
  // -- Define principais constantes
  const {token} = useContext(StoreContext);
  const [conteudo, setConteudo] = useState("");
  const [material, setMaterial] = useState(initialState(props));
  const [message, setMessage] = useState("Houve um erro com a atualização do conteúdo, verifique se todas as informações estão corretas. Se o erro persistir, informe à equipe técnica.");
  const [noError, setNoError] = useState(true);
  const [open, setOpen] = useState(false);
  
  // -- Carrega Conteúdo do Banco
  useEffect( () => {
    const abortController = new AbortController();
    async function fetchConteudoAPI() {
      const response = await api.encConteudoPorID(material.id);
      const value = response.data.data;

      setMaterial(preValue => ({ 
        ...preValue,
        area: value.disciplinaID.areaConhecimento,
        disciplinaID: value.disciplinaID._id,
        turmaID: value.disciplinaID.turmaID,
        numeracao: value.numeracao, 
        videoAulaURL: value.videoAulaURL, 
        videoAulaExtraURL: value.videoAulaExtraURL, 
        topico: value.topico,
        classificacao: value.classificacao,
        autor: value.autor,
        conteudoURL: value.conteudoURL
      }));

      setConteudo({
        file: false,
        url: value.conteudoURL
      })
    }
    fetchConteudoAPI();
    return abortController.abort();
  // eslint-disable-next-line
  }, []);

  const onSubmit = async event => {
    const { autor, disciplinaID, topico, classificacao, numeracao, videoAulaURL, videoAulaExtraURL, conteudoURL, turmaID } = material;
    const error = validate(material);

    setMaterial(preValue => ({
      ...preValue,
      erros: error
    }))

    if (error.validated) {
      var conteudoAtualizado = {
        autor,
        disciplinaID, 
        turmaID,
        topico, 
        classificacao, 
        numeracao,
        videoAulaURL,
        videoAulaExtraURL,
        conteudoURL,
        dataModificacao: new Date(),
        autorModificacao: token.userID,
      };
      
      // Verifica se o usuário subiu algum conteúdo pdf
      if (conteudo.file) {
        // Salva o pdf na pasta local
        const formData = new FormData();
        formData.append("conteudo", conteudo.file);
        
        await Axios.post(`${process.env.REACT_APP_API_URL}upload-conteudo/${material.id}`, formData)
        .then(res => {
          conteudoAtualizado.conteudoURL = res.data.url
        })
        .catch(err => {
          console.log(err);
        });
      }

      // Guarda novo usuário no banco
      await api
        .atualizarConteudo(material.id, conteudoAtualizado)
        .then(res => {
          setMessage(res.data.message);
        })
        .catch(err => {
          setNoError(false);
        });
        
      setOpen(true);
    }
  }
  
  return (
    <div>
      <ContentForm 
        material={material}
        setMaterial={setMaterial}
        conteudo={conteudo}
        setConteudo={setConteudo}
        onSubmit={onSubmit}
        titulo="Atualizar Conteúdo"
      />

      <SimpleSnackMessage 
        open={open}
        setOpen={setOpen}
        message={message}
        error={noError}
      />
    </div>
  );
};
