import React, { memo, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { CustomButton } from '../Accordions/Utils/Buttons';
import * as books from "../../assets/animations/books.json";
import Lottie from "react-lottie";
import { Fade } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: books.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ContentLinkDialog(props) {
    const { classes, progresso, progressoTopico, setProgresso, open, setOpen, check, setCheck, setWasChecked, materialURL } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    const history = useHistory();

    // Põe um delay inicial na animação
    useEffect(() => {
        setTimeout(() => setIsLoaded(true), 600);
    },[]);

    // Encerra o diálogo
    const handleClose = () => {
        setOpen(x => ({ ...x, materialEstudo: false }));
    };

    // Marca conteúdo como finalizado / concluído
    const handleFinalized = () => {
        if (!check.materialEstudo) {
            setCheck(preValue => ({
                ...preValue,
                materialEstudo: true
            }));
            setProgresso(progresso + 1);
            setWasChecked(true);
        }
        handleClose();
    };

    // Redireciona aluno para página de leitura
    const handleClick = () => {
        history.push({
            pathname: '/material-estudo',
            state: { 
                materialURL: materialURL,
                progressoTopico: progressoTopico,
                check: check
            }
        })
    }

    return (
        <Dialog
            open={open.materialEstudo}
            TransitionComponent={Transition}
            keepMounted={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className={classes.iconTitle}>
                <div style={{height: "160px", width: "160px"}}>
                {
                    isLoaded &&
                    <Fade in={isLoaded} style={{transitionDelay: "100ms"}}>
                        <Lottie options={defaultOptions} height={160} width={160} isStopped={!isLoaded} isPaused={!isLoaded}/>
                    </Fade>
                }  
                </div>
            </DialogTitle>

            <DialogContent>
                <CustomButton 
                    id="materialEstudo"
                    check={false}
                    classe={classes.videoButton}
                    handleClick={handleClick}
                    title="Visualizar Material"
                />
            </DialogContent>
            <DialogActions>
                <Button className={classes.visualizedButton} id="materialEstudo" variant='text' color="primary" onClick={handleFinalized}>
                    Visto
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default memo(ContentLinkDialog);