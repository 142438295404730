import React, { useState, useEffect, useContext } from "react";
import { makeStyles, useMediaQuery, useTheme, Grid, MenuItem, Tooltip, Fab, Fade } from "@material-ui/core";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { GeneralSubtitle, GeneralText, GeneralTitle, MyContainer, MyTextField } from "../../assets/styles/styledComponents"
import { ContentAccordion, AccordionSkeleton } from "../../components";
import { currentWeek, StoreContext } from "../../utils";
import api from '../../api';

const useStyles = makeStyles(() => ({
  filterText: {
    margin: "0", 
    alignContent: "flex-end",
    alignSelf: "center",
  },
  filterFab: {
    display: "flex",
    justifyContent: "flex-end",
  },
  smFilterFab: {
    marginTop: "0.5rem",
  },
  smLeftFilterFab: {
    display: "flex",
    justifyContent: "flex-end",
  },
  smRightFilterFab: {
    display: "flex",
    justifyContent: "flex-start",
  },
  libraryMain: {
    marginTop: "1rem",
  }, 
  notFoundMsg1: {
    fontSize: '1.5rem',
  },
  notFoundMsg2: {
    fontSize: '1rem',
  }
}));

export default function Library (props) {
  const classes = useStyles();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { token } = useContext(StoreContext)
  const [filter, setFilter] = useState({ topico: 0, disciplina: 0, numeracao: 0 });
  const [listaDisciplina, setListaDisciplina] = useState([]);
  const [listaConteudo, setListaConteudo] = useState([]);
  const [numSemana, setNumSemana] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [clearBtn, setClearBtn] = useState(false);
  const turmaID = token.turmaID;
  
  // -- Carrega Disciplinas/Número de Semanas
  useEffect(() => {
    const ctrlAbort = new AbortController();
    // Preenche constante de numeração de acordo com o número de semanas do ano letivo
    async function fetchSemanaAtual() {
      try {
        const numSemanas = await currentWeek(turmaID);
        var array = [];
        for (let i = 1; i <= numSemanas; ++i) { array.push(i) }
        setNumSemana(array);
        setFilter(preValue => ({
          ...preValue,
          numeracao: numSemanas
        }));
      } catch (error) { }
    }
  
    // Lista Disciplina
    async function fetchDisciplinaAPI() {
      try {
        const response = await api.listarDisciplinasPorTurma(turmaID);
        setListaDisciplina(response.data.data);
      } catch (error) { 
        console.log(error);
      }
    }
    !isLoaded && fetchSemanaAtual();
    !isLoaded && fetchDisciplinaAPI();
    return ctrlAbort.abort();
  }, [isLoaded, turmaID]);

  // -- Carrega Conteúdo de acordo com filtro ou semana atual
  useEffect(() => {
    const ctrlAbort = new AbortController();
    // Lista Conteúdo
    async function fetchConteudoAPI() {
      const { numeracao, disciplina, topico } = filter;
      const checkFilter = numeracao + disciplina + topico;
      // Verifica se filtro foi alterado
      if (!checkFilter) {
        setListaConteudo([]);
      } else {
        setClearBtn(true);
        try {
          var params = {
            topicoID: topico,
            turmaID: turmaID,
            disciplinaID: disciplina,
            numeracao: numeracao
          }
          const response = await api.listarConteudoPorFiltro(params);
          setListaConteudo(response.data.data);
        } catch (error) { 
          setListaConteudo([]);
        }
        setFilterChanged(false);
        setTimeout(() => setIsLoaded(true), 1500);
        clearTimeout();
      }
    }
    if (!isLoaded || filterChanged) fetchConteudoAPI();
    return ctrlAbort.abort();
  }, [filter, filterChanged, isLoaded, turmaID])

  // -- Ao alterar qualquer valor do filtro
  function onFilterChange (event) {
    const { name, value } = event.target;
    setFilterChanged(true);
    setFilter (preValue => ({
      ...preValue,
      [name]: value
    }))
    setListaConteudo([]);
  }

  // -- Limpa o filtro
  function clearFilter() {
    setFilterChanged(false);
    setClearBtn(false);
    setFilter({ topico: 0, disciplina: 0, numeracao: 0 });
    setListaConteudo([]);
  }

  return (
    <MyContainer id="studentPageContainer">

      <section id="libraryHeader">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={12}>
            <GeneralTitle id="libraryTitle">Biblioteca</GeneralTitle>
          </Grid>

          <Grid item={true} xs={12} sm={12}>
            <GeneralSubtitle id="librarySubTitle">Conteúdo Disponível</GeneralSubtitle>
          </Grid>
        </Grid>
      </section>

      <section id="libraryFilter">
          <Grid container={true} style={{marginTop: "1rem"}} justifyContent={"center"}>
            <Grid item={true} xs={12} sm={clearBtn ? 11 : 12}>
              <Grid container={true} justifyContent="flex-start" spacing={1}>

                <Grid item={true} xs={12} lg={4} sm={4}>
                  <Fade in={true} style={{transitionDelay: '250ms'}}>
                    <MyTextField
                      variant="outlined"
                      label="Tópico"
                      name="topico"
                      className={classes.filterText}
                      value={filter.topico ? filter.topico : ''}
                      onChange={onFilterChange}/>
                  </Fade>
                </Grid>
                
                <Grid item={true} xs={12} lg={4} sm={4}>
                  <Fade in={true} style={{transitionDelay: '250ms'}}>
                    <MyTextField
                      variant="outlined"
                      select={true}
                      label="Disciplina"
                      name="disciplina"
                      disabled={listaDisciplina.length === 0}
                      className={classes.filterText}
                      value={filter.disciplina ? filter.disciplina : ''}
                      onChange={onFilterChange}>
                      {
                        listaDisciplina.map((row, index) => {
                          return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
                        })
                      }
                    </MyTextField>
                  </Fade>
                </Grid>
                
                <Grid item={true} xs={12} lg={4} sm={4}>
                  <Fade in={true} style={{transitionDelay: '250ms'}}>
                    <MyTextField
                      id="filtroNumeracao"
                      select={true}
                      label="Semana"
                      variant="outlined"
                      name="numeracao"
                      type="text"
                      className={classes.filter}
                      value={filter.numeracao ? filter.numeracao : ''}
                      onChange={onFilterChange}>
                        {
                          numSemana.map((row, index) => {
                            return <MenuItem key={index} value={row}>{row}</MenuItem>
                          })
                        }
                    </MyTextField>
                  </Fade>
                </Grid>
                
              </Grid>
            </Grid>

            <Grid item={true} xs={12} sm={clearBtn ? 1 : false} className={smScreen ? classes.smFilterFab : 'none'} hidden={!clearBtn} align="center">
              <Fade
                in={clearBtn}
                style={{ transformOrigin: '0 0 0' }}
                {...({ timeout: 1000 })}>
                  <Tooltip title="Limpar" hidden={!clearBtn} aria-label="clear">
                    <Fab color="secondary" hidden={!clearBtn} justify="flex-end" onClick={() => clearFilter()}>
                      <ClearAllIcon />
                    </Fab>
                  </Tooltip>
              </Fade>
            </Grid>

          </Grid>
      </section>

      <section id="libraryMain">
        <Grid container={true} className={classes.libraryMain}>
          {
            (isLoaded) 
            ? 
              (!listaConteudo.length) 
                ?
                  <Grid item={true}>
                    <Fade in={true} style={{transitionDelay: '250ms'}}>
                      <GeneralText className={classes.notFoundMsg1}>Nenhum conteúdo encontrado.</GeneralText>
                    </Fade>
                    <Fade in={true} style={{transitionDelay: '250ms'}}>
                      <GeneralText className={classes.notFoundMsg2}>Tente reajustar o filtro acima para mais conteúdos.</GeneralText>
                    </Fade>
                  </Grid>
                : 
                  listaConteudo.map((row, index) => {
                    const { _id, topico, disciplinaID, numeracao, videoAulaURL, videoAulaExtraURL, conteudoURL } = row;
                    return (
                      <Grid key={index} item={true} xs={12} lg={12} sm={12}>
                        <ContentAccordion 
                          topicoID={_id} 
                          disciplinaNome={topico} 
                          titulo={disciplinaID.nome} 
                          semana={numeracao}
                          tipoAcordeao="biblioteca"
                          filterChanged={filterChanged}
                          linkAula={videoAulaURL}
                          linkAulaOpcional={videoAulaExtraURL}
                          materialURL={conteudoURL}/>
                      </Grid>
                    )
                  })
            : 
              <Fade in={true} style={{transitionDelay: '50ms'}}>{ AccordionSkeleton(1) }</Fade>
          }
        </Grid>
      </section>
    </MyContainer>
  );
};
