import React, { memo } from 'react';
import { ExerciseDialog,  PDFViewer,  SimpleFeedback, UploadEssay } from '../..';
import { CustomTopicButton } from './Buttons';
import { Grid, Typography } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FullScreenDialog from '../../Dialogs/FullScreenDialog';

function ReturnEssays(props) {
    const { 
        check, setCheck, progresso, setProgresso, setWasChecked, essay, alunoID, 
        classes, open, setOpen, topicProgress, setRedacaoURL, materialURL,
        feedMsg, setFeedMsg, feedOpen, setFeedOpen, redacaoURL, plano, setFeatureOpen
    } = props;

    const uploadLink = `${process.env.REACT_APP_API_URL}upload-redacao/${alunoID}/${essay._id}`;

    // Função de abertura dos diálogos
    const handleClickOpen = (name) => {
        setOpen(x => ({ ...x, [name]: true }));
    };

    // Encerra o diálogo de visualização 
    const handleCloseContentDialog = () => {
        setOpen(preValue => ({ 
            ...preValue, 
            'materialEstudo': false 
        }));
    }

    return (
        <>
            <Grid item={true} align='center' xs={12} sm={plano === "Free" ? 4 : 3}>
                <Typography id="secondaryHeading" className={classes.secondaryHeading}>
                    Não se esqueça de ler com atenção o tema e a proposta da redacão.
                </Typography>
            </Grid>

            {/* Material de Estudo */}
            <Grid item={true} align='right' xs={12} sm={plano === "Free" ? 4 : 3}>
                <CustomTopicButton 
                    id="materialEstudo" 
                    icon={<SchoolIcon />}
                    check={check.redacao}
                    classes={classes}
                    handleClick={handleClickOpen}
                    title1="Tema"
                    title2="Tema"
                />

                <FullScreenDialog 
                    dialogContent={<PDFViewer height={70} source={materialURL}/>}
                    open={open.materialEstudo}
                    handleClose={handleCloseContentDialog}
                />
            </Grid>

            {/* Visualizar Enunciado */}
            <Grid item={true} align='right' xs={12} sm={plano === "Free" ? 4 : 3}>
                <CustomTopicButton 
                    id="redacao" 
                    icon={<VisibilityIcon />}
                    check={check.redacao}
                    classes={classes}
                    handleClick={handleClickOpen}
                    title1="Proposta"
                    title2="Proposta"
                />
                <ExerciseDialog 
                    activity={essay}
                    open={open.redacao ? open.redacao : false}
                    setOpen={setOpen}
                    setCheck={setCheck}
                    title="Redação"
                    name="redacao"
                    progresso={progresso}
                    setProgresso={setProgresso}
                    setWasChecked={setWasChecked}
                    answered={topicProgress}
                />
            </Grid>

            {/* Subir Redação */}
            {
                plano !== "Free" &&
                <Grid item={true} align='right' xs={12} sm={3}>
                    <UploadEssay 
                        uploadLink={uploadLink} 
                        checked={check.redacao}
                        correction={false}
                        setFeedMsg={setFeedMsg}
                        setFeedOpen={setFeedOpen}
                        setCheck={setCheck}
                        progresso={progresso}
                        setProgresso={setProgresso}
                        setWasChecked={setWasChecked}
                        setRedacaoURL={setRedacaoURL}
                        primaryTitle="Subir Redação" 
                        secondaryTitle="Reenviar Redação" 
                        plano={plano}
                        setFeatureOpen={setFeatureOpen}/>
                        
                    <SimpleFeedback
                        redacaoURL={redacaoURL}
                        open={feedOpen}
                        setOpen={setFeedOpen}
                        title={feedMsg.title}
                        message={feedMsg.message}/>
                </Grid>
            }
        </>
    )
}

export default memo(ReturnEssays);