import {useEffect} from "react";
import api from "../../api";

export default function GeneralTimer(props) {
    const { alunoID, away, setAway, onBlur, setOnBlur } = props;

    const incrementaContador = async () => {
        if(localStorage.getItem("TimestampGeral")) {
            const timestampAtual = new Date();
            const timestampGeral = new Date(JSON.parse(localStorage.getItem("TimestampGeral")));

            let incremento = Math.ceil((timestampAtual.getTime() - timestampGeral.getTime())/1000);
            if(localStorage.getItem("Contador")) {
                incremento += JSON.parse(localStorage.getItem("Contador"));
            }
            localStorage.setItem("Contador", JSON.stringify(incremento));
            localStorage.removeItem("TimestampGeral");
            setAway(true);
        }
    };

    useEffect(() => {
        if(!localStorage.getItem("TimestampGeral")) 
            localStorage.setItem("TimestampGeral", JSON.stringify(new Date().toString()));
        // eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        if(onBlur && !away) {
            const intervalAwayId = setInterval(incrementaContador, 300000);
            return () => clearInterval(intervalAwayId);
        }
        // eslint-disable-next-line
    },[onBlur, away]);

    window.addEventListener("blur", () => {
        !onBlur && setOnBlur(true);
    });

    window.addEventListener("focus", () => {
        onBlur && setOnBlur(false);
    });

    window.addEventListener("beforeunload", async () => {
        if(localStorage.getItem("TimestampGeral")) {
            const timestampAtual = new Date();
            const timestampGeral = new Date(JSON.parse(localStorage.getItem("TimestampGeral")));
    
            let incremento = Math.ceil((timestampAtual.getTime() - timestampGeral.getTime())/1000);
            if(localStorage.getItem("Contador")) 
                incremento += JSON.parse(localStorage.getItem("Contador"));
            
            localStorage.removeItem("TimestampGeral");
            localStorage.removeItem("Contador");
            await api.atualizarContador(alunoID, {contador: incremento});
        }
    });
    
    return null;
}