import React from 'react'
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Slide, Dialog, DialogContent, Button, Grid, DialogActions } from '@material-ui/core';

import { GeneralTitle } from '../../assets/styles/styledComponents';
import { PhotoCarousel } from '..';

// -- Local Styles
const useStyles = makeStyles(() => ({
    button: {
        margin: 0,
        padding: 0,
        position: "relative",
        textAlign: "center"
    },
    card: {
        maxWidth: "40rem",
        paddingBottom: "1.5rem"
    },
    foto: {
        maxHeight: '100%',
        maxWidth: '100%'
    },
    title: {
        padding: "1rem 0 0 1rem",
        marginBottom: "-0.3rem",
        textAlign: "center"
    },
    text: {
        textAlign: "center",
        fontSize: "1.2rem",
        padding: "0.5rem 0"
    },
    swipeableViews: {
        margin: 0,
        padding: 0,
        border: 0
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default withRouter(function PhotoDialog(props) {
    const classes = useStyles();
    const { fotos, open, setOpen } = props;

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}
            keepMounted={true}>
            <GeneralTitle className={classes.title}>{ fotos.length > 1 ? "Anexos" : "Anexo"}</GeneralTitle>
            
            <DialogContent className={classes.card}>
                <Grid>
                    <PhotoCarousel className={classes.swipeableViews} fotos={fotos}/>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button className={classes.button} onClick={() => setOpen(false)} color="primary">
                    Voltar
                </Button>
            </DialogActions>
        </Dialog>
    );
});
