//#region Importações Iniciais
import React from "react";
import { Dialog, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
//#endregion

//#region Componentes e Estilos
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    alignContent: "flex-end",
  },
}))(MuiDialogActions);
//#endregion

export default function GeneralDialog({setOpen, open, dialogTitle, dialogContent, dialogActions, noClose, maxWidth, rest}) {
  return (
    <Dialog 
      {...rest}
      onClose={() => !noClose && setOpen(false)} // noClose: caso não queira encerrar o diálogo ao clicar fora do mesmo
      aria-labelledby="customized-dialog-title" 
      fullWidth={true} 
      maxWidth={maxWidth ? maxWidth : 'md'} 
      open={open}>
      {
        dialogTitle &&
          <DialogTitle id="customized-dialog-title">
            { dialogTitle }
          </DialogTitle>
      }
      {
        dialogContent &&
          <DialogContent dividers>
            { dialogContent }
          </DialogContent>
      }
      {
        dialogActions &&
          <DialogActions>
            { dialogActions }
          </DialogActions>
      }
    </Dialog>
  );
}