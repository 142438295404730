import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, makeStyles } from '@material-ui/core';

// -- Estilos locais
const useStyles = makeStyles(() => ({
  generalCard: {
    height: "20rem",
    width: "95%"
  }
}));

export default function CardSkeleton(num) {

  const classes = useStyles();

  const RetSkeletons = () => {
    var array = [];
    for (let index = 0; index < num; index++) {
      array.push( 
        <Grid item={true} xs={12} sm={3}>
          <Skeleton className={classes.generalCard} animation="wave" height="20 rem"/> 
        </Grid>
      )
    }
    return array;
  }
  return (
    <Grid container >
      <RetSkeletons />
    </Grid>
  )
}