import React, { memo } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useStyles } from './styles';
import "./RadioStyles.css";
import { green } from '@material-ui/core/colors';

function RadioAnswer(props) {
	const { answered, gabarito, questaoID, resposta, respostaQuestao, setRespostaQuestao } = props;
	const classes = useStyles();

	const handleChange = (event) => {
		const { value } = event.target;
		setRespostaQuestao(preValue => ({
			...preValue,
			resposta: value,
			nota: gabarito === value ? 100 : 0
		}));
	};

	return (
		<>
			<FormControl className={classes.formControl} component="fieldset">
				<RadioGroup
					aria-label="respostas"
					value={respostaQuestao && respostaQuestao.questaoID === questaoID && respostaQuestao.resposta}
					name="respostas"
					onChange={handleChange}>
					{
						resposta.map((row, index) => {
							return (
								<FormControlLabel
									key={index}
									value={row._id}
									control={
										<Radio
											id={row._id}
											color="primary"
											className={
												respostaQuestao?.questaoID === questaoID && (answered || respostaQuestao.corrigido) 
													? row._id === gabarito 
														? "gabarito" 
														:  row._id === respostaQuestao?.resposta 
															? "respostaErrada" 
															: ""
													: ""
											}
											disabled={answered}
										/>
									}
									style={{ margin: 0 }}
									label={
										<div
											id="mostrarEnunciadoOpcao"
											// className={
											// 	respostaQuestao?.questaoID === questaoID && (answered || respostaQuestao.corrigido) 
											// 		? row._id === gabarito 
											// 			? "ck-content gabarito" 
											// 			:  row._id === respostaQuestao?.resposta 
											// 				? "ck-content respostaErrada" 
											// 				: "ck-content"
											// 		: "ck-content"
											// }
											className={
												(answered && row._id === gabarito)
													? 'ck-content gabarito'
													: (answered && (row._id === respostaQuestao?.resposta))
														? 'ck-content respostaErrada'
														: 'ck-content'
											}
											color={(answered && row._id === gabarito) ? green[500] : 'default'}
											dangerouslySetInnerHTML={{ __html: row.opcao }}
										/>
									}
								/>
							)
						})
					}
				</RadioGroup>
			</FormControl>
		</>
	);
}

export default memo(RadioAnswer);