import React, { useState, useEffect, useContext } from 'react';
import { Link } from '../../../node_modules/react-router-dom'

import { MyAvatar, MyTextField, MyContainer, GeneralTitle, GeneralText } from "../../assets/styles/styledComponents"
import { MenuItem, Grid, Fab, makeStyles, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

// Upload da Imagem do Perfil
import { UploadButton } from "../";
import "./Styles/styleUserForm.css";
import api from '../../api';
import { StoreContext } from '../../utils'

const useStyles = makeStyles((theme) => ({
    fabButton: {
        marginTop: '0.4rem', 
        marginLeft: "1.2rem",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0.8rem",
        }
    },
    errorMessage: {
        fontSize: "0.75rem",
        paddingLeft: "1rem",
        color: "#f44336"
    }
}));

export default function UserForm (props) {
    const { data, setUsuario, onSubmit, typeForm, edit, profDisciplinas, setProfDisciplinas, turmas, setTurmas } = props;
    const { nome, email, acesso, senha, novaSenha1, novaSenha2, erros, url, alunoID, plano } = data;
    const [listaDisciplina, setListaDisciplina] = useState([]);
    const [listaTurma, setListaTurma] = useState([]);
    const [listaAluno, setListaAluno] = useState([]);
    const [disciplinas, setDisciplinas] = useState([]);
    const [subjectLoading, setSubjectLoading] = useState(true);
    const [comDisciplinas, setComDisciplinas] = useState(true);
    const [semTurmas, setSemTurmas] = useState(true);
    const classes = useStyles();

    const {token} = useContext(StoreContext);
    const adminUser = token.accessType === "Administrador" ? true : false;
    
    // #region HandleChanges
    // Guarda o dado vindo do input
    const handleChange = (event) => {
        const {name, value} = event.target;
        setUsuario(preValue => ({
            ...preValue,
            [name]: value
        }));


        if (name === "alunoID") {
            var aluno = listaAluno.filter(x => {return x._id === value});

            setUsuario(preValue => ({
                ...preValue,
                turma: [{ turmaID: aluno[0].turma[0].turmaID }]
            }));
        }

        // Caso não seja professor ou administrador, limpa campo de disciplinas
        if (name === "acesso" && (value !== "Professor" || value !== "Administrador")) {
            setProfDisciplinas([{ disciplinaID: '' }]);
            setUsuario(preValue => ({
                ...preValue,
                disciplina: []
            }));
        }
    }

    // Guarda o dado vindo do upload da imagem de perfil
    const handleUpload = async (event) => {
        const file = await event.target.files[0];
        setUsuario(preValue => ({
            ...preValue,
            url: URL.createObjectURL(file),
            foto: file
        }));
    }
    // #endregion

    // #region Turma
    // -- Faz alterações em turmas
    const handleClassChange = (position, value) => {
        const updatedClassItems = turmas.map((item, index) => {
            if (index === position)
                return { ...item, turmaID: value };
            return item;
        });
        setTurmas(updatedClassItems);
    }

    // -- Adiciona novo campo para disciplina
    const addNewClass = () => {
        setTurmas([
            ...turmas,
            { turmaID: '', }
        ]);
    }

    // -- Remover disciplina
    const deleteThisClass = (event, position) => {
        setTurmas(turmas.filter((value, index) => {
            return index !== position;
        }));
    }
    // #endregion

    // #region Disciplina
    // -- Faz alterações em disciplina do professor
    const handleSubjectChange = (position, value) => {
        const updatedSubjectItems = profDisciplinas.map((item, index) => {
            if (index === position)
                return { ...item, disciplinaID: value };
            return item;
        });
        setProfDisciplinas(updatedSubjectItems);
    }

    // -- Adiciona novo campo para disciplina
    const addNewSubject = () => {
        setProfDisciplinas([
          ...profDisciplinas,
          { disciplinaID: '', }
        ]);
    }

    // -- Remover disciplina
    const deleteThisSubject = (event, position) => {
        setProfDisciplinas(profDisciplinas.filter((value, index) => {
            return index !== position;
        }));
    }
    // #endregion

    // #region API
    async function fetchTurmaAPI() {
        const response = await api.listarTurma();
        setListaTurma(response.data.data);
    }

    async function fetchDisciplinaAPI() {
        const response = await api.listarDisciplinas();
        setListaDisciplina(response.data.data);
        setSubjectLoading(false);
    }

    async function fetchAlunoAPI() {
        const response = await api.listarAlunosAtivos();
        setListaAluno(response.data.data);
    }
    // #endregion

    // #region UseEffects
    const [count, setCount] = useState(10);
    useEffect(() => {
        const abortController = new AbortController();
        if (listaTurma.length === 0) fetchTurmaAPI();
        if (turmas.length > 0) fetchDisciplinaAPI();
        count > 0 && setCount(count-1);
        return abortController.abort();
        // eslint-disable-next-line
    }, [count]);

    // -- Caso o usuário seja do tipo professor, permite que sejam escolhidas disciplinas
    useEffect(() => {
        const abortController = new AbortController();

        // Caso seja professor ou administrador, carrega disciplinas
        const flag1 = (acesso === 'Professor' || acesso === 'Administrador') ? true : false;
        (flag1 && subjectLoading) && fetchDisciplinaAPI();
        setComDisciplinas(flag1);

        // Caso seja responsável, carrega alunos
        const flag2 = (acesso === "Responsável") ? true : false;
        (flag2) && fetchAlunoAPI();
        setSemTurmas(flag2)

        return abortController.abort();
        // eslint-disable-next-line
    }, [acesso])

    // -- Salva alterações de disciplina do professor/administrador no campo 'disciplina' do usuário
    useEffect(() => {
        const abortController = new AbortController();
        if (comDisciplinas) {
            const disciplinasCorrigidas = profDisciplinas.filter(item => {
                return item.disciplinaID !== undefined;
            })
            setUsuario(preValue => ({
                ...preValue,
                disciplina: disciplinasCorrigidas
            }))
        }
        return abortController.abort();
        // eslint-disable-next-line
    }, [profDisciplinas]);

    // -- Salva alterações de turmas do professor/administrador no campo 'turma' do usuário
    useEffect(() => {
        const abortController = new AbortController();
        const turmasCorrigidas = turmas.filter(item => {
            return item.turmaID !== undefined;
        })
        setUsuario(preValue => ({
            ...preValue,
            turma: turmasCorrigidas
        }));

        if (comDisciplinas) {
            setDisciplinas(listaDisciplina.filter(d => {
                return turmas.some(t => {
                    return (t.turmaID === d.turmaID) && (d.nome = DisciplinaFormatada(t.turmaID, d))
                });
            }));
        }
        return abortController.abort();
        // eslint-disable-next-line
    }, [turmas]);
    // #endregion

    // -- Retorna campo de alteração de senha
    function CamposNovaSenha() {
        if (edit) {
            return (
                <>
                    <MyTextField
                        label="Nova Senha"
                        variant="outlined"
                        name="novaSenha1"
                        type="password"
                        value={novaSenha1 ? novaSenha1 : ''}
                        error={erros.novaSenha1 ? true : false}
                        onChange={handleChange}/>
                        {erros.novaSenha1 && <p className={classes.errorMessage}>{erros.novaSenha1}</p>}
    
                    <MyTextField
                        label="Confirmação da Nova Senha"
                        variant="outlined"
                        name="novaSenha2"
                        type="password"
                        value={novaSenha2 ? novaSenha2 : ''}
                        error={erros.novaSenha2 ? true : false}
                        onChange={handleChange}/>
                        {erros.novaSenha2 && <p className={classes.errorMessage}>{erros.novaSenha2}</p>}
                </>
            )
        }
    }

    // -- Retorna campo de alteração de disciplina
    function CamposDisciplina() {
        if (comDisciplinas) {
            let tam = profDisciplinas.length-1;
            return profDisciplinas.map((item, index) => {
                return (
                    <Grid key={index} container={true}>
                        <Grid item={true} xs={adminUser ? 10 : 12} sm={adminUser ? 11 : 12}>
                            <MyTextField
                                select={true}
                                label="Disciplina"
                                name="disciplina"
                                value={item?.disciplinaID ? item.disciplinaID : ''}
                                hidden={!comDisciplinas}
                                disabled={disciplinas.length === 0 || !adminUser}
                                variant="outlined"
                                onChange={e => handleSubjectChange(index, e.target.value)}
                                fullWidth={true}
                                error={erros.disciplina ? true : false}>
                                    {
                                        disciplinas.map((row, disKey) => {
                                            return <MenuItem key={disKey} value={row._id}>{row.nome}</MenuItem>
                                        })
                                    }
                            </MyTextField>
                            {erros.disciplina && <p className={classes.errorMessage}>{erros.disciplina}</p>}
                        </Grid>
    
                        <Grid item={true} hidden={!adminUser} xs={adminUser ? 2 : false} sm={adminUser ? 1 : false}>
                            {index === tam
                                ?   <Fab className={classes.fabButton} onClick={addNewSubject} size="small" color="primary" aria-label="add">
                                        <AddIcon />
                                    </Fab>
                                :   <Fab className={classes.fabButton} onClick={(event) => deleteThisSubject (event, index)} size="small" color="secondary" aria-label="add">
                                        <DeleteIcon />
                                    </Fab>
                            } 
                        </Grid>
                    </Grid>
                )
            })
        }
    }

    // -- Retorna campo de alteração de turma
    function CamposTurma() {
        let tam = turmas.length-1;
        return turmas.map((item, index) => {
            return (
                <Grid key={index} container={true}>
                    <Grid item={true} xs={adminUser ? 10 : 12} sm={adminUser ? 11 : 12}>
                        <MyTextField
                            select={true}
                            label="Turma"
                            name="turma"
                            value={item?.turmaID ? item.turmaID : ''}
                            disabled={!adminUser}
                            variant="outlined"
                            onChange={e => handleClassChange(index, e.target.value)}
                            fullWidth={true}
                            error={erros.turma ? true : false}>
                                {
                                    listaTurma.map((item, index) => {
                                        return <MenuItem key={index} value={item._id}>{item.nome}</MenuItem>
                                    })
                                }
                        </MyTextField>
                        {erros.turma && <p className={classes.errorMessage}>{erros.turma}</p>}
                    </Grid>

                    <Grid item={true} hidden={!adminUser} xs={adminUser ? 2 : false} sm={adminUser ? 1 : false}>
                        {index === tam
                            ?   <Fab className={classes.fabButton} onClick={addNewClass} size="small" color="primary">
                                    <AddIcon />
                                </Fab>
                            :   <Fab className={classes.fabButton} onClick={(event) => deleteThisClass (event, index)} size="small" color="secondary">
                                    <DeleteIcon />
                                </Fab>
                        } 
                    </Grid>
                </Grid>
            )
        })
    }

    // -- Retorna nome da disciplina contendo sigla da turma
    function DisciplinaFormatada(turmaID, d) {
        const sigla = listaTurma.find(t => t._id === turmaID).sigla;
        if (!d.nome.includes(`${sigla} -`))
            return `${sigla} - ${d.nome}`;
        return d.nome;
    }

    return (
        <MyContainer>
            <GeneralTitle>{typeForm} Usuário</GeneralTitle>

            <Grid container={true} spacing={2} justifyContent="center">
                <Grid item={true} xl={8} xs={12} sm={9} className="uploadImage">
                    <MyAvatar src={url} alt="Preview"/>
                    <UploadButton handleUpload={handleUpload} single={false}/>
                </Grid>

                <Grid item={true} xl={8} xs={12} sm={9}>
                    <MyTextField
                        label="Nome"
                        variant="outlined"
                        name="nome"
                        type="text"
                        value={nome ? nome : ''}
                        autoFocus={true}
                        onChange={handleChange}
                        error={erros.nome ? true : false}/>
                    {erros.nome && <p className={classes.errorMessage}>{erros.nome}</p>}

                    <MyTextField
                        label="E-mail"
                        variant="outlined"
                        name="email"
                        type="email"
                        value={email ? email : ''}
                        onChange={handleChange}
                        error={erros.email ? true : false}/>
                    {erros.email && <p className={classes.errorMessage}>{erros.email}</p>}
                    
                    <MyTextField
                        select={true}
                        label="Acesso"
                        name="acesso"
                        type="text"
                        value={acesso ? acesso : ''}
                        disabled={!adminUser}
                        onChange={handleChange}
                        variant="outlined"
                        error={erros.acesso ? true : false}>
                            <MenuItem value="Aluno">Aluno</MenuItem>
                            <MenuItem value="Professor">Professor</MenuItem>
                            <MenuItem value="Administrador">Administrador</MenuItem>
                            <MenuItem value="Responsável">Responsável</MenuItem>
                    </MyTextField>
                    {erros.acesso && <p className={classes.errorMessage}>{erros.acesso}</p>}

                    <MyTextField
                        select={true}
                        label="Plano"
                        name="plano"
                        type="text"
                        value={plano ? plano : ''}
                        disabled={!adminUser}
                        style={{display: acesso !== "Aluno" ? "none" : ''}}
                        onChange={handleChange}
                        variant="outlined"
                        error={erros.plano ? true : false}>
                            <MenuItem value="Free">Gratuito</MenuItem>
                            <MenuItem value="Premium">Premium</MenuItem>
                    </MyTextField>

                    <MyTextField
                        select={true}
                        label="Aluno"
                        name="alunoID"
                        value={alunoID ? alunoID : ''}
                        disabled={!adminUser}
                        hidden={acesso !== "Responsável"}
                        style={{display: acesso !== "Responsável" ? "none" : ''}}
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth={true}
                        error={erros.alunoID ? true : false}>
                            {
                                listaAluno.map((item, index) => {
                                    return <MenuItem key={index} value={item._id}>{item.nome}</MenuItem>
                                })
                            }
                    </MyTextField>
                    {erros.alunoID && <p className={classes.errorMessage}>{erros.alunoID}</p>}

                    <GeneralText style={{marginBottom: "0.5rem", display: (semTurmas) ? "none" : "block"}}>Turmas</GeneralText>

                    { !semTurmas && CamposTurma() }

                    <GeneralText style={{marginBottom: "0.5rem", display: (comDisciplinas) ? "block" : "none"}}>Disciplinas</GeneralText>

                    { CamposDisciplina() }

                    <GeneralText style={{marginBottom: "0.5rem"}}>Alterar Senha</GeneralText>

                    <MyTextField
                        label="Senha Atual"
                        variant="outlined"
                        name="senha"
                        type="password"
                        value={senha ? senha : ''}
                        onChange={handleChange}
                        error={erros.senha ? true : false}/>
                    {erros.senha && <p className={classes.errorMessage}>{erros.senha}</p>}

                    { CamposNovaSenha() }

                </Grid>
            </Grid>

            <Grid container={true} spacing={2} justifyContent='center'>
                <Grid item={true} xs={6} sm={3}>
                    <Button
                        size="large"
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}>{typeForm}</Button>
                </Grid>
                <Grid item={true} xs={6} sm={3}>
                    <Link to="/controle-usuario/list" style={{ textDecoration: 'none' }}>
                        <Button
                            size="large"
                            fullWidth={true}
                            variant="contained"
                            color="secondary">Voltar</Button>
                    </Link>
                </Grid>
            </Grid>
        </MyContainer>
    )
}