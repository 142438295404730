import React, { useState, useEffect, useContext } from "react";
import { MyTextField } from "../../../../assets/styles/styledComponents";
import { SimpleSnackMessage, DateAdsTable } from "../../..";
import { Grid, Button, Accordion, AccordionSummary, AccordionDetails, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import useStyles from "./styles";
import api from "../../../../api";
import { areaConhecimento, StoreContext } from "../../../../utils";

function ConverteDatas(model) {
  var newModel = model;
  newModel.dataInicio = `${moment(model.dataInicio).format("YYYY-MM-DD")} 03:00:00.000Z`
  newModel.dataFim = `${moment(model.dataFim).format("YYYY-MM-DD")} 23:59:59.000Z`
  return newModel;
}

export default function ManageDateAds(props) {
  const { mount, setMount } = props;
  const { token } = useContext(StoreContext);
  const initialDateState = {
    turmaID: '',
    areaConhecimento: '',
    dataInicio: new Date(),
    dataFim: new Date(),
    dataModificacao: new Date(),
    autorModificacao: token.userID,
  };
  const classes = useStyles();
  const [dataAd, setDataAd] = useState(initialDateState);
  const [listaTurma, setListaTurma] = useState([]);
  const [listaData, setListaData] = useState([]);
  const [dataID, setDataID] = useState(null);
  const [noError, setNoError] = useState(true);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  // Lista Turmas em tela
  async function fetchTurmaAPI() {
    const response = await api.listarTurma();
    setListaTurma(response.data.data);
  }

  // Lista Datas em tela
  async function fetchDataAPI() {
    const response = await api.listarDataAd();
    setListaData(response.data.data);
  }

  // Caso haja mudança em turma
  useEffect(() => {
    const abortController = new AbortController();
    if (mount.dataAd) {
      fetchDataAPI();
      setMount((preValue) => ({ ...preValue, dataAd: false }));
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [mount]);

  // Carregamento Inicial
  const initialLoad = () => {
    if (mount.isMountedDataAd) {
      fetchDataAPI();
      fetchTurmaAPI();
      setMount((preValue) => ({ ...preValue, isMountedDataAd: false }));
    }
  };

  // Função para pegar os valores do formulário
  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataAd((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  // Função para pegar os valores de data do formulário
	const handleDateChange = (date, name) => {
		setDataAd((preValue) => ({
			...preValue,
			[name]: date,
		}));
	};

  // Salva Inserção
  async function handleSave() {
    await api.inserirDataAd(ConverteDatas(dataAd))
      .then((res) => {
        setDataAd(initialDateState);
        setMount((preValue) => ({ ...preValue, dataAd: true }));
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao inserir a data.");
        console.log(err);
      });
    setOpen(true);
  }

  // Salva Atualização
  async function handleUpload() {
    var novaDataAD = ConverteDatas(dataAd);
    novaDataAD.autorModificacao = token.userID;
    novaDataAD.dataModificacao = new Date();

    await api.atualizarDataAd(dataID, novaDataAD)
      .then(res => {
        setDataAd(initialDateState);
        setDataID(null);
        setMount(preValue => ({ ...preValue, dataAd: true }));
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao inserir a data.");
        console.log(err);
      });
    setOpen(true);
  }

  return (
    <>
      <Accordion
        square
        elevation={2}
        style={{ borderBottom: `0.2rem solid #3300ff` }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => initialLoad()}
          aria-controls="panel1a-content"
          id="panel2-settings"
        >
          <h2 className="heading-page">Gerenciar Datas das ADs</h2>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container={true} className={classes.root} spacing={2}>
            <Grid item={true} xs={12} sm={5}>
              <MyTextField
                variant="outlined"
                select={true}
                label="Turma"
                name="turmaID"
                onChange={handleChange}
                value={dataAd.turmaID ? dataAd.turmaID : ""}
              >
                {
                  listaTurma.map((row, index) => {
                    return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
                  })
                }
              </MyTextField>

              <MyTextField
                variant="outlined"
                select={true}
                label="Área do Conhecimento"
                name="areaConhecimento"
                onChange={handleChange}
                value={dataAd.areaConhecimento ? dataAd.areaConhecimento : ""}
              >
                {areaConhecimento.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.value}
                    </MenuItem>
                  );
                })}
              </MyTextField>

              <MuiPickersUtilsProvider
                locale="pt-br"
                libInstance={moment}
                utils={MomentUtils}
              >
                <KeyboardDatePicker
                  autoOk={true}
                  disableToolbar={true}
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  label="Data de Início"
                  name="dataInicio"
                  fullWidth
                  value={dataAd.dataInicio}
                  onChange={date => handleDateChange(date, "dataInicio")}
                  maxDate={dataAd.dataFim}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider
                locale="pt-br"
                libInstance={moment}
                utils={MomentUtils}
              >
                <KeyboardDatePicker
                  autoOk={true}
                  disableToolbar={true}
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  label="Data de Fim"
                  name="dataFim"
                  fullWidth
                  value={dataAd.dataFim}
                  onChange={date => handleDateChange(date, "dataFim")}
                  minDate={dataAd.dataInicio}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                />
              </MuiPickersUtilsProvider>

              <div className={classes.group}>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  type="submit"
                  color="secondary"
                  onClick={() => {
                    setDataID(null);
                    setDataAd(initialDateState);
                  }}
                >
                  Limpar
                </Button>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  type="submit"
                  color="primary"
                  onClick={dataID ? handleUpload : handleSave}
                >
                  Salvar
                </Button>
              </div>
            </Grid>

            <Grid item={true} xs={12} sm={7}>
              <DateAdsTable
                turmas={listaTurma}
                setDataAd={setDataAd}
                setDataID={setDataID}
                data={listaData}
                setMount={setMount}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      
      <SimpleSnackMessage 
        open={open}
        setOpen={setOpen}
        message={message}
        error={noError}/>
    </>
  );
}
