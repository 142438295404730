import React, { useState, useEffect, useContext } from "react";
import { MyTextField } from "../../../../assets/styles/styledComponents";
import { SimpleSnackMessage, ClassificationTable } from "../../..";
import { Grid, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./styles";
import api from "../../../../api";
import { StoreContext } from "../../../../utils";

export default function ManageClassificacao(props) {
  const { mount, setMount } = props;
  const { token } = useContext(StoreContext);
  const classes = useStyles();
  const initialClassificacaoState = {
    nome: "",
    qtdQuestoes: 0,
    dataModificacao: new Date(),
    autorModificacao: token.userID,
  };
  const [classificacao, setClassificacao] = useState(initialClassificacaoState); // Constante para armazenamento da CLASSIFICACAO
  const [listaClassificacao, setListaClassificacao] = useState([]); // Classificacaos do Banco
  const [classificacaoID, setEditClassificacao] = useState(null); // Constante para verificar se há edição em CLASSIFICACAO
  const [noError, setNoError] = useState(true);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  // Lista CLASSIFICACOES em tela
  async function fetchClassificacaoAPI() {
    const response = await api.listarClassificacoes();
    setListaClassificacao(response.data.data);
  }

  // Caso haja mudança em classificacao
  useEffect(() => {
    const abortController = new AbortController();
    if (mount.classificacao) {
      fetchClassificacaoAPI();
      setMount((preValue) => ({ ...preValue, classificacao: false }));
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [mount]);

  // Carregamento Inicial
  const initialClassificacaoLoad = () => {
    if (mount.isMountedClassificacao) {
      fetchClassificacaoAPI();
      setMount((preValue) => ({ ...preValue, isMountedClassificacao: false }));
    }
  };

  // Função para pegar os valores do formulário - CLASSIFICACAO
  const handleClassificacaoChange = (event) => {
    const { name, value } = event.target;
    setClassificacao((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  // Guarda nova classificacao no banco
  async function saveClassificacaoChange() {
    await api.inserirClassificacao(classificacao)
    .then((res) => {
      setClassificacao(initialClassificacaoState);
      setMount((preValue) => ({ ...preValue, classificacao: true }));
      setNoError(true);
      setMessage(res.data.message);
    })
    .catch(err => {
      setNoError(false);
      setMessage("Houve um erro ao inserir a classificacao.");
    });
    setOpen(true);
  }

  // Guarda disciplina atualizada no banco
  async function editClassificacaoChange() {
    var novaClassificacao = classificacao;
    novaClassificacao.autorModificacao = token.userID;
    novaClassificacao.dataModificacao = new Date();

    await api.atualizarClassificacao(classificacaoID, novaClassificacao)
      .then((res) => {
        setClassificacao(initialClassificacaoState);
        setEditClassificacao(null);
        setMount((preValue) => ({ ...preValue, classificacao: true }));
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao editar a classificacao.");
      });
    setOpen(true);
  }

  // Limpa formulário
  const cleanForm = () => {
    setClassificacao(initialClassificacaoState);
  }

  return (
    <>
      <Accordion
        square
        elevation={2}
        style={{ borderBottom: `0.2rem solid #fbab18` }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => initialClassificacaoLoad()}
          aria-controls="panel1a-content"
          id="panel2-settings"
        >
          <h2 className="heading-page">Gerenciar Classificações</h2>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container={true} className={classes.root} spacing={2}>
            <Grid item={true} xs={12} sm={6}>
              <MyTextField
                id="campoClassificacaoNome"
                variant="outlined"
                label="Nome"
                name="nome"
                type="text"
                value={classificacao.nome}
                onChange={handleClassificacaoChange}
              />

              <MyTextField
                variant="outlined"
                type="number"
                label="Quantidade de Questões"
                name="qtdQuestoes"
                onChange={handleClassificacaoChange}
                value={classificacao.qtdQuestoes ? classificacao.qtdQuestoes : 0}
              ></MyTextField>

              <div className={classes.group}>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  type="submit"
                  color="secondary"
                  onClick={() => cleanForm()}
                >
                  Limpar
                </Button>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  type="submit"
                  color="primary"
                  onClick={classificacaoID ? editClassificacaoChange : saveClassificacaoChange}
                >
                  Salvar
                </Button>
              </div>
            </Grid>

            <Grid item={true} xs={12} sm={6}>
              <ClassificationTable
                pushClassificacao={setClassificacao}
                setClassificacaoID={setEditClassificacao}
                data={listaClassificacao}
                setMount={setMount}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <SimpleSnackMessage 
        open={open}
        setOpen={setOpen}
        message={message}
        error={noError}/>
    </>
  );
}
