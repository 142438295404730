export default function validate (props) {
    const {topicos, enunciado, tipoResposta, resposta} = props;
    let erros = { validated: true };

    // Validar topico
    if (topicos.length === 0) {
        erros.topico = "É preciso escolher um tópico."
        erros.validated = false;
    }

    // Validar enunciado
    if (enunciado === '') {
        erros.enunciado = "É preciso inserir o enunciado da questão."
        erros.validated = false;
    }

    // Validar respostas em caso de questão múltipla-escolha
    if (tipoResposta === "multiplaEscolha") {
        if (resposta.length < 2) {
            erros.resposta = "Insira ao menos duas respostas para a questão."
            erros.validated = false;
        } else if (!resposta.find(item => item.gabarito)) {
            erros.resposta = "É preciso informar o gabarito desta questão." 
            erros.validated = false;
        }
        else if (resposta.find(item => item.opcao === '')) {
            erros.resposta = "Há alternativa em branco."
            erros.validated = false;
        }
    }

    return erros;
}