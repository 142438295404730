import React, {useEffect, useContext, useState, memo} from 'react'
import {withRouter} from "react-router-dom";
import {StoreContext} from "../../utils"
import api from "../../api";
import {Badge, List, ListItem, ListItemIcon, ListItemText, Divider, useMediaQuery, useTheme } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
// -- Material UI: Icons
import Dashboard from '@material-ui/icons/Dashboard';
import StudyPlan from '@material-ui/icons/LibraryBooks';
import Library from '@material-ui/icons/MenuBook';
import Resultados from '@material-ui/icons/InsertChart';
import Mural from '@material-ui/icons/Announcement';
// -- Teacher
import Analize from '@material-ui/icons/Timeline';
import Exercises from '@material-ui/icons/Ballot';
import Questions from '@material-ui/icons/Storage';
import Correction from '@material-ui/icons/AssignmentTurnedIn';
import ContentControl from '@material-ui/icons/InsertDriveFile';
// -- Admin
import UserControl from '@material-ui/icons/People';
import Settings from '@material-ui/icons/Settings';
// -- General
import Logout from '@material-ui/icons/ExitToApp';
import Profile from '@material-ui/icons/AccountBox';
import OnboardingTooltip from '../Tooltip/OnboardingTooltip';
import { OnboardingBackdrop } from '../../assets/styles/styledComponents';

const useStyles = makeStyles(theme => ({
    root: {
        color: "#fff",
        fontWeight: "500",
        fontSize: "1rem"
    }
}));

const onboarding = [
    {
        title: "Dashboard",
        message: "Aqui você encontrará as estatísticas do seu desempenho. Tudo que você faz no Odisseia vai te entregar inteligência de dados para você saber os seus pontos fortes e a melhorar.",
        url: "/plano-estudo"
    },
    {
        title: "Plano de Estudo",
        message: "Saiba o que você precisa estudar diariamente. Material teórico e listas de exercícios de todas as disciplinas, pensado sob medida para sua preparação ENEM.",
        url: "/biblioteca"
    },
    {
        title: "Biblioteca",
        message: "Navegue por todo o conteúdo disponível. Quer visualizar ou revisar aquele conteúdo passado? É aqui mesmo.",
        url: ""
    }
]

const ListarItens = (props) => {
    const {itens, classes, numMurais, numCorrections, primeiroIngresso } = props;
    const [etapa, setEtapa] = useState(0);
    const [count, setCount] = useState(0);
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if(count < 50) {
            if(primeiroIngresso)
                setEtapa(1)
            setCount(count+1)
        }
    }, [primeiroIngresso, count])

    return (
        <> 
            <OnboardingBackdrop open={etapa !== 0} />
            <Divider /> 
            <List>
                {
                    itens.map((item, index) => {
                        const {text, icon, onClick} = item;
                        return (
                            <OnboardingTooltip
                                lastStep={index === 2}
                                nextStep={index + 2}
                                step={index + 1}
                                currentStep={etapa}
                                setCurrentStep={setEtapa}
                                tip={onboarding[index]?.message}
                                title={onboarding[index]?.title}
                                url={onboarding[index]?.url}
                                placement={!smScreen ? "right" : "bottom"}
                            >
                                <ListItem button={true} key={text} onClick={onClick} disabled={primeiroIngresso} className={classes.root}>
                                    {
                                        text === 'Correções' ?
                                            <Badge badgeContent={numCorrections} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} max={99} invisible={numCorrections === 0} color="secondary">
                                                <ListItemIcon className={classes.root}>{icon}</ListItemIcon>
                                                <ListItemText className={classes.root} primary={text} disableTypography={true}/>
                                            </Badge>
                                        : text === 'Mural' ?
                                            <Badge badgeContent={numMurais} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} max={99} invisible={numMurais === 0} color="secondary">
                                                <ListItemIcon className={classes.root}>{icon}</ListItemIcon>
                                                <ListItemText className={classes.root} primary={text} disableTypography={true}/>
                                            </Badge>    
                                        :
                                            <Badge badgeContent={0} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} max={99} invisible={true} color="secondary">
                                                <ListItemIcon className={classes.root}>{icon}</ListItemIcon>
                                                <ListItemText className={classes.root} primary={text} disableTypography={true}/>
                                            </Badge>    
                                    }
                                    
                                </ListItem>
                            </OnboardingTooltip>
                        )
                    })
                }
            </List>
        </>
    );
}

function ItemsDrawer(props) {
    const { history, setOpen } = props;
    const classes = useStyles();
    const [access, setAccess] = useState({geral: true});
    const [numCorrections, setNumCorrections] = useState(0);
    const [numMurais, setNumMurais] = useState(0);
    const [wasLoaded, setWasLoaded] = useState(false);
    const { token, setToken, dataProvider, setDataProvider } = useContext(StoreContext);

    const disciplinas = token.disciplina;

    const handleLogout = async () => {
        const { userID } = token;
        setToken(null);
        if(access.aluno) {
            const timestampAtual = new Date();
            const timestampGeral = new Date(JSON.parse(localStorage.getItem("TimestampGeral")));
            
            let incremento = Math.ceil((timestampAtual.getTime() - timestampGeral.getTime())/1000);
            if(localStorage.getItem("Contador")) 
            incremento += JSON.parse(localStorage.getItem("Contador"));
            
            localStorage.removeItem("TimestampGeral");
            localStorage.removeItem("Contador");
            await api.atualizarContador(userID, {contador: incremento});
        }
        
        history.push('/login');
    };

    const onItemClick = (path) => {
        history.push(path);
        setOpen(false);
    }

    const itens = {
        aluno: [
            {
                text: "Dashboard",
                icon: <Dashboard/>,
                onClick: () => onItemClick("/dashboard")
            }, {
                text: "Plano de Estudos",
                icon: <StudyPlan/>,
                onClick: () => onItemClick("/plano-estudo")
            }, {
                text: "Biblioteca",
                icon: <Library/>,
                onClick: () => onItemClick("/biblioteca")
            },
            // {
            //     text: "Classroom",
            //     icon: <Classroom/>,
            //     onClick: () => onItemClick("/classroom")
            // }, 
            {
                text: "Mural",
                icon: <Mural/>,
                onClick: () => onItemClick("/mural")
            }, {
                text: "Resultados",
                icon: <Resultados/>,
                onClick: () => onItemClick("/resultados")
            }
        ],
        professor: [
            {
                text: "Análises",
                icon: <Analize/>,
                onClick: () => onItemClick("/analisar-desempenho")
            }, {
                text: "Atividades",
                icon: <Exercises/>,
                onClick: () => onItemClick("/controle-atividade")
            }, {
                text: "Banco de Questões",
                icon: <Questions/>,
                onClick: () => onItemClick("/controle-questoes")
            }, {
                text: "Correções",
                icon: <Correction/>,
                onClick: () => onItemClick("/controle-correcoes")
            }, {
                text: "Material de Estudo",
                icon: <ContentControl/>,
                onClick: () => onItemClick("/controle-conteudo")
            }, {
                text: "Mural",
                icon: <Mural/>,
                onClick: () => onItemClick("/controle-mural")
            } 
        ],
        admin: [
            {
                text: "Controle de Usuário",
                icon: <UserControl/>,
                onClick: () => onItemClick("/controle-usuario")
            }, {
                text: "Configurações",
                icon: <Settings/>,
                onClick: () => onItemClick("/configuracoes")
            }
        ],
        geral: [
            {
                text: "Perfil",
                icon: <Profile/>,
                onClick: () => onItemClick(`/perfil/${token.userID}`)
            }, {
                text: "Sair",
                icon: <Logout/>,
                onClick: () => handleLogout()
            }
        ]
    };

    useEffect(() => {
        if (token.accessType === "Aluno")
            setAccess(x => ({ ...x, aluno: true }));
        else if (token.accessType === "Professor")
            setAccess(x => ({ ...x, professor: true }));
        else if (token.accessType === "Administrador")
            setAccess(x => ({ ...x, professor: true, admin: true }));
    }, [token]);

    useEffect(() => {
        const abortController = new AbortController();
        const today = `${moment(new Date()).format("YYYY-MM-DD")}T03:00:00.000Z`;
        var area = [];

        if ((access.professor || access.admin) && disciplinas.length && !wasLoaded) {
            if (!dataProvider?.correcoes) {
                function CarregaQuantidadeCorrecoes() {
                    var count = 0;
                    disciplinas.forEach(async item => {
                        if (item) {
                            const resRA = await api.contarRAsNaoCorrigidas(item).catch();
                            const resRED = await api.contarRedacoesNaoCorrigidas(item).catch();
                            var valAD = 0;
                            
                            const res = await api.encDisciplinaPorID(item);
                            if(!area.includes(res.data.data.areaConhecimento)) {
                                area.push(res.data.data.areaConhecimento);
                                const resAD = await api.contarADsNaoCorrigidas(res.data.data.areaConhecimento);
                                valAD = resAD?.data.data || 0;
                            }

                            const valRA = resRA.data;
                            const valRED = resRED.data;
            
                            count += valRA.success ? valRA.data : 0;
                            count += valRED.success ? valRED.data : 0;
                            count += valAD;
                        }
                        setNumCorrections(count);
                        setDataProvider(x => ({...x, correcoes: count}))
                    });
                    setWasLoaded(true);
                }
                CarregaQuantidadeCorrecoes();
            }
            else {
                setNumCorrections(dataProvider.correcoes);
                setWasLoaded(true);
            }
        }
        
        else if (access.aluno) {
            async function CarregaQuantidadeMurais() {
                await api.listarComunicado(token.turmaID, today)
                    .then(res => {
                        setNumMurais(res.data.data.length);
                    })
                    .catch(err => console.log(err));
                setWasLoaded(true);
            }
            CarregaQuantidadeMurais();
        }
        return abortController.abort();
    }, [access.admin, access.professor, access.aluno, disciplinas, wasLoaded, token, dataProvider, setDataProvider])
    
    return (
        <div>
            {access.aluno && <ListarItens numMurais={numMurais} itens={itens.aluno} classes={classes} primeiroIngresso={token.firstTime} />}
            {access.professor && <ListarItens numMurais={numMurais} numCorrections={numCorrections} itens={itens.professor} classes={classes}/>}
            {access.admin && <ListarItens itens={itens.admin} classes={classes}/>}
            {access.geral && <ListarItens itens={itens.geral} classes={classes}/>}
        </div>
    );
}
export default withRouter(memo(ItemsDrawer));
