import React, { useState, useContext } from "react";
import { StoreContext } from "../../utils"
import { ActivityValidater, SimpleSnackMessage } from "../../components";
import api from '../../api'
import { ActivityForm } from "../../components";

// -- Dados iniciais da constante Atividade
const initialState = {
    tipoAtividade: "",
    turmaID: "", 
    disciplinaID: "",
    areaConhecimento: "",
    dataLiberacao: "",
    topicoID: "",
    questoes: [],
    dataCriacao: new Date(),
    dataModificacao: new Date(),
    autor: "",
    erros: []
}

export default function ActivityInsert() {
    const [atividade, setAtividade] = useState(initialState);
    const [clear, setClear] = useState(false);
    const {token} = useContext(StoreContext);
    const [message, setMessage] = useState("Houve um erro ao inserir a atividade.");
    const [noError, setNoError] = useState(true);
    const [open, setOpen] = useState(false);
    const [questaoSelecionada, setQuestaoSelecionada] = useState([]);
    const [classificacao, setClassificacao] = useState();
        
    // -- Salva as alterações feitas no banco*
    async function InserirAtividade() {
        // Validação de erros
        const error = ActivityValidater({atividade, classificacao});
        setAtividade(preValue => ({
            ...preValue,
            erros: error
        }));

        // Verifica se há erro
        if (error.validated) {
            const { disciplinaID, topicoID, tipoAtividade, questoes, areaConhecimento, dataCriacao } = atividade;
            
            const novaAtividade = {
                disciplinaID,
                topicoID,
                tipoAtividade,
                questoes: questoes.map(x => x.id).filter(item => { return item !== "" }),
                areaConhecimento,
                dataCriacao,
                dataModificacao: new Date(),
                autor: token.userID,
            }

            // Inserção pela API
            await api
                .inserirAtividade(novaAtividade)
                .then(res => {
                    setNoError(true);
                    setMessage(res.data.message);
                    setAtividade(initialState);
                    setClear(true);
                })
                .catch(err => {
                    setNoError(false);
                });
            setOpen(true);
        }
    }

    // -- Salva as alterações feitas no banco*
    async function InserirRevisao() {
        // Validação de erros
        const error = ActivityValidater({atividade, classificacao});
        setAtividade(preValue => ({
            ...preValue,
            erros: error
        }));

        // Verifica se há erro
        if (error.validated) {
            const { turmaID, tipoAtividade, questoes, areaConhecimento, dataLiberacao, dataCriacao } = atividade;
            
            const novaRevisao = {
                turmaID,
                tipoAtividade,
                areaConhecimento,
                dataLiberacao,
                questoes: questoes.map(x=>x.id),
                dataCriacao,
                dataModificacao: new Date(),
                autor: token.userID,
            }

            // Inserção pela API
            await api
                .inserirRevisao(novaRevisao)
                .then(res => {
                    setNoError(true);
                    setMessage(res.data.message);
                    setAtividade(initialState);
                    setClear(true);
                })
                .catch(err => {
                    console.log(err);
                    setNoError(false);
                });
            setOpen(true);
        }
    }


    return (
        <div>
            <ActivityForm 
                atividade={atividade}
                setAtividade={setAtividade}
                initialState={initialState}
                saveActivity={InserirAtividade}
                saveRevision={InserirRevisao}
                questaoSelecionada={questaoSelecionada}
                setQuestaoSelecionada={setQuestaoSelecionada}
                isRevision={false}
                clear={clear}
                classificacao={classificacao}
                setClassificacao={setClassificacao}
            />

            <SimpleSnackMessage 
                open={open}
                setOpen={setOpen}
                message={message}
                error={noError}
            />
        </div>
    );
};
