import React, { useState, useContext } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { StoreContext } from "../../utils";
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../api";

// -- Styles / Componentes gráficos
import { Backdrop, CircularProgress, makeStyles, Grid, Card, Zoom, Button } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { MyTextField, MyTypography } from "../../assets/styles/styledComponents"
import logo from "../../assets/images/odisseia-color.png"
import "./styles.css"
import { SimpleSnackMessage } from "../../components";
import publicIp from "public-ip";

// -- Style Classes
const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    logo: {
        alignSelf: "center",
        height: "100%",
        width: "100%"
    },
    loginCard: {
        alignContent: "center", 
        textAlign: "center",
        backgroundColor: "#fff",
        borderRadius: "1rem",
        boxShadow: "0.5rem 1rem 2rem black",
        margin: "auto",
        padding: "0 2rem 2rem 2rem",
    },
    loginContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        justifySelf: "center",
        minWidth: "18.75rem",
        padding: "0 2rem",
        width: "100vw",
        height: "100vh",
        margin: "auto",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        [theme.breakpoints.down('sm')]: {
            padding: "0 1rem",
            backgroundSize: "cover",            
        }
    },
    loginHeader: {
        alignContent: "center",
        alignItems: "center"
    },
    forgotButton: {
        color: red[500],
        fontSize: "1rem",
        marginTop: "1rem",
        marginBottom: "-0.5rem",
        cursor: 'pointer'
    },
    registerButton: {
        color: theme.palette.primary.main,
        fontSize: "1rem",
        marginTop: "1rem",
        marginBottom: "-0.5rem",
        cursor: 'pointer'
    },
    loginForm: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "2rem"
        }
    },
    marginFields: {
        marginTop: "1.5rem"
    },
    smLoginForm: {
        marginTop: "3rem",
        marginBottom: "2rem",
    },
    redefinirSenha: {
        margin: "1rem"
    }
}));

const schema = Yup.object().shape({
    email: Yup.string("Insira seu e-mail de acesso.").email("Insira um e-mail válido.").required("E-mail necessário."),
    senha: Yup.string("").required("Senha necessária."),
});

export default withRouter(function Login() {
    const classes = useStyles();
    const history = useHistory();
    const { setToken } = useContext(StoreContext)
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState(false);

    const formik = useFormik({
        initialValues: {email: '', senha: ''},
        validationSchema: schema,
        onSubmit: async (values) => {
            if (values.email && values.senha) {
                setLoadingUser(true);
                try {
                    var token = (await api.confirmarUsuario(values)).data.data;
                    setToken(token);
                    api.inserirLog({
                        userID: token.userID,
                        objectID: 'N/A',
                        timestamp: new Date(),
                        userIP: await publicIp.v4(),
                        description: 'Login'
                    });
                    return history.push('/');
                } catch (error) {
                    setLoadingMsg(true);
                }
                setLoadingUser(false);
            } else {
                setLoadingMsg(true);
            }
        }
    })

    return (
        <Grid id="loginScreen" container={true} className={classes.loginContainer}>
            <Grid item={true} xl={5} lg={5} md={6} sm={9} xs={12}>
                <Zoom in={true} style={{ transitionDelay: '250ms' }}>
                    <Card className={classes.loginCard}>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <div className={classes.loginHeader}>
                                <Zoom in={true} style={{ transitionDelay: '300ms' }}>
                                    <img className={classes.logo} src={logo} alt="Logo"/>
                                </Zoom>
                                <Zoom in={true} style={{ transitionDelay: '300ms' }}>
                                    <MyTypography align="center" variant="h5">Login</MyTypography>
                                </Zoom>
                            </div>

                            <div className={classes.loginForm}>
                                <Zoom in={true} style={{ transitionDelay: '350ms' }}>
                                    <MyTextField
                                        className={classes.marginFields}
                                        label="E-mail"
                                        variant="outlined"
                                        name="email"
                                        type="email"
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        autoComplete="true"
                                        autoFocus
                                        value={formik.values.email}
                                        onChange={formik.handleChange}/>
                                </Zoom>
                                <Zoom in={true} style={{ transitionDelay: '400ms' }}>
                                    <MyTextField
                                        className={classes.marginFields}
                                        label="Senha"
                                        variant="outlined"
                                        name="senha"
                                        type="password"
                                        error={formik.touched.senha && Boolean(formik.errors.senha)}
                                        helperText={formik.touched.senha && formik.errors.senha}
                                        autoComplete="true"
                                        value={formik.values.senha}
                                        onChange={formik.handleChange}/>
                                </Zoom>
                            </div>

                            <div className={classes.marginFields}>
                                <Zoom in={true} style={{ transitionDelay: '450ms' }}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        type="submit">Entrar</Button>
                                </Zoom>

                                <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                                    <Link to="/reset" style={{ textDecoration: "none"}}>
                                        <p className={classes.forgotButton}>Esqueceu a senha?</p>
                                    </Link>
                                </Zoom>


                                <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                                    <Link to="/register" style={{ textDecoration: "none"}}>
                                        <p className={classes.registerButton}>Não possui uma conta? Venha em uma jornada de inteligência rumo ao ENEM!</p>
                                    </Link>
                                </Zoom>
                            </div>
                        </form>
                        <SimpleSnackMessage open={loadingMsg} setOpen={setLoadingMsg} message='Usuário ou senha inválido' error={false}/>

                        <Backdrop className={classes.backdrop} open={loadingUser}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Card>
                </Zoom>
            </Grid>

        </Grid>
    );
});