import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from "../../utils"
import { ActivityValidater, SimpleSnackMessage } from "../../components";
import api from '../../api'

// -- Estilos
import { ActivityForm } from "../../components";

// -- Dados iniciais da constante Atividade/Revisão
const initialState = {
    tipoAtividade: "",
    turmaID: "", 
    areaConhecimento: "",
    dataLiberacao: "",
    disciplinaID: "",
    topicoID: "",
    questoes: [],
    dataCriacao: new Date(),
    dataModificacao: new Date(),
    autor: "",
    erros: []
}

export default function ActivityUpdate(props) {
    const [atividade, setAtividade] = useState(initialState);
    const {token} = useContext(StoreContext);
    const isRevision = props.history.location.pathname.includes("revisao");     // Verifica se é uma atividade do tipo Revisão
    const id = props.match.params.id;     // Verifica se é uma atividade do tipo Revisão
    const [message, setMessage] = useState("Houve um erro ao inserir a atividade.");
    const [noError, setNoError] = useState(true);
    const [open, setOpen] = useState(false);
    const [questaoSelecionada, setQuestaoSelecionada] = useState([]);
    const [classificacao, setClassificacao] = useState();
    
    // -- Carrega atividade ou revisão selecionada pelo usuário
    useEffect(() => {
        const abortController = new AbortController();
        if (isRevision) {
            // -- Carrega revisão do banco
            async function fetchRevisaoAPI() {
                const response = await api.encRevisaoPorID(id);
                const value = response.data.data;
                
                // Separa somente ID das questões num array
                let questionArray = value.questoes;
                if (questionArray.length) {
                    questionArray = value.questoes.map(item => {
                        return item.questaoID._id;
                    })
                }

                await api.encQuestoesDaAtividadeID(id).then((response) => {
                    setQuestaoSelecionada(response.data.data.map(x => ({ id: x.questaoID._id, enunciado: x.questaoID.enunciado })))
                });
        
                setAtividade(preValue => ({
                    ...preValue,
                    tipoAtividade: value.tipoAtividade,
                    areaConhecimento: value.areaConhecimento,
                    dataLiberacao: value.dataLiberacao,
                    turmaID: value.turmaID,
                    questoes: questionArray,
                    dataCriacao: value.dataCriacao,
                    dataModificacao: value.dataModificacao,
                    autor: value.autor,
                    erros: []
                }));
            }
            fetchRevisaoAPI();
        } else {
            // -- Carrega atividade do banco
            async function fetchAtividadeAPI() {
                const response = await api.encAtividadePorID(id);
                const value = response.data.data;
                
                // Separa somente ID das questões num array
                let questionArray = value.questoes;
                if (questionArray.length) {
                    questionArray = value.questoes.map(item => {
                        return item.questaoID._id;
                    })
                }

                await api.encQuestoesDaAtividadeID(id).then((response) => {
                    setQuestaoSelecionada(response.data.data.map(x => ({ id: x.questaoID._id, enunciado: x.questaoID.enunciado })))
                });

                setAtividade(preValue => ({
                    ...preValue,
                    tipoAtividade: value.tipoAtividade,
                    areaConhecimento: value.areaConhecimento,
                    disciplinaID: value.disciplinaID._id,
                    topicoID: value.topicoID,
                    turmaID: value.disciplinaID.turmaID,
                    questoes: questionArray,
                    dataCriacao: value.dataCriacao,
                    dataModificacao: value.dataModificacao,
                    autor: value.autor,
                    erros: []
                }));
            }
            fetchAtividadeAPI();
        }
        return abortController.abort();
    }, [isRevision, id]);

    // -- Salva as alterações feitas no banco*
    async function AtualizarAtividade() {
        // Validação de erros
        const error = ActivityValidater({atividade, classificacao});
        setAtividade(preValue => ({
            ...preValue,
            erros: error
        }));

        // Verifica se há erro
        if (error.validated) {
            const { disciplinaID, topicoID, tipoAtividade, questoes, areaConhecimento, dataCriacao } = atividade;
            const atividadeAtualizada = {
                disciplinaID,
                topicoID,
                tipoAtividade,
                questoes: questoes.map(x => x.id).filter(item => { return item !== "" }),
                areaConhecimento,
                dataCriacao,
                dataModificacao: new Date(),
                autor: token.userID,
            }

            // Inserção pela API
            await api
                .atualizarAtividade(props.match.params.id, atividadeAtualizada)
                .then(res => {
                    // Limpa os campos
                    if (res.status === 200) {
                        setNoError(true);
                        setMessage(res.data.message);
                    }
                })
                .catch(err => {
                    setNoError(false);
                });
            setOpen(true);
        }
    }

    // -- Salva as alterações feitas no banco*
    async function AtualizarRevisao() {
        // Validação de erros
        const error = ActivityValidater({atividade, classificacao});
        setAtividade(preValue => ({
            ...preValue,
            erros: error
        }));

        // Verifica se há erro
        if (error.validated) {
            const { turmaID, tipoAtividade, questoes, areaConhecimento, dataLiberacao, dataCriacao } = atividade;
            
            const novaRevisao = {
                turmaID,
                tipoAtividade,
                areaConhecimento,
                dataLiberacao,
                questoes: questoes.filter(item => { return item !== "" }),
                dataCriacao,
                dataModificacao: new Date(),
                autor: token.userID,
            }

            // Atualização pela API
            await api
                .atualizarRevisao(props.match.params.id, novaRevisao)
                .then(res => {
                    // Limpa os campos
                    if (res.status === 200) {
                        setNoError(true);
                        setMessage(res.data.message);
                    }
                })
                .catch(err => {
                    setNoError(false);
                });
            setOpen(true);
        }
    }

    return (
        <div>
            <ActivityForm 
                atividade={atividade}
                setAtividade={setAtividade}
                initialState={initialState}
                saveActivity={AtualizarAtividade}
                saveRevision={AtualizarRevisao}
                questaoSelecionada={questaoSelecionada}
                setQuestaoSelecionada={setQuestaoSelecionada}
                isRevision={isRevision}
                clear={false}
                classificacao={classificacao}
                setClassificacao={setClassificacao}
            />

            <SimpleSnackMessage 
                open={open}
                setOpen={setOpen}
                message={message}
                error={noError}
            />
        </div>
    );
};