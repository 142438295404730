import React from 'react';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Grid
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const { dialogContent, open, functionText, handleClick, handleClose } = props;
  const classes = useStyles();
  return (
    <Dialog fullScreen open={open} className={classes.dialog} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Grid container>
            <Grid item xs={6}>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Grid>
            { handleClick && <Grid item xs={6} style={{margin: 'auto', textAlign: 'right'}}>
              <Button autoFocus color="inherit" onClick={handleClick}>
                { functionText }
              </Button>
            </Grid> }
          </Grid>
        </Toolbar>
      </AppBar>
      { dialogContent }
    </Dialog>
  );
}
