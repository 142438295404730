import React, { useContext, useEffect, useState } from "react";
import api from "../../../api";
import { MyContainer, MyCard, GeneralTitle } from "../../../assets/styles/styledComponents"
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { ActivityCard, FullWidthActivityTab } from "../../../components";
import { StoreContext } from "../../../utils";
import { useHistory } from "react-router-dom";
import useStyles from "./styles";

export default function StudentActivity () {
  const history = useHistory();
  const { dataProvider } = useContext(StoreContext);
  if (dataProvider === null) history.goBack();

  const classes = useStyles();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { activity, progress, type } = dataProvider;
  
  const activityId = activity._id;
  const [ wasLoaded, setWasLoaded ] = useState(false);
  const [ indice, setIndice ] = useState(0);
  const [ questoes, setQuestoes ] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    async function listarQuestoes() {
      await api.encQuestoesDaAtividadeID(activityId)
        .then(({data}) => {
          setQuestoes(data.data.map((x, index) => { 
            const { _id, disciplinaId, enunciado, padraoResposta, resposta, tags, tipoResposta, topicoID } = x.questaoID;
            return { _id, disciplinaId, enunciado, padraoResposta, resposta, tags, tipoResposta, topicoID, index } 
          }));
        });
    }
    listarQuestoes();
    setWasLoaded(true);
    return abortController.abort();
    // eslint-disable-next-line
  }, [wasLoaded])

  //#region Funções de Salvamentos
  // -- Salvamento do progresso do TÓPICO
  async function saveTopicProgress() {
    progress.progresso[type] = true;
    if (!progress._id) await api.inserirProgresso(progress)
    else await api.atualizarProgresso(progress._id, progress)
  }

  // -- Salvamento do progresso da REVISÃO
  async function saveRevisionProgress() {
    const novoProgresso = {
      alunoID: progress.alunoID,
      revisaoID: activityId,
      progresso: true
    } 
    if (!progress._id) await api.inserirProgressoRevisao(novoProgresso);
    else await api.atualizarProgressoRevisao(progress._id, novoProgresso);
  }
  //#endregion

  // -- Fechamento do card
  const handleClose = (ev) => {
    history.goBack();
  };

  // -- Finalização da atividade (apenas salva o progresso)
  const handleFinalized = () => {
    if (type === "avaliacaoDiagnostica") saveRevisionProgress();
    else saveTopicProgress();
    handleClose();
  };

  return (
    <MyContainer id="activityContainer">

      <section id="cabecalhoAtividade">
      <GeneralTitle align="center" className="heading-page">{`Atividade de ${ activity.tipoAtividade }`}</GeneralTitle>
      </section>

      <section id="areaDeCorrecao">
        <Grid container={true} spacing={2}>

          {/* <Grid item={true} xs={12} sm={12}>
            <CorrectionProgressBar 
              max={numTasks} 
              progresso={progresso} 
              wasLoaded={wasLoaded}
            />
          </Grid> */}
          {
            !smScreen && 
            <Grid item={true} xs={2} sm={2}>
              <MyCard className={classes.fullWidthActivityTab}>
                <FullWidthActivityTab
                  questoes={ questoes }
                  setIndice={setIndice}
                />
              </MyCard>
            </Grid>
          }

          <Grid item xs={smScreen ? 12 : 10} sm={smScreen ? 12 : 10}>
            <MyCard className={classes.card}>
              <ActivityCard 
                atividadeID={activity.tipoAtividade === 'Avaliação Diagnóstica' ? undefined : activity._id} 
                revisaoID={activity.tipoAtividade !== 'Avaliação Diagnóstica' ? undefined : activity._id} 
                name={type}                         // Tipo de exercício
                handleClose={handleClose} 
                handleFinalized={handleFinalized} 
                questoes={questoes}                               // Questoes da atividade
                answered={progress.progresso}                  // Informa se atividade já foi concluída
                indice={indice + 1}
              />
            </MyCard>
          </Grid>

        </Grid>
      </section>

    </MyContainer>
  );
};
