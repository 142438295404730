import { createTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import nunitoRegular from '../fonts/Nunito-Regular.ttf'

const theme = createTheme({
    palette: {
      primary: {
        main: "#3300ff",
        contrastText: '#fff',
      },
      secondary: {
        main: "#0f0d59",
        contrastText: '#fff',
      },
      success: {
        main: '#4dbc8e',
        contrastText: '#fff'
      },
      warning: {
        main: '#fbab18',
        contrastText: '#fff'
      },
      error: {
        main: '#f15a22',
        contrastText: '#fff'
      }
    },
    typography: {
      color: "#606161",
      fontFamily: 'Nunito'
    },
    button: {
      textDecoration: "none",
      brackgroundColor: "#fff",
    },
    iconButton: {
      textDecoration: "none",
      brackgroundColor: "#fff",
    },

    overrides: {
      MuiCssBaseline: {
        "@global":
          {
            '@font-face': {
              'font-family': 'Nunito',
              'font-style': 'normal',
              'font-display': 'swap',
              'font-weight': 400,
              'src': `url(${nunitoRegular}) format('truetype')`,
              'unicodeRange': 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
            }
          }
      },
    }
}, ptBR);

export default theme;