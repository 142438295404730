import React, { useState, useEffect } from 'react';
import { GeneralSubtitle, GeneralText, GeneralTitle, MyContainer, MyTextField } from '../../assets/styles/styledComponents';
import { makeStyles, Grid, ButtonGroup, Button, MenuItem, Tooltip, useTheme, useMediaQuery } from '@material-ui/core';
import { AccordionSkeleton, AnalysisAccordion, AreaCard, RevisionAccordion, SubjectCard } from '../../components';
import apis from '../../api';
import { areaConhecimento } from '../../utils';
import moment from "moment";

const colors = ["#eb7120", "#94c93d", "#a283bc", "#fdc504", "#39b2d2"]

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    margin: "auto",
    textAlign: "right",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    }
  },
  generalSubtitle: {
    marginTop: '1rem',
  },
  smSubtitle: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    }
  }
}));

const novaFrequencia = {
  material: {
    mEstudado: 0,
    mTotal: 0
  },
  videoaula: {
    vAssistido: 0,
    vTotal: 0
  },
  atividade: {
    aFeito: 0,
    aTotal: 0
  }
}

const initialParams = {
  tipo: "0",
  disciplinaID: "0",
  turmaID: "0",
  topico: "0",
  numeracao: "0",
  area: "0"
}

export default function AdminAnalysis() {
  const classes = useStyles();
  const [analiseGeral, setAnaliseGeral] = useState({});
  const [wasLoaded, setWasLoaded] = useState({area: false, disciplina: false});
  const [modoArea, setModoArea] = useState(true);
  const [listaTurma, setListaTurma] = useState([]);
  const [listaAtividade, setListaAtividade] = useState([]);
  const [listaAds, setListaAds] = useState([]);
  const [turmaID, setTurmaID] = useState("");
  const [medias, setMedias] = useState([]);
  const [frequencias, setFrequencias] = useState([]);
  const [turmaChanged, setTurmaChanged] = useState(false);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!wasLoaded.area && modoArea) {
      async function gerarAnalise() {
        await apis.gerarAnaliseAdministrador()
          .then(res => {
            const { analise, progressos, totalAlunos } = res.data;
            progressos.map(async row => {
              await apis.calcularFrequenciaGeral(row.topicoID, totalAlunos)
                .then(fg => {
                  const {aTotal, aFeito} = fg.data.frequencia.atividade;
                  const {mTotal, mEstudado} = fg.data.frequencia.material;
                  const {vTotal, vAssistido} = fg.data.frequencia.videoaula;
            
                  if (row.area === 'Linguagens') {
                    analise.porArea.linguagens.atividades.parte += aFeito;
                    analise.porArea.linguagens.atividades.total += aTotal;
                    analise.porArea.linguagens.topicos.parte += mEstudado;
                    analise.porArea.linguagens.topicos.total += mTotal;
                    analise.porArea.linguagens.videoaulas.parte += vAssistido;
                    analise.porArea.linguagens.videoaulas.total += vTotal;
                    
                    if (row.disciplina === analise.porDisciplina.linguagens.melhor.nome) {
                      analise.porDisciplina.linguagens.melhor.total += vTotal + mTotal + aTotal;
                      analise.porDisciplina.linguagens.melhor.parte += vAssistido + mEstudado + aFeito;
                    } 
                    else if (row.disciplina === analise.porDisciplina.linguagens.pior.nome) {
                      analise.porDisciplina.linguagens.pior.total += vTotal + mTotal + aTotal;
                      analise.porDisciplina.linguagens.pior.parte += vAssistido + mEstudado + aFeito;
                    }
                  } 
                  
                  else if (row.area === 'Matematicas') {
                    analise.porArea.matematicas.atividades.parte += aFeito;
                    analise.porArea.matematicas.atividades.total += aTotal;
                    analise.porArea.matematicas.topicos.parte += mEstudado;
                    analise.porArea.matematicas.topicos.total += mTotal;
                    analise.porArea.matematicas.videoaulas.parte += vAssistido;
                    analise.porArea.matematicas.videoaulas.total += vTotal;
                    
                    if (row.disciplina === analise.porDisciplina.matematicas.melhor.nome) {
                      analise.porDisciplina.matematicas.melhor.total += vTotal + mTotal + aTotal;
                      analise.porDisciplina.matematicas.melhor.parte += vAssistido + mEstudado + aFeito;
                    } 
                    else if (row.disciplina === analise.porDisciplina.matematicas.pior.nome) {
                      analise.porDisciplina.matematicas.pior.total += vTotal + mTotal + aTotal;
                      analise.porDisciplina.matematicas.pior.parte += vAssistido + mEstudado + aFeito;
                    }
                  } 
                  
                  else if (row.area === 'Ciências Humanas') {
                    analise.porArea.cienciasHumanas.atividades.parte += aFeito;
                    analise.porArea.cienciasHumanas.atividades.total += aTotal;
                    analise.porArea.cienciasHumanas.topicos.parte += mEstudado;
                    analise.porArea.cienciasHumanas.topicos.total += mTotal;
                    analise.porArea.cienciasHumanas.videoaulas.parte += vAssistido;
                    analise.porArea.cienciasHumanas.videoaulas.total += vTotal;
                    
                    if (row.disciplina === analise.porDisciplina.cienciasHumanas.melhor.nome) {
                      analise.porDisciplina.cienciasHumanas.melhor.total += vTotal + mTotal + aTotal;
                      analise.porDisciplina.cienciasHumanas.melhor.parte += vAssistido + mEstudado + aFeito;
                    } 
                    else if (row.disciplina === analise.porDisciplina.cienciasHumanas.pior.nome) {
                      analise.porDisciplina.cienciasHumanas.pior.total += vTotal + mTotal + aTotal;
                      analise.porDisciplina.cienciasHumanas.pior.parte += vAssistido + mEstudado + aFeito;
                    }
                  } 
                  
                  else if (row.area === 'Ciências da Natureza') {
                    analise.porArea.cienciasNaturais.atividades.parte += aFeito;
                    analise.porArea.cienciasNaturais.atividades.total += aTotal;
                    analise.porArea.cienciasNaturais.topicos.parte += mEstudado;
                    analise.porArea.cienciasNaturais.topicos.total += mTotal;
                    analise.porArea.cienciasNaturais.videoaulas.parte += vAssistido;
                    analise.porArea.cienciasNaturais.videoaulas.total += vTotal;
                    
                    if (row.disciplina === analise.porDisciplina.cienciasNaturais.melhor.nome) {
                      analise.porDisciplina.cienciasNaturais.melhor.total += vTotal + mTotal + aTotal;
                      analise.porDisciplina.cienciasNaturais.melhor.parte += vAssistido + mEstudado + aFeito;
                    } 
                    else if (row.disciplina === analise.porDisciplina.cienciasNaturais.pior.nome) {
                      analise.porDisciplina.cienciasNaturais.pior.total += vTotal + mTotal + aTotal;
                      analise.porDisciplina.cienciasNaturais.pior.parte += vAssistido + mEstudado + aFeito;
                    }
                  }
                });
            })
            setAnaliseGeral(analise);
          });
      }

      gerarAnalise();
      setWasLoaded(x => ({...x, area: true}));
    }

    if (!wasLoaded.disciplina && !modoArea) {
      async function fetchTurmaAPI() {
        await apis.listarTurmasAtivas()
          .then(res => {
            setListaTurma(res.data.data);
          })
      }
      fetchTurmaAPI();
      setWasLoaded(x => ({...x, disciplina: true}));
    }
    return () => {};
  }, [modoArea, wasLoaded])

  useEffect(() => {
    if (!modoArea && turmaChanged && turmaID !== "") {
      async function fetchDesempenhoPorTurma() {
        // Pega disciplinas da turma
        await apis.listarDisciplinasPorTurma(turmaID)
          .then(res => {
            const disciplinas = res.data.data;
            var arrayMedias = [];
            var arrayFrequencias = [];
            
            
            disciplinas.forEach(async disciplinaID => {
              arrayMedias = [];
              arrayFrequencias = [];
              var array = [];
              var params = initialParams;
              try {
                // Gera análise para cada disciplina
                await apis.gerarAnaliseProfessor(disciplinaID._id)
                  .then(async res => {
                    var { data, progresso } = res.data;
                    
                    // Calcula progressos
                    var frequenciaTopicos = novaFrequencia;
                    
                    // Carrega dados de cada disciplina do professor
                    await apis.encDisciplinaPorID(disciplinaID._id)
                      .then(dis => {
                        var aux = dis.data.data;
                        data.disciplina = aux.turmaID.sigla + " - " + aux.nome;
                        data.disciplinaID = aux._id;
                        data.areaConhecimento = aux.areaConhecimento;
                        data.turmaID = aux.turmaID._id;
                        arrayMedias.push(data);

                        progresso.map(async row => {
                          if (row.length) {
                            await apis.calcularProgresso(row, data.topicos.melhor.tmID, data.topicos.pior.tpID)
                              .then(values => {
                                frequenciaTopicos.material.mEstudado += values.data.frequencia.material.mEstudado;
                                frequenciaTopicos.material.mTotal += values.data.frequencia.material.mTotal;
                                frequenciaTopicos.videoaula.vAssistido += values.data.frequencia.videoaula.vAssistido;
                                frequenciaTopicos.videoaula.vTotal += values.data.frequencia.videoaula.vTotal;
                                frequenciaTopicos.atividade.aFeito += values.data.frequencia.atividade.aFeito;
                                frequenciaTopicos.atividade.aTotal += values.data.frequencia.atividade.aTotal;
                              });
                          }
                        });
                        
                      });
                      // Salva frequências
                      arrayFrequencias.push(frequenciaTopicos);
                  });
                  
                  arrayMedias = arrayMedias.sort(function (a, b) {
                    if (a.disciplina > b.disciplina) return 1;
                    if (a.disciplina < b.disciplina) return -1;
                    return 0;
                  })
                } catch (error) { }
                
                // Pega atividades da turma
                try {
                  params.disciplinaID = disciplinaID._id;
                  await apis.listarAtividadePorFiltro(params)
                  .then(res => {
                    const value = res.data.data;
                    if (array.length) array = array.concat(value);
                    else array = value;
                  });
                  setListaAtividade(array);
                } catch (error) { }
              });
              
              setMedias(arrayMedias);
              setFrequencias(arrayFrequencias);
            })
        
          var arrayAD = [];
          const today = `${moment(new Date()).format("YYYY-MM-DD")}T08:00:00.000Z`;
          await apis.encAnoLetivoPorTurma(turmaID)
            .then(async res => {
              const ano = res.data.data;
              await apis.encRevisaoPorPeriodo(turmaID, ano.dataInicio, today)
                .then(rev => {
                  const value = rev.data.data;
                  if (value.length) {
                    if (arrayAD.length) arrayAD.concat(value);
                    else arrayAD = value;
                  }
                })
                .catch(err => {
                  console.log(err);
                });
              })
              .catch(err => {
                console.log(err);
              })
          setListaAds(arrayAD);
          setTurmaChanged(false);
      }
      fetchDesempenhoPorTurma();
    }
  }, [modoArea, turmaChanged, turmaID])

  function RetornaDesempenhoPrioritario() {
    return areaConhecimento.map((item, index) => {
      if (item.value !== "Redação") {
        return (
          <Grid item key={index + 1000} xs={12} sm={4} md={3} style={{minWidth: "16rem"}}>
            <AreaCard 
              areaConhecimento={item ? item.value : ""}
              dados={
                (index === 0) 
                  ? (analiseGeral === undefined ? analiseGeral.porArea.linguagens : "")
                  : (index === 1 )
                    ? (analiseGeral === undefined ? analiseGeral.porArea.matematicas : "")
                    : (index === 2 )
                      ? (analiseGeral === undefined ? analiseGeral.porArea.cienciasHumanas : "")
                      : (analiseGeral === undefined ? analiseGeral.porArea.cienciasNaturais : "")
              }
            />
          </Grid>
        );
      }
      return null;
    })
  }

  function RetornaDesempenhoExtra() {
    return areaConhecimento.map((item, index) => {
      if (item.value !== "Redação") {
        return (
          <Grid item key={index + 2000} xs={12} sm={4} md={3}>
          <SubjectCard 
            areaConhecimento={item ? item.value : ""}
            dados={
              (index === 0) 
              ? (analiseGeral === undefined ? analiseGeral.porDisciplina.linguagens : "")
                : (index === 1 )
                  ? (analiseGeral === undefined ? analiseGeral.porDisciplina.matematicas : "")
                  : (index === 2 )
                    ? (analiseGeral === undefined ? analiseGeral.porDisciplina.cienciasHumanas : "")
                    : (analiseGeral === undefined ? analiseGeral.porDisciplina.cienciasNaturais : "")
            }
            />
        </Grid>
        );
      }
      return null;
    })
  }

  function RetornaDisciplinas() {
    if (medias.length > 0 && frequencias.length > 0) {
      return (
        medias.map((media, index) => {
          var array = listaAtividade.filter(x => {return x.disciplinaID._id === media.disciplinaID});
          return (
            <div key={index + 3000} style={{paddingBottom: "1rem"}}>
              <AnalysisAccordion 
                disciplina={media.disciplina} 
                disciplinaID={media.disciplinaID}
                color={colors[index%5]} 
                data={media}
                frequencias={frequencias[index]}
                atividades={array}
              />
            </div>
          )
        })
      )
    }
  }

  function RetornaADs() {
    if (listaAds.length > 0) {
        var rev = listaAds
          .sort((a,b) => {
            if (a.dataLiberacao.dataInicio > b.dataLiberacao.dataInicio) return -1;
            if (a.dataLiberacao.dataInicio < b.dataLiberacao.dataInicio) return 1;
            return 0;
          });
  
        if (rev?.length > 0) {
          var sigla = medias.find(x => { return x.turmaID === rev[0].turmaID });
          if (sigla?.disciplina) sigla = sigla.disciplina.substring(0, sigla.disciplina.indexOf("-")).trim();
          var index = Math.floor(Math.random() * 4); 

          return (
            <div style={{paddingBottom: (index !== listaAds.length-1) ? "1rem" : 0}}>
              <RevisionAccordion 
                turma={sigla} 
                color={colors[index]} 
                data={rev}
              />
            </div>
          )
      }
    }
  }

  function Carregamento() {
    if (turmaChanged) {
      return (AccordionSkeleton(5));
    }
    else if (!turmaChanged && medias.length === 0) {
      return (
        <GeneralText>
          Selecione para qual turma deseja visualizar o desempenho por disciplina.
        </GeneralText>
      );
    }
  }

  function handleChange (event) {
    setTurmaID(event.target.value);
    setTurmaChanged(true);
    setListaAtividade([]);
    setListaAds([]);
  }

  return (
    <MyContainer>

      <section id="cabeçalho">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <GeneralTitle>Desempenho Geral</GeneralTitle>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.buttonGroup}>
            <ButtonGroup fullWidth color="primary" aria-label="outlined primary button group">
              <Tooltip title="Visualizar desempenho por área do conhecimento">
                <Button onClick={() => setModoArea(true)}>{smScreen ? "Área" : "Área do Conhecimento"}</Button>
              </Tooltip>
              <Tooltip title="Visualizar desempenho por disciplina">
                <Button onClick={() => setModoArea(false)}>Disciplina</Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </Grid>
      </section>

      <section id="desemporPorArea" hidden={!modoArea}>
        <GeneralSubtitle className={classes.smSubtitle}>Desempenho por Área</GeneralSubtitle>
        <Grid container spacing={2}>
          { RetornaDesempenhoPrioritario() }
        </Grid>

        <GeneralSubtitle className={classes.generalSubtitle}>Disciplinas em Destaque</GeneralSubtitle>
        <Grid container spacing={2}>
          { RetornaDesempenhoExtra() }
        </Grid>
      </section>

      <section id="desempenhoPorDisciplina" hidden={modoArea}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <GeneralSubtitle className={classes.smSubtitle}>Desempenho por Disciplina</GeneralSubtitle>
          </Grid>
          <Grid item xs={12} sm={6}>
              <MyTextField
                variant="outlined"
                select={true}
                label="Turma"
                name="turmaID"
                disabled={listaTurma.length === 0}
                value={turmaID ? turmaID : ""}
                onChange={handleChange}>
                  {
                    listaTurma.map((item, index) => {
                      return <MenuItem key={index} value={item._id}>{item.nome}</MenuItem>
                    })
                  }
              </MyTextField>
            <p>Apenas turmas ativas são listadas</p>
          </Grid>
        </Grid>
      </section>

      <section id="carregamento" hidden={modoArea}>
       { Carregamento() }
      </section>

      <section id="desempenhoDasDisciplinas" hidden={modoArea}>
        { RetornaDisciplinas () }
        <GeneralSubtitle hidden={listaAds.length === 0} style={{paddingTop: "1rem"}}>Resultado das ADs</GeneralSubtitle>
        { RetornaADs() }
      </section>

    </MyContainer>
  );
}