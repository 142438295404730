import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { GreenButton } from '../../assets/styles/styledComponents';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

export default function UploadButtons(props) {
  const { handleUpload, single, text, uploaded, fullWidth } = props;
  const classes = useStyles();

  return (
    <>
      <input
        accept="image/*, application/*"
        className={classes.input}
        name="foto"
        id="contained-button-photo"
        multiple={single}
        type="file"
        onChange={ handleUpload } />
      <label htmlFor="contained-button-photo">
       { 
        uploaded 
          ?
            <GreenButton 
              variant="contained" 
              component="span"
              fullWidth={fullWidth}
              className={classes.button}
              startIcon={<PhotoCamera />}>{text ? text : "Upload"}</GreenButton>
          :
            <Button
              variant="contained"
              color="primary"
              component="span"
              fullWidth={fullWidth}
              className={classes.button}
              startIcon={<PhotoCamera />}>{text ? text : "Upload"}</Button>
        }
      </label>
    </>
  );
}