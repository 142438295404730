import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
}))(LinearProgress);

export default function SimpleLinearProgress(props) {
  const { hidden } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fade in={!hidden} style={{animationDelay: "300ms"}}>
        <BorderLinearProgress />
      </Fade>
    </div>
  );
}