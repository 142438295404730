import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../utils";
import api from '../../api';

import { MyContainer, CreateButton, GeneralTitle } from "../../assets/styles/styledComponents";
import { ContentTable, GeneralDialog, PDFViewer } from '../../components';

const initialFilter = {
  turmaID: "",
  area: "",
  disciplinaID: "",
  numeracao: ""
}

export default function ContentList () {
  const { token } = useContext(StoreContext);
  const disciplinas = token.disciplina;
  const [content, setContent] = useState([])
  const [auxContent, setAuxContent] = useState([])
  const [mount, setMount] = useState({
    isMounting: true,
    wasChanged: false
  })
  const [open, setOpen] = useState(false);
  const [titulo, setTitulo] = useState('');
  const [id, setId] = useState('');
  const [filterDialog, setFilterDialog] = useState(false);
  const [filter, setFilter] = useState(initialFilter);

  async function fetchConteudoAPI() {
    var initialParams = {
      area: "0",
      turmaID: "0",
      disciplinaID: "0",
      numeracao: "0"
    }

    if (filter === initialFilter && disciplinas.length) {
      var arrayAux = [];
      
      disciplinas.forEach(async item => {
        try {
          var params = initialParams;
          params.disciplinaID = item;

          const response = await api.listarConteudoPorFiltroInterno(params);
          const value = response.data;
          
          if (arrayAux.length) {
            arrayAux = arrayAux.concat(value.data);
          } else {
            arrayAux = value.data;
          }
          setContent(arrayAux);
        } catch (error) { }
      });
    } 
    
    else {
      var firstSubject = await api.listarDisciplinas();
      firstSubject = firstSubject.data.data[0];
      setAuxContent(firstSubject._id);

      var params = initialParams;
      params.disciplinaID = firstSubject._id;

      const response = await api.listarConteudoPorFiltroInterno(firstSubject._id);
      if (response.data.success) {
        setContent(response.data.data);
      }
    }
  }

  // -- Carrega os Tópicos existentes no banco
  useEffect(() => {
    const abortController = new AbortController();

    // Carregamento inicial
    if (mount.isMounting) {
      fetchConteudoAPI();
      setMount(preValue => ({...preValue, isMounting: false}))
    }

    // Carregamento após cada mudança
    if (mount.wasChanged) {
      fetchConteudoAPI();
      setMount(preValue => ({...preValue, wasChanged: false}))
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [mount]);

  // -- Carrega tabela conforme disciplina selecionada no filtro
  useEffect(() => {
    const abortController = new AbortController();

    if (!mount.isMounting) {
      async function fetchContentByFilter(filter) {
        var query = {};

        query.area = filter?.area !== "" ? filter.area : "0";
        query.turmaID = filter?.turmaID !== "" ? filter.turmaID : "0";
        query.disciplinaID = filter?.disciplinaID !== "" ? filter.disciplinaID : "0";
        query.numeracao = filter?.numeracao !== "" ? filter.numeracao : "0";

        const response = await api.listarConteudoPorFiltroInterno(query);
        setContent(response.data.data);
      }
  
      if (filter !== initialFilter) {
        fetchContentByFilter(filter);
      }
      else if (auxContent !== '') {
        fetchContentByFilter(auxContent);
      }
    }

    return abortController.abort();
    // eslint-disable-next-line
}, [filter])

  return (
    <MyContainer>
      
      <section id="cabecalhoConteudo">
        <GeneralTitle>Conteúdos Disciplinares</GeneralTitle>
      </section>

      <section id="tabelaConteudo">
        <ContentTable 
          data={content} 
          open={open} 
          setOpen={setOpen} 
          setTitulo={setTitulo} 
          setId={setId}
          filterDialog={filterDialog}
          setFilterDialog={setFilterDialog}
          setMount={setMount}
          filter={filter}
          setFilter={setFilter}
          readOnly={token.accessType !== "Administrador"}
        />

        <GeneralDialog 
          open={open}
          setOpen={setOpen}
          dialogTitle={titulo === "" ? "Pré-Visualização" : titulo}
          dialogContent={<PDFViewer source={id} height={120}/>}
        />
      </section>

      <section id="rodapeConteudo" hidden={token.accessType !== "Administrador"}>
        <div className="create-button">
          <CreateButton title="Inserir Conteúdo" url="/controle-conteudo/create"/>
        </div>
      </section>
    
    </MyContainer>
  );
};