import React, { useContext, useState } from "react";
import {Link as RouterLink} from 'react-router-dom';
import api from '../../api'
import { StoreContext } from "../../utils";

import {makeStyles} from '@material-ui/core/styles';
import {Table, Backdrop, CircularProgress, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@material-ui/core';
import { SimpleSnackMessage } from "../../components";
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        color: "#606161"
    },
    row: {
        color: "#606161"
    },
    head: {
        color: "#606161",
        fontWeight: "bold"
    }
}));

// -- Atualização
function UpdateClass(props) {
    const {setTurma, turma, setTurmaID, setMount} = props;

    function AtualizandoTurma() {
        setTurmaID(turma._id)
        setTurma(turma);
        setMount(preValue => ({...preValue, turma: true}));
    }

    return (
        <IconButton aria-label="update" color="primary" size="small" onClick={AtualizandoTurma}>
            <EditIcon/>
        </IconButton>
    )
}

// -- Clonagem
function CloneClass(props) {
    const {id, nome, setMount, setLoading, setNoError, setMessage, setOpen } = props;
    const { token } = useContext(StoreContext);
    const msg = `Quer clonar a turma ${nome}?\nTodas as disciplinas,`+
                ` conteúdos, atividades, datas das avaliações diagnósticas `+
                `e AD's atreladas a turma ${nome} também serão clonadas `+
                `e esse processo pode levar alguns minutos.`

    async function ClonandoTurma() {
        if (window.confirm(msg)) {
            setLoading(true)
            await api
                .clonarTurma(id, token.userID)
                .then(res => {
                    setNoError(true);
                    setMessage(res.data.message);
                })
                .catch(err => {
                    setNoError(false);
                });
            setTimeout(() => { 
                setLoading(false) 
                setMount(preValue => ({...preValue, turma: true}));
                setOpen(true);
            }, 20000);
                
        }
    }

    return (
        <IconButton aria-label="clone" color="primary" size="small" onClick={ClonandoTurma}>
            <FileCopyIcon />
        </IconButton>
    )
}

// -- Remoção
function DeleteClass(props) {
    const {id, nome, setMount} = props;

    function RemovendoTurma() {
        if (window.confirm(`Quer remover a turma ${nome} permanentemente?`)) {
            api.removerTurma(id)
            setMount(preValue => ({...preValue, turma: true}));
        }
    }

    return (
        <RouterLink to={"/configuracoes"}>
            <IconButton
                aria-label="delete"
                color="secondary"
                size="small"
                onClick={RemovendoTurma}>
                <DeleteIcon/>
            </IconButton>
        </RouterLink>
    )
}

export default function ClassTable(props) {
    const {data, setTurma, setTurmaID, setMount} = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Houve um erro ao clonar a turma.");
    const [noError, setNoError] = useState(true);
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.head}>Nome</TableCell>
                            <TableCell className={classes.head}>Sigla</TableCell>
                            <TableCell className={classes.head}>Status</TableCell>
                            <TableCell className={classes.head}>Funções</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((row, index) => {
                                const {nome, sigla, status} = row;
                                return (
                                    <TableRow key={index}>
                                        <TableCell className={classes.row} component="th" scope="row">{nome}</TableCell>
                                        <TableCell className={classes.row} component="th" scope="row">{sigla}</TableCell>
                                        <TableCell className={classes.row} component="th" scope="row">{status ? "Ativa" : "Inativa"}</TableCell>

                                        <TableCell className={classes.row} component="th" scope="row">
                                            <UpdateClass turma={row} setTurma={setTurma} setMount={setMount} setTurmaID={setTurmaID}/>
                                            <CloneClass id={row._id} nome={row.nome} setMount={setMount} setLoading={setLoading} setNoError={setNoError} setMessage={setMessage} setOpen={setOpen} />
                                            <DeleteClass id={row._id} nome={row.nome} setMount={setMount}/>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <SimpleSnackMessage 
                open={open}
                setOpen={setOpen}
                message={message}
                error={noError}/>
        </>
    );
}