import React, { memo, useContext, useEffect, useState } from 'react';
import api from '../../../api';
import { AppBar, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import { StoreContext } from '../../../utils';
import { useStyles } from '../../../assets/styles/classes';
import { ActivityCard } from '../../'
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
}); 

function ExerciseDialog(props) {
    const { activity, open, setOpen, setCheck, title, name, progresso, setProgresso, setWasChecked, answered } = props;
    const classes = useStyles();
    const [questoes, setQuestoes] = useState([]); 
    const [respostas, setRespostas] = useState([]);
    const [wasLoaded, setWasLoaded] = useState(false);
    const token = useContext(StoreContext);
    const alunoID = token.token.userID;

    // -- Carrega as questões da atividade
    useEffect(() => {
        const abortController = new AbortController();
        if (open && activity && !wasLoaded) {
            // -- Fetch das questões
            async function fetchQuestaoAPI(atividadeID) {
                await api.encQuestoesDaAtividadeID(atividadeID)
                    .then(({data}) => {
                        setQuestoes(data.data.map(x => { return x.questaoID }));
                    });
            }

            // -- Fetch da resposta das questões
            async function fetchRespostasQuestaoAPI(atividadeID, tipo) {
                if (tipo === 'Avaliação Diagnóstica') {
                    await api.listarRQPorRevisaoID(alunoID, atividadeID)
                        .then(res => {
                            setRespostas(res.data.data.map(x => { return x.resposta }));
                        })
                }
                else {
                    await api.listarRQPorAtividadeID(alunoID, atividadeID)
                        .then(res => {
                            setRespostas(res.data.data.map(x => { return x.resposta }));
                        });
                }
            }

            fetchQuestaoAPI(activity._id);
            fetchRespostasQuestaoAPI(activity._id, activity.tipoAtividade);
            setWasLoaded(true);
        }
        return abortController.abort();
    }, [activity, alunoID, open, wasLoaded] )

    // -- Fechamento do card
    const handleClose = (event) => {
        setOpen(preValue => ({
            ...preValue,
            [name]: false
        }))
    };

    // -- Finalização da atividade (apenas salva o progresso)
    const handleFinalized = () => {
        setCheck(preValue => ({
            ...preValue,
            [name]: true
        }));
        setProgresso(progresso+1);
        setWasChecked(true);
        handleClose();
    };

    // -- Card contendo questões da Atividade
    const RetornaActivityCard = () => {
        if (questoes.length > 0) {
            return (
                <ActivityCard 
                    atividadeID={activity.tipoAtividade === 'Avaliação Diagnóstica' ? undefined : activity._id} 
                    revisaoID={activity.tipoAtividade !== 'Avaliação Diagnóstica' ? undefined : activity._id} 
                    name={name}                         // Tipo de exercício
                    handleClose={handleClose} 
                    handleFinalized={handleFinalized} 
                    respostas={respostas}               // Respostas da questão
                    questoes={questoes}                 // Questoes da atividade
                    answered={answered}                 // Informa se atividade já foi concluída
                    indice={1}
                />
            );
        }
    }

    return (
        <Dialog style={{overflow: "scroll"}} fullScreen={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton id="exercicioAprofundamento" edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>{title}</Typography>
                </Toolbar>
            </AppBar>

            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={12} sm={12} align='center'>
                { RetornaActivityCard() }
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default memo(ExerciseDialog);