import React, { useState, useEffect, useContext } from "react";
import { Divider, Fade, Grid, makeStyles } from '@material-ui/core';
import { GeneralTitle, GeneralSubtitle, MyContainer, GeneralText } from "../../assets/styles/styledComponents";
import { ContentAccordion, AccordionSkeleton } from "../../components";
import { currentWeek, diaDaSemana, StoreContext } from '../../utils';
import api from "../../api";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  grid: {
    width: '100%',
    padding: 0
  },
  secondaryHeading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  }
}));

const Title = (props) => {
  const {classes, day, week, isHidden, title} = props;
  return (
    <div hidden={isHidden}>
      <Grid container={true} spacing={3}>    
        <Grid item={true} xs={6} lg={6} sm={6}>
          <GeneralSubtitle className={classes.secondaryHeading}>{title}</GeneralSubtitle>
        </Grid>
        
        <Grid item={true} align="right" xs={6} lg={6} sm={6}>
          <GeneralSubtitle className={classes.secondaryHeading}>{diaDaSemana[day] + ", Semana " + week}</GeneralSubtitle>
        </Grid>
      </Grid>
    </div>
  );
}

export default function StudyPlan () {
  const classes = useStyles();
  const borderColor = [
    '#eb7120',
    '#94c93d',
    '#a283bc',
    '#fdc504',
    '#39b2d2',
  ];
  const { token } = useContext(StoreContext)
  const [content, setContent] = useState([]);
  const [nextContent, setNextContent] = useState([]);
  const [essay, setEssay] = useState([]);
  const [revision, setRevision] = useState(0);
  const [thisWeek, setThisWeek] = useState(0);
  const [currentDay, setCurrentDay] = useState(0);
  const [nextWeek, setNextWeek] = useState(0);
  const [nextDay, setNextDay] = useState(0);
  const turmaID = token.turmaID;
  const [isLoaded, setIsLoaded] = useState({
    content: false,
    essay: false,
    revision: false
  });
  const [isMounting, setIsMounting] = useState({
    content: true,
    revision: true,
    essay: true
  })

  // #region Retorno dos acordeões com os respectivos conteúdos
  // -- Retorna acordeões dos tópicos
  function returnContent(listaConteudo, isCurrent) {
    if (isLoaded.content && isLoaded.revision && isLoaded.essay) {
      if (!listaConteudo.length && revision.length === 0 && essay.length === 0) {
        return (
          <Grid className={classes.grid} item={true} style={{display: isCurrent ? "" : "none" }}>
            <Fade in={true} style={{transitionDelay: '250ms'}}>
              <GeneralText className={classes.secondaryHeading}>Não há conteúdo a ser estudado hoje, portanto, aproveite o descanso!</GeneralText>
            </Fade>
          </Grid>
        )
      } else {
        return listaConteudo.map((row, index) => {
          if(index === 0 && isCurrent) {

            return (
                <Grid className={classes.grid} key={row._id} item={true} xs={12} lg={12} sm={12}>
                  <ContentAccordion 
                    color={borderColor[index % 6]}
                    disciplinaNome={row.disciplinaID.nome}
                    topicoID={row._id} 
                    titulo={row.topico}
                    linkAula={row.videoAulaURL}
                    linkAulaOpcional={row.videoAulaExtraURL}
                    materialURL={row.conteudoURL}
                    tipoAcordeao="planoEstudo"
                    week={thisWeek}
                  />
                </Grid>
            )
          }
          return (
            <Grid className={classes.grid} key={row._id} item={true} xs={12} lg={12} sm={12}>
              <ContentAccordion 
                color={borderColor[index % 6]}
                disciplinaNome={row.disciplinaID.nome}
                topicoID={row._id} 
                titulo={row.topico}
                linkAula={row.videoAulaURL}
                linkAulaOpcional={row.videoAulaExtraURL}
                materialURL={row.conteudoURL}
                tipoAcordeao="planoEstudo"
                week={thisWeek}
              />
            </Grid>
          )
        })
      }
    }
    else {
      return (
        <Fade in={true} style={{transitionDelay: '50ms'}}>
          { AccordionSkeleton(1) }
        </Fade>
      );
    }
  }

  // -- Retorna acordeões dos ADs
  function returnRevision() {
    if (isLoaded.content && isLoaded.revision && isLoaded.essay && revision.length > 0) {
      return revision.map(x => {
        return (
          <Grid className={classes.grid} key={x._id} item={true} xs={12} lg={12} sm={12}>
            <ContentAccordion 
              color={borderColor[borderColor.length-1]}
              area={x.areaConhecimento}
              disciplinaNome={x.tipoAtividade}
              revisaoID={x._id} 
              revision={x}
              titulo={x.tipoAtividade}
              questoesAvDiag={x.questoes}
              tipoAcordeao="planoEstudo"
              semana={thisWeek}
            />
          </Grid>
        )
      })
    }
  }

  // -- Retorna acordeão de redação
  function returnEssay() {
    if (isLoaded.content && isLoaded.revision && isLoaded.essay && essay.length !== 0) {
      return essay.map(item => {
        return (
          <Grid key={item._id} className={classes.grid} item={true} xs={12} lg={12} sm={12}>
            <ContentAccordion 
              color={borderColor[borderColor.length-2]}
              area={"Linguagens"}
              disciplinaNome={"Redação"}
              essay={item} 
              titulo={"Redação de " + item.disciplinaID.nome}
              materialURL={item.topicoID.conteudoURL}
              tipoAcordeao="planoEstudo"
              semana={thisWeek}
            />
          </Grid>
        )}
      )
    }
  }
  // #endregion

  // -- Carrega tópicos por semana e dia da semana
  useEffect(() => {
    const abortController = new AbortController();

    // Pega semana e dia corrente
    async function fetchCurrentDate() {
      const auxWeek = await currentWeek(turmaID);
      const auxDay = new Date().getDay();

      if (auxDay === 6) {
        setNextWeek(auxWeek+1);
        setNextDay(0);
      } else {
        setNextWeek(auxWeek);
        setNextDay(auxDay+1);
      }

      setThisWeek(auxWeek);
      setCurrentDay(auxDay);
    }

    // Retorna tópicos conforme semana e dia correntes
    async function fetchConteudoAPI() {
      try {
        const response1 = await api.listarConteudoCorrente(thisWeek, currentDay, turmaID);
        var value = response1.data.data.filter(x => {return x.disciplinaID.areaConhecimento !== "Redação"});
        setContent(value);

        const response2 = await api.listarConteudoCorrente(nextWeek, nextDay, turmaID);
        value = response2.data.data.filter(x => {return x.disciplinaID.areaConhecimento !== "Redação"});
        setNextContent(value);
      } catch (error) { }
      
      setIsMounting(preValue => ({
        ...preValue,
        content: false
      }))
    }

    // Retorna AD conforme semana atual e área de conhecimento programada
    async function fetchRevisaoAPI() {
      var today = `${moment(new Date()).format("YYYY-MM-DD")}T08:00:00.000Z`;
      try {
        const response = await api.encRevisaoPorFiltro(turmaID, today);
        setRevision(response.data.data);
      } catch (error) { }
      setIsMounting(preValue => ({
        ...preValue,
        revision: false
      }))
    }

    // Retorna redação da semana atual
    async function fetchRedacaoAPI() {
      try {
        const response = await api.encRedacaoDaSemana(thisWeek, turmaID);
        setEssay(response.data.data);
      } catch (error) { }
      setIsMounting(preValue => ({
        ...preValue,
        essay: false
      }));
    }

    if (thisWeek > 0) {
      (isMounting.content) && fetchConteudoAPI();                               // Carrega tópicos do dia
      (isMounting.revision) && fetchRevisaoAPI();                               // Carrega ADs da semana
      (isMounting.essay) && fetchRedacaoAPI();                                  // Carrega Redação da semana

      setTimeout(() => setIsLoaded(preValue => ({
        ...preValue,
        content: true,
        revision: true,
        essay: true
      })), 1500);
    } else {
      fetchCurrentDate();
    }
    return abortController.abort();
  }, [thisWeek, currentDay, isMounting, turmaID, nextWeek, nextDay]);

  return (
    <MyContainer>
      
      <GeneralTitle>Plano de Estudo</GeneralTitle>
      
      <Title 
        classes={classes}
        day={currentDay}
        week={thisWeek}
        isHidden={false}
        title="Estudo do dia" />
      
      <Grid container={true} spacing={2}>
        { returnContent(content, true) }
        { returnEssay() }
        { returnRevision() }
      </Grid>

      <Divider width="100%" hidden={nextContent.length === 0} style={{margin: "1rem 0"}}/>

      <Title 
        classes={classes}
        day={nextDay}
        week={nextWeek}
        isHidden={nextContent.length === 0}
        title="Estudo do próximo dia" />
      
      <Grid container={true} spacing={2}>
        { returnContent(nextContent, false) }
      </Grid>
    </MyContainer>
  );
};
