import React, { useState, useEffect, useContext } from 'react';
import api from '../../../api';
import { GeneralSubtitle, GeneralTitle, MyContainer } from '../../../assets/styles/styledComponents';
import { AnalysisAccordion, AccordionSkeleton, RevisionAccordion } from "../../../components"
import { StoreContext } from '../../../utils';
import moment from "moment";

const colors = ["#eb7120", "#94c93d", "#a283bc", "#fdc504", "#39b2d2"]

const novaFrequencia = {
  material: {
    mEstudado: 0,
    mTotal: 0
  },
  videoaula: {
    vAssistido: 0,
    vTotal: 0
  },
  atividade: {
    aFeito: 0,
    aTotal: 0
  }
}

const initialParams = {
  tipo: "0",
  disciplinaID: "0",
  turmaID: "0",
  topico: "0",
  numeracao: "0",
  area: "0"
}

export default function TeacherAnalysis() {
  const { token } = useContext(StoreContext);
  const disciplinas = token.disciplina;
  const turmas = token.turmaID;
  const [wasLoaded, setWasLoaded] = useState({analise: false, atividades: false, ads: false});
  const [medias, setMedias] = useState([]);
  const [frequencias, setFrequencias] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [revisoes, setRevisoes] = useState([]);
  const today = `${moment(new Date()).format("YYYY-MM-DD")}T08:00:00.000Z`;

  // -- Carregamento dos dados
  useEffect(() => {
    const abortController = new AbortController();
    // Carrega análises
    if (disciplinas.length > 0 && !wasLoaded.analise) {
      async function gerarAnalise() {
        var arrayMedias = [];
        var arrayFrequencias = [];
        
        disciplinas.forEach(async disciplinaID => {
          try {
            // Gera análise para cada disciplina
            await api.gerarAnaliseProfessor(disciplinaID)
              .then(async res => {
                var { data, progresso } = res.data;
                
                // Carrega dados de cada disciplina do professor
                await api.encDisciplinaPorID(disciplinaID)
                  .then(dis => {
                    var aux = dis.data.data;
                    data.disciplina = aux.turmaID.sigla + " - " + aux.nome;
                    data.disciplinaID = aux._id;
                    data.areaConhecimento = aux.areaConhecimento;
                    data.turmaID = aux.turmaID._id;
                    arrayMedias.push(data);

                    // Calcula progressos
                    var frequenciaTopicos = novaFrequencia;
                    progresso.map(async row => {
                      if (row.length) {
                        await api.calcularProgresso(row, data.topicos.melhor.tmID, data.topicos.pior.tpID)
                          .then(values => {
                            frequenciaTopicos.material.mEstudado += values.data.frequencia.material.mEstudado;
                            frequenciaTopicos.material.mTotal += values.data.frequencia.material.mTotal;
                            frequenciaTopicos.videoaula.vAssistido += values.data.frequencia.videoaula.vAssistido;
                            frequenciaTopicos.videoaula.vTotal += values.data.frequencia.videoaula.vTotal;
                            frequenciaTopicos.atividade.aFeito += values.data.frequencia.atividade.aFeito;
                            frequenciaTopicos.atividade.aTotal += values.data.frequencia.atividade.aTotal;
                          });
                      }
                    });
    
                    // Salva frequências
                    arrayFrequencias.push(frequenciaTopicos);
                  });
              });

            arrayMedias = arrayMedias.sort(function (a, b) {
              if (a.disciplina > b.disciplina) return 1;
              if (a.disciplina < b.disciplina) return -1;
              return 0;
            })
          } catch (error) {
            console.log(error);
          }
        });
        
        setMedias(arrayMedias);
        setFrequencias(arrayFrequencias);
      }
      gerarAnalise();
      setWasLoaded(x => ({...x, analise: true}));
    }

    // Carrega as atividades
    if (disciplinas.length > 0 && atividades.length === 0 && !wasLoaded.atividades) {
      async function fetchAtividadeAPI() {
        var array = [];
        var params = initialParams;

        disciplinas.forEach(async disciplinaID => {
          try {
            params.disciplinaID = disciplinaID;
            await api.listarAtividadePorFiltro(params)
              .then(res => {
                const value = res.data.data;
                if (array.length) array = array.concat(value);
                else array = value;
              });
            setAtividades(array);
          } catch (error) { }
        });

        setWasLoaded(x => ({...x, atividades: true}));
      }
      fetchAtividadeAPI();
    }
  
    // Carrega ano letivo e ADs
    if (token.turmaID.length > 0 && !wasLoaded.ads) {
      async function FetchADs() {
        var arrayAD = [];

        turmas.forEach(async ({turmaID}) => {
          await api.encAnoLetivoPorTurma(turmaID)
            .then(async res => {
              const ano = res.data.data;
              await api.encRevisaoPorPeriodo(turmaID, ano.dataInicio, today)
                .then(rev => {
                  const value = rev.data.data;
                  if (value.length) {
                    if (arrayAD.length) arrayAD.concat(value);
                    else arrayAD = value;
                    setRevisoes(arrayAD.concat(value));
                  }
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err => {
              console.log(err);
            })
        })
        setWasLoaded(x => ({...x, ads: true}));
      }
      FetchADs();
    }
    return abortController.abort();
  }, [medias, atividades, disciplinas, wasLoaded, token, turmas, today]);

  // -- Retorna Acordeão das Disciplinas
  const RetornaDisciplinas = () => {
    if (wasLoaded.analise && wasLoaded.atividades &&  medias.length > 0) {
      return (
        medias.map((media, index) => {
          var array = atividades.filter(x => {return x.disciplinaID._id === media.disciplinaID});
          return (
            <div key={index} style={{paddingBottom: "1rem"}}>
              <AnalysisAccordion 
                disciplina={media.disciplina}
                disciplinaID={media.disciplinaID}
                color={colors[index%5]} 
                data={media}
                frequencias={frequencias[index]}
                atividades={array}
              />
            </div>
          )
        })
      )
    } else {
      return AccordionSkeleton(disciplinas.length);
    }
  }

  // -- Retorna Acordeão das ADs
  const RetornaADs = () => {
    if (wasLoaded.ads && medias.length === disciplinas.length) {
      if (revisoes.length > 0) {
        return turmas.map((turma, index) => {
          var rev = revisoes
            .filter(x => { return x.turmaID === turma.turmaID })
            .sort((a,b) => {
              if (a.dataLiberacao.dataInicio > b.dataLiberacao.dataInicio) return -1;
              if (a.dataLiberacao.dataInicio < b.dataLiberacao.dataInicio) return 1;
              return 0;
            });
    
          if (rev?.length > 0) {
            var sigla = medias.find(x => { return x.turmaID === rev[0].turmaID });
            if (sigla?.disciplina) sigla = sigla.disciplina.substring(0, sigla.disciplina.indexOf("-")).trim();
            
            return (
              <div key={index} style={{paddingBottom: (index !== revisoes.length-1) ? "1rem" : 0}}>
                <RevisionAccordion 
                  turma={sigla} 
                  color={colors[(index+disciplinas.length)%5]} 
                  data={rev}
                />
              </div>
            )
          }
          return null;
        });
      }
    } else {
      return AccordionSkeleton(turmas.length)
    }
  }

  return (
    <MyContainer>
      <GeneralTitle>Desempenho por Disciplina</GeneralTitle>
      { RetornaDisciplinas () }
      <GeneralSubtitle hidden={revisoes.length === 0} style={{paddingTop: "1rem"}}>Resultado das ADs</GeneralSubtitle>
      { RetornaADs() }
    </MyContainer>
  );
}