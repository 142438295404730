import React, { useContext, useEffect, useState } from "react"
import {withRouter} from "react-router-dom"
import { GeneralTimer, InactiveDialog, SideBar, TopBar } from "../../components"
import {PrivateRoutes} from "../../routes"
import clsx from "clsx"
import { Backdrop, CssBaseline } from "@material-ui/core"
import { GeneralText } from "../../assets/styles/styledComponents"
import { StoreContext, useWindowDimensions } from "../../utils"
import useStyles from "./styles"

function HomeScreen(props) {
    const classes = useStyles();
    const { token } = useContext(StoreContext);
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState();
    const [onBlur, setOnBlur] = useState(false);
    const [away, setAway] = useState(false);

    const heighScreen = useWindowDimensions();
    useEffect(() =>  setHeight(heighScreen.height - 108), [heighScreen.height]);
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <TopBar open={open} setOpen={setOpen}/>
            <SideBar open={open} setOpen={setOpen}/>
            <div className={clsx(classes.content, { [classes.contentShift]: open })}>
                <div className={classes.drawerHeader}/>
                <main className={classes.mainContent} style={{minHeight: height}}>
                    {   
                        away && 
                        <Backdrop open={away}>
                            <InactiveDialog away={away} setAway={setAway} setOnBlur={setOnBlur}/>
                        </Backdrop>
                    }
                    { token.accessType === "Aluno" && <GeneralTimer alunoID={token.userID} onBlur={onBlur} setOnBlur={setOnBlur} away={away} setAway={setAway} /> }

                    <PrivateRoutes onBlur={onBlur} setOnBlur={setOnBlur} away={away} setAway={setAway} />
                </main>
                <footer className={classes.footer}>
                    <GeneralText className={classes.footerText}>Copyright © 2020 Curso Intuitivo. Todos os direitos reservados.</GeneralText>
                </footer>
            </div>
        </div>
    );
}

export default withRouter(HomeScreen);