import React, { memo, useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, TextField } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import apis from '../../../api';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SimpleFeedback(props) {
    const { open, setOpen, title, message, questionID, redacaoURL } = props;
    const [comment, setComment] = useState('');

    async function adicionandoComentario() {
        if (questionID){
            const response = await apis.encRespostaQuestaoPorID(questionID);
            let novaResposta = response.data.data;
            novaResposta.comentario = comment;
            await apis.atualizarRespostaQuestao(questionID, novaResposta);
        }
        setOpen(false);
    }

    function handleChange(ev) {
        setComment(ev.target.value);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                {
                    message === 'addComment' 
                    ? <TextField multiline onChange={handleChange}/>
                    : <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={9}>
                        <div style={{textAlign: "left", display: (message === 'addComment' || redacaoURL === "") ? "none" : "block" }}>
                            <Button 
                                color="primary"
                                startIcon={<VisibilityIcon />}
                                onClick={() =>  window.open(redacaoURL)}
                                >
                                Visualizar Redação
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{textAlign: "right"}}>
                            <Button onClick={adicionandoComentario} color="primary">
                                {
                                    message === 'addComment' 
                                    ? 'Inserir comentário'
                                    : 'Ok'
                                }
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default memo(SimpleFeedback);