import React, {useState, useEffect} from 'react'
import { MyContainer, GeneralTitle } from "../../assets/styles/styledComponents"
import { ManageClass, ManageClassLink, ManageDateAds, ManageSubject, ManageTag, ManageYear, ManageClassification } from "../../components"
import api from '../../api'
import "moment/locale/pt-br";
import useStyles from "./styles"

// Main Function
export default function Settings() {
    const classes = useStyles();
    const [listaDisciplina, setListaDisciplina] = useState([]);
    const [mount, setMount] = useState({
        disciplina: false,
        isMountedSubject: true,
        tag: false,
        isMountedTag: true,
        classificacao: false,
        isMountedClassificacao: true,
        anoLetivo: false,
        isMountedYear: true,
        classLink: false,
        isMountedClassLink: true,
        turma: false,
        isMountedTurma: true,
        dataAd: false,
        isMountedDataAd: true
    })

    // Observa mudanças no conteúdo de listagem - Disciplina
    useEffect(() => {
        const abortController = new AbortController();
        // Caso haja mudança em disciplina
        // api.preencherTopicoQuestao();
        if (mount.disciplina) {
            async function fetchDisciplinaAPI() {
                const response = await api.listarDisciplinas();
                setListaDisciplina(response.data.data);
            }
            fetchDisciplinaAPI();
            setMount(preValue => ({...preValue, disciplina: false}));
        }
        return abortController.abort();
        // eslint-disable-next-line
    }, [mount]);

    return (
        <MyContainer>
            <GeneralTitle>Configurações</GeneralTitle>
            <section id="gerenciarDisciplina">
               <ManageSubject
                    listaDisciplina={listaDisciplina}
                    setListaDisciplina={setListaDisciplina}
                    mount={mount}
                    setMount={setMount}/>
            </section>
            
            <section id="gerenciarTurma" className={classes.section}>
                <ManageClass
                    mount={mount}
                    setMount={setMount}/>
            </section>

            <section id="gerenciarTag" className={classes.section}>
                <ManageTag 
                    listaDisciplina={listaDisciplina}
                    setListaDisciplina={setListaDisciplina}
                    mount={mount}
                    setMount={setMount}/>
            </section>

            <section id="gerenciarClassificacao" className={classes.section}>
                <ManageClassification 
                    mount={mount}
                    setMount={setMount}/>
            </section>
            
            <section id="gerenciarAnoLetivo" className={classes.section}>
                <ManageYear
                    mount={mount} 
                    setMount={setMount} />
            </section>
        
            <section id="gerenciarAulaOnline" className={classes.section}>
                <ManageClassLink 
                    mount={mount} 
                    setMount={setMount} />
            </section>

            <section id="gerenciarDateAds" className={classes.section}>
                <ManageDateAds 
                    mount={mount} 
                    setMount={setMount} />
            </section>
        </MyContainer>
    );
};