import api from '../../../api';

const checkEmailOnDatabase = async (email) => {
    try {
        const response = await api.encUsuarioPorEmail(email);
        return response.success;
    } catch (error) {
        return false;
    }
}

export default async function validate(values, editing) {
    let erros = {}, isValid = true;

    // Validar nome
    if (!values.nome || values.nome.length < 4) {
        erros.nome = "Nome muito curto."
        isValid = false;
    }
    
    if (!editing && await checkEmailOnDatabase(values.email)) {
        erros.email = "E-mail já em uso por outro usuário."
        isValid = false;
    }

    // Validar e-mail
    if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
        erros.email = "E-mail inválido."
        isValid = false;
    }

    // Validar acesso
    if (values.acesso === "") {
        erros.acesso = "Tipo de acesso inválido."
        isValid = false;
    }

    // Validar turma
    if (values.acesso !== "Administrador" && values.acesso !== "Responsável") {
        if (!values.turma.length) {
            var type = (values.acesso === "Aluno") ? "Alunos" : "Professores";
            erros.turma = `${type} precisam estar relacionados com ao menos uma turma.`
            isValid = false;
        }
    }

    // Validar turma
    if (values.acesso === "Aluno") {
        if (!values.plano.length) {
            erros.plano = `Aluno precisa pertencer a algum plano.`
            isValid = false;
        }
    }

    // Validar disciplina
    if (values.acesso === "Professor") {
        if (!values.disciplina.length) {
            erros.disciplina = "Professores precisam estar relacionados com ao menos uma disciplina."
            isValid = false;
        }
    }

    // Validar alunoID do responsável
    if (values.acesso === "Responsável") {
        if (!values.alunoID.length) {
            erros.alunoID = "Responsável precisa estar relacionado com um aluno."
            isValid = false;
        }
    }

    // Validar senha
    if (!editing && (!values.senha || values.senha.length < 6)) {
        erros.senha = "A senha deve conter ao menos 6 caracteres.";
        isValid = false;
    }

    if (editing && values.senha?.length && values.senha?.length < 6) {
        erros.senha = "A senha deve conter ao menos 6 caracteres.";
        isValid = false;
    }

    if (editing && values.senha.length) {
        if (!values.novaSenha1 || values.novaSenha1.length < 6) {
            erros.novaSenha1 = "A nova senha deve conter ao menos 6 caracteres.";
            isValid = false;
        }
    
        if (!values.novaSenha2 || values.novaSenha2.length < 6) {
            erros.novaSenha2 = "A nova senha deve conter ao menos 6 caracteres.";
            isValid = false;
        }
        
        if (values?.novaSenha1 !== values?.novaSenha2) {
            erros.novaSenha2 = "A senha fornecida deve ser igual à informada no campo anterior.";
            isValid = false;
        }
    }

    erros.validated = isValid
    return erros;
}