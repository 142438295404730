import React, {useState, useEffect, useContext} from 'react'
import { MyContainer, GeneralTitle, GeneralText } from "../../assets/styles/styledComponents"
import api from '../../api'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { MuralCard } from '../../components';
import { StoreContext } from '../../utils';
import moment from "moment";

// Styles
const useStyles = makeStyles((theme) => ({
  alignVertically: {
    margin: "auto"
  },
  cards: {
    padding: "1rem"
  },
  fab: {
    position: 'relative',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  }
}));

// Main Function
export default function StudentMural() {
  const classes = useStyles();
  const today = `${moment(new Date()).format("YYYY-MM-DD")}T03:00:00.000Z`;
  const [listaComunicado, setListaComunicado] = useState([]);
  const [listaTurma, setListaTurma] = useState([]);
  const { token } = useContext(StoreContext);

  useEffect(() => {
    async function fetchTurmaAPI() {
      await api.listarTurma()
        .then(res => setListaTurma(res.data.data))
        .catch(err => console.log(err));
    }
    fetchTurmaAPI();
    async function fetchComunicadoAPI() {
      try {
        await api.listarComunicado(token.turmaID, today)
          .then(res => setListaComunicado(res.data.data))
          .catch(err => console.log(err));
      } catch (error) {
        setListaComunicado([]);
      }
    }
    fetchComunicadoAPI();
  }, [today, token]);

  return (
    <MyContainer>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12} sm={10}>
          <GeneralTitle className={classes.alignVertically}>Meus Comunicados</GeneralTitle>
        </Grid>
      
        {
          listaComunicado.length > 0
            ?
              listaComunicado.map(x => {
                var turma = listaTurma.find(t => t._id === x.turmaID);
                return (
                  <Grid key={x._id} item xs={12} sm={listaComunicado.length > 1 ? 6 : 12}>
                    <MuralCard model={x} turma={turma} student={true} />
                  </Grid>
                )
              })
            :
              <Grid item xs={12}>
                <GeneralText>Ainda nenhum comunicado no seu mural.</GeneralText>
              </Grid>
        }

      </Grid>
    </MyContainer>
  );
};