import React, { memo, useContext, useState, useEffect } from 'react';
import { StoreContext } from '../../utils';
import { QuestionCard, VerificationAnswersDialog } from '../';
import { useStyles } from './styles';
import { Button, Grid, MobileStepper, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import api from '../../api';
import Axios from 'axios';

import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

function ActivityCard(props) {
	const { handleClose, handleFinalized, questoes, atividadeID, revisaoID, name, answered, indice } = props;
	const { token } = useContext(StoreContext);
	const alunoID = token.userID;
	const [marcou, setMarcou] = useState(true);
	const [numeracaoQuestao, setNumeracaoQuestao] = useState(1);        // Numeração da questão
	const [discursiva, setDiscursiva] = useState(false);                // Verifica se é discursiva
	const [respostaQuestaoIDs, setRespostaQuestaoIDs] = useState([]);
	const [respostaQuestao, setRespostaQuestao] = useState({});
	const [gabarito, setGabarito] = useState([]);
	const [newPhoto, setNewPhoto] = useState(false);
	const [verificarRespostas, setVerificarRespostas] = useState(false);
	const [respostas, setRespostas] = useState([]);
	const [wasLoaded, setWasLoaded] = useState(false);
	const [mounted, setMounted] = useState(false);
	const isEssay = (name === 'redacao') ? true : false;
	const classes = useStyles();
	const theme = useTheme();
	const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const letras = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
	
	async function criaRQ(questaoID, indice) {
		var res;

		// Encontra resposta questão do aluno
		if (atividadeID !== undefined) {
			res = await api.encRespostaQuestaoPorAtividade(atividadeID, alunoID, questaoID);
		} else {
			res = await api.encRespostaQuestaoPorRevisao(revisaoID, alunoID, questaoID);
		}
		// Se encontrar, recupera IDs
		if (res.data.success === true) {
			if (!respostaQuestaoIDs.find(x => x === res.data.data._id)) {
				let array = respostaQuestaoIDs;
				let aux = respostas;
				aux[indice] = verificaQuestao(questoes[indice], res.data.data, indice);
				array[indice] = res.data.data._id;
				setRespostaQuestaoIDs(array);
				setRespostas(aux);
				setMounted(true);
			}
		}

		// Se não encontrar, cria respostaQuestao
		else {
			await api.inserirRespostaQuestao({
				alunoID,
				atividadeID,
				revisaoID,
				questaoID: questaoID,
				resposta: '',
				corrigido: false,
				nota: 0,
				comentario: '',
				foto: [],
				contador: 0
			})
				.then((res) => {
					if (!respostaQuestaoIDs.find(element => element === res.data.id)) {
						let array = respostaQuestaoIDs;
						array[indice] = res.data.id;
						setRespostaQuestaoIDs(array);
					}
				});
			setMounted(true);
		}
	}

	//#region useEffects
	// -- Guarda informação das respostas das questões;
	//    infoArray guarda os objetos { gab: gabarito da questão, quest: questaoID }
	useEffect(() => {
		const abortController = new AbortController();
		if (questoes.length > 0 && gabarito.length === 0) {
			var infoArray = [];
			questoes.forEach((questao, index) => {
				if (questao.tipoResposta !== 'multiplaEscolha') setDiscursiva(true);
				var gab = questao.resposta.find(x => x.gabarito === true);
				var quest = questao._id;
				criaRQ(quest, index);
				return infoArray[index] = { gab, quest }
			});
			setGabarito(infoArray);
		}
		return abortController.abort();
		// eslint-disable-next-line
	}, [questoes])

	// -- Atualiza a respostaQuestao no banco antes de trocar para a próxima questão
	useEffect(() => {
		const abortController = new AbortController();
		if (respostaQuestao?._id !== undefined) {
			atualizaRQ(respostaQuestao?._id, respostaQuestao);
		}
		setNumeracaoQuestao(indice);
		setWasLoaded(false);
		return abortController.abort();
		// eslint-disable-next-line
	}, [indice])

	// -- Atualiza o cartão resposta do aluno
	useEffect(() => {
		const abortController = new AbortController();
		if (questoes.length > 0) {
			var array = respostas;
			array[numeracaoQuestao - 1] = verificaQuestao(questoes[numeracaoQuestao - 1], respostaQuestao, numeracaoQuestao - 1);
			setRespostas(array);
		}
		return abortController.abort();
		// eslint-disable-next-line
	}, [respostaQuestao, questoes])
	//#endregion

	// -- Verifica se o aluno respondeu determinada questão e, caso seja múltipla-escolha, a alternativa marcada.
	const verificaQuestao = (questao, resultado, num) => {
		const { _id, comentario, resposta, tipoResposta } = questao;
		var aux = {
			questaoID: _id,
			numero: num,
			resposta: "Sem Resposta",
			letra: "",
			tipo: "",
			comentario
		}

		//--Múltipla-Escolha Respondida
		if (tipoResposta === "multiplaEscolha") {
			const index = resposta.findIndex(x => x._id === resultado?.resposta);
			const letter = (index >= 0) ? letras[index] : "N";
			aux.tipo = "multiplaEscolha";
			//--Respondido
			if (letter !== "N") {
				aux.resposta = "Respondido";
				aux.letra = letter;
			}
		}

		//--Discursiva Respondida
		if (tipoResposta === "discursiva" && (resultado?.foto?.length > 0 || resultado?.resposta !== "")) {
			aux.tipo = "discursiva";
			aux.resposta = "Respondido";
		}

		return aux;
	}

	// -- Salva URL das fotos
	const salvarUrls = async () => {
		// -- Salva foto na AWS
		const salvarFoto = async (foto, index) => {
			var url;
			const data = new FormData();
			data.append("resposta", foto);
			await Axios
				.post(`${process.env.REACT_APP_API_URL}upload-resposta/${respostaQuestao._id}/${index}`, data)
				.then(res => url = res.data.url)
				.catch(err => console.log(err));
			return url;
		}

		// -- Salva cada imagem no bucket
		var aux = [];
		Array.from(respostaQuestao.foto).map(async (row, index) => { return aux.push(salvarFoto(row, index)) })
		const urls = await Promise.all(aux);

		var novaRQ = respostaQuestao;
		novaRQ.foto = urls;

		await api.atualizarRespostaQuestao(respostaQuestao._id, novaRQ)
			.then(() => {
				setNewPhoto(false);
			});
	}

	// -- Atualiza a resposta questão antes de trocar de índice
	const atualizaRQ = async (id, resposta) => {
		await api.atualizarRespostaQuestao(id, resposta)
			.then(() => {
				if (newPhoto) salvarUrls();
			});
	}

	// -- Função para verificar se a atividade já foi respondida pelo aluno ou não
	const verificaProgresso = () => {
		if (revisaoID) return answered === true;
		if (isEssay) return answered === true;
		return answered[name];
	}

	//#region Funções de Navegação
	//==================================

	// -- Passa para a próxima questão
	const proximaQuestao = async () => {
		await api.atualizarRespostaQuestao(respostaQuestao._id, respostaQuestao)
			.then(() => {
				if (newPhoto) salvarUrls();
				setNumeracaoQuestao(numeracaoQuestao + 1);
				setWasLoaded(false);
			})
	}

	// -- Passa para a questão anterior
	const questaoAnterior = async () => {
		await api.atualizarRespostaQuestao(respostaQuestao._id, respostaQuestao)
			.then(() => {
				if (newPhoto) salvarUrls();
				setNumeracaoQuestao(numeracaoQuestao - 1);
				setWasLoaded(false);
			})
	}

	// -- Sair da atividade
	const sairAtividade = async () => {
		await api.atualizarRespostaQuestao(respostaQuestao._id, respostaQuestao).then(() => {
			if (newPhoto) salvarUrls();
			handleClose();
		});
	}

	//#endregion

	// -- Corrige questões multipla-escolha
	const correctActivity = async () => {
		var resultado = 0;
		var auxiliar;

		if (revisaoID !== undefined) auxiliar = await api.listarRQPorRevisaoID(alunoID, revisaoID);
		else auxiliar = await api.listarRQPorAtividadeID(alunoID, atividadeID);

		auxiliar.data.data.forEach(async (rq) => {
			resultado += rq.nota;
		})

		return resultado / auxiliar.data.data.length;
	}

	// -- Atualiza RespostaQuestao e cria RespostaAtividade
	const handleSubmit = async () => {
		let listarRQs = api.listarRQPorAtividadeID;
		let activityID = atividadeID;
		if (atividadeID === undefined) {
			listarRQs = api.listarRQPorRevisaoID;
			activityID = revisaoID;
		}
		await listarRQs(alunoID, activityID).then(async (response) => {
			if(response.data.success) {
				
				response.data.data.forEach(async (respostaQuestaoID, indice) => {
					if(questoes.find(questao => questao._id === respostaQuestaoID.questaoID).tipoResposta !== "discursiva") {
						respostaQuestaoID.corrigido = true;
						await api.atualizarRespostaQuestao(respostaQuestaoID._id, respostaQuestaoID);
					}
				})
				
				// -- Salva Url das Imagens
				if (newPhoto) await salvarUrls();

				let notaTotal = 0;
				// -- Corrige caso não possua questão de multipla-escolha
				if (!discursiva) notaTotal = await correctActivity();

				// -- Cria RespostaAtividade
				const respostaAtividade = {
					alunoID,
					atividadeID,
					revisaoID,
					respostaQuestaoIDs,
					nota: notaTotal,
					corrigido: !discursiva,
					dataRealizacao: new Date()
				}

				// -- Insere RespostaAtividade
				await api.inserirRespostaAtividade(respostaAtividade).then(() => {
					handleFinalized()
				});
			}
		})
	};

	// -- Verifica a última questão e exibe o cartão resposta.
	const exibirCartaoResposta = async () => {
		await api.atualizarRespostaQuestao(respostaQuestao._id, respostaQuestao)
			.then(() => {
				if (newPhoto) salvarUrls();
				setWasLoaded(false);
			})
		var array = respostas;
		array[numeracaoQuestao - 1] = verificaQuestao(questoes[numeracaoQuestao - 1], respostaQuestao, numeracaoQuestao-1);
		setRespostas(array)
		setVerificarRespostas(true);
	}

	// -- Calcula nota e atualiza respostaQuestao
	const corrigirQuestao = async () => {

		if(respostaQuestao?.questaoID === questoes[numeracaoQuestao - 1]._id) {
			if (questoes[numeracaoQuestao - 1].tipoResposta === "multiplaEscolha") {
				let nota = (respostaQuestao.resposta === gabarito[numeracaoQuestao - 1].gab._id) ? 100 : 0;
				setRespostaQuestao( prev => ({
					...prev, 
					nota: nota,
					corrigido: true
				}));
			} else {
				setRespostaQuestao( prev => ({
					...prev, 
					corrigido: true
				}));
			}
			// -- Salva resposta do aluno após cada atualização
			await api.atualizarRespostaQuestao(respostaQuestao._id, respostaQuestao)
			.then(() => setMarcou(!marcou))
			.catch(err => console.log(err));
		}
	}

	return (
		<Grid container={true} justifyContent="center" className={classes.question} spacing={2}>
			
			{/* Navegação Mobile */}
			{
				smScreen &&
				<Grid item xs={12}>
					<Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
						<Grid item xs={12} sm={4}>
							<MobileStepper
								variant="text"
								steps={questoes.length}
								position="bottom"
								activeStep={numeracaoQuestao - 1}
								nextButton={
									<Button color="primary" onClick={proximaQuestao} disabled={numeracaoQuestao - 1 === questoes.length - 1} >
										Próxima
										<KeyboardArrowRight />
									</Button>
								}
								backButton={
									<Button color="primary" onClick={questaoAnterior} disabled={numeracaoQuestao - 1 === 0} >
										<KeyboardArrowLeft />
										Anterior
									</Button>
								}
							/>
							
						</Grid>
					</Grid>
				</Grid>
			}

			{/* QuestionCard */}
			{questoes.length > 0 && 
				<Grid item className={classes.question} alignItems="center" xs={isEssay ? 11 : 10}>
					<Typography variant="h6" className={classes.title}>{isEssay ? "Enunciado" : "Questão " + (numeracaoQuestao)}</Typography>
					<QuestionCard
						questaoID={questoes[numeracaoQuestao - 1]._id}
						enunciado={questoes[numeracaoQuestao - 1].enunciado}
						tipoResposta={questoes[numeracaoQuestao - 1].tipoResposta}
						padraoResposta={questoes[numeracaoQuestao - 1].padraoResposta}
						resposta={questoes[numeracaoQuestao - 1].resposta}
						gabarito={gabarito[numeracaoQuestao - 1]}
						atividadeID={atividadeID}
						revisaoID={revisaoID}
						respostaQuestao={respostaQuestao}
						setRespostaQuestao={setRespostaQuestao}
						alunoID={alunoID}
						answered={answered[name] || (respostaQuestao && respostaQuestao.questaoID === questoes[numeracaoQuestao - 1]._id && respostaQuestao.corrigido)}
						setNewPhoto={setNewPhoto}
						marcou={marcou}
						setMarcou={setMarcou}
						wasLoaded={wasLoaded}
						setWasLoaded={setWasLoaded}
						isEssay={isEssay}
						mounted={mounted}
					/>
				</Grid>
			}

			{/* Rodapé */}
			<Grid item xs={12} sm={8}>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
					<Grid item xs={6}>
						<Button
							fullWidth={true}
							variant='contained'
							color="secondary"
							onClick={sairAtividade}>
							Voltar
                      </Button>
					</Grid>
					{
						!answered[name] && (respostaQuestao && questoes.length > 0 && respostaQuestao.questaoID === questoes[numeracaoQuestao - 1]?._id && !respostaQuestao.corrigido) &&
						<Grid item xs={12} sm={6}>
							<Button
								// disabled={respostaQuestao?.foto?.length === 0 ? true : false}
								color="primary"
								variant="contained"
								fullWidth={true}
								onClick={corrigirQuestao}
								startIcon={<CheckRoundedIcon />}
								endIcon={<ClearRoundedIcon />}
							>Corrigir Questão</Button>
						</Grid>
					}
					{
						numeracaoQuestao === questoes.length && !isEssay && !verificaProgresso() &&
							<Grid item xs={12}>
								<Button
									fullWidth={true}
									variant='contained'
									color="primary"
									onClick={exibirCartaoResposta}>Concluir Atividade</Button>
							</Grid>
					}
				</Grid>
			</Grid>

			{/* Diálogo de Verificação Final */}
			<VerificationAnswersDialog
				open={verificarRespostas}
				setOpen={setVerificarRespostas}
				handleSubmit={handleSubmit}
				respostas={respostas} />
		</Grid>
	)
}

export default memo(ActivityCard);