import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom'
import api from '../../api'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, MenuItem } from '@material-ui/core';
import { SelectQuestionTable, QuestionDialog, DndCard } from "../../components";
import { MyContainer, MyTextField, AddButton, DeleteButton, GeneralTitle } from "../../assets/styles/styledComponents"
import { areaConhecimento, StoreContext, tipoAtividade } from "../../utils";
import moment from "moment";
import "moment/locale/pt-br";
import SimpleLinearProgress from "../ProgressBar/SimpleLinearProgress";
import { Alert } from "@material-ui/lab";

// -- Estilos locais
const useStyles = makeStyles((theme) => ({
    alert: {
        backgroundColor: "#fbab18",
        marginBottom: "0.625rem"
    },
    buttons: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1)
    },
    group: {
        textAlign: "center",
        marginTop: "2rem"
    },
    errorMessage: {
        fontSize: "0.75rem",
        paddingLeft: "1rem",
        color: "#f44336"
    },
    errorAlarm: {
        border:"1px solid #f44336"
    }
}));

// -- Ajuste de data para data das ADs
const DisplayDate = (item) => {
    var dataInicio = moment(item.dataInicio).format("DD");
    var dataFim = moment(item.dataFim).format("DD/MM");
    return (`${dataInicio}-${dataFim}`);
}

export default function ActivityForm (props) { 
    const { atividade, setAtividade, saveActivity, saveRevision, initialState, isRevision, clear, questaoSelecionada, setQuestaoSelecionada, classificacao, setClassificacao } = props;

    const { token } = useContext(StoreContext);
    const classes = useStyles();
    const [listaDisciplina, setListaDisciplina] = useState([]);                 // Disciplinas do Banco de Dados
    const [listaTurma, setListaTurma] = useState([]);                           // Turma do Banco de Dados
    const [topicos, setTopicos] = useState([]);                                 // Tópicos do Banco de Dados
    const [questoes, setQuestoes] = useState([]);                               // Questões do Banco de Dados
    const [revisao, setRevisao] = useState(isRevision);                         // Flag para verificar se é revisão
    const [disciplinas, setDisciplinas] = useState([]);
    const [filterDialog, setFilterDialog] = useState(false);
    const [hiddenDialog, setHiddenDialog] = useState(false);
    const [listaData, setListaData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mount, setMount] = useState(false);
    const [questionPreview, setQuestionPreview] = useState(false);
    const [changed, setChanged] = useState({
        disciplinaID: false,
        topicoID: false,
        turmaID: false,
        areaConhecimento: false,
        tipoAtividade: false
    });
    const [initialFilter, setInitialFilter] = useState({
        disciplinaID: "",
        topicoID: "",
        turmaID: "",
        tipo: "",
        tags: "",
        areaConhecimento: ""
    });
    const [filter, setFilter] = useState(initialFilter);

    // -- Salva alterações do formulário
    async function handleChange (event) {
        const {name, value} = event.target;
        setAtividade(preValue => ({
            ...preValue,
            [name]: value
        }));
        
        setChanged(preValue => ({
            ...preValue,
            [name]: true
        }));
        
        name !== 'tipoAtividade' && setInitialFilter(preValue => ({
            ...preValue,
            [name]: value
        }));

    }
    
    // -- Salva Disciplina/Tópico e Área do Conhecimento juntos
    async function handleSubjectChange (nome, id, area) {
        if (nome === "disciplinaID") {
            setAtividade(preValue => ({
                ...preValue,
                [nome]: id,
                areaConhecimento: area
            }));
        } else {
            setAtividade(preValue => ({
                ...preValue,
                [nome]: id
            }));
        }
        setInitialFilter(preValue => ({
            ...preValue,
            [nome]: id,
        }));
        setChanged(preValue => ({
            ...preValue,
            [nome]: true
        }));
    }

    // -- Informa se questões estão vinculadas a alguma atividade
    async function verificaQuestoes (disciplinaID, topicoID, area, atividade) {
        const res = await api.verificarQuestao((disciplinaID || 'disciplina'), (topicoID || 'topico'), (area || 'area'), (atividade || "Nenhum"));
        return res.data;
    }

    function removerQuestao (id) {
        let aux = questaoSelecionada;
        aux = aux.filter(x => x.id !== id);
        setQuestaoSelecionada(aux);
    }

    useEffect(() => {
        if(atividade && initialState && atividade !== initialState) {
            setInitialFilter((x) => ({
                ...x,
                disciplinaID: atividade.disciplinaID,
                topicoID: atividade.topicoID,
                turmaID: atividade.turmaID,
                tipo: atividade.tipo,
                areaConhecimento: atividade.areaConhecimento
            }))
        }
        //eslint-disable-next-line
    }, [atividade.disciplinaID, atividade.topicoID, atividade.turmaID, atividade.tipo, atividade.areaConhecimento, initialState])

    // -- Observa mudanças no filtro inicial
    useEffect(() => {
        const abortController = new AbortController();
        setFilter(initialFilter);
        return abortController.abort();
        // eslint-disable-next-line
    }, [initialFilter]);

    // -- Carregamentos iniciais
    useEffect(() => {
        const abortController = new AbortController();
        // Carrega Turma
        async function fetchTurmaAPI() {
            await api.listarTurma()
                .then(res => {
                    setListaTurma(res.data.data);
                });
        }
        // Carrega Disciplinas do Professor
        async function fetchDisciplinaAPI() {
            await api.listarDisciplinas()
            .then(res => {
                var value = res.data.data;

                // Filtra disciplina pelas disciplinas do professor
                if (token.accessType === "Professor" && token.disciplina.length)
                    value = res.data.data.filter(x => { return token.disciplina.includes(x._id) });

                if (atividade.turmaID !== "")
                    setDisciplinas(value.filter(x => { return x.turmaID === atividade.turmaID }));
                
                setListaDisciplina(value);
            });
        }
        fetchTurmaAPI();
        fetchDisciplinaAPI();
        return abortController.abort();
        // eslint-disable-next-line
    }, []);

    // -- Altera o valor de revisão e limpa campos, caso o tipo de exercício seja igual à revisao
    useEffect(() => { 
        // -- Observa mudanças em TurmaID
        if (changed.turmaID) {
            setAtividade(preValue => ({
                ...preValue,
                disciplinaID: "",
                topicoID: ""
            }));
            setInitialFilter(preValue => ({
                ...preValue,
                disciplinaID: "",
                topicoID: ""
            }));
            setTopicos([]);
            setChanged(preValue => ({
                ...preValue,
                turmaID: false
            }));
        }
        // -- Observa mudanças em DisciplinaID
        if (changed.disciplinaID) {
            setAtividade(preValue => ({
                ...preValue,
                topicoID: ""
            }));
            setInitialFilter(preValue => ({
                ...preValue,
                topicoID: ""
            }));
            setTopicos([]);
            setChanged(preValue => ({
                ...preValue,
                disciplinaID: false
            }));
        }
        // -- Observa mudanças em TopicoID
        if (changed.topicoID) {
            setChanged(preValue => ({
                ...preValue,
                topicoID: false
            }));
        }
    }, [changed, setAtividade, setQuestaoSelecionada])

    // -- Altera o valor de revisão e limpa campos, caso o tipo de exercício seja igual à revisao
    useEffect(() => { 

        // Verifica se é uma avaliação diagnóstica
        if(changed.tipoAtividade) {
            if (atividade.tipoAtividade === "Avaliação Diagnóstica") {
                // Limpa campos Disciplina, Tópico e Questões
                setAtividade(prevValue => ({ ...prevValue, topicoID: "", disciplinaID: "", areaConhecimento: "", turmaID: "" }));
                setInitialFilter(prevValue => ({ ...prevValue, topicoID: "", disciplinaID: "", areaConhecimento: "", turmaID: "" }));
                setChanged(preValue => ({
                    ...preValue,
                    tipoAtividade: false
                }));
                setRevisao(true);
            } else if(atividade.tipoAtividade !== "Avaliação Diagnóstica") {
                setRevisao(false);
            }
        }

    }, [atividade, setAtividade, changed])

    // -- Carrega os Tópicos, por Disciplina, existentes no banco
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchClassificacaoAPI() {
            if (atividade.topicoID !== '' && topicos.length) {
                const topico = topicos.find(x => x._id === atividade.topicoID);
                if(topico.classificacao && topico.classificacao._id !== classificacao?._id ) {
                    await api.encClassificacaoPorID(topico.classificacao._id)
                        .then(res => {
                            setClassificacao(res.data.data);
                        })
                        .catch(err => {
                            console.log(err);
                            setClassificacao();
                        });
                }
            }
        }
        !revisao && fetchClassificacaoAPI();
        return abortController.abort();
    }, [revisao, atividade.topicoID, topicos, classificacao, setClassificacao]);

    // -- Carrega os Tópicos, por Disciplina, existentes no banco
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchTopicoAPI() {
            if (atividade.disciplinaID !== '') {
                var params = {
                    area: "0",
                    turmaID: "0",
                    disciplinaID: atividade.disciplinaID,
                    numeracao: "0"
                }
                await api.listarConteudoPorFiltroInterno(params)
                    .then(res => {
                        setTopicos(res.data.data);
                    })
                    .catch(err => {
                        console.log(err);
                        setTopicos([]);
                    });
            }
        }
        !revisao && fetchTopicoAPI();
        return abortController.abort();
    }, [atividade.disciplinaID, changed, revisao]);

    // -- Carrega as Questões, por Área do conhecimento, existentes no banco
    useEffect(() => {
        const abortController = new AbortController();
        setMount(true);
        // async function fetchQuestoesPorAreaAPI() {
        //     if (atividade.areaConhecimento !== '') {
        //         setLoading(true);
        //         await api.listarQuestaoPorArea(atividade.areaConhecimento)
        //             .then(async (res) => {
        //                 await verificaQuestoes(null, null, atividade.areaConhecimento, atividade.tipoAtividade)
        //                 .then(({data}) => {
        //                     var auxiliar = [];
        //                     res.data.data.map(item => {
        //                         if (data.includes(item.questaoID._id)) item.questaoID.vinculada = "Sim"
        //                         else item.questaoID.vinculada = "Não"
        //                         auxiliar.push(item);
        //                         return null;
        //                     });
        //                     setQuestoes(auxiliar)
        //                 });
        //             })
        //             .catch(err => {
        //                 console.log(err);
        //             });
        //         setLoading(false);
        //     }
        // }
        async function fetchQuestoesPorFiltroAPI() {
            if (atividade.topicoID !== '' || atividade.areaConhecimento !== '') {
                setLoading(true);
                var query = {
                    disciplinaID: filter?.disciplinaID !== "" ? filter.disciplinaID : "0",
                    topicoID: filter?.topicoID !== "" ? filter.topicoID : "0",
                    turmaID: filter?.turmaID !== "" ? filter.turmaID : "0",
                    tipo: filter?.tipo !== "" ? filter.tipo : "0",
                    area: filter?.areaConhecimento !== "" ? filter.areaConhecimento : "0"
                };
                await api.listarQuestaoPorFiltro(query)
                .then(async (res) => {
                    await verificaQuestoes(atividade.disciplinaID, atividade.topicoID, null, atividade.tipoAtividade)
                    .then(async ({data}) => {
                        var auxiliar = [];
                        await res.data.data.map(item => {
                            if (data.includes(item.questaoID._id)) item.questaoID.vinculada = "Sim"
                                    else item.questaoID.vinculada = "Não"
                                    auxiliar.push(item);
                                    return null;
                                })
                                setQuestoes(auxiliar);
                            });
                            setLoading(false);
                        })
                        .catch(err => {
                            console.log(err);
                        });
            }
        }
        
        if(filter.topicoID !== "" || filter.areaConhecimento) !mount && fetchQuestoesPorFiltroAPI();
        setMount(false);
        
        return abortController.abort();
        //eslint-disable-next-line 
    }, [revisao, filter, mount, setMount]);
            
    // -- Carrega datas das ADs
    useEffect(() => {
        const abortController = new AbortController();
        if (atividade.tipoAtividade !== "Avaliação Diagnóstica" && atividade.turmaID !== "") {
            setDisciplinas(listaDisciplina.filter(x => { return x.turmaID === atividade.turmaID }));
        } 
        else if (atividade.areaConhecimento !== "") {
            async function fetchDataAdsAPI() {
                await api.listarDataAdPorTurma(atividade.turmaID)
                    .then(res => {
                        // Filtra ADs por área do conhecimento
                        const value = res.data.data.filter(x => {return x.areaConhecimento === atividade.areaConhecimento});
                        setListaData(value);
                        // Limpa data de liberação caso ocorra mudança em turma ou area do conhecimento
                        if (value.find(x => x._id === atividade.dataLiberacao) === undefined)
                            setAtividade(x => ({...x, dataLiberacao: ""}));
                    });
            }
            fetchDataAdsAPI();
        }
        return abortController.abort();
    }, [atividade.areaConhecimento, atividade.dataLiberacao, atividade.tipoAtividade, atividade.turmaID, listaDisciplina, setAtividade])

    // -- Observa mudanças em questão selecionada
    useEffect(() => { 
        const abortController = new AbortController();
        setAtividade(preValue => ({
            ...preValue,
            questoes: questaoSelecionada
        }));
        return abortController.abort();
    }, [questaoSelecionada, setAtividade]);

    // -- Limpa os campos do formulário
    useEffect(() => {
        const abortController = new AbortController();
        if (clear) {
            setAtividade(initialState)
            setQuestoes([])
            setQuestaoSelecionada([])
            setInitialFilter({
                disciplinaID: "",
                topicoID: "",
                turmaID: "",
                tipo: "",
                areaConhecimento: ""
            })
        }
        return abortController.abort();
    }, [clear, initialState, setAtividade, setQuestaoSelecionada]);

    return (
        <MyContainer>
            <section id="cabecalhoAtividade">
                <GeneralTitle>Inserir Atividades</GeneralTitle>

                <Grid container={true} spacing={1}>
                    <Grid item={true} xs={12} sm={6}>
                        <MyTextField
                            variant="outlined"
                            select={true}
                            label="Tipo de Atividade"
                            name="tipoAtividade"
                            value={atividade.tipoAtividade ? atividade.tipoAtividade : ""}
                            onChange={handleChange}
                            error={atividade.erros.tipoAtividade ? true : false}>
                                {
                                    tipoAtividade.map((item, index) => {
                                        return <MenuItem key={index} value={item.value}>{item.value}</MenuItem>
                                    })
                                }
                        </MyTextField>
                        {atividade.erros.tipoAtividade && <p className={classes.errorMessage}>{atividade.erros.tipoAtividade}</p>}
                    </Grid>

                    <Grid item={true} xs={12} sm={6}>
                        <MyTextField
                            variant="outlined"
                            select={true}
                            label="Turma"
                            name="turmaID"
                            value={atividade.turmaID ? atividade.turmaID : ""}
                            onChange={handleChange}
                            error={atividade.erros.turma ? true : false}>
                                {
                                    listaTurma.map((item, index) => {
                                        return <MenuItem key={index} value={item._id}>{item.nome}</MenuItem>
                                    })
                                }
                        </MyTextField>
                        {atividade.erros.turma && <p className={classes.errorMessage}>{atividade.erros.turma}</p>}
                    </Grid>

                    <Grid item={true} hidden={revisao} xs={12} sm={6}>
                        <MyTextField
                            variant="outlined"
                            select={true}
                            label="Disciplina"
                            name="disciplinaID"
                            disabled={revisao || disciplinas.length === 0}
                            value={atividade?.disciplinaID ? atividade.disciplinaID : ''}
                            error={atividade.erros.disciplina ? true : false}>
                                {
                                    disciplinas.map((row, index) => {
                                        if (atividade.tipoAtividade === "Redação" && row.areaConhecimento === 'Redação') {
                                            return <MenuItem key={index} value={row._id} onClick={() => handleSubjectChange("disciplinaID", row._id, row.areaConhecimento)}>{row.nome}</MenuItem>
                                        }
                                        return <MenuItem key={index} value={row._id} onClick={() => handleSubjectChange("disciplinaID", row._id, row.areaConhecimento)}>{row.nome}</MenuItem>
                                    })
                                }
                        </MyTextField>
                        {atividade.erros.disciplina && <p className={classes.errorMessage}>{atividade.erros.disciplina}</p>}
                    </Grid>
                
                    <Grid item={true} hidden={revisao} xs={12} sm={6}>
                        <MyTextField
                            variant="outlined"
                            select={true}
                            label="Tópico"
                            name="topicoID"
                            disabled={(topicos.length === 0 ? true : false) || (revisao)}
                            value={atividade.topicoID ? atividade.topicoID : ''}
                            error={atividade.erros.topico ? true : false}>
                                { topicos !== undefined &&
                                    topicos.length >= 1 
                                    ? topicos.map((row, index) => {
                                        return <MenuItem key={index} value={row._id} onClick={() => handleSubjectChange("topicoID", row._id)}>{row.topico}</MenuItem>
                                    })
                                    : <MenuItem key={topicos._id} value={topicos._id} onClick={() => handleSubjectChange("topicoID", topicos._id)}>{topicos.topico}</MenuItem>
                                }
                        </MyTextField>
                        {atividade.erros.topico && <p className={classes.errorMessage}>{atividade.erros.topico}</p>}
                    </Grid>

                    <Grid item={true} hidden={!revisao} xs={12} sm={6}>
                        <MyTextField
                            variant="outlined"
                            disabled={revisao ? false : (atividade.disciplina !== '' ? true : false)}
                            select={true}
                            label="Área do Conhecimento"
                            name="areaConhecimento"
                            value={atividade.areaConhecimento ? atividade.areaConhecimento : ""}
                            onChange={handleChange}
                            error={atividade.erros.areaConhecimento ? true : false}>
                                {
                                    areaConhecimento.map((item, index) => {
                                        return <MenuItem key={index} value={item.value}>{item.value}</MenuItem>
                                    })
                                }
                        </MyTextField>
                        { (revisao && atividade.erros.areaConhecimento) && <p className={classes.errorMessage}>{atividade.erros.areaConhecimento}</p> }
                    </Grid>
                
                    <Grid item={true} hidden={!revisao} xs={12} sm={6}>
                        <MyTextField
                            variant="outlined"
                            select={true}
                            label="Data de Liberação"
                            name="dataLiberacao"
                            disabled={listaData.length === 0}
                            value={atividade.dataLiberacao ? atividade.dataLiberacao : ""}
                            onChange={handleChange}
                            error={atividade.erros.dataLiberacao ? true : false}>
                            {
                                listaData.map(item => {
                                    return <MenuItem key={item._id} value={item._id}>{DisplayDate(item)}</MenuItem>
                                })
                            }
                        </MyTextField>
                        { (revisao && atividade.erros.dataLiberacao) && <p className={classes.errorMessage}>{atividade.erros.dataLiberacao}</p> }
                    </Grid>
                </Grid>
            </section>

            <section id="escolherQuestoes">
                <h2 className="heading-page">Escolha as Questões</h2>

                {
                    atividade.tipoAtividade === "Fixação" && atividade.topicoID !== ""
                    ? (classificacao) ? 
                        classificacao.qtdQuestoes !== questaoSelecionada.length && <Alert variant="filled" severity="warning" className={classes.alert}>A quantidade de questões desta atividade não condiz com o preestabelecido: {classificacao.qtdQuestoes} questões.</Alert>
                    : <Alert variant="filled" severity="warning" className={classes.alert}>Este tópico ainda não possui uma classificação.</Alert>
                    : null    
                }

                <SimpleLinearProgress hidden={!loading} />

                <SelectQuestionTable 
                    data={questoes} 
                    setData={setAtividade} 
                    setQuestionPreview={setQuestionPreview} 
                    filterDialog={filterDialog}
                    setFilterDialog={setFilterDialog}
                    setHidden={setHiddenDialog} 
                    tableSelection={true}
                    selectedQuestions={questaoSelecionada}
                    setSelectedQuestions={setQuestaoSelecionada}
                    activity={atividade}
                    filter={filter}
                    setFilter={setFilter}
                />
                { atividade.erros.questoes && <p className={classes.errorMessage}>{atividade.erros.questoes}</p> }

                <QuestionDialog 
                    enunciado={questionPreview.enunciado}
                    tipoResposta={questionPreview.tipoResposta}
                    padraoResposta={questionPreview.padraoResposta && questionPreview.padraoResposta}
                    resposta={questionPreview.resposta}
                    open={hiddenDialog}
                    setOpen={setHiddenDialog}
                />
            </section>

            {
                atividade.questoes.length > 0 && 
                <section id="ordernarQuestoes" style={{marginTop: "2rem"}} hidden={atividade.questoes.length === 0}>
                    <h2 className="heading-page" style={{marginBottom: "0.5rem"}}>Alterar ordem das questões na Atividade</h2>
                    <h3 className="heading-page" style={{marginBottom: "0.5rem"}}>Arraste as questões para as ordenar.</h3>
                    <DndCard
                        setData={setAtividade}
                        selected={questaoSelecionada} 
                        setSelected={setQuestaoSelecionada} 
                        removeThisQuestion={removerQuestao}
                    />
                </section>
            }

            <section id="submitButtons" className={classes.group}>
                <Grid container={true} spacing={2} justifyContent='center'>
                    <Grid item={true} xs={6} sm={3}>
                        <AddButton fullWidth={true} onClick={revisao ? saveRevision : saveActivity}>Salvar</AddButton>
                    </Grid>
                    <Grid item={true} xs={6} sm={3}>
                        <Link to="/controle-atividade/list" style={{ textDecoration: 'none' }}>
                            <DeleteButton fullWidth={true}>Voltar</DeleteButton>
                        </Link>
                    </Grid>
                </Grid>
            </section>
        </MyContainer>
    );
}