const areaConhecimento = [
  {
    id: "cienciasHumanas",
    value: "Ciências Humanas",
  }, {
    id: "cienciasNatureza",
    value: "Ciências da Natureza",
  }, {
    id: "linguagens",
    value: "Linguagens",
  }, {
    id: "matematica",
    value: "Matemática",
  }, {
    id: "redacao",
    value: "Redação",
  }
]

export {
  areaConhecimento
}