import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid, Tab, Tabs, Typography } from '@material-ui/core';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: '3px solid #94c93d'
  },

  check: {
    color: '#94c93d',
    fontSize: '300%'
  },

  progressText: {
    fontSize: '0.7rem',
    fontWeight: "lighter"
  },

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },

  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '100%'
  }
}));

export default function ActivityTab(props) {
  const { alunos, questoes, setIndice } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (questoes !== 0 && questoes !== undefined) {
      setIndice(questoes[newValue].index);
    } else {
      setIndice(newValue);
    }
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {
          (questoes !== 0 && questoes !== undefined )
          ?
            questoes.map((row, index) => {
              return (
                <Tab 
                  key={index} 
                  label={
                    <Typography>{"Questão " + (questoes[index].index + 1)}</Typography>
                  } 
                  {...a11yProps(index)}/>
              )
            }) 
          :
            (alunos !== 0 && alunos !== undefined) 
              ?
                alunos.map((row, index) => {
                  return ( 
                    <Tab 
                      key={index} 
                      label={
                        <Grid container justifyContent='center' alignItems='center'>
                          <Grid item xs={3}>
                            <Avatar sizes="small" src={row.url} alt="Preview"/>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography>{row.nome}</Typography>
                          </Grid>
                        </Grid>
                      } 
                      {...a11yProps(index)}/> 
                  );
                }) 
              :
                <Tab label={"Questão 1"} {...a11yProps(0)} />
        }
      </Tabs>
    </div>
  );
}
