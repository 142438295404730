import React, {useState, useEffect, memo} from "react";
import {Link as RouterLink} from 'react-router-dom';
import api from '../../api'
import { QuestionDialogFilter } from ".."
import clsx from 'clsx';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearAllIcon from '@material-ui/icons/ClearAll';

// -- Botão de Atualização
function UpdateQuestion(props) {
    return (
        <RouterLink to={"/controle-questoes/update/" + props.id}>
            <IconButton aria-label="update" color="primary" size="small">
                <EditIcon/>
            </IconButton>
        </RouterLink>
    )
}

// -- Botão de Visualização
function ShowQuestion(props) {
    const { setQuestionPreview, setHidden, question } = props;
    const choosingQuestion = () => {
        setQuestionPreview(question);
        setHidden(true);
    }
    return (
        <IconButton aria-label="update" color="primary" size="small" onClick={() => choosingQuestion()}>
            <VisibilityIcon/>
        </IconButton>
    )
}

// -- Botão de Remoção
function DeleteQuestion(props) {
    function removing() {
        if (window.confirm(`Tem certeza que quer remover esta questão permanentemente?`)) {
            api.removerQuestao(props.id)
            props.setMount(preValue => ({...preValue, wasChanged: true}));
        }
    }

    return (
        <RouterLink to={"/controle-questoes"}>
            <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    onClick={removing}>
                <DeleteIcon/>
            </IconButton>
        </RouterLink>
    )
}

// -- Funções auxiliares para Ordenação
function descendingComparator(a, b, orderBy) {

    switch (orderBy) {
        case 'disciplina':
            if (b.topicoID.disciplinaID.nome < a.topicoID.disciplinaID.nome) {
                return 1;
            }
            if (b.topicoID.disciplinaID.nome > a.topicoID.disciplinaID.nome) {
                return -1;
            }
            break;
        case 'topico':
                if (b.topicoID.topico < a.topicoID.topico) {
                return 1;
            }
            if (b.topicoID.topico > a.topicoID.topico) {
                return -1;
            }
            break;
        case 'tipo':
            if (b.questaoID.tipoResposta < a.questaoID.tipoResposta) {
                return 1;
            }
            if (b.questaoID.tipoResposta > a.questaoID.tipoResposta) {
                return -1;
            }
            break;
        case 'identificador':
            if (b.questaoID.identificador < a.questaoID.identificador) {
                return 1;
            }
            if (b.questaoID.identificador > a.questaoID.identificador) {
                return -1;
            }
            break;
        case 'vinculada':
            if (b.questaoID.vinculada < a.questaoID.vinculada) {
                return 1;
            }
            if (b.questaoID.vinculada > a.questaoID.vinculada) {
                return -1;
            }
            break;
        default:
            break;  
    }

    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) 
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// -- Componentes das Células de Cabeçalho
const headCells = [
    {
        id: 'disciplina',
        label: 'Disciplina'
    }, {
        id: 'topico',
        label: 'Topico'
    }, {
        id: 'tipo',
        label: 'Tipo'
    }, {
        id: 'identificador',
        label: 'Identificador'
    }, {
        id: 'vinculada',
        label: 'Vinculada'
    }, {
        id: 'funcoes',
        label: ''
    }
];

const phoneHeadCells = [
    {
        id: 'topicoID.topico',
        label: 'Topico'
    }, {
        id: 'funcoes',
        label: ''
    }
]

// -- Table: Head
function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, width, tableSelection, permitAll } = props;

    var cells = !width ? headCells : phoneHeadCells;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {
                    tableSelection && 
                        <TableCell padding="checkbox">
                            { permitAll && <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all desserts' }}
                            /> } 
                        </TableCell>
                }
                {
                    cells.map((headCell) => (
                        <TableCell
                            className={classes.row}
                            key={headCell.id}
                            align={'left'}
                            padding="normal"
                            sortDirection={order}>
                            {
                                (headCell.id !== "funcoes")

                                    ? <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id
                                                ? order
                                                : 'asc'}
                                            className={classes.row}
                                            onClick={createSortHandler(headCell.id)}>
                                            {headCell.label}
                                            {
                                                orderBy === headCell.id
                                                    ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {
                                                                order === 'desc'
                                                                    ? 'sorted descending'
                                                                    : 'sorted ascending'
                                                            }
                                                        </span>
                                                    )
                                                    : null
                                            }
                                        </TableSortLabel>
                                    : <div>{headCell.label}</div>
                            }
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    );
}

// -- Definição de Funções do Cabeçalho
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// -- Toolbar Styles
const useToolbarStyles = makeStyles((theme) => ({
    root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
    flex: '1 1 100%',
    color: "#606161",
    },
}));

// -- Toolbar
const EnhancedTableToolbar = (props) => {
    const { filter, setFilter, filterDialog, setFilterDialog, isCleaned, setIsCleaned, activity } = props;
    const classes = useToolbarStyles();

    // -- Limpa o filtro
    function clearFilter() {
        setFilter((prevValue) => ({
            ...prevValue,
            disciplinaID: activity.disciplinaID,
            topicoID: activity.topicoID,
            turmaID: activity.turmaID
        }));
        setIsCleaned(true);
    }

    return (
        <Toolbar className={clsx(classes.root)}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Lista de Questões
            </Typography>

            <div hidden={isCleaned} >
                <Tooltip title="Limpar filtro">
                    <IconButton aria-label="filter list" color="secondary" onClick={() => clearFilter()}>
                        <ClearAllIcon />
                    </IconButton>
                </Tooltip>
            </div>

            <Tooltip title="Filtrar lista">
                <IconButton aria-label="filter list" onClick={() => setFilterDialog(true)}>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>

            <QuestionDialogFilter 
                activity={activity}
                filter={filter}
                setFilter={setFilter}
                open={filterDialog}
                setOpen={setFilterDialog}
                setIsCleaned={setIsCleaned}
            />
        </Toolbar>
    );
};

// -- Styles: Tabela-Body
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        color: "#606161"
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    row: {
        color: "#606161"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

function SelectQuestionTable(props) {
    const {data, setData, setQuestionPreview, setHidden, tableSelection, filterDialog, setFilterDialog, filter, setFilter, selectedQuestions, setSelectedQuestions, setMount, activity } = props;
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isCleaned, setIsCleaned] = useState(true);
    const [questoes, setQuestoes] = useState([]);

    useEffect(() => {
        setQuestoes(data.filter(row => {
            const { topicoID, questaoID } = row;
            const auxType = (questaoID.tipoResposta === filter.tipo) ? true : !filter.tipo;
            const auxTopic = (topicoID._id === filter.topicoID) ? true : !filter.topicoID;
            const auxSubject = (topicoID.disciplinaID._id === filter.disciplinaID) ? true : !filter.topicoID;
            const auxClass = (topicoID.disciplinaID.turmaID === filter.turmaID) ? true : !filter.topicoID;
            return (auxType && auxTopic && auxSubject && auxClass)
        }));
        setPage(0);
        if (tableSelection) setSelectedQuestions(selectedQuestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, filter])

    // -- Solicita Ordenação
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(
            isAsc
                ? 'desc'
                : 'asc'
        );
        setOrderBy(property);
    };

    // -- Funções de Paginação
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // -- Funções de Seleção - Questões
    const handleSelectAllClick = () => {
        if(selectedQuestions.length > 0) setSelectedQuestions([]);
        else {
            const newSelecteds = data.map((n) => ({ id: n.questaoID._id, enunciado: n.questaoID.enunciado }));
            setSelectedQuestions(newSelecteds);
        }
    };
    
    const handleClick = (event, id) => {
        const selectedQuestion = data.find(element => element.questaoID._id === id).questaoID;
        const selectedIndex = selectedQuestions.map(x => x.id).indexOf(id);
        let newSelected = [];
        
        if(selectedIndex === -1) {
            newSelected = newSelected.concat(selectedQuestions, {id, enunciado: selectedQuestion.enunciado});
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedQuestions.slice(1));
        } else if (selectedIndex === selectedQuestions.length - 1) {
            newSelected = newSelected.concat(selectedQuestions.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedQuestions.slice(0, selectedIndex),
                selectedQuestions.slice(selectedIndex + 1),
            );
        }

        setSelectedQuestions(newSelected);
    };

    // -- Vigilantes
    useEffect(() => {
        if (tableSelection) {
            setData(preValue => ({
                ...preValue,
                questoes: (selectedQuestions.length > 0 && selectedQuestions.map(x => x.id)) || []
            }))
        }
    }, [selectedQuestions, setData, tableSelection]);

    // -- Rows vazias para complementação
    const emptyRows = rowsPerPage - Math.min( rowsPerPage, questoes.length - page * rowsPerPage );

    const isSelected = (name) => {
        try {
            return selectedQuestions.find(questao => questao.id === name) !== undefined;
        } catch (error) {
            return 0;
        }
    }

    // -- Tabela: Body
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                    activity={activity}
                    filter={filter} 
                    setFilter={setFilter} 
                    isCleaned={isCleaned}
                    setIsCleaned={setIsCleaned}
                    filterDialog={filterDialog} 
                    setFilterDialog={setFilterDialog}/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table">
                        <EnhancedTableHead                            
                            classes={classes}
                            numSelected={selectedQuestions.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={questoes.length}
                            tableSelection={tableSelection}
                            width={smScreen}
                            permitAll={activity.tipoAtividade !== "Avaliação Diagnóstica"}
                        />
                        <TableBody>
                            {
                                questoes.length > 0 &&
                                stableSort(questoes, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const { topicoID, questaoID } = row;
                                        const resposta = questaoID.tipoResposta === "discursiva" ? "Discursiva" : "Múltipla escolha";
                                        
                                        if (topicoID !== null) {
                                            const isItemSelected = isSelected(questaoID._id);
                                            const labelId = `enhanced-table-checkbox-${questaoID._id}`;
                                            return (
                                                <TableRow
                                                    hover={true}
                                                    onClick={event => { tableSelection && handleClick(event, questaoID._id) }}
                                                    role="checkbox"
                                                    aria-checked={tableSelection && isItemSelected}
                                                    tabIndex={-1}
                                                    key={questaoID._id}
                                                    selected={tableSelection && isItemSelected}
                                                    >

                                                    {
                                                        tableSelection && 
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    />
                                                            </TableCell>
                                                    }

                                                    {!smScreen && <TableCell className={classes.row} align="left">{topicoID.disciplinaID.nome}</TableCell>}
                                                    <TableCell className={classes.row} align="left">{topicoID.topico}</TableCell>
                                                    {!smScreen && <TableCell className={classes.row} align="left">{resposta}</TableCell>}
                                                    {!smScreen && <TableCell className={classes.row} align="left">{questaoID.identificador}</TableCell>}
                                                    {!smScreen && <TableCell className={classes.row} align="left">{questaoID.vinculada}</TableCell>}

                                                    <TableCell align={smScreen ? "right" : "left"}>
                                                        <ShowQuestion id={questaoID._id} question={questaoID} setQuestionPreview={setQuestionPreview} setHidden={setHidden}/>
                                                        {!tableSelection && <UpdateQuestion id={questaoID._id}/>}
                                                        {!tableSelection && <DeleteQuestion id={questaoID._id} setMount={setMount}/>}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                        return null;
                                    })
                                }
                            {
                                emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (33) * emptyRows
                                        }}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Footer: Paginação */}
                <TablePagination
                    className={classes.row}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={questoes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
            </Paper>
        </div>
    );
}

export default memo(SelectQuestionTable);