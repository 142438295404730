import React, { useContext, useEffect, useState } from "react";
import { Grid, Divider, Paper, IconButton, InputBase } from "@material-ui/core";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import SaveIcon from "@material-ui/icons/Save";
import "moment/locale/pt-br";
import { GeneralAccordion, SimpleSnackMessage } from "../../..";
import useStyles from "./styles";
import api from "../../../../api";
import { StoreContext } from "../../../../utils";

function CustomInput(props) {
  const {classes, id, handleChange, editChange, saveChange, value, name, placeholder} = props;
  return (
    <Paper component="form" className={classes.classLink}>
        <IconButton
          className={classes.iconButton}
          disabled={true}
          aria-label="link"
        >
          <InsertLinkIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder={placeholder}
          fullWidth={true}
          name={name}
          onChange={handleChange}
          value={value}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          type="submit"
          color="primary"
          className={classes.iconButton}
          onClick={id ? editChange : saveChange}
          aria-label="search"
        >
          <SaveIcon />
        </IconButton>
      </Paper>
  )
}

function InputClassLink (props) {
  const { classes, model, turma, userID } = props;
  const [classLink, setClassLink] = useState(model);
  const [noError, setNoError] = useState(true);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    var aux = model;
    aux.turmaID = turma._id;
    setClassLink(aux);
  }, [turma._id, model])

  // Função para pegar os valores do formulário - CLASSLINK
  const handleChange = (event) => {
    const { name, value } = event.target;
    setClassLink((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  // Guarda link da aula no banco
  async function saveChange(event) {
    event.preventDefault();
    await api
      .inserirClassLink(classLink)
      .then(res => {
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao inserir o link da aula.");
        console.log(err);
      });
    setOpen(true);
  }

  // Guarda link da aula atualizado no banco
  async function editChange(event) {
    event.preventDefault();

    var novoLink = classLink;
    novoLink.autorModificacao = userID;
    novoLink.dataModificacao = new Date();

    await api
      .atualizarClassLink(classLink._id, novoLink)
      .then(res => {
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao atualizar o link da aula.");
        console.log(err);
      });
    setOpen(true);
  }

  return (
    <div>
      <h4 className="heading-page">{turma.nome}</h4>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInput 
            classes={classes}
            id={classLink._id}
            handleChange={handleChange}
            editChange={editChange}
            saveChange={saveChange}
            value={classLink?.aulaLink}
            name={"aulaLink"}
            placeholder={"Link para Aula ao vivo"}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput        
            classes={classes}
            id={classLink._id}
            handleChange={handleChange}
            editChange={editChange}
            saveChange={saveChange}
            value={classLink?.classRoomLink}
            name={"classRoomLink"}
            placeholder={"Link para o ClassRoom"}
          />     
        </Grid>
      </Grid>
      <SimpleSnackMessage 
        open={open}
        setOpen={setOpen}
        message={message}
        error={noError}/>
    </div>
  )
}

export default function ManageClassLink(props) {
  const { mount, setMount } = props;
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const initialClassLinkState = {
    aulaLink: "",
    turmaID: "",
    classRoomLink: "",
    dataModificacao: new Date(),
    autorModificacao: token.userID,
  };
  const [listaTurma, setListaTurma] = useState([]);
  const [listaLinks, setListaLinks] = useState([]);

  // Lista ClassLink
  async function fetchClassLinkAPI() {
    await api.listarClassLink().then(res => setListaLinks(res.data.data));
  }

  // Observa mudanças no conteúdo
  useEffect(() => {
    const abortController = new AbortController();
    // Caso haja mudança em classLink
    if (mount.classLink) {
      fetchClassLinkAPI();
      setMount(x => ({ ...x, classLink: false }));
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [mount]);

  // Carregamento Inicial - Class Link
  const initialClassLinkLoad = () => {
    if (mount.isMountedClassLink) {
      // Lista Turmas
      async function fetchTurmaAPI() {
        await api.listarTurma().then(res => setListaTurma(res.data.data));
      }
      fetchTurmaAPI();
      fetchClassLinkAPI();
      setMount(x => ({ ...x, isMountedClassLink: false }));
    }
  };

  return (
    <GeneralAccordion 
      square
      elevation={2}
      style={{ borderBottom: `0.2rem solid #eae1d7` }}
      noClass={true}
      onClick={() => initialClassLinkLoad()}
      accordionSummary={<h2 className="heading-page">Gerenciar Aula Online</h2>}
      accordionDetails={
        <Grid container={true} className={classes.root} spacing={2}>
          <Grid item={true} xs={12}>
            {
              listaTurma.map((turma, index) => {
                var link = initialClassLinkState;
                listaLinks.forEach(x => { 
                  if (x.turmaID === turma._id) {
                    link = x;
                  }
                });

                return (
                  <InputClassLink 
                    key={index}
                    model={link} 
                    classes={classes}
                    turma={turma}
                    userID={token.userID}/>
                );
              })
            }
          </Grid>
        </Grid>
      }
    />  
  );
}
