import React, { memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { MyTypography } from '../../assets/styles/styledComponents';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    minWidth: "18.75rem",
    maxWidth: "31.25rem",
  },
});

const useStyle = makeStyles((theme) => ({
  semResposta: {
    color: "#af0000",
  },
  comResposta: {
    color: "#00af2c",
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" align="center">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: "18.75rem",
    maxWidth: "31.25rem",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    alignContent: "flex-end",
    minWidth: "18.75rem",
    maxWidth: "31.25rem",
  },
}))(MuiDialogActions);

function VerificationAnswersDialog(props) {
  const { respostas, handleSubmit, open, setOpen } = props;
  const classes = useStyle();
  
  return (
    <Dialog onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
          Cartão Resposta
      </DialogTitle>
      
      <DialogContent dividers={true}>
        {
          respostas.map((x, index) => {
            const row = x;
            var text = row?.resposta;
            var respondido = row?.resposta === "Respondido" ? true : false;
            if (respondido) {
              text = (row?.tipo === "discursiva") ? "Respondida - Discursiva" :`Você marcou a letra ${row?.letra}`;
            }
            return (
              <Grid container align="center" spacing={1}>
                <Grid item align="center" xs={12} lg={12} sm={12}>
                  <Typography>{`Questão ${index+1}`}</Typography>
                </Grid>
                <Divider orientation="horizontal" />
                <Grid item align="center" xs={12} lg={12} sm={12}>
                  <MyTypography className={respondido ? classes.comResposta : classes.semResposta}>{text}</MyTypography>
                </Grid>
              </Grid>
            )
          })
        }
      </DialogContent>

      <DialogActions>
        <Button fullWidth variant='contained' color="primary" onClick={handleSubmit}>
          Concluir
        </Button>
        <Button autoFocus fullWidth variant='contained' onClick={() => setOpen(false)} color="primary">
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(VerificationAnswersDialog);