import React, {useEffect, useState} from "react";
import {Link as RouterLink} from 'react-router-dom';
import api from '../../api'
import { QuestionDialogFilter } from "../"
import clsx from 'clsx';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearAllIcon from '@material-ui/icons/ClearAll';

// Botão de Atualização
function UpdateQuestion(props) {
    const path = "/controle-questoes/update/" + props.id;
    return (
        <RouterLink  
            to={{ 
                pathname: path, 
                state: props.data 
            }}
        >
            <IconButton aria-label="update" color="primary" size="small">
                <EditIcon/>
            </IconButton>
        </RouterLink>
    )
}

// Botão de Atualização
function ShowQuestion(props) {
    const { setQuestion, setHidden, question } = props;
    const choosingQuestion = () => {
        setQuestion(question);
        setHidden(true);
    }
    return (
        <IconButton aria-label="update" color="primary" size="small" onClick={() => choosingQuestion()}>
            <VisibilityIcon/>
        </IconButton>
    )
}

// Botão de Remoção
function DeleteQuestion(props) {
    function removing() {
        if (window.confirm(`Tem certeza que quer remover esta questão permanentemente?`)) {
            api.removerQuestao(props.id)
            props.setMount(preValue => ({...preValue, wasChanged: true}));
        }
    }

    return (
        <RouterLink to={"/controle-questoes"}>
            <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    onClick={removing}>
                <DeleteIcon/>
            </IconButton>
        </RouterLink>
    )
}

// -- Funções auxiliares para Ordenação
function descendingComparator(a, b, orderBy) {

    switch (orderBy) {
        case 'disciplina':
            if (b.topicoID.disciplinaID.nome < a.topicoID.disciplinaID.nome) {
                return 1;
            }
            if (b.topicoID.disciplinaID.nome > a.topicoID.disciplinaID.nome) {
                return -1;
            }
            break;
        case 'topico':
                if (b.topicoID.topico < a.topicoID.topico) {
                return 1;
            }
            if (b.topicoID.topico > a.topicoID.topico) {
                return -1;
            }
            break;
        case 'tipo':
            if (b.questaoID.tipoResposta < a.questaoID.tipoResposta) {
                return 1;
            }
            if (b.questaoID.tipoResposta > a.questaoID.tipoResposta) {
                return -1;
            }
            break;
        case 'identificador':
            if (b.questaoID.identificador < a.questaoID.identificador) {
                return 1;
            }
            if (b.questaoID.identificador > a.questaoID.identificador) {
                return -1;
            }
            break;
        default:
            break;  
    }

    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) 
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// -- Componentes das Células de Cabeçalho
const headCells = [
    {
        id: 'disciplina',
        label: 'Disciplina'
    }, {
        id: 'topico',
        label: 'Topico'
    }, {
        id: 'tipo',
        label: 'Tipo'
    }, {
        id: 'identificador',
        label: 'Identificador'
    }, {
        id: 'funcoes',
        label: ''
    }
];

const phoneHeadCells = [
    {
        id: 'topicoID.topico',
        label: 'Topico'
    }, {
        id: 'funcoes',
        label: ''
    }
]

// -- Table: Head
function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, width } = props;

    var cells = !width ? headCells : phoneHeadCells;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {
                    cells.map((headCell) => (
                        <TableCell
                            className={classes.row}
                            key={headCell.id}
                            align={'left'}
                            padding="normal"
                            sortDirection={order}>
                            {
                                (headCell.id !== "funcoes")

                                    ? <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id
                                                ? order
                                                : 'asc'}
                                            className={classes.row}
                                            onClick={createSortHandler(headCell.id)}>
                                            {headCell.label}
                                            {
                                                orderBy === headCell.id
                                                    ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {
                                                                order === 'desc'
                                                                    ? 'sorted descending'
                                                                    : 'sorted ascending'
                                                            }
                                                        </span>
                                                    )
                                                    : null
                                            }
                                        </TableSortLabel>
                                    : <div>{headCell.label}</div>
                            }
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    );
}

// -- Definição de Funções do Cabeçalho
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// -- Toolbar Styles
const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
      color: "#606161",
    },
  }));

// -- Toolbar
const EnhancedTableToolbar = (props) => {
    const { filter, setFilter, filterDialog, setFilterDialog, isCleaned, setIsCleaned, activity, initialFilter, setMount } = props;
    const classes = useToolbarStyles();

    // -- Limpa o filtro
    function clearFilter() {
        setFilter(initialFilter);
        setIsCleaned(true);
        setMount(preValue => ({ ...preValue, isMounting: true}));
    }

    return (
    <Toolbar className={clsx(classes.root)}>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Lista de Questões
        </Typography>

        <div hidden={isCleaned} >
            <Tooltip title="Limpar filtro">
                <IconButton aria-label="filter list" color="secondary" onClick={() => clearFilter()}>
                    <ClearAllIcon />
                </IconButton>
            </Tooltip>
        </div>

        <Tooltip title="Filtrar lista">
            <IconButton aria-label="filter list" onClick={() => setFilterDialog(true)}>
                <FilterListIcon />
            </IconButton>
        </Tooltip>

        <QuestionDialogFilter 
            activity={activity}
            filter={filter}
            setFilter={setFilter}
            open={filterDialog}
            setOpen={setFilterDialog}
            setIsCleaned={setIsCleaned}
        />
    </Toolbar>
    );
};

// -- Styles: Tabela-Body
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        color: "#606161"
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    row: {
        color: "#606161"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

export default function QuestionTable(props) {
    const {data, setQuestion, setHidden, filterDialog, setFilterDialog, filter, setFilter, setMount, activity, initialFilter} = props;

    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selected, setSelected] = useState([]);
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isCleaned, setIsCleaned] = useState(true);

    // -- Solicita Ordenação
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(
            isAsc
                ? 'desc'
                : 'asc'
        );
        setOrderBy(property);
    };

    // -- Funções de Paginação
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // -- Funções de Seleção - Questões
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = data.map((n) => n._id);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    };

    // -- Rows vazias para complementação
    const emptyRows = rowsPerPage - Math.min( rowsPerPage, data.length - page * rowsPerPage );

    // -- Verifica mudança em data
    useEffect(() => {
        setPage(0);
    }, [data])

    // -- Tabela: Body
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                    activity={activity}
                    filter={filter} 
                    setFilter={setFilter} 
                    isCleaned={isCleaned}
                    setIsCleaned={setIsCleaned}
                    filterDialog={filterDialog} 
                    setFilterDialog={setFilterDialog}
                    initialFilter={initialFilter}
                    setMount={setMount}/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table">
                        <EnhancedTableHead                            
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                            width={smScreen}/>
                        <TableBody>
                            {
                                data.length > 0 &&
                                stableSort(data, getComparator(order, orderBy))
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        const { questaoID, topicoID } = row;
                                        const { tipoResposta, identificador } = questaoID;
                                        const { disciplinaID, topico } = topicoID;
                                        const resposta = tipoResposta === "discursiva" ? "Discursiva" : "Múltipla escolha";
                                        return (
                                            <TableRow
                                                hover={true}
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row._id}>

                                                {!smScreen && <TableCell className={classes.row} align="left">{disciplinaID.nome}</TableCell>}
                                                <TableCell className={classes.row} align="left">{topico}</TableCell>
                                                {!smScreen && <TableCell className={classes.row} align="left">{resposta}</TableCell>}
                                                {!smScreen && <TableCell className={classes.row} align="left">{identificador}</TableCell>}

                                                <TableCell align={smScreen ? "right" : "left"}>
                                                    <ShowQuestion id={questaoID._id} question={row.questaoID} setQuestion={setQuestion} setHidden={setHidden}/>
                                                    <UpdateQuestion id={questaoID._id} data={questaoID}/>
                                                    <DeleteQuestion id={questaoID._id} setMount={setMount}/>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            {
                                emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (33) * emptyRows
                                        }}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Footer: Paginação */}
                <TablePagination
                    className={classes.row}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
            </Paper>
        </div>
    );
}
