import Axios from 'axios';
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { StoreContext } from "../../utils"
import api from '../../api'
import { UserForm, SimpleSnackMessage } from "../../components"
import validate from "../../components/Form/Validation/FormValidateUser"

export default function UsersUpdate (props) {
    const initialState = {
        id: props.match.params.id,
        nome: "",
        email: "",
        acesso: "",
        senha: "", 
        novaSenha1: "", 
        novaSenha2: "", 
        disciplina: [],
        erros: [],
        url: "",
        foto: {},
        turma: [],
        plano: "",
        alunoID: ""
    }
    const history = useHistory();
    const { token, setToken } = useContext(StoreContext);
    const [usuario, setUsuario] = useState(initialState);
    const [profDisciplinas, setProfDisciplinas] = useState([{ disciplinaID: '' }]);
    const [turmas, setTurmas] = useState([{ turmaID: '' }]);
    const [senhaAntiga, setSenhaAntiga] = useState('');
    const [urlAntiga, setUrlAntiga] = useState('');
    const [open, setOpen] = useState(false);
    const [noError, setNoError] = useState(true);
    const [message, setMessage] = useState("Houve um erro ao atualizar o usuário.");

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchUsuarioAPI () {
            try {
                const response = await api.encUsuarioPorID(props.match.params.id);
                const value = response.data.data;
                setSenhaAntiga(value.senha);
                setUrlAntiga(value.url);
                setUsuario(preValue => ({
                    ...preValue,
                    nome: value.nome, 
                    email: value.email, 
                    acesso: value.acesso, 
                    turma: value.turma, 
                    url: value.url,
                    alunoID: value.alunoID,
                    plano: value.plano
                }));
                if (value?.disciplina?.length) {
                    setProfDisciplinas(value.disciplina);
                }
                if (value?.turma?.length) {
                    setTurmas(value.turma);
                }
            } catch (error) { }
        }
        fetchUsuarioAPI();
        return abortController.abort();
    }, [props.match.params.id])

    async function UpdateUser (id, usuarioAtualizado) {
        try {
            await api
                .atualizarUsuario(id, usuarioAtualizado)
                .then(res => {
                    setMessage(res.data.message);
                    setOpen(true);

                    setTimeout(() => {
                        history.push('/controle-usuario');
                    }, 1000);

                    if (token.userID === id) {
                        setToken(preValue => ({
                            ...preValue,
                            url: usuarioAtualizado.url
                        }));
                    }
                }).catch(error => {
                    setOpen(true);
                    setNoError(false);
                    console.log(error);
                });
        } catch (error) {
            setOpen(true);
            setNoError(false);
            console.log(error);
        }
    }

    async function handleUpdateUser () {
        // Validação dos campos
        const error = await validate(usuario, true);
        setUsuario(preValue => ({
            ...preValue,
            erros: error
        }));

        // Não havendo erro, realiza salvamento
        if (error.validated) {
            var usuarioAtualizado = {
                nome: usuario.nome,
                email: usuario.email,
                acesso: usuario.acesso,
                senha: usuario.senha === '' ? senhaAntiga : usuario.novaSenha2,
                turma: usuario.turma,
                disciplina: usuario.disciplina,
                url: urlAntiga,
                alunoID: usuario.acesso === "Responsável" ? usuario.alunoID : null
            }

            if(usuario.acesso === "Responsável")
                usuarioAtualizado.alunoID = usuario.alunoID
            else if(usuario.acesso === "Aluno")
                usuarioAtualizado.plano = usuario.plano

            if (usuario.foto?.name !== undefined) {
                const data = new FormData();
                data.append("foto", usuario.foto);

                try {
                    await Axios
                        .post(`${process.env.REACT_APP_API_URL}upload-profile/${usuario.id}`, data)
                        .then(async res => {
                            usuarioAtualizado.url = res.data.url;
                            if (res.data.url.length > 0) {
                                await UpdateUser(usuario.id, usuarioAtualizado);
                            }
                        });
                } catch (error) { console.log(error) }
            } else {
                await UpdateUser(usuario.id, usuarioAtualizado);
            }
        }

    }

    return (
        <>
            <UserForm
                data={usuario}
                onSubmit={handleUpdateUser}
                setUsuario={setUsuario}
                typeForm="Atualizar"
                edit={true}
                profDisciplinas={profDisciplinas}
                setProfDisciplinas={setProfDisciplinas}
                turmas={turmas}
                setTurmas={setTurmas}
            /> 
            
            <SimpleSnackMessage 
                open={open}
                setOpen={setOpen}
                message={message}
                error={noError}
            />
        </>
    );
}
