import axios from "axios"

// Custom Upload Adapter
export class UploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  async upload() {
    return this.loader.file.then((file) => {
      const data = new FormData()
      data.append("questao", file)
      const genericError = `Não foi possível fazer o upload do arquivo: ${file.name}.`

      return axios({
        data,
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}upload-questao`,
        headers: { "Content-Type": "multipart/form-data", },
        onUploadProgress: (progressEvent) => {
          this.loader.uploadTotal = progressEvent.total
          this.loader.uploaded = progressEvent.loaded
        },
      })
        .then(res => {
          return ({ default: res.data.url });
        })
        .catch(error => Promise.reject(error?.message ?? genericError))
    })
  }

  abort() {
    return Promise.reject()
  }
}
 
// CKEditor FileRepository
const uploadAdapterPlugin = (editor) => {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => new UploadAdapter(loader)
}

// -- Configuração da toolbar para uso na edição das respostas da questão
const optionConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  toolbar: {
  items: [
    'bold',
    'italic',
    'subscript',
    'superscript',
    '|',
    'MathType',
    'ChemType',
    'specialCharacters',
    'imageUpload',
    'undo',
    'redo'
  ]
  },
  removePlugins: [
    'List'
  ],
}

// -- Configuração padrão da toolbar do editor
const defaultConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  toolbar: {
    items: [
      'heading',
      'fontSize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'subscript',
      'superscript',
      '|',
      'alignment',
      'bulletedList',
      'numberedList',
      'outdent',
      'indent',
      '|',
      'imageUpload',
      'mediaEmbed',
      'insertTable',
      '|',
      'blockQuote',
      'link',
      'horizontalLine',
      '|',
      'MathType',
      'ChemType',
      'specialCharacters',
      '|',
      'undo',
      'redo'
    ]
  },
}

export { optionConfig,  defaultConfig, uploadAdapterPlugin }