import React, { useContext, useEffect, useState } from 'react';
import { GeneralText, GeneralTitle, MyContainer } from '../../assets/styles/styledComponents';
import { StoreContext } from "../../utils";
import { StudentResultsEssay, StudentResultsAD } from '../../components';
import api from '../../api';

export default function StudentAnalysis() {
  const { token } = useContext(StoreContext)
  const alunoID = token.userID;
  const [listaRedacoes, setListaRedacoes] = useState([]);
  const [listaADs, setListaADs] = useState([]);
  const [notaRedacao, setNotaRedacao] = useState(0);
  const [notaAD, setNotaAD] = useState(0);

  //--Carregamento Inicial
  useEffect(() => {
    //--Carrega redações realizadas por AlunoID
    async function ListarRedacoes() {
      await api.listarRedacoesCorrigidasPorAlunoID(alunoID)
      .then(res => {
        const value = res.data.data;
        if (value.length) {
          let media = 0;
          value.forEach(item => {
            media += item.nota;
          });
          setListaRedacoes(value);
          setNotaRedacao(media/value.length);
        }
      })
      .catch(err => console.log(err))
    }

    //--Carrega ADs realizadas por AlunoID
    async function ListarADs() {
      var adsEncontradas = [];
      await api.encProgressoPorAlunoID(alunoID)
        .then(res => {
          const value = res.data.data;
          if (value.length) {
            setListaADs(value.filter(x => { return x.revisaoID }));
            value.map(x => {
              if (x.revisaoID !== null) {
                adsEncontradas.push(x.revisaoID._id)
              }
              return 0;
            });
          }
        })
        .catch(err => console.log(err));

      if (adsEncontradas.length) {
        await api.listarRAPorAlunoID(alunoID)
        .then(res => {
          const revisoes = res.data.data;
          
          if (revisoes.length) {
            let media = -1;

            adsEncontradas.forEach(x => {
              let item = revisoes.find(y => y.revisaoID === x);
              if (item !== null) {
                media += item.nota !== undefined && item.nota;
              }
            })

            media = media/adsEncontradas.length;
            
            if (media < 0) {
              setNotaAD("Pendente");
            } else if (media > 100) {
              setNotaAD(100);
            } else {
              setNotaAD(media);
            }
          } else {
            setNotaAD(0);
          }
        })
        .catch(err => console.log(err));
      }
    }

    ListarRedacoes();
    ListarADs();
  }, [alunoID]);

  return (
    <MyContainer>
      <GeneralTitle>Meus Resultados</GeneralTitle>
      <section id="resultadoRedacoes" hidden={listaRedacoes.length === 0}>
        <StudentResultsEssay 
          title1="Redações"
          title2="Média Acumulada"
          title3="Redações Feitas"
          msg1="Acesso o link abaixo a fim de visualizar mais informações sobre seus erros e acertos nesta redação."
          msg2="Caso tenha alguma dúvida, contate o seu professor."
          msg3="Média calculada com base nos resultados obtidos em todas as redações realizadas até o momento."
          mediaAcumulada={notaRedacao}
          listagem={listaRedacoes}
        />
      </section>

      <section id="resultadoADs" hidden={listaADs.length === 0} style={{marginTop: "2rem"}}>
        <StudentResultsAD 
          title1="Avaliações Diagnósticas"
          title2="Média Acumulada"
          title3="ADs Feitas"
          msg1="Acesse o link abaixo a fim de visualizar mais informações sobre seus erros e acertos nesta avaliação diagnóstica."
          msg2="Caso tenha alguma dúvida, contate seus professores."
          msg3="Média calculada com base nos resultados obtidos em todas as avaliações realizadas até o momento."
          mediaAcumulada={notaAD}
          listagem={listaADs}
        />
      </section>

      <section id="semResultados" hidden={(listaADs.length + listaRedacoes.length) !== 0}>
        <GeneralText>Você ainda não tem resultados para visualizar.</GeneralText>
        <GeneralText>Assim que houver os resultados de alguma redação ou avaliação diagnóstica que tenha realizado, você poderá visualizar aqui.</GeneralText>
      </section>
    </MyContainer>
  );
}