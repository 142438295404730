import { makeStyles } from  "@material-ui/core";

export const useStyles = makeStyles((theme) => ({  
  material: {
    marginTop: '0',
    marginRight: '0',
    marginLeft: '0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  toolbar: {
    alignItems: 'center',
    backgroundColor: '#eeeeee',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
    bottom: '16px',
    display: 'flex',
    left: '50%',
    padding: '3px',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    width: '17rem',
    zIndex: 1,
  },
  viewContainer: {
    border: '1px solid rgba(0, 0, 0, 0.3)',
    height: '100%',
    position: 'relative'
  },
  viewer: {
    flex: 1,
    overflow: 'hidden',
    margin: '-1rem',
    zIndex: 1,
  }
}));
