import React, { useState, useEffect } from "react";
import {
    makeStyles, 
    Grid, 
    Button,
    Avatar, 
    Badge,
    Divider
} from '@material-ui/core';
import { UploadEssay, SimpleSnackMessage, GeneralDialog, GeneralAccordion } from "../";
import { GeneralSubtitle, GeneralText } from "../../assets/styles/styledComponents"
import SaveIcon from '@material-ui/icons/Save';
import DownloadIcon from '@material-ui/icons/GetApp';
import CheckCircleIcon from '@material-ui/icons/Check';
import { green } from "@material-ui/core/colors";
import api from '../../api';
import { WirisIframe } from "../"
import SimpleList from "./Utils/SimpleList";
import { eixo1, eixo2, eixo3, competencia, inicialEixos } from "../../utils"
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginLeft: '1rem'
    },
        myCard: {
        minHeight: 400,
        padding: '1rem'
    },
        buttonGroup: {
        textAlign: 'center',
        alignItems: 'center',
        marginBottom: '1rem',
        padding: '0 1rem 0'
    },
        divider: {
        marginRight: '1rem'
    },
        userName: {
        paddingTop: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '-1.5rem',
        },
        color: "#606161",
        fontWeight: 'semi-bold'
    },
        success: {
        backgroundColor: green[500],
        color: '#fafafa',
        borderRadius: '50%',
        margin: '0.5rem 0 0 0.5rem'
    }
}));

export default function CorrectionEssayDialog(props) {
    const {redacaoID, data, aluno, open, setOpen, setWasChanged} = props;
    const classes = useStyles();
    const [feedMsg, setFeedMsg] = useState({title: '', message: ''});
    const [feedOpen, setFeedOpen] = useState(false);
    const [essayUploaded, setEssayUploaded] = useState(false);
    const [propostaRedacao, setPropostaRedacao] = useState('');
    const [wasLoaded, setWasLoaded] = useState({ proposta: false });
    const [uploadError, setUploadError] = useState(false);
    const [eixo1Aluno, setEixo1Aluno] = useState(inicialEixos.eixo1);
    const [eixo2Aluno, setEixo2Aluno] = useState(inicialEixos.eixo2);
    const [eixo3Aluno, setEixo3Aluno] = useState(inicialEixos.eixo3);
    const [competenciaAluno, setCompetenciaAluno] = useState(inicialEixos.competencia);
    const [correcaoURL, setCorrecaoURL] = useState("");
    const uploadLink = `${process.env.REACT_APP_API_URL}upload-redacao/corrigida/${aluno._id}/${redacaoID}`;

    const DownloadEssay = async () => {
        var key = data.redacaoURL.split("/");
        await api.pegarArquivoAWS(key[key.length-2], key[key.length-1])
            .then(res => {
                var file = res.data;
                var type = file.ContentType.split("/")[1];
                saveAs(data.redacaoURL, `Redação - ${data.alunoID.nome}.${type}`);
            })
            .catch(err => console.log(err));
    };

    const SubmitButton = async () => {
        const { d1, d2, d3, d4, d5  } = competenciaAluno;
        const notaAluno = d1 + d2 + d3 + d4 + d5;

        const progressoRedacao = {
            redacaoID: redacaoID,
            alunoID: aluno._id,
            progresso: data.progresso,
            dataConclusao: data.dataConclusao,
            corrigido: true,
            nota: notaAluno,
            eixo1: eixo1Aluno,
            eixo2: eixo2Aluno,
            eixo3: eixo3Aluno,
            competencia: competenciaAluno > 100 ? 100 : competenciaAluno,
            redacaoURL: data.redacaoURL,
            correcaoURL,
        }

        if (essayUploaded) {
            await api
                .atualizarProgressoRedacao(data._id, progressoRedacao)
                .then(res => {
                    setFeedOpen(true)
                    setUploadError(true);
                    setFeedMsg({title: 'Correção finalizada com sucesso!'})
                    setWasChanged(preValue => ({
                        ...preValue,
                        redacao: false
                    }));
                    CloseDialog();
                })
        } else {
            setFeedOpen(true)
            setUploadError(false);
            setFeedMsg({title: 'É preciso fazer o upload da redação corrigida para concluir a correção!'})
        }
    };

    const CloseDialog = () => {
        setOpen(false);
        setEssayUploaded(false);
        setFeedOpen(false);
        setEixo1Aluno(inicialEixos.eixo1);
        setEixo2Aluno(inicialEixos.eixo2);
        setEixo3Aluno(inicialEixos.eixo3);
        setCompetenciaAluno(inicialEixos.competencia);
    }

    useEffect(() => {
        const fetchPropostaRedacao = async () => {
            await api.encPropostaRedacao(redacaoID)
                .then(res => {
                    setPropostaRedacao(res.data.data.questaoID.enunciado);
                    setWasLoaded(preValue => ({
                        ...preValue,
                        proposta: true
                    }))
                })
                .catch(err => console.log(err));
        }
        !wasLoaded.proposta && fetchPropostaRedacao();
    },[redacaoID, wasLoaded.proposta]);

    return (
        <>
            <GeneralDialog 
                open={open}
                setOpen={setOpen}
                dialogTitle={
                    <Grid container={true}>
                        <Grid item={true} xs={2} sm={1}>
                            <Badge overlap="circle" badgeContent={essayUploaded && <CheckCircleIcon className={classes.success} />}>
                                <Avatar sizes="small" src={aluno.url} alt="Preview"/>
                            </Badge>
                        </Grid>
                        <Grid item={true} xs={10} sm={11}>
                            <GeneralText variant="h6" className={classes.userName}>{aluno.nome}</GeneralText>
                        </Grid>
                    </Grid>
                }
                dialogContent={
                    <Grid container={true} spacing={2}>
                        <Grid id="correctionEssayPropose" item={true} xs={12}>

                            <GeneralAccordion 
                                square
                                accordionSummary={
                                    <Grid container>
                                        <Grid item xs={12} style={{marginBottom: '-1rem'}}>
                                            <GeneralSubtitle>Proposta de Redação</GeneralSubtitle>
                                        </Grid>
                                        <Grid item xs={12} style={{marginBottom: '-1rem'}}>
                                            <p>Clique no botão ao lado para visualizar a proposta da redação.</p>
                                        </Grid>
                                    </Grid>
                                }
                                accordionDetails={<WirisIframe text={propostaRedacao}/>}
                            />
                            <Divider style={{ margin: '1rem 0 1rem 0' }}/>
                        </Grid>

                        <Grid id="correctionEssayEixo1" item={true} xs={12} sm={6}>
                            <GeneralAccordion 
                                square
                                accordionSummary={<Grid container><GeneralSubtitle>Eixo 1</GeneralSubtitle></Grid>}
                                accordionDetails={<SimpleList array={eixo1} value={eixo1Aluno} setValue={setEixo1Aluno} word="A"/>}
                            />
                            <Divider style={{ margin: '1rem 0 1rem 0' }}/>
                        </Grid>

                        <Grid id="correctionEssayEixo2" item={true} xs={12} sm={6}>
                            <GeneralAccordion 
                                square
                                accordionSummary={<Grid container><GeneralSubtitle>Eixo 2</GeneralSubtitle></Grid>}
                                accordionDetails={<SimpleList array={eixo2} value={eixo2Aluno} setValue={setEixo2Aluno} word="B"/>}
                            />
                            <Divider style={{ margin: '1rem 0 1rem 0' }}/>
                        </Grid>

                        <Grid id="correctionEssayEixo3" item={true} xs={12} sm={6}>
                            <GeneralAccordion 
                                square
                                accordionSummary={<Grid container><GeneralSubtitle>Eixo 3</GeneralSubtitle></Grid>}
                                accordionDetails={<SimpleList array={eixo3} value={eixo3Aluno} setValue={setEixo3Aluno} word="C"/>}
                            />
                            <Divider style={{ margin: '1rem 0 1rem 0' }}/>
                        </Grid>

                        <Grid id="correctionEssayCompetencias" item={true} xs={12} sm={6}>
                            <GeneralAccordion 
                                square
                                accordionSummary={<Grid container><GeneralSubtitle>Competências</GeneralSubtitle></Grid>}
                                accordionDetails={<SimpleList array={competencia} value={competenciaAluno} setValue={setCompetenciaAluno} word="C-"/>}
                            />
                            <Divider style={{ margin: '1rem 0 1rem 0' }}/>
                        </Grid>

                        <Grid id="correctionEssayButtons" item={true} xs={12}>
                            <Grid container={true} className={classes.buttonGroup} spacing={1}>
                                <Grid item={true} xs={12} sm={4}>
                                    <Button fullWidth={true} variant="outlined" color="primary" onClick={DownloadEssay} startIcon={<DownloadIcon />}>
                                        <i className="fas fa-download" />
                                        Baixar Redação
                                    </Button>
                                </Grid>
                                <Grid item={true} xs={12} sm={4}>
                                    <UploadEssay 
                                        uploadLink={uploadLink} 
                                        checked={false} 
                                        primaryTitle="Enviar Correção" 
                                        correction={true} 
                                        setFeedMsg={setFeedMsg} 
                                        setEssayUploaded={setEssayUploaded}
                                        setUploadError={setUploadError}
                                        setRedacaoURL={setCorrecaoURL}
                                        setFeedOpen={setFeedOpen}/>
                                </Grid>
                                <Grid item={true} xs={12} sm={4}>
                                    <Button fullWidth={true} variant="contained" color="primary" onClick={SubmitButton} startIcon={<SaveIcon />}>
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                dialogActions={
                    <Button autoFocus onClick={() => CloseDialog()} color="primary">
                        Voltar
                    </Button>
                }
            />
            <SimpleSnackMessage
                open={feedOpen}
                setOpen={setFeedOpen}
                error={uploadError}
                message={feedMsg.title}/>
        </>
    );
}