import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Slide, Button, Dialog, DialogContent, Fade } from '@material-ui/core';

import * as coneError from "../../assets/animations/coneError.json";
import Lottie from "react-lottie";
import { GeneralText, GeneralTitle } from '../../assets/styles/styledComponents';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: coneError.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

// -- Local Styles
const useStyles = makeStyles(() => ({
    button: {
        textAlign: "center"
    },
    card: {
        minWidth: "18.75rem",
        paddingBottom: "1.5rem"
    },
    title: {
        padding: "1rem 0 0 1rem",
        marginBottom: "-0.3rem",
        textAlign: "center"
    },
    text: {
        textAlign: "center",
        fontSize: "1.2rem",
        padding: "0.5rem 0"
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default withRouter(function FeatureDialog(props) {
    const { open, setOpen } = props;
    const classes = useStyles();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 100);
    }, []);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={true}>
            <GeneralTitle className={classes.title}>{`You shall not pass!`}</GeneralTitle>
            <DialogContent className={classes.card}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} style={{height: 160, width: 160}}>
                        {
                            isLoaded &&
                            <Fade in={isLoaded} style={{transitionDelay: "100ms"}}>
                                <Lottie options={defaultOptions} height={160} width={160} isStopped={!isLoaded} isPaused={!isLoaded}/>
                            </Fade>
                        }
                    </Grid>

                    <Grid item xs={12} alignItems="center">
                        <GeneralText className={classes.text}>
                            Este recurso ainda não está disponível nesta versão do Odisseia.
                        </GeneralText>
                    </Grid>
                    
                    <Grid item className={classes.button} xs={6}>
                        <Button variant="contained" onClick={ () => setOpen(false) } color="secondary">
                            Voltar
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
});
