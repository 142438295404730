import React, {useState, useEffect, useContext} from "react";
import {Link} from 'react-router-dom'
import { TextEditor } from "../";
import api from '../../api'
import { MyContainer, MyCard, AddButton, DeleteButton, GeneralTitle, MyTypography } from "../../assets/styles/styledComponents"
import { SelectTopicTable } from "../../components"
import { Grid, ButtonGroup, Button, Fab, Checkbox, Chip  } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import "./Styles/stylesQuestionForm.css"
import { StoreContext } from "../../utils";

// -- Local Styles
const useStyles = makeStyles((theme) => ({
    buttons: {
        marginTop: theme.spacing(2),
        margin: theme.spacing(1)
    },
    root: {
        flexGrow: 1
    },
    group: {
        textAlign: "center"
    },
    fabButton: {
        marginTop: "0.4rem"
    },
    checkBox: {
        display: "flex",
        marginTop: "0.15rem"
    },
    questaoOpcoes: {
        padding: "1rem",
        textAlign: "center",
        alignItems: "center",
    },
    errorMessage: {
        fontSize: "0.75rem",
        paddingLeft: "1rem",
        color: "#f44336"
    },
    errorAlarm: {
        border:"1px solid #f44336"
    },
    alert: {
        textAlign: "right",
        fontSize: "1rem",
        color: "#f44336"
    }
}));

const initialFilter = {
    disciplinaID: '',
    area: '',
    turmaID: '',
    numeracao: ''
};

const optionsLetter = ["A)", "B)", "C)", "D)", "E)", "F)", "G)"]

export default function QuestionForm (props) {
    const classes = useStyles();
    const {title, questao, setQuestao, opcoes, setOpcoes, saveQuestion, initialOptionState, topicoSelecionado, setTopicoSelecionado } = props;
    const { token } = useContext(StoreContext);
    const [topicos, setTopicos] = useState([]);                                    // Tópicos do Banco de Dados
    const [topicosChips, setTopicosChips] = useState([]);                          // Tópicos Selecionados que serão exibidos no chips
    const [filterDialog, setFilterDialog] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [fullData, setFullData] = useState([]);
    const linkWithMath = "https://614f1773665a3f000712fed5--portal-odisseia.netlify.app/controle-questoes/create"; 

    // -- Salva alterações de 'opcoes' em 'questao'
    useEffect(() => {
        setQuestao(preValue => ({
            ...preValue,
            resposta: opcoes
        }));
        // eslint-disable-next-line
    }, [opcoes])

    // -- Confirma mudanças realizadas em questao
    useEffect(() => {
        setQuestao(questao);
        // eslint-disable-next-line
    }, [questao]);
    
    // -- Carrega os Tópicos existentes no banco (com ou sem filtro)
    useEffect(() => {
        async function fetchTopicos(filter, accessType) {
            const abortController = new AbortController();
            setTopicos([]);
            let aux = [];
            if(filter === initialFilter && accessType === "Professor") {
                token.disciplina.map(async element => {
                    await api.listarConteudoPorFiltroInterno({ 
                        disciplinaID: element, 
                        area: '0',
                        turmaID: '0',
                        numeracao: '0'
                    })
                    .then(res => {
                        aux.push(...res.data.data);
                        setTopicos( [...aux ] );
                        setFullData( [...aux ] );
                    })
                    .catch(err => {
                        console.log(err);
                    });
                    
                })
            } else if(filter === initialFilter && accessType === "Administrador") {
                await api.listarConteudos()
                .then(res => {
                    aux.push(...res.data.data);
                    setTopicos( [...aux ] );
                    setFullData( [...aux ] );
                })
                .catch(err => {
                    console.log(err);
                });
            } else if (filter !== initialFilter) {
                const params = {
                    disciplinaID: filter.disciplinaID ? filter.disciplinaID : '0',
                    area: filter.area ? filter.area : '0',
                    turmaID: filter.turmaID ? filter.turmaID : '0',
                    numeracao: filter.numeracao ? filter.numeracao : '0'
                }
                await api.listarConteudoPorFiltroInterno(params) 
                .then(res => {
                    aux.push(...res.data.data);
                    setTopicos( [...aux ] );
                }).catch(err => {
                    console.log(err);
                });
            }
            return abortController.abort();
        }
        fetchTopicos(filter, token.accessType);
        // eslint-disable-next-line
    }, [ setTopicoSelecionado, token, filter ]);
    
    // -- Salvar dados do enunciado da questão
    function handleEnunciado(value) {
        setQuestao(preValue => ({
            ...preValue,
            enunciado: value
        }));
    }

    // -- Salvar dados do enunciado da questão
    function handlePadraoResposta(value) {
        setQuestao(preValue => ({
            ...preValue,
            padraoResposta: value
        }));
    }

    // -- Salvar dados das opções da questão
    function handleOpcao(position, value) {
        value = value
            .replaceAll('<ol style="list-style-type:upper-alpha;">', '')
            .replaceAll('</ol>', '')
            .replaceAll('<li', '<p')
            .replaceAll('</li>', '</p>')
            .replaceAll('>&nbsp;<', '><')
            .replaceAll('<br>&nbsp;', '')
            .replaceAll('<br>', '</p><p>')
            .replaceAll('</p><p', '</p>@#$%$#@<p')
            .replaceAll('>a) ', '>')
            .replaceAll('>b) ', '>')
            .replaceAll('>c) ', '>')
            .replaceAll('>d) ', '>')
            .replaceAll('>e) ', '>')
            .replaceAll('>A) ', '>')
            .replaceAll('>B) ', '>')
            .replaceAll('>C) ', '>')
            .replaceAll('>D) ', '>')
            .replaceAll('>E) ', '>');

        // Se sim, Inserção múltipla de opções de resposta
        if (value.includes('@#$%$#@')) {
            // Separa o texto entre parágrafos
            var options = value.split('@#$%$#@').map(option => {
                // Retorna um objeto opção contstarto o campo de 'opcao' preenchido
                return {
                    opcao: option,
                    gabarito: false,
                }
            });
            
            // Verifica se o array de opções já contém algum valor
            var optionsAux = opcoes.filter(item => {
                return item.opcao !== "";
            });

            // Caso o array opcoes já contenha algum valor, arrayAux recebe esses valores, do contrário recebe um array vazio
            var arrayAux = (optionsAux.length > 0) ? optionsAux : [];

            // Caso não, o array auxiliar será um vetor vazio
            if (arrayAux === []) {
                setOpcoes(options);
            }

            // De outro modo, serão inseridos novos valores
            else {
                options.forEach(item => { arrayAux.push(item) });
                setOpcoes(arrayAux);
            }
        }
        
        // Inserção individual de opções de resposta
        else {
            setOpcoes(opcoes.map((item, index) => {
                if (index === position) {
                    return { ...item, opcao: value };
                } else {
                    return item;
                }
            }));
        }
    }

    // -- Salvar dados de gabarito de cada opção
    function handleResposta(value) {
        // Altera valor do gabarito de cada questão
        setQuestao(preValue => ({
            ...preValue,
            tipoResposta: value
        }));
    }

    // -- Salvar dados de gabarito de cada opção
    function handleGabarito(position) {
        // Altera valor do gabarito de cada questão
        setOpcoes(opcoes.map((item, index) => {
            if (index === position) {
                return { ...item, "gabarito": !item.gabarito };
            } else {
                return item;
            }
        }));
    }

    // -- Remover opção de resposta
    function deleteThisOption(event, position) {
        setOpcoes(opcoes.filter((option, index) => {
            return index !== position;
        }));
    }

    // -- Adicionar opção de resposta
    function addNewOption() {
        setOpcoes([
            ...opcoes, 
            initialOptionState
        ]);
    }

    return (
        <MyContainer>
            <section className="cabecalhoQuestao">
                <Grid container direction="row" className={classes.root}>
                    <Grid item xs={12}>
                        <GeneralTitle>{title}</GeneralTitle>
                    </Grid>
                    {   questao.identificador &&
                        <Grid item xs={12}>
                            <MyTypography variant='body2' style={{fontWeight: "600", marginTop: "-0.75rem", marginBottom: "0.75rem"}}>
                                {questao.identificador}
                            </MyTypography>
                        </Grid>
                    }
                </Grid>
            </section>
                
            <section className="enunciadoQuestao">
                <h2 className="subtitle-page">Enunciado</h2>
                <MyCard className={questao?.erros?.enunciado && classes.errorAlarm}>
                    <TextEditor 
                        optionType={false}
                        text={questao.enunciado} 
                        setText={handleEnunciado}
                        initialMessage="<p><span style='color:hsl(0, 0%, 30%)'>Digite o enunciado aqui</span></p>"
                    />
                </MyCard>
                <p className={classes.alert}>Caro professor, o plugin de matemática/química do editor de texto está temporariamente indisponível, caso necessite utilizá-lo, sugerimos que acesso este <a href={linkWithMath} rel="noreferrer" target="_blank">link</a>.</p>
                {questao?.erros?.enunciado && <p className={classes.errorMessage}>{questao?.erros?.enunciado}</p>}
            </section>

            <section className="respostaQuestao">
                <h2 className="subtitle-page">Respostas</h2>

                <Grid container={true} spacing={1}>
                    <Grid item={true} xs={12} md={8} sm={8}>
                        {
                            questao.tipoResposta === "discursiva" 
                            ? (<p>Questões discursivas possuem como opção de resposta uma caixa de texto.</p>) 
                            : (<p>Defina abaixo as opções de resposta de acordo com o enunciado informado acima.</p>)
                        }
                    </Grid>

                    <Grid item={true} xs={12} md={4} sm={4}>
                        <div className="questaoTipo">
                            <ButtonGroup size="small" variant="contained" color="primary" aria-label="contained primary button group">
                                <Button onClick={() => handleResposta("multiplaEscolha")}>Múltipla Escolha</Button>
                                <Button onClick={() => handleResposta("discursiva")}>Discursiva</Button>
                            </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>

                <MyCard 
                    className={questao?.erros?.resposta && classes.errorAlarm}
                    hidden={questao.tipoResposta === "discursiva" ? true : false}
                >
                        <label id="gabaritoLabel">Gabarito</label>
                        
                        {opcoes.map((item, index) => {
                            let tam = opcoes.length;
                            return (
                                <Grid key={index} className={classes.questaoOpcoes} container={true} spacing={2}>
                                    <Grid item={true} xs={2} sm={1} lg={1}>
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <Checkbox
                                                    checked={item.gabarito}
                                                    className={classes.checkBox}
                                                    onChange={() => handleGabarito(index)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}/>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <p>{optionsLetter[index]}</p>
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>

                                    <Grid item={true} xs={8} sm={10} lg={10}>
                                        <div className="questionEditor">
                                            <TextEditor 
                                                optionType={true}
                                                opcoes={opcoes}
                                                text={item.opcao} 
                                                setText={handleOpcao}
                                                position={index}
                                                initialMessage={`<p><span style='color:hsl(0, 0%, 30%)'>Opção de resposta</span></p>`}
                                                />
                                        </div>
                                    </Grid>

                                    <Grid item={true} xs={2} sm={1} lg={1}>
                                        {index === tam-1 
                                            ?   <Fab className={classes.fabButton} onClick={addNewOption} size="small" color="primary" aria-label="add">
                                                    <AddIcon />
                                                </Fab>
                                            :   <Fab className={classes.fabButton} onClick={(event) => deleteThisOption (event, index)} size="small" color="secondary" aria-label="add">
                                                    <DeleteIcon />
                                                </Fab>
                                        } 
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </MyCard>
                    {questao?.erros?.resposta && <p className={classes.errorMessage}>{questao?.erros?.resposta}</p>}
            </section>
        
            <section className="padraoResposta">
                <h2 className="subtitle-page">Padrão Resposta</h2>
                <MyCard className={questao?.erros?.enunciado && classes.errorAlarm}>
                    <TextEditor 
                        optionType={false}
                        text={questao.padraoResposta} 
                        setText={handlePadraoResposta}
                        initialMessage="<p><span style='color:hsl(0, 0%, 30%)'>Digite aqui o padrão resposta da questão</span></p>"
                    />
                </MyCard>
            </section>

            <section className="topicos">
                <h2 className="subtitle-page">Tópicos</h2>
                <p>Selecione abaixo os tópicos que essa questão será vinculada.</p>
                <SelectTopicTable 
                    data={topicos} 
                    setData={setQuestao}
                    filterDialog={filterDialog}
                    setFilterDialog={setFilterDialog}
                    tableSelection={true}
                    selectedTopics={topicoSelecionado}
                    initialFilter={initialFilter} 
                    filter={filter}
                    setFilter={setFilter}
                    setTopicosChips={setTopicosChips}
                    fullData={fullData}
                />
        
                <Grid container={true} className={classes.root} spacing={1}>
                    {topicosChips?.length > 0 && topicosChips.map((row, index) => {
                        let label = (row && row.disciplinaID && row.disciplinaID.turmaID) 
                            ?  (row.disciplinaID.turmaID.sigla + " | " + row.disciplinaID.nome + " | " + row.topico) 
                            : "Carregando";
                        return (
                            <Grid key={index} item={true}>
                                <Chip color="primary" label={label} />
                            </Grid>
                        )
                    })}
                </Grid>
            </section>

            <section className="grupoBotoes">
                <Grid container={true} spacing={2} justifyContent='center'>
                    <Grid item={true} xs={6} sm={3}>
                        <AddButton fullWidth={true} onClick={saveQuestion}>Salvar</AddButton>
                    </Grid>
                    <Grid item={true} xs={6} sm={3}>
                        <Link to="/controle-questoes/list" style={{ textDecoration: 'none' }}>
                            <DeleteButton fullWidth={true}>Voltar</DeleteButton>
                        </Link>
                    </Grid>
                </Grid>
            </section>
        </MyContainer> 
    )
}