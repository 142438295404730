import api from "../../api";

// Calcula o número de semanas existente entre o início do ano letivo e a data atual
async function currentWeek(turmaID) {
    // Contagem em ms da data de início do ano letivo
    const response = await api.encAnoLetivoPorTurma(turmaID);
    const value = response.data.data;
    const firstDay = value.contagem;
    
    // Contagem em ms da data atual
    var today = new Date();
    today.setDate(today.getDate() + 1);
    today = today.valueOf();

    // Número total de semana entre os intervalos (hoje e início do ano letivo)
    var weeks = Math.trunc(((((today - firstDay)/1000)/3600)/24)/7);
    return weeks + 1;
}

const diaDaSemana = [ "Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado" ]
const meses = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro" ]

export {
    currentWeek,
    diaDaSemana,
    meses
}