import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GeneralSubtitle } from '../../assets/styles/styledComponents';
import { Grid } from '@material-ui/core';
import api from '../../api';
import { StoreContext } from '../../utils';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    marginTop: '-1rem',
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 10,
    backgroundColor: '#94c93d',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    marginTop: '0rem',
    marginBottom: '0rem'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.secondary,
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(13),
      textAlign: 'center',
    }
  }
}));

export default function LinearProgressBar(props) {
  const classes = useStyles();
  const { titulo } = props;
  const [ dataInicio, setDataInicio ] = useState();
  const [ dataAtual, setDataAtual ] = useState();
  const [ dataFim, setDataFim ] = useState();
  const [title, setTitle] = useState(titulo);
  const [isLoaded, setIsLoaded] = useState(false);
  const [progress, setProgress] = useState(false);
  const semanas = 1000 * 60 * 60 * 24 * 7;
  const { token } = useContext(StoreContext)
  const turmaID = token.turmaID;
  
  useEffect(() => {
    async function calcularProgresso() {
      try {
        const res = await api.encAnoLetivoPorTurma(turmaID);
        setDataInicio(Date.parse(res.data.data.dataInicio)/semanas);
        setDataFim(Date.parse(res.data.data.dataFim)/semanas);
        setDataAtual(Date.now()/semanas);
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
      }
    }
    if (!isLoaded) calcularProgresso();
    return () => {};
  }, [isLoaded, semanas, turmaID]);

  useEffect(() => {
    if (isLoaded && title === "Progresso do Curso") {
      var valA = dataAtual-dataInicio;
      var valB = dataFim-dataInicio;
      var valC = Math.round(((100*valA)/valB)*10)/10;

      valC = valC > 100 ? 100 : valC;
      valC = valC < 0 ? 0 : valC;

      setProgress(valC);

      var progressText = "Progresso do Curso: " + valC + "%";
      setTitle(progressText);
    }
    return () => {};
  }, [dataAtual, dataFim, dataInicio, isLoaded, title]);

  return (
    <Grid item className={classes.root}>
      <GeneralSubtitle className={classes.secondaryHeading}>{title}</GeneralSubtitle>
      <BorderLinearProgress 
        variant="determinate" 
        value={ progress ? progress : 0 } 
      />
    </Grid>
  );
}
