import React, { useState, useContext, useEffect } from "react";
import api from '../../api';
import { MyContainer, MyTextField, GeneralTitle, MyTypography } from "../../assets/styles/styledComponents";
import { makeStyles, Grid, MenuItem, Card, useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { StoreContext } from "../../utils"
import UploadContent from "../Upload/UploadContent";
import AssistantIcon from '@material-ui/icons/Assistant';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Rating from '@material-ui/lab/Rating';
import { Alert } from "@material-ui/lab";

// -- Local Styles
const useStyles = makeStyles((theme) => ({
    alert: {
        backgroundColor: "#fbab18",
        marginBottom: "0.625rem"
    },
    category: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom: "0.625rem"
    },
    center: {
        textAlign: "center",
        justifyContent: "center",
    },
    errorMessage: {
        fontSize: "0.75rem",
        paddingLeft: "1rem",
        color: "#f44336"
    },
    heading: {
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        textAlign: "center",
        justifyContent: "center",
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "1rem"
    }
}));

export default function ContentForm (props) {
    const { material, setMaterial, conteudo, setConteudo, onSubmit, titulo } = props;
    const [listaDisciplina, setListaDisciplina] = useState([]);
    const [listaTurma, setListaTurma] = useState([]);
    const [numSemana, setNumSemana] = useState([]);
    const [disciplinas, setDisciplinas] = useState([]);
    const [classificacao, setClassificacao] = useState(1);
    const [classificacaoHover, setClassificacaoHover] = useState(0);
    const [listaClassificacoes, setListaClassificacoes] = useState([]);
    const { token } = useContext(StoreContext);
    const classes = useStyles();
    const coresRank = ["#FFD700", "#44CCC0", "#4235FB", "#4dbc8e"];
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [expanded, setExpanded] = useState(false);
    
    // -- Carrega as Disciplinas, Turmas e Classificações existentes no banco
    useEffect(() => {
        const abortController = new AbortController();
       
        async function fetchDisciplinaAPI() {
            const response = await api.listarDisciplinas();
            var value = response.data.data;
            if (token.userAccess === "Professor" && token.disciplina.length) {
                value = response.data.data.filter(item => {
                    return token.disciplina.includes(item._id);
                });
            }
            setListaDisciplina(value);
        }
        async function fetchTurmaAPI() {
            const response = await api.listarTurma();
            setListaTurma(response.data.data);
        }

        async function fetchClassificacoesAPI() {
            const response = await api.listarClassificacoes();
            setListaClassificacoes(response.data.data);
        }
        
        fetchDisciplinaAPI();
        fetchTurmaAPI();
        fetchClassificacoesAPI();

        return abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        if (material.turmaID !== "") {
            setDisciplinas(listaDisciplina.filter(item => {
                return item.turmaID === material.turmaID;
            }));
            async function fetchNumeracao() {
                try {
                    const response = await api.encAnoLetivoPorTurma(material.turmaID);
                    const numSemanas = response.data.data.numSemanas;
                    var array = [];
                    for (let i = 1; i <= numSemanas; ++i) { array.push(i) }
                    setNumSemana(array);
                } catch (error) { }
            }
            fetchNumeracao();
        }
        return abortController.abort();
        // eslint-disable-next-line
      }, [material.turmaID])

    useEffect(() => {
        const abortController = new AbortController();
        if (material.classificacao !== "") {
            const aux = listaClassificacoes.map(item => item._id).indexOf(material.classificacao) + 1;
            setClassificacao(aux);
        }
        return abortController.abort();
        // eslint-disable-next-line
      }, [material.classificacao])

    // -- Definição das Funções
    const handleChange = (event) => {
        const {name, value} = event.target;
        setMaterial(preValue => ({
            ...preValue,
            [name]: value
        }));
    }

    const handleChangeClassification = (newValue) => {
        if(newValue > 0){
            setClassificacao(newValue)
            setMaterial(preValue => ({
                ...preValue,
                classificacao: listaClassificacoes[newValue-1]._id
            }));
        }
    }
        
    const handleUpload = async event => {
        const file = event.target.files[0];
        if (file?.type.includes("pdf")) {
            setConteudo({
                file: file,
                url: URL.createObjectURL(file)
            });
            var erros = material.erros;
            erros.conteudoURL = false;
            setMaterial(preValue => ({
                ...preValue,
                conteudo: file,
                erros: erros
            }));
        } else {
            setMaterial(preValue => ({
                ...preValue,
                erros: { 
                    conteudoURL: "Conteúdo precisa estar em PDF." 
                }
            }));
        }
    }

    const getLabel = () => {
        if(listaClassificacoes.length > 0) {
            if(classificacaoHover > 0) return listaClassificacoes[classificacaoHover-1].nome;
            if(classificacao > 0) return listaClassificacoes[classificacao-1].nome
        } 
        return "Selecione uma classificação";
    }

    const ContentRank = () => {
        if(smScreen)
        {   
            return (
                <>
                    {!classificacao && <Alert variant="filled" severity="warning">A classificação deste conteúdo precisa ser definida.</Alert>}
                    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} className={classes.category}>

                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                            <MyTypography variant="h6" className={classes.heading}>Classificação</MyTypography>
                            {
                                !expanded && <>
                                    <Rating
                                        name="classificacao"
                                        className={classes.secondaryHeading}
                                        value={classificacao}
                                        style={{
                                            color: !(classificacaoHover > 0 && classificacaoHover !== classificacao) 
                                                ? coresRank[classificacao - 1] 
                                                : coresRank[classificacaoHover - 1]
                                        }}
                                        max={listaClassificacoes.length}
                                        defaultValue={1}
                                        size="large"
                                        onChange={(event, newValue) => handleChangeClassification(newValue)}
                                        onChangeActive={(event, newHover) => setClassificacaoHover(newHover)}
                                    />
                                    <MyTypography className={classes.secondaryHeading}>{ getLabel() }</MyTypography>
                                </>
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item sm={12} container justifyContent="center" alignItems="center">
                                    <Grid item sm={12} className={classes.center}>
                                        <Rating
                                            name="classificacao"
                                            value={classificacao}
                                            style={{
                                                color: !(classificacaoHover > 0 && classificacaoHover !== classificacao) 
                                                    ? coresRank[classificacao - 1] 
                                                    : coresRank[classificacaoHover - 1]
                                            }}
                                            max={listaClassificacoes.length}
                                            defaultValue={1}
                                            size="large"
                                            onChange={(event, newValue) => handleChangeClassification(newValue)}
                                            onChangeActive={(event, newHover) => setClassificacaoHover(newHover)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.center}>
                                        <MyTypography>{ getLabel() }</MyTypography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.center}>
                                        <MyTypography variant="caption">A classificação do conteúdo define a quantidade de questões que sua atividade de fixação deve respeitar.</MyTypography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container justifyContent="center" spacing={2}>
                                    {
                                        listaClassificacoes.map((item, index) => {
                                            return (
                                                <Grid key={index} item xs={12} container direction="column" justifyContent="center">
                                                    <Card className={classes.category}>
                                                        <Grid item xs={12} className={classes.center}>
                                                            <AssistantIcon fontSize="large" htmlColor={coresRank[index]}/>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.center}>
                                                            <MyTypography>{ item.nome }</MyTypography>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.center}>
                                                            {item.qtdQuestoes + " questões"}
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </>
            )
        }
        return (
            <>
                {!classificacao && <Alert variant="filled" severity="warning" className={classes.alert}>A classificação deste conteúdo precisa ser definida.</Alert>}
                <Card className={classes.category}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <MyTypography variant="h6">Classificação</MyTypography>
                            <Rating
                                name="classificacao"
                                value={classificacao}
                                style={{
                                    color: !(classificacaoHover > 0 && classificacaoHover !== classificacao) 
                                        ? coresRank[classificacao - 1] 
                                        : coresRank[classificacaoHover - 1]
                                }}
                                max={listaClassificacoes.length}
                                defaultValue={1}
                                size="large"
                                onChange={(event, newValue) => handleChangeClassification(newValue)}
                                onChangeActive={(event, newHover) => setClassificacaoHover(newHover)}
                            />
                            <MyTypography>{ getLabel() }</MyTypography>
                            <MyTypography variant="caption">A classificação do conteúdo define a quantidade de questões que sua atividade de fixação deve respeitar.</MyTypography>
                        </Grid>
                        <Grid item xs={9} container justifyContent="center" spacing={2}>
                            {
                                listaClassificacoes.map((item, index) => {
                                    return (
                                        <Grid key={index} item xs container direction="column">
                                            <Card className={classes.category}>
                                                <Grid>
                                                    <AssistantIcon fontSize="large" htmlColor={coresRank[index]}/>
                                                </Grid>
                                                <Grid>
                                                    <MyTypography>{ item.nome }</MyTypography>
                                                </Grid>
                                                <Grid>
                                                    {item.qtdQuestoes + " questões"}
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Card>
            </>
        )
    }

    return (
        <MyContainer>
            <GeneralTitle>{titulo}</GeneralTitle>

            <Grid container spacing={1}>
                <Grid item={true} xs={12} sm={4}>
                    <MyTextField
                        variant="outlined"
                        select={true}
                        label="Turma"
                        name="turmaID"
                        onChange={handleChange}
                        value={material.turmaID ? material.turmaID : ""}
                        error={material.erros.turma ? true : false}>
                        {
                            listaTurma.map((row, index) => {
                                return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
                            })
                        }
                    </MyTextField>
                    {material.erros.turma && <p className={classes.errorMessage}>{material.erros.turma}</p>}
                </Grid>

                <Grid item={true} xs={12} sm={4}>
                    <MyTextField
                        variant="outlined"
                        select={true}
                        label="Disciplina"
                        name="disciplinaID"
                        disabled={disciplinas.length === 0}
                        onChange={handleChange}
                        value={material.disciplinaID ? material.disciplinaID : ""}
                        error={material.erros.disciplina ? true : false}>
                        {
                            disciplinas.map((row, index) => {
                                
                                return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
                            })
                        }
                    </MyTextField>
                    {material.erros.disciplina && <p className={classes.errorMessage}>{material.erros.disciplina}</p>}
                </Grid>
                
                <Grid item={true} xs={12} sm={4}>
                    <MyTextField
                        label="Numeração"
                        variant="outlined"
                        name="numeracao"
                        type="text"
                        select={true}
                        value={material.numeracao}
                        disabled={material.turmaID === ""}
                        onChange={handleChange}
                        error={material.erros.numeracao ? true : false}>
                            {
                                numSemana.map((item, index) => {
                                    return <MenuItem key={index} value={item}>{item}</MenuItem>
                                })
                            }
                    </MyTextField>
                    {material.erros.numeracao && <p className={classes.errorMessage}>{material.erros.numeracao}</p>}
                </Grid>

                <Grid item={true} xs={12}>
                    <MyTextField
                        label="Tópico"
                        variant="outlined"
                        name="topico"
                        type="text"
                        value={material.topico}
                        onChange={handleChange}
                        error={material.erros.topico ? true : false}/>
                        {material.erros.topico && <p className={classes.errorMessage}>{material.erros.topico}</p>}
                </Grid>
                
                <Grid item xs={12}>
                    { ContentRank() }
                </Grid>


                <Grid item={true} xs={12}>
                    <MyTextField
                        label="Link da Videoaula"
                        variant="outlined"
                        name="videoAulaURL"
                        type="text"
                        value={material.videoAulaURL ? material.videoAulaURL : ""}
                        onChange={handleChange}
                        error={material.erros.videoAulaURL ? true : false}/>
                        {material.erros.videoAulaURL && <p className={classes.errorMessage}>{material.erros.videoAulaURL}</p>}
                </Grid>

                <Grid item={true} xs={12}>
                    <MyTextField
                        label="Link da Videoaula 2 (Opcional)"
                        variant="outlined"
                        name="videoAulaExtraURL"
                        type="text"
                        value={material.videoAulaExtraURL ? material.videoAulaExtraURL : ""}
                        onChange={handleChange}/>
                </Grid>

                <Grid item={true} xs={12}>
                    <MyTextField
                        label="URL do Material"
                        variant="outlined"
                        name="conteudoURL"
                        type="text"
                        value={material.conteudoURL}
                        onChange={handleChange}
                        error={material.erros.conteudoURL ? true : false}/>
                        {material.erros.conteudoURL && <p className={classes.errorMessage}>{material.erros.conteudoURL}</p>}

                </Grid>
            </Grid>
            
            <UploadContent 
                onChange={handleUpload}
                topico={material.topico} 
                conteudo={conteudo} 
                backTo="/controle-conteudo" 
                onSubmit={onSubmit}
            />
        </MyContainer>
    );
}