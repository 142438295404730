import React, { useEffect, memo, useState } from "react";

import { Button, Grid, TextField } from "@material-ui/core";
import { MyCardContent, MyTypography } from "../../assets/styles/styledComponents"
import { PhotoDialog, RadioAnswer, UploadButton, WirisIframe } from "../";

import { useStyles } from '../../assets/styles/classes';
import "./QuestionStyles.css"
import VisibilityIcon from '@material-ui/icons/Visibility';

import api from "../../api";

function QuestionCard(props) {
	const classes = useStyles();
	const {
		questaoID, answered, enunciado, tipoResposta, gabarito, resposta, respostaQuestao, atividadeID, revisaoID, alunoID, 
		setRespostaQuestao, setNewPhoto, padraoResposta, marcou, setMarcou, wasLoaded, setWasLoaded, isEssay, mounted
	} = props;
	const [open, setOpen] = useState(false);
	const [ onBlur, setOnBlur ] = useState(false);
    const [ away, setAway ] = useState(false);
    const [ contador, setContador ] = useState(0);

	async function pegaRQ() {

		var res;

		// Encontra resposta questão do aluno
		if (atividadeID !== undefined) res = await api.encRespostaQuestaoPorAtividade(atividadeID, alunoID, questaoID);
		else res = await api.encRespostaQuestaoPorRevisao(revisaoID, alunoID, questaoID);

		// Se encontrar, recupera IDs
		if (res.data.success === true) {
			const resposta = res.data.data;
			setRespostaQuestao(resposta);
			setWasLoaded(true);
			if(resposta.contador) setContador(resposta.contador)
			else setContador(0)
		}

	}

	useEffect(() => {
		const abortController = new AbortController();
		if (mounted && !wasLoaded) pegaRQ();
		return abortController.abort();
		// eslint-disable-next-line
	}, [questaoID, mounted, wasLoaded]);

	useEffect(() => {
        if(!away && !respostaQuestao.corrigido) {
            const intervalId = setInterval(() => {
				setContador(contador => contador + 1);
				setRespostaQuestao(prevValue => ({
					...prevValue,
					contador: contador + 1
				}));
			}, 1000);
            return () => clearInterval(intervalId);
        } 

		// eslint-disable-next-line
    },[away, contador]);

    useEffect(() => {

        if(onBlur && !away && !respostaQuestao.corrigido) {
            const intervalAwayId = setInterval(async () => { 
				setAway(true);
				setRespostaQuestao(prevValue => ({
					...prevValue,
					contador: contador
				}));
			}, 300000);
            return () => clearInterval(intervalAwayId);
        }
        
		// eslint-disable-next-line
    },[onBlur]);

    window.addEventListener("blur", () => {
        !onBlur && setOnBlur(true);
    });

    window.addEventListener("focus", () => {
        onBlur && setOnBlur(false);
    });
	
	//#endregion

	// -- Pega resposta discursiva após cada mudança no Input
	const pegarRespostaDiscursiva = (event) => {
		const { value } = event.target;
		setRespostaQuestao(prevValue => ({
			...prevValue,
			resposta: value
		}));
	}

	// -- Guarda o dado vindo do upload da imagem em resposta discursiva
	const handleUpload = async (event) => {
		const file = await event.target.files;
		if (file.length > 0) {
			setRespostaQuestao(prevValue => ({
				...prevValue,
				foto: file
			}));
			setNewPhoto(true);
		}
	}

	// -- Atualiza respostaQuestao
	const atualizaRespostaQuestao = async (rqID, model) => {
		await api.atualizarRespostaQuestao(rqID, model)
			.then(res => {
				setMarcou(!marcou);
			})
			.catch(err => console.log(err));
	}

	// -- Função para verificar se as fotos já estão armazenadas na Amazon
	const verificarFotos = (fotos) => {
		var auxiliar = [];
		Array.from(fotos).map(row => {
			if (String(row).includes("https://odisseia-bucket.s3")) auxiliar.push(row);
			else auxiliar.push(URL.createObjectURL(row));
			return null;
		})
		return auxiliar;
	}

	// -- Retorna botão de upload ou padrão resposta da questão
	const returnUploadOption = () => {

		return (
			<Grid container justifyContent="center" spacing={1}>
				<Grid item direction="column" hidden={answered} xs={6} sm={4}>
					<UploadButton
						fullWidth={true}
						text="Responder"
						handleUpload={handleUpload}
						uploaded={(respostaQuestao?.foto?.length > 0 && respostaQuestao?.questaoID === questaoID)}
						single={true} />
				</Grid>
				<Grid item direction="column" hidden={respostaQuestao?.foto?.length === 0} xs={6} sm={4}>
					<Button
						style={{ marginTop: '0.5rem' }}
						disabled={respostaQuestao?.foto?.length === 0 ? true : false}
						color="primary"
						variant="contained"
						fullWidth={true}
						onClick={() => { setOpen(true) }}
						startIcon={<VisibilityIcon />}>Visualizar</Button>
					<PhotoDialog open={open} setOpen={setOpen} fotos={(respostaQuestao?.foto?.length > 0 && respostaQuestao?.questaoID === questaoID) ? verificarFotos(respostaQuestao?.foto) : []} />
				</Grid>
				<Grid className={classes.questionGrid} align="center" item hidden={!answered} xs={10}>
					<MyTypography className={classes.infoAnswer}>
						{padraoResposta !== '' ? "Padrão de Resposta" : "Não há um padrão de resposta para esta questão."}
					</MyTypography>
				</Grid>
				<Grid className={classes.questionGrid} align="center" item hidden={!answered} xs={10}>
					<WirisIframe className={classes.infoAnswer} text={padraoResposta} />
				</Grid>
				{
					respostaQuestao?.comentario !== "" && respostaQuestao?.comentario !== undefined ?
						<>
							<Grid className={classes.questionGrid} align="center" item hidden={!answered} xs={10}>
								<MyTypography className={classes.infoAnswer}>Comentário do Professor</MyTypography>
							</Grid>
							<Grid className={classes.questionGrid} align="center" item hidden={!answered} xs={10}>
								<WirisIframe className={classes.infoAnswer} text={respostaQuestao?.comentario} />
							</Grid>
						</>
						: null
				}
			</Grid>
		);
	}

	// -- Retorna campos de resposta da questão
	const returnAnswerOption = () => {
		if (tipoResposta === "multiplaEscolha") {
			return (
				<Grid className={classes.questionGrid} item={true} align="left" xs={12} lg={12} sm={12}>
					<RadioAnswer
						questaoID={questaoID}
						answered={answered}
						gabarito={gabarito ? gabarito.gab?._id : false}
						resposta={resposta}
						respostaQuestao={respostaQuestao}
						setRespostaQuestao={setRespostaQuestao}
						atualizaRespostaQuestao={atualizaRespostaQuestao}
					/>
				</Grid>
			)
		}

		if (tipoResposta === "discursiva") {
			return (
				<Grid item xs={12}>
					<Grid container spacing={1} alignItems="center" justifyContent="center" alignContent="center" xs={12}>
						<Grid className={classes.textField} item align="center" xs={10}>
							<TextField
								fullWidth
								id={respostaQuestao?.questaoID ? respostaQuestao.questao : ''}
								label={respostaQuestao?.resposta ? null : "Resposta"}
								disabled={answered}
								value={(respostaQuestao?.questaoID === questaoID) ? (respostaQuestao?.resposta) : ""}
								onChange={pegarRespostaDiscursiva}
								onKeyUp={pegarRespostaDiscursiva}
								multiline
							/>
						</Grid>
						{returnUploadOption()}
					</Grid>
				</Grid>
			)
		}
	}

	return (
		<MyCardContent>
			<Grid container={true}>
				<Grid item={true} align="left" xs={12} lg={12} sm={12}>
					<WirisIframe text={enunciado} />
				</Grid>
				{
					!isEssay &&
					<Grid container={true}>
						{returnAnswerOption()}
					</Grid>
				}
			</Grid>
		</MyCardContent>
	);
}

export default memo(QuestionCard);