import React, { useContext, useEffect, useState } from 'react';
import Axios from "axios";
import api from '../../api'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { StoreContext } from '../../utils';
import { MyCard, MyTextField } from '../../assets/styles/styledComponents';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import { TextEditor } from '..';
import UploadAttachment from '../Upload/UploadAttachment';

const useStyle = makeStyles((theme) => ({
  cards: {
    padding: "2rem 1.5rem 1.5rem 1.5rem"
  },
  group: {
    textAlign: "center"
  },
  fabButton: {
    marginTop: "0.4rem"
  },
  checkBox: {
    marginTop: "0.4rem"
  },
  questaoOpcoes: {
    padding: "1rem",
    textAlign: "center",
    alignItems: "center",
  },
  errorMessage: {
    fontSize: "0.75rem",
    paddingLeft: "1rem",
    color: "#f44336"
  },
  errorAlarm: {
    border:"1px solid #f44336"
  },
  input: {
    display: 'none',
  }
}));

function ConverteDatas(model) {
  var newModel = model;
  newModel.dataInicio = `${moment(model.dataInicio).format("YYYY-MM-DD")} 03:00:00.000Z`
  newModel.dataFim = `${moment(model.dataFim).format("YYYY-MM-DD")} 23:59:59.000Z`
  return newModel;
}

export default function MuralForm(props) {
  const { token } = useContext(StoreContext);
  
  const initialComunicado = {
    turmaID: '',
    conteudo: '',
    dataInicio: new Date(),
    dataFim: new Date(),
    dataCriacao: new Date(),
    dataModificacao: new Date(),
    autor: token.userID,
    file: false,
    anexoURL: "",
    anexoExt: ""
  }

  const classes = useStyle();
  const today = `${moment(new Date()).format("YYYY-MM-DD")}T03:00:00.000Z`;
  const {id, setMount, setOpen, setComunicadoSelecionado, setNoError, setMessage, setSnackOpen} = props;
  const [comunicado, setComunicado] = useState(initialComunicado);
  const [listaTurma, setListaTurma] = useState([]);
  const [erros, setErros] = useState({});
  
  // -- Carrega comunicado, caso haja id
  useEffect(() => {
    setErros({});
    if (id !== undefined) {
      async function fetchComunicadoAPI() {
        await api.encComunicadoPorID(id)
          .then(res => setComunicado(res.data.data))
          .catch(err => console.error(err));
      }
      fetchComunicadoAPI();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // -- Carrega turmas de acordo com usuário
  useEffect(() => {
    async function fetchTurmaAPI() {
      const response = await api.listarTurma();
      if (token.accessType === "Professor") {
        var turmasProfessor = [];
        token.turmaID.forEach(x => {turmasProfessor.push(x.turmaID)});
        var value = response.data.data.filter(x => {return turmasProfessor.includes(x._id)});
        setListaTurma(value);
      }
      else {
        setListaTurma(response.data.data);
      }
    }
    fetchTurmaAPI();
  }, [token])
  
  // -- Validador
  const validateModel = (model) => {
    let error = {};
    if (model?.dataInicio === "" || model?.dataInicio === null) {
      error.dataInicio = "É necessário informar a data de início do comunicado.";
    }
    if (model?.dataFim === "" || model?.dataFim === null) {
      error.dataFim = "É necessário informar a data de fim do comunicado.";
    }
    if (model?.turmaID === "") {
      error.turmaID = "É necessário informar a turma do comunicado.";
    }
    if (model?.conteudo === "") {
      error.conteudo = "É necessário informar o conteúdo do comunicado.";
    }
    else {
      error.validated = true
    }
    return error;
  }

  // -- Função para pegar os valores do formulário
  const handleChange = (event) => {
    const { name, value } = event.target;
    setComunicado((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  // -- Função para pegar os valores de data do formulário
	const handleDateChange = (date, name) => {
		setComunicado((preValue) => ({
			...preValue,
			[name]: date,
		}));
	};

  // -- Salvar dados do conteúdo do comunicado
  const handleConteudo = (value) => {
    setComunicado(preValue => ({
        ...preValue,
        conteudo: value
    }));
  }

  // -- Encerra card de inserção/edição
  const handleClose = () => {
    if (id !== undefined)
    setComunicadoSelecionado(null);
    else
    setOpen(false);
  }
  
  // - Realiza o upload do anexo
  const handleAttachment = async event => {
    const file = event.target.files[0];
    const allowedExtensions = /(.jpg|.jpeg|.png|.pdf)$/i;
    if (allowedExtensions.exec(file?.type)) {
        setComunicado(prevValue => ({
          ...prevValue,
          file,
          anexoURL: URL.createObjectURL(file),
          anexoExt: file.type
        }));
        setErros(preValue => ({
            ...preValue,
            anexo: ""
        }));
    } else {
        setErros(preValue => ({
            ...preValue,
            anexo: "A extensão do arquivo deve ser do tipo: .png, .jpeg, .jpg ou .pdf."
        }));
    }
  }

  // -- Salva Comunicado
  async function handleSave() {
    var error = validateModel(comunicado);
    setErros(error);

    if (error.validated) {

      let novoComunicado = ConverteDatas(comunicado);

      await api.inserirComunicado(novoComunicado)
        .then(async (res) => {
          setMessage(res.data.message);
          setNoError(true);

          if (comunicado.file) {
            
            // Salva o pdf na núvem
            const formData = new FormData();
            formData.append("anexo", comunicado.file);
            const { id } = res.data;
            
            await Axios.post(`${process.env.REACT_APP_API_URL}upload-anexo/${id}`, formData)
              .then(async res => {
                novoComunicado.anexoURL = res.data.url;
                novoComunicado.anexoExt = comunicado.anexoExt;
                await api
                  .atualizarComunicado(id, novoComunicado)
                  .then(res => console.log("Salvou arquivo!"))
                  .catch(err => {
                    setNoError(false);
                    setMessage("Houve um erro com a inserção do anexo, verifique se todas as informações estão corretas. Se o erro persistir, informe à equipe técnica.");
                  });
              })
              .catch(err => {
                console.log(err);
              });
          }

          setComunicado(initialComunicado);
          setMount(x => ({...x, wasChanged: true}));
          setOpen(false);
        })
        .catch(err => {
          setNoError(false);
          setMessage("Houve um erro ao inserir o comunicado.");
          console.log(err);
        });
        setSnackOpen(true);
    }
  }

  // -- Atualiza Comuicado
  async function handleUpload() {
    var error = validateModel(comunicado);
    setErros(error);
    if (error.validated) {

      let comunicadoAtualizado = ConverteDatas(comunicado);

      // Verifica se o usuário subiu algum conteúdo pdf
      if (comunicado.file) {
        // Salva o pdf na pasta local
        const formData = new FormData();
        formData.append("anexo", comunicado.file);
        
        await Axios.post(`${process.env.REACT_APP_API_URL}upload-anexo/${id}`, formData)
          .then(res => {
            comunicadoAtualizado.anexoURL = res.data.url
            comunicadoAtualizado.anexoExt = comunicado.anexoExt
          })
          .catch(err => console.log(err) );
      }

      await api.atualizarComunicado(id, comunicadoAtualizado)
        .then(res => {
          setComunicado(initialComunicado);
          setComunicadoSelecionado(null);
          setNoError(true);
          setMessage(res.data.message);
          setMount(x => ({...x, wasChanged: true}));
        })
        .catch(err => {
          setNoError(false);
          setMessage("Houve um erro ao atualizar o comunicado.");
          console.log(err);
        });
        setSnackOpen(true);
    }
  }

  return (
    <MyCard className={classes.cards}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Turma"
              name="turmaID"
              onChange={handleChange}
              value={comunicado.turmaID ? comunicado.turmaID : ""}
              error={erros.turmaID ? true : false}
            >
              {
                listaTurma.map((row, index) => {
                  return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
                })
              }
            </MyTextField>
            {erros.turmaID && <p className={classes.errorMessage}>{erros.turmaID}</p>}
          </Grid>

          <Grid item xs={12}>
            <MuiPickersUtilsProvider
              locale="pt-br"
              libInstance={moment}
              utils={MomentUtils}
            >
              <KeyboardDatePicker
                autoOk={true}
                disableToolbar={true}
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label="Data de Início"
                name="dataInicio"
                fullWidth
                value={comunicado.dataInicio}
                onChange={date => handleDateChange(date, "dataInicio")}
                minDate={today}
                maxDate={comunicado.dataFim}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                error={erros.dataInicio ? true : false}
              />
            </MuiPickersUtilsProvider>
            {erros.dataInicio && <p className={classes.errorMessage}>{erros.dataInicio}</p>}
          </Grid>

          <Grid item xs={12}>
            <MuiPickersUtilsProvider
              locale="pt-br"
              libInstance={moment}
              utils={MomentUtils}
            >
              <KeyboardDatePicker
                autoOk={true}
                disableToolbar={true}
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label="Data de Fim"
                name="dataFim"
                fullWidth
                value={comunicado.dataFim}
                onChange={date => handleDateChange(date, "dataFim")}
                minDate={comunicado.dataInicio}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                error={erros.dataFim ? true : false}
              />
            </MuiPickersUtilsProvider>
            {erros.dataFim && <p className={classes.errorMessage}>{erros.dataFim}</p>}
          </Grid>

          <Grid item xs={12} container>
            <Grid xs={6}>
              <h3 className="subtitle-page" style={{margin: "1rem 0"}}>Conteúdo</h3>
            </Grid>
            
            <Grid xs={12}>
              <MyCard className={erros?.conteudo && classes.errorAlarm}>
                <TextEditor 
                  optionType={false}
                  text={comunicado.conteudo} 
                  setText={handleConteudo}
                  initialMessage="<p><span style='color:hsl(0, 0%, 30%)'>Digite aqui o conteúdo do comunicado</span></p>"
                />
              </MyCard>
              {erros.anexo && <p className={classes.errorMessage}>{erros.anexo}</p>}  
            </Grid>
          </Grid>
          
          <Grid item xs={12}>
            <UploadAttachment 
              comunicado={comunicado}
              onChange={handleAttachment}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <Button
              size="large"
              fullWidth={true}
              variant="outlined"
              color="secondary"
              onClick={handleClose}>Cancelar</Button>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <Button
              size="large"
              fullWidth={true}
              variant="outlined"
              color="primary"
              onClick={id !== undefined ? handleUpload : handleSave}>Salvar</Button>
          </Grid>
        </Grid>
    </MyCard>
  );
}