import React, { memo, useContext } from 'react';
import { CustomTopicButton } from './Buttons';
import { Grid, Typography } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../../utils';

function ReturnADs(props) {
  const { check, revision, classes, topicProgress, plano, setFeatureOpen } = props;
  const { setDataProvider } = useContext(StoreContext);

  return (
  <>
    <Grid style={{overflow: 'hidden'}} align="center" item={true} xs={12} sm={8}>
      <Typography id="secondaryHeading" className={classes.secondaryHeading}>
        Are you ready?! <br/> Já está liberada sua avaliação diagnóstica, clique no botão que se segue para começar!
      </Typography>
    </Grid>
    <Grid align="center" item={true} xs={12} sm={4}>
      {
        plano === "Free" ?
          <CustomTopicButton 
            id="avaliacaoDiagnostica" 
            handleClick={() => setFeatureOpen(true) } 
            check={check.avaliacaoDiagnostica }
            classes={classes}
            icon={<SchoolIcon />}
            title1="Avaliação Concluída"
            title2="Iniciar Avaliação"
          />
        :
        <Link to={`/atividade/`} style={{ textDecoration: 'none' }}>
          <CustomTopicButton 
            id="avaliacaoDiagnostica" 
            handleClick={() => { setDataProvider( prevValue => ({ ...prevValue, activity: revision, progress: topicProgress, type: "avaliacaoDiagnostica"}))}} 
            check={check.avaliacaoDiagnostica }
            classes={classes}
            icon={<SchoolIcon />}
            title1="Avaliação Concluída"
            title2="Iniciar Avaliação"
          />
        </Link>
      }
    </Grid>
  </>
  );
}

export default memo(ReturnADs);