import React, { useState, useEffect, useContext } from "react";
import { MyTextField } from "../../../../assets/styles/styledComponents";
import { SimpleSnackMessage, SubjectTable } from "../../../../components";
import { MenuItem, Grid, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "moment/locale/pt-br";
import useStyles from "./styles";
import api from "../../../../api";
import { areaConhecimento, StoreContext } from "../../../../utils";

// Main Function
export default function ManageSubject(props) {
  const { mount, setMount, listaDisciplina, setListaDisciplina } = props;
  const { token } = useContext(StoreContext);
  const initialSubjectState = {
    nome: "",
    diaSemana: "",
    areaConhecimento: "",
    turmaID: "",
    dataModificacao: new Date(),
    autorModificacao: token.userID,
  };
  const classes = useStyles();
  const [disciplina, setDisciplina] = useState(initialSubjectState);
  const [subjectID, setEditSubject] = useState(null);
  const [listaTurma, setListaTurma] = useState([]);
  const [noError, setNoError] = useState(true);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  // Lista Disciplinas
  async function fetchDisciplinaAPI() {
    let response = await api.listarDisciplinas();
    setListaDisciplina(response.data.data);
  }

  // Lista Turmas
  async function fetchTurmaAPI() {
    const response = await api.listarTurma();
    setListaTurma(response.data.data);
  }

  // Observa mudanças no conteúdo de listagem - Disciplina
  useEffect(() => {
    const abortController = new AbortController();
    // Caso haja mudança em disciplina
    if (mount.disciplina) {
      fetchDisciplinaAPI();
      setMount((preValue) => ({ ...preValue, disciplina: false }));
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [mount]);

  // Carregamento Inicial - Disciplina
  const initialLoad = () => {
    if (mount.isMountedSubject) {
      fetchDisciplinaAPI();
      fetchTurmaAPI();
      setMount((preValue) => ({ ...preValue, isMountedSubject: false }));
    }
  };

  // Função para pegar os valores do formulário - DISCIPLINA
  const handleChange = (event) => {
    const { name, value } = event.target;
    setDisciplina((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  // Guarda nova disciplina no banco
  async function saveChange() {
    await api.inserirDisciplina(disciplina)
      .then(res => {
        setDisciplina(initialSubjectState);
        setMount((preValue) => ({ ...preValue, disciplina: true }));
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao inserir a disciplina.");
      });
    setOpen(true);
  }

  // Guarda disciplina atualizada no banco
  async function editChange() {
    var novaDisciplina = disciplina;
    novaDisciplina.autorModificacao = token.userID;
    novaDisciplina.dataModificacao = new Date();

    await api.atualizarDisciplina(subjectID, novaDisciplina)
      .then(res => {
        setDisciplina(initialSubjectState);
        setEditSubject(null);
        setMount((preValue) => ({ ...preValue, disciplina: true }));
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao atualizar a disciplina.");
      });
    setOpen(true);
  }

  return (
  <>
    <Accordion
      square
      elevation={2}
      style={{ borderBottom: `0.2rem solid #3300ff` }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => initialLoad()}
        aria-controls="panel1a-content"
        id="panel1-settings"
      >
        <h2 className="heading-page">Gerenciar Disciplinas</h2>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container={true} className={classes.root} spacing={2}>
          <Grid item={true} xs={12} sm={5}>
            <MyTextField
              variant="outlined"
              label="Nome"
              name="nome"
              type="text"
              value={disciplina.nome}
              onChange={handleChange}
            />

            <MyTextField
              variant="outlined"
              select={true}
              label="Turma"
              name="turmaID"
              onChange={handleChange}
              value={disciplina.turmaID ? disciplina.turmaID : ""}
            >
              {
                listaTurma.map((row, index) => {
                  return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
                })
              }
            </MyTextField>

            <MyTextField
              variant="outlined"
              select={true}
              label="Dia da Semana"
              name="diaSemana"
              value={disciplina.diaSemana}
              onChange={handleChange}
            >
              <MenuItem value="1">Segunda-feira</MenuItem>
              <MenuItem value="2">Terça-feira</MenuItem>
              <MenuItem value="3">Quarta-feira</MenuItem>
              <MenuItem value="4">Quinta-feira</MenuItem>
              <MenuItem value="5">Sexta-feira</MenuItem>
              <MenuItem value="6">Sábado</MenuItem>
            </MyTextField>

            <MyTextField
              variant="outlined"
              select={true}
              label="Área do Conhecimento"
              name="areaConhecimento"
              onChange={handleChange}
              value={disciplina.areaConhecimento ? disciplina.areaConhecimento : ""}
            >
              {areaConhecimento.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.value}
                  </MenuItem>
                );
              })}
            </MyTextField>

            <div className={classes.group}>
              <Button
                className={classes.buttons}
                variant="outlined"
                type="submit"
                color="secondary"
                onClick={() => setDisciplina(initialSubjectState)}
              >
                Limpar
              </Button>
              <Button
                className={classes.buttons}
                variant="outlined"
                type="submit"
                color="primary"
                onClick={subjectID ? editChange : saveChange}
              >
                Salvar
              </Button>
            </div>
          </Grid>

          <Grid item={true} xs={12} sm={7}>
            <SubjectTable
              pushSubject={setDisciplina}
              setID={setEditSubject}
              data={listaDisciplina}
              setMount={setMount}
              turmas={listaTurma}/>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
    <SimpleSnackMessage 
      open={open}
      setOpen={setOpen}
      message={message}
      error={noError}/>
  </>
  );
}
