import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  dialog: {
    overlfow: 'scroll' // <-- This tells the modal to scrol
  },
  grid: {
    margin: 'auto', 
    textAlign: 'right'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default useStyles;