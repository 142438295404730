import React, { useState, useEffect, useContext } from 'react';
import { Button,  Dialog , Grid, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import { MyTextField } from "../../../assets/styles/styledComponents";
import api from '../../../api';
import { StoreContext, areaConhecimento, tipoAtividade } from '../../../utils';
import moment from "moment";
import "moment/locale/pt-br";

const DisplayDate = (item) => {
  var dataInicio = moment(item.dataInicio).format("DD");
  var dataFim = moment(item.dataFim).format("DD/MM");
  return (`${dataInicio}-${dataFim}`);
}

const initialFilter = {
  tipo: "",
  disciplinaID: "",
  turmaID: "",
  topico: "",
  dataLiberacao: "",
  area: ""
}

export default function ActivityDialogFilter(props) {
  const { filter, setFilter, open, setOpen, revision, setIsCleaned } = props;
  const [listaDisciplina, setListaDisciplina] = useState([]);
  const [listaData, setListaData] = useState([]);
  const [mount, setMount] = useState(true);
  const [tempFilter, setTempFilter] = useState(initialFilter);
  const { token } = useContext(StoreContext);
  const [listaTurmas, setListaTurma] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [sigla, setSigla] = useState("");
  const [listaTopico, setListaTopico] = useState([]);

  //--Carregamentos do filtro
  useEffect(() => {
    // Verifica se filtro já está preenchido
    if (filter !== initialFilter) {
      setTempFilter(filter);
    } else if (tempFilter !== initialFilter) {
      setTempFilter(initialFilter);
    }
    // eslint-disable-next-line
  }, [filter]);

  //--Carregamentos iniciais
  useEffect(() => {
    const abortController = new AbortController();
  
    // Montagem das listas
    if (mount) {
      async function fetchTurmaAPI() {
        await api.listarTurma()
          .then(res => {
            var value = res.data.data;
            if (token.accessType !== "Administrador" && token.turmaID.length) {
              var turmas = [];
              token.turmaID.map(x => { return turmas.push(x.turmaID) });
              value = res.data.data.filter(item => {
                return turmas.includes(item._id);
              });
            }
            setListaTurma(value);
          });
      }

      async function fetchDisciplinaAPI() {
        await api.listarDisciplinas()
          .then(res => {
            var value = res.data.data;
            if (token.accessType !== "Administrador" && token.disciplina.length) {
              value = res.data.data.filter(item => {
                return token.disciplina.includes(item._id);
              });
            }
            setListaDisciplina(value);
          });
      }

      // Faz o fetch e desmonta
      fetchTurmaAPI();
      fetchDisciplinaAPI();
      setMount(false);
    }

    return abortController.abort();
  }, [filter, mount, token]);

  //--Fetch dos tópicos
  useEffect(() => {
    const abortController = new AbortController();
    if (tempFilter.disciplinaID !== "") {
      async function fetchTopicoAPI() {
        try {
          var params = {
            area: "0",
            turmaID: "0",
            disciplinaID: tempFilter.disciplinaID,
            numeracao: "0"
          }
          const response = await api.listarConteudoPorFiltroInterno(params);
          setListaTopico(response.data.data);
        } catch (error) {
          setListaTopico([]);
        }
      }

      fetchTopicoAPI();

      setTempFilter(preValue => ({
        ...preValue,
        topicoID: "",
      }))
    }
    return abortController.abort();
  }, [tempFilter.disciplinaID])

  //--Datas de Liberação
  useEffect(() => {
    const abortController = new AbortController();
    if (tempFilter?.turmaID !== "") {
      setDisciplinas(listaDisciplina.filter(x => {return x.turmaID === tempFilter.turmaID}));

      if (tempFilter?.area !== "") {
        async function fetchDataAdsAPI() {
          try {
            const response = await api.listarDataAdPorTurma(tempFilter.turmaID);
            const value = response.data.data.filter(x => {return x.areaConhecimento === tempFilter.area});
            setListaData(value);
  
            // Limpa data de liberação caso ocorra mudança em turma ou area do conhecimento
            if (value.find(x => x._id === tempFilter.dataLiberacao) === undefined) {
              setTempFilter(x => ({...x, dataLiberacao: ""}));
            }
          } catch (error) {
            setListaData([]);
            setTempFilter(x => ({...x, dataLiberacao: ""}));
          }
        }
        fetchDataAdsAPI();
      }
    }
    return abortController.abort();
  }, [listaDisciplina, tempFilter.area, tempFilter.dataLiberacao, tempFilter.turmaID])

  // Salva modificações do filtro temporariamente
  const onFilterChange = (event) => {
    const { name, value } = event.target;
    setTempFilter(preValue => ({
      ...preValue,
      [name]: value
    }))
    setIsCleaned(false);
    if (name === "turmaID") {
      setSigla(listaTurmas.filter(x => {return (x._id === value)})[0].sigla)
    }
  }

  // Salva modificações no filtro permanentemente
  const onSubmit = () => {
    setFilter(tempFilter);
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Filtro</DialogTitle>

      <DialogContent>
        <Grid container={true} spacing={2}>

          <Grid item={true} xs={12}>
            <MyTextField
              variant="outlined"
              disabled={false}
              select={true}
              label="Área do Conhecimento"
              name="area"
              value={tempFilter.area ? tempFilter.area : ""}
              onChange={onFilterChange}>
                {
                  areaConhecimento.map((item, index) => {
                    return <MenuItem key={index} value={item.value}>{item.value}</MenuItem>
                  })
                }
            </MyTextField>
          </Grid>

          <Grid item={true} hidden={revision} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Tipo de Atividade"
              name="tipo"
              value={tempFilter.tipo ? tempFilter.tipo : ""}
              onChange={onFilterChange}>
                {
                  tipoAtividade.map((item, index) => {
                    if (item.value !== "Avaliação Diagnóstica") {
                      return <MenuItem key={index} value={item.value}>{item.value}</MenuItem>
                    }
                    // eslint-disable-next-line array-callback-return
                    return;
                  })
                }
            </MyTextField>
          </Grid>

          <Grid item={true} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Turma"
              name="turmaID"
              value={tempFilter.turmaID ? tempFilter.turmaID : ""}
              onChange={onFilterChange}>
              {
                listaTurmas.map((item, index) => {
                  return <MenuItem key={index} value={item._id}>{item.nome}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>

          <Grid item={true} hidden={revision} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Disciplina"
              name="disciplinaID"
              disabled={disciplinas.length === 0 || tempFilter.turmaID === ""}
              value={tempFilter.disciplinaID ? tempFilter.disciplinaID : ""}
              onChange={onFilterChange}>
              {
                disciplinas.map((item, index) => {
                  return <MenuItem key={index} value={item._id}>{sigla}-{item.nome}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>

          <Grid item={true} hidden={revision} xs={12}>
            <MyTextField
                variant="outlined"
                select={true}
                disabled={listaTopico.length === 0 || tempFilter.turmaID === ""}
                label="Tópico"
                name="topico"
                value={tempFilter.topico ? tempFilter.topico : ""}
                onChange={onFilterChange}>
                {
                  listaTopico.map((row, index) => {
                    return <MenuItem key={index} value={row._id}>{row.topico}</MenuItem>
                  })
                }
              </MyTextField>
          </Grid>

          <Grid item={true} hidden={!revision} xs={12}>
            <MyTextField
              select={true}
              label="Data de Liberação"
              variant="outlined"
              name="dataLiberacao"
              disabled={listaData.length === 0 && tempFilter.turmaID !== ""}
              value={tempFilter.dataLiberacao ? tempFilter.dataLiberacao : ""}
              onChange={onFilterChange}>
                {
                  listaData.map((row, index) => {
                    return <MenuItem key={index} value={row._id}>{DisplayDate(row)}</MenuItem>
                  })
                }
            </MyTextField>
          </Grid>

        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Voltar
        </Button>
        <Button onClick={onSubmit} color="primary">
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}