import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../utils";
import api from '../../api'
import { MyContainer, CreateButton, GeneralTitle } from "../../assets/styles/styledComponents"
import { makeStyles } from '@material-ui/core/styles';
import { ActivityTable, ActivityDialog, SimpleLinearProgress } from "../../components";
import { Grid, Button, ButtonGroup, Tooltip } from '@material-ui/core';

// -- Local Styles
const useStyles = makeStyles((theme) => ({
    activityMode: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
        }
    }
}));

const initialFilter = {
    tipo: "",
    disciplinaID: "",
    turmaID: "",
    topico: "",
    dataLiberacao: "",
    area: ""
}

export default function ActivityList() {
    const classes = useStyles();
    const { token } = useContext(StoreContext);
    const disciplinas = token.disciplina;
    const [atividades, setAtividades] = useState([]);
    const [auxAtividade, setAuxAtividade] = useState('');
    const [revisoes, setRevisoes] = useState([]);
    const [isRevision, setIsRevision] = useState(false);
    const [atividadeSelecionda, setAtividadeSelecionda] = useState('');
    const [hiddenDialog, setHiddenDialog] = useState(false);
    const [filterDialog, setFilterDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState(initialFilter);
    const [mount, setMount] = useState({
        activity: {
            isMounted: false,
            wasChanged: false
        },
        revision: {
            isMounted: false,
            wasChanged: false
        }
    })

    // -- Atualização a partir de montagem
    useEffect(() => {
        // Fetch Atividades
        async function fetchAtividadesAPI() {
            setLoading(true);
            var initialParams = {
                tipo: "0",
                disciplinaID: "0",
                turmaID: "0",
                topico: "0",
                numeracao: "0",
                area: "0"
            }
            if (disciplinas.length) {
                var arrayAux = [];

                disciplinas.forEach(async item => {
                    try {
                        var params = initialParams;
                        params.disciplinaID = item;

                        await api.listarAtividadePorFiltro(params)
                            .then(res => {
                                const value = res.data.data;
                                if (arrayAux.length) arrayAux = arrayAux.concat(value);
                                else arrayAux = value;
                                setAtividades(arrayAux);
                            })
                            .catch(() => setAtividades([]));
                        setLoading(false);

                    } catch (error) { }
                });
            } 
            
            else {
                // Não havendo disciplinas do usuário
                // Retorna primeira disciplina
                await api.listarDisciplinas()
                    .then(async tmp => {
                        var firstSubject = tmp.data.data[0];
                        setAuxAtividade(firstSubject._id);
                        
                        var params = initialParams;
                        params.disciplinaID = firstSubject._id;
        
                        await api.listarAtividadePorFiltro(params)
                            .then(res => {
                                setAtividades(res.data.data);
                            })
                            .catch(() => setAtividades([]));
                        
                        setLoading(false);
                    });
            }
        }

        // Fetch Revisões
        async function fetchRevisoesAPI() {
            setLoading(true);
            await api.listarRevisao()
                .then(res => {
                    setRevisoes(res.data.data);
                })
                .catch(() => setRevisoes([]));
                setLoading(false);
        }

        // Montagem inicial de Revisões
        if (!mount.revision.isMounted) {
            fetchRevisoesAPI();
            setMount(preValue => ({
                ...preValue, 
                revision: { 
                    isMounted: true, 
                    wasChanged: mount.revision.wasChanged
                }
            }));
        }

        // Montagem inicial de Atividades
        if (!mount.activity.isMounted) {
            fetchAtividadesAPI();
            setMount(preValue => ({
                ...preValue, 
                activity: { 
                    isMounted: true, 
                    wasChanged: mount.activity.wasChanged
                }
            }));
        }

        // Montagem após mudança em Atividade
        if (mount.revision.wasChanged) {
            fetchRevisoesAPI();
            setMount(preValue => ({
                ...preValue, 
                revision: { 
                    isMounted: mount.revision.isMounted, 
                    wasChanged: false
                }
            }));
        }

        // Montagem após mudança em Revisão
        if (mount.activity.wasChanged) {
            fetchAtividadesAPI();
            setMount(preValue => ({
                ...preValue, 
                activity: { 
                    isMounted: mount.activity.isMounted, 
                    wasChanged: false
                }
            }));
        } 
    }, [disciplinas, mount]);

    // -- Carrega tabela conforme disciplina selecionada no filtro
    useEffect(() => {
        if (!isRevision) {
            setLoading(true);
            async function fetchActivitiesByFilter() {
                var query = {
                    disciplinaID: filter?.disciplinaID !== "" ? filter.disciplinaID : "0",
                    area: filter?.area !== "" ? filter.area : "0",
                    turmaID: filter?.turmaID !== "" ? filter.turmaID : "0",
                    tipo: filter?.tipo !== "" ? filter.tipo : "0",
                    topico: filter?.topico !== "" ? filter.topico : "0"
                };
                await api.listarAtividadePorFiltro(query)
                    .then(res => setAtividades(res.data.data))
                    .catch(() => setAtividades([]));
                setLoading(false);
            }
            if (filter !== initialFilter) {
                fetchActivitiesByFilter();
            } 
            else if (auxAtividade !== '') {
                fetchActivitiesByFilter(auxAtividade);
            }
        }
        
        else {
            setLoading(true);
            async function fetchRevisionByFilter() {
                const area = filter?.area !== "" ? filter.area : "0";
                const turmaID = filter?.turmaID !== "" ? filter.turmaID : "0";
                const dataLiberacao = filter?.dataLiberacao !== "" ? filter.dataLiberacao : "0";
                await api.encRevisaoPorFiltroInterno(turmaID, dataLiberacao, area)
                    .then(res => setRevisoes(res.data.data))
                    .catch(() => setAtividades([]));
                setLoading(false);
            }
            fetchRevisionByFilter();
        }
    }, [auxAtividade, filter, isRevision])

    return (
        <MyContainer>
            <Grid container={true}>
                <Grid item={true} xs={12} sm={6} lg={6}>
                    <GeneralTitle>Atividades</GeneralTitle>
                </Grid>
                <Grid item={true} xs={12} sm={6} lg={6} className={classes.activityMode}>
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                        <Tooltip title="Atividades Gerais">
                            <Button onClick={() => setIsRevision(false)}>Atividades</Button>
                        </Tooltip>

                        <Tooltip title="Avaliações Diagnósticas">
                            <Button onClick={() => setIsRevision(true)}>ADs</Button>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>

            <SimpleLinearProgress hidden={!loading} />

            <ActivityTable 
                data={isRevision ? revisoes : atividades} 
                revision={isRevision} 
                filterDialog={filterDialog}
                setFilterDialog={setFilterDialog}
                setActivity={setAtividadeSelecionda} 
                setHidden={setHiddenDialog} 
                setMount={setMount}
                filter={filter}
                setFilter={setFilter}
            />

            <ActivityDialog 
                atividadeID={atividadeSelecionda}
                open={hiddenDialog}
                setOpen={setHiddenDialog}
            />

            <div className="create-button">
                <CreateButton title="Inserir Atividade" url="/controle-atividade/create"/>
            </div>
        </MyContainer>
    );
};