import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  areaConhecimento: {
    fontSize: "1.3rem"
  },
  contentCard: {
    width: '100%',
    minHeight: 284,
    padding: '1rem',
    backgroundColor: theme.palette.background.paper,
  },
  listCard: {
    width: '100%',
    padding: '1rem',
    backgroundColor: theme.palette.background.paper,
  },
  sumario: {
    textAlign: "center",
  },
  caixa: {
    flex: 6,
    flexDirection: "flex-end",
  },
  caixaNota: {
    flex: 6,
    flexDirection: "flex-end",
    borderRight: "1px solid #ddd"
  },
  caixaRedacoes: {
    [theme.breakpoints.up('sm')]: {
      borderLeft: "1px solid #ddd",
      paddingLeft: "0.5rem"
    }
  },
  legenda: {
    fontSize: "1rem"
  },
  resultado: {
    fontSize: "1.3rem"
  },
  resultadoNota: {
    padding: "1rem 0 0.8rem 0",
    fontSize: "2rem"
  },
  rightSubtitle: {
    textAlign: "right",
    fontSize: "0.8rem",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    }
  },
  rightText: {
    fontSize: "1rem",
    textAlign: "right",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    }
  },
  subtitle: {
    fontSize: "1rem",
  },
  success: {
    display: "grid",
    justifyContent: "center",
    padding: "0.5rem 0"
  },
  observacao: {
    fontSize: "0.9rem",
    padding: "2rem 0.5rem 0 0.5rem"
  }
}));

export default useStyles;