import { makeStyles } from '@material-ui/core/styles';

// -- Estilos locais
const useStyles = makeStyles((theme) => ({
  
  accordion: {
    marginTop: '1rem',
    minHeight: "15rem",
  },

  accordionReturns: {
    padding: '0.5rem'
  },

  card: {
    marginTop: '1rem',
    minHeight: 400,
    padding: '1rem'
  },
  
  comment: {
    marginTop: '1rem',
    marginBottom: '2rem',
    width: '100%'
  },
  
  commentChanged: {
    marginTop: '1rem',
    marginBottom: '2rem',
    width: '90%'
  },

  commentIcon: {
    marginTop: '1rem'
  },

  content: {
    fontStyle: 'normal',
    fontWeight: `300`,
    fontSize: '1rem',
    marginTop: '1rem',
    color: '#606161',
  },

  discursiveAnswer: {
    width: '100%'
  },

  fullWidthActivityTab: {
    marginTop: '1rem',
    minHeight: 400,
  },
  
  phrase: {
    fontSize: '1rem'
  },
  
  question: {
    padding: '1rem',
    paddingTop: '0rem'
  },
  
  questionProgress: {
    marginLeft: '2rem',
  },

  student: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingLeft: '1rem'
  },
  
  subTitle: {
    fontStyle: 'normal',
    fontWeight: `500`,
    fontSize: '1.25rem',
    marginTop: '1rem',
    color: '#606161',
  },

  title: {
    fontStyle: 'normal',
    fontWeight: `300`,
    fontSize: '1.25rem',
    color: '#606161',
  }
}));

export default useStyles;