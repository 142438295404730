import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../utils";
import api from '../../api'
import { MyContainer, CreateButton, GeneralTitle } from "../../assets/styles/styledComponents"
import { QuestionTable, QuestionDialog, SimpleLinearProgress } from "../../components"
import "./styles.css"

const initialFilter = {
    disciplinaID: "",
    topicoID: "",
    turmaID: "",
    tipo: ""
}

export default function QuestionList() {
    const { token } = useContext(StoreContext);
    const disciplinas = token.disciplina;
    const [questoes, setQuestoes] = useState([]);
    const [auxQuestion, setAuxQuestion] = useState('');
    const [questaoSelecionada, setQuestaoSelecionada] = useState('');
    const [filterDialog, setFilterDialog] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [hiddenDialog, setHiddenDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [mount, setMount] = useState({
        isMounting: true,
        wasChanged: false
    });

    // -- Lista as questões do banco após cada alteração
    useEffect(() => {
        const abortController = new AbortController();
        // -- Função de Listagem de Questões da API
        async function fetchQuestoesAPI() {
            setLoading(true);

            var initialParams = {
                tipo: "0",
                disciplinaID: "0",
                turmaID: "0",
                topicoID: "0"
            }
            
            if (disciplinas.length) {
                var arrayAux = [];
                disciplinas.forEach(async item => {
                    try {
                        var params = initialParams;
                        params.disciplinaID = item;
                        
                        await api.listarQuestaoPorFiltro(params)
                            .then(({ data }) => {
                                if (arrayAux.length) arrayAux = arrayAux.concat(data.data)
                                else arrayAux = data.data;
                                setQuestoes(arrayAux);
                                setLoading(false);
                            })
                            .catch(() => setQuestoes([]));
                        } catch (error) { }
                    });
            } 
                
            else {
                await api.listarDisciplinas()
                .then(async tmp => {
                    var firstSubject = tmp.data.data[0];
                    setAuxQuestion(firstSubject._id);
                    
                    var params = initialParams;
                    params.disciplinaID = firstSubject._id;
                    
                    await api.listarQuestaoPorFiltro(params)
                    .then(res => {
                        setQuestoes(res.data.data);
                        setLoading(false);
                    })
                    .catch(() => setQuestoes([]));
                })
                .catch(() => setQuestoes([]));
            }
                
        }
            
        // -- Montagem Inicial
        if (mount.isMounting) {
            fetchQuestoesAPI()
            setMount(preValue => ({ ...preValue, isMounting:false }));
        }
        
        // -- Montagem após cada mudança
        if (mount.wasChanged) {
            fetchQuestoesAPI()
            setMount(preValue => ({ ...preValue, wasChanged:false }));
        }

        return abortController.abort();
    }, [disciplinas, mount]);
        
    // -- Observa mudanças em questão selecionada
    useEffect(() => {
        setQuestaoSelecionada(questaoSelecionada)
    }, [questaoSelecionada]);

    // -- Carrega tabela conforme disciplina selecionada no filtro
    useEffect(() => {
        const abortController = new AbortController();
        if (!mount.isMounting) {
            async function fetchQuestionByFilter(filter) {
                var query = {
                    disciplinaID: filter?.disciplinaID !== "" ? filter.disciplinaID : "0",
                    topicoID: filter?.topicoID !== "" ? filter.topicoID : "0",
                    turmaID: filter?.turmaID !== "" ? filter.turmaID : "0",
                    tipo: filter?.tipo !== "" ? filter.tipo : "0"
                };
                
                setLoading(true);
                await api.listarQuestaoPorFiltro(query)
                    .then(res => {
                        setQuestoes(res.data.data);
                    })
                    .catch(() => {
                        setQuestoes([]);
                    });
                setLoading(false);
            }
        
            if (filter !== initialFilter) {
                fetchQuestionByFilter(filter);
            }
        }
        return abortController.abort();
    }, [auxQuestion, filter, mount.isMounting])

    return (
        <MyContainer>
            <section id="cabecalhoQuestao">
                <GeneralTitle>Banco de Questões</GeneralTitle>
            </section>

            <section id="tabelaQuestao">
                <SimpleLinearProgress hidden={!loading} />

                <QuestionTable 
                    data={questoes} 
                    setMount={setMount} 
                    setQuestion={setQuestaoSelecionada} 
                    filterDialog={filterDialog}
                    setFilterDialog={setFilterDialog}
                    setHidden={setHiddenDialog} 
                    activity={false}
                    filter={filter}
                    setFilter={setFilter}
                    initialFilter={initialFilter}/>

                <QuestionDialog 
                    enunciado={questaoSelecionada?.enunciado}
                    tipoResposta={questaoSelecionada?.tipoResposta}
                    padraoResposta={questaoSelecionada?.padraoResposta}
                    resposta={questaoSelecionada?.resposta}
                    identificador={questaoSelecionada?.identificador}
                    open={hiddenDialog}
                    setOpen={setHiddenDialog}
                />
            </section>

            <section id="rodapeQuestao">
                <div className="create-button">
                    <CreateButton title="Inserir Questão" url="/controle-questoes/create"/>
                </div>
            </section>
        </MyContainer>
    );
};