import React, {memo, useState} from 'react';
import { GeneralSubtitle, GeneralText } from '../../../assets/styles/styledComponents';
import { Accordion, AccordionSummary, AccordionDetails, Grid, useTheme, useMediaQuery, Tooltip, Slide, List, ListItem, ListItemText, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import { useStyles } from "./Styles"
import clsx from 'clsx';
import { useContext } from 'react';
import { StoreContext } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { CircularStatic } from '../../';

const GradeCard = (props) => {
  const {classes, media, parte, total, setWasLoaded, wasLoaded} = props;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.rankContainer} style={{paddingBottom: "0.5rem"}}>
        <Grid container>
          <Grid item xs={8} sm={9} style={{paddingRight: "0.2rem"}}>
            <GeneralText className={classes.leftTitle}>Nota Média da Turma</GeneralText>
            <GeneralText className={classes.gradeText} style={{textAlign: "left"}}>Desempenho médio com base no resultado das atividades.</GeneralText>
          </Grid>
          <Grid item xs={4} sm={3} className={classes.itemGridRank} style={{justifyContent: "flex-end"}}>
            <GeneralText className={clsx(classes.resultGrade, classes.helper)}>{media}</GeneralText>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.rankContainer}>
        <Grid container>
          <Grid item xs={8} sm={9} style={{paddingRight: "0.2rem"}}>
            <GeneralText className={classes.leftTitle}>Questões</GeneralText>
            <GeneralText className={classes.gradeText} style={{textAlign: "left"}}>Relação entre acertos e total de questões realizadas.</GeneralText>
          </Grid>
          <Grid item xs={4} sm={3} className={classes.itemGridRank} style={{justifyContent: "flex-end"}}>
            <div className={classes.helper} style={{padding: "0 0.8rem"}}>
              <CircularStatic wasLoaded={wasLoaded} setWasLoaded={setWasLoaded} numTasks={total} progresso={parte}/>
              <GeneralText className={classes.gradeText}>{parte}/{total}</GeneralText>
              <Tooltip title="Acertos/Total" >
                <GeneralText className={classes.avgLabel}>A/T</GeneralText>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const StudentRank = (props) => {
  const {aluno, best, classes, nota} = props;
  var title = best ? 'Melhor Desempenho' : 'Pior Desempenho';

  return (
    <Grid container className={clsx(classes.container, classes.rankContainer)}>
      <Grid item xs={8} sm={10} md={9} className={classes.itemGridRank}>
        <div>
          <GeneralText className={classes.leftTitle}>{title}</GeneralText>
          <GeneralText className={classes.gridSubtitle}>{aluno}</GeneralText>
        </div>
      </Grid>
      <Grid item xs={4} sm={2} md={3} className={classes.itemGridRank} style={{justifyContent: "flex-end"}}>
        <div style={{ paddingLeft: "1rem" }}>
          <GeneralText className={classes.avgGrade}>{nota}</GeneralText>
          <GeneralText className={classes.gradeText}>Nota Média</GeneralText>
        </div>
      </Grid>
    </Grid>
  )
}

const ContentRank = (props) => {
  const {best, classes, conteudo, nota, parte, total, setWasLoaded, wasLoaded} = props;
  var title = best ? 'Melhor Desempenho' : 'Pior Desempenho';

  return (
    <Grid container className={clsx(classes.container, classes.rankContainer)}>
      <Grid item xs={12} sm={6}>
        <GeneralText className={classes.leftTitle}>{title}</GeneralText>
        <GeneralText className={classes.gridSubtitle}>{conteudo}</GeneralText>
      </Grid>
      <Grid item xs={8} sm={4} className={classes.itemGridRank}>
        <div style={{ paddingLeft: "1rem" }}>
          <GeneralText className={classes.avgGrade}>{nota}</GeneralText>
          <GeneralText className={classes.gradeText}>Nota Média</GeneralText>
        </div>
      </Grid>
      <Grid item xs={4} sm={2} className={classes.itemGridRank} style={{justifyContent: "flex-end", padding: "0 0.5rem"}}>
        <div className={classes.subjectResults}>
          <CircularStatic wasLoaded={wasLoaded} setWasLoaded={setWasLoaded} numTasks={total} progresso={parte}/>
          <GeneralText className={classes.gradeText}>{parte}/{total}</GeneralText>
          <Tooltip title="Estudado/Total" >
            <GeneralText className={classes.avgLabel}>E/T</GeneralText>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  )
}

const FrequencyCard = (props) => {
  const { classes, messages, dataFrequency, wasLoaded, setWasLoaded } = props;

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container className={classes.container}>
      {
        dataFrequency.map((item, index) => {
          const {tooltip} = item;
          return (
            <Grid item key={index} xs={12} sm={12} md={4} style={{ marginTop: (index > 0 && smScreen) && "1rem"}}>
              <Grid container className={classes.rankContainer}>
                <Grid item xs={8} style={{paddingRight: "0.2rem"}}>
                  <GeneralText className={classes.leftTitle}>{messages[index].title}</GeneralText>
                  <GeneralText className={classes.gradeText} style={{textAlign: "left"}}>{messages[index].message}</GeneralText>
                </Grid>
                <Grid item xs={4} className={classes.itemGridRank} style={{justifyContent: "flex-end"}}>
                  <div className={clsx(classes.helper, classes.resultFrequency)}>
                    <CircularStatic wasLoaded={wasLoaded} setWasLoaded={setWasLoaded} numTasks={item.total} progresso={item.parte}/>
                    <GeneralText className={classes.gradeText}>{item.parte}/{item.total}</GeneralText>
                    <Tooltip title={tooltip} >
                      <GeneralText className={classes.avgLabel}>{item.tip}</GeneralText>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })
      }
    </Grid>
  );
}

const frequencyMessages = [
    {
        title: "Material de Estudo",
        message: "Relação entre a quantidade de tópicos completamente estudados pelos alunos e o total de materiais disponibilizados."
    },
    {
        title: "Videoaula",
        message: "Relação entre a quantidade de videoaulas completamente assistidas de cada tópico e total de videoaulas disponibilizadas."
    },
    {
        title: "Atividades",
        message: "Relação entre a quantidade de atividades concluídas de cada tópico e total de atividades programadas."
    },
]

function AnalysisAccordion (props) {
    const classes = useStyles();
    const { color, disciplina, data, frequencias, atividades, disciplinaID } = props;
    const [wasLoaded, setWasLoaded] = useState(false);
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { alunos, questoes, topicos } = data;
    const { atividade, material, videoaula } = frequencias;
    const { setDataProvider } = useContext(StoreContext)
    const history = useHistory();
    
    const resTopicos = {
        tmEstudado: frequencias.tmEstudado ? frequencias.tmEstudado : 0,
        tmTotal: frequencias.tmTotal ? frequencias.tmTotal : 0,
        tpEstudado: frequencias.tpEstudado ? frequencias.tpEstudado : 0,
        tpTotal: frequencias.tpTotal ? frequencias.tpTotal : 0,
    }

    const dataFrequency = [
        {
            parte: material ? material.mEstudado : 0,
            total: material ? material.mTotal : 0,
            tooltip: "Estudados/Total",
            tip: "E/T"
        },
        {
            parte: videoaula ? videoaula.vAssistido : 0,
            total: videoaula ? videoaula.vTotal : 0,
            tooltip: "Assistidos/Total",
            tip: "A/T"
        },
        {
            parte: atividade ? atividade.aFeito : 0,
            total: atividade ? atividade.aTotal : 0,
            tooltip: "Feitos/Total",
            tip: "F/T"
        },
    ]

    function RetornaAtividades() {
        if (atividades.length > 0) {
            return atividades.slice(0).map(item => {
                var text = `${item.topicoID.numeracao}. ${item.tipoAtividade} - ${item.topicoID.topico} `
                return (
                    <ListItem key={item._id} className={classes.listItem} button>
                        <Link to={"/analise-atividade/" + item._id} className={classes.routerLink}>
                            <ListItemText primary={text} />
                        </Link>
                    </ListItem>
                )
            })
        }
        return;
    }

    function OnClick() {
        setDataProvider(x => ({ 
            ...x,
            turmaID: data.turmaID, 
            disciplinaID: disciplinaID, 
            disciplinaNome: data.disciplina, 
            areaConhecimento: data.areaConhecimento 
        }));
        history.push("/analise-desempenho/mais-detalhes/" + disciplinaID);
    }
  
    return (
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '250ms'}}>
            <Accordion square style={{borderBottom: `0.2rem solid ${color}`}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel3-settings">
                    <GeneralSubtitle style={{paddingLeft: "0.4rem"}}>{disciplina}</GeneralSubtitle>
                </AccordionSummary>
        
                <AccordionDetails>
                    <Grid container className={classes.accordionContainer}>
                        <Grid item xs={12} md={4} className={classes.avgGradeGrid}>
                            <GeneralText className={classes.gridTitle}>Resultados</GeneralText>
                            <GradeCard 
                                classes={classes}
                                media={Math.round(data.mediaTurma * 100) / 100}
                                parte={questoes.qCorretas}    
                                total={questoes.qTotal}    
                                setWasLoaded={setWasLoaded} 
                                wasLoaded={wasLoaded} 
                            />
                        </Grid>               
                        
                        <Grid item xs={12} md={4} className={smScreen ? classes.topDivider : classes.helper}>
                            <GeneralText className={classes.gridTitle}>Aluno em Destaque</GeneralText>
                            <StudentRank aluno={alunos.amNome} best={true} classes={classes} isLast={false} nota={Math.round(alunos.amNota * 100) / 100}/>
                            <StudentRank aluno={alunos.apNome} best={false} classes={classes} isLast={true} nota={Math.round(alunos.apNota * 100) / 100}/>
                            <GeneralText className={clsx(classes.gradeText, classes.container)} style={{textAlign: "left", padding: "0 0.5rem"}}>Valores encontrado através de média simples, (soma das notas / número de atividades realizadas).</GeneralText>
                        </Grid>
                        
                        <Grid item xs={12} md={4} className={smScreen ? classes.topDivider : classes.helper}>
                            <GeneralText className={classes.gridTitle}>Conteúdo em Destaque</GeneralText>
                            <ContentRank 
                                best={true}
                                classes={classes} 
                                conteudo={topicos.melhor.tmNome} 
                                isLast={false} 
                                nota={Math.round(topicos.melhor.tmNota * 100) / 100} 
                                parte={resTopicos.tmEstudado} 
                                total={resTopicos.tmTotal} 
                                setWasLoaded={setWasLoaded} 
                                wasLoaded={wasLoaded}
                            />
                            <ContentRank 
                                best={false}
                                classes={classes} 
                                conteudo={topicos.pior.tpNome} 
                                isLast={true}
                                nota={Math.round(topicos.pior.tpNota * 100) / 100} 
                                parte={resTopicos.tpEstudado} 
                                total={resTopicos.tpTotal} 
                                setWasLoaded={setWasLoaded} 
                                wasLoaded={wasLoaded}
                            />
                            
                        </Grid>
                        
                        <Grid item xs={12} className={classes.topDivider} style={{marginTop: "1rem"}}>
                            <GeneralText className={classes.gridTitle} style={{marginTop: "1rem"}}>Engajamento da Turma</GeneralText>
                            <FrequencyCard 
                                classes={classes} 
                                messages={frequencyMessages}
                                setWasLoaded={setWasLoaded} 
                                wasLoaded={wasLoaded}
                                dataFrequency={dataFrequency}
                            />
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "1rem", textAlign: "right"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => OnClick()}
                                >
                                Mais Detalhes
                            </Button>
                        </Grid>
                    
                        <Grid item xs={12} className={classes.topDivider} hidden={atividades.length === 0} style={{margin: "1rem"}}>
                            <GeneralText className={classes.gridTitle} style={{marginTop: "1rem"}}>Atividades</GeneralText>
                            <List className={classes.list} component="nav" aria-label="contacts">
                                { RetornaAtividades() }
                            </List>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
      </Slide>
    );
}

export default memo(AnalysisAccordion);