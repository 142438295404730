import { Button } from '@material-ui/core';
import React from 'react';
import { GreenButton } from '../../../assets/styles/styledComponents';

function CustomTopicButton(props) {
    const {check, classes, id, icon, title1, title2, handleClick} = props;
    return (
        check
            ? <GreenButton 
                className={classes.activityButton} 
                variant="contained" 
                color="primary" 
                fullWidth={true} 
                startIcon={icon}
                onClick={() => handleClick(id)}>{title1}</GreenButton>
            : <Button 
                className={classes.activityButton} 
                variant="outlined" 
                color="primary" 
                fullWidth={true} 
                startIcon={icon}
                onClick={() => handleClick(id)}>{title2}</Button>
    );
}

function CustomVideoButton(props) {
    const {check, classes, id, icon, title, handleClick} = props;
    return (
        check
            ? <GreenButton 
                className={classes.activityButton} 
                id={id}
                variant="contained" 
                color="primary" 
                fullWidth={true} 
                startIcon={icon}
                onClick={handleClick}>{title}</GreenButton>
            : <Button 
                className={classes.activityButton} 
                id={id} 
                variant="outlined" 
                color="primary" 
                fullWidth={true} 
                startIcon={icon}
                onClick={handleClick}>{title}</Button>
    );
}

function CustomButton(props) {
    const {check, classe, id, icon, title, handleClick} = props;
    return (
        check
            ? <GreenButton 
                className={classe} 
                id={id}
                variant="contained" 
                color="primary" 
                fullWidth={true} 
                startIcon={icon}
                onClick={handleClick}>{title}</GreenButton>
            : <Button 
                className={classe} 
                id={id} 
                variant="outlined" 
                color="primary" 
                fullWidth={true} 
                startIcon={icon}
                onClick={handleClick}>{title}</Button>
    );
}

export {
    CustomButton,
    CustomTopicButton,
    CustomVideoButton
};