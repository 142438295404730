import React, { useState } from 'react'
import { Divider, Fab, Grid, makeStyles, useTheme, Zoom } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/DeleteOutline';
import moment from "moment";
import { MyCard } from '../../assets/styles/styledComponents';
import { WirisIframe } from '..';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PhotoDialog from '../Dialogs/PhotoDialog';
import GeneralDialog from '../GeneralComponents/GeneralDialog';
import PdfViewer from '../PDFViewer';

const useStyles = makeStyles((theme) => ({
  alignVertically: {
    margin: "auto"
  },
  cards: {
    padding: "1rem"
  },
  fab: {
    position: 'relative',
    margin: theme.spacing(1)
  }
}));

export default function MuralCard (props) {
  const { model, turma, student, handleEdit, handleRemove } = props;
  const classes = useStyles();
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const [ open, setOpen ] = useState(false);

  return (
    <MyCard className={classes.cards}>
      <WirisIframe text={model.conteudo} />
      <Divider width="100%" />
      <Grid container>
        <Grid item xs={6} sm={4} style={{textAlign: "left"}}>
          <p>{turma?.nome ? turma?.nome : ""}</p>
          {!student && <p>De {moment(model.dataInicio).format("DD/MM/YYYY")} até {moment(model.dataFim).format("DD/MM/YYYY")}</p>}
        </Grid>
        <Grid item xs={6} sm={8} container justifyContent="flex-end" alignContent="center" style={{display: !student ? "flex": "none"}}>
          <Zoom
            key={model._id}
            in={true}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${true ? transitionDuration.exit : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab aria-label="Visualizar" size="small" className={classes.fab} onClick={() => setOpen(true)} color='primary'>
              <VisibilityIcon />
            </Fab>
          </Zoom>
          <Zoom
            key={model._id}
            in={true}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${true ? transitionDuration.exit : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab aria-label="Editar" size="small" className={classes.fab} onClick={() => handleEdit(model._id)} color='primary'>
              <EditIcon />
            </Fab>
          </Zoom>
          <Zoom
            key={model._id+1}
            in={true}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${true ? transitionDuration.exit : 0}ms`,
            }}
            unmountOnExit
            >
            <Fab aria-label="Remover" size="small" className={classes.fab} onClick={() => handleRemove(model._id)} color='secondary'>
              <RemoveIcon />
            </Fab>
          </Zoom>
        </Grid>
        <Grid item xs={6} sm={8} container justifyContent="flex-end" alignContent="center" style={{textAlign: "right", display: student ? "flex": "none"}}>
          <Zoom
            key={model._id}
            in={true}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${true ? transitionDuration.exit : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab aria-label="Visualizar" size="small" className={classes.fab} onClick={() => setOpen(true)} color='primary'>
              <VisibilityIcon />
            </Fab>
          </Zoom>
        </Grid>
      </Grid>
      {
        (model.anexoURL !== "" && model.anexoExt.includes("pdf") &&
            <GeneralDialog 
              open={open}
              setOpen={setOpen}
              dialogTitle={"Pré-Visualização"}
              dialogContent={<PdfViewer source={model.anexoURL}  height={120}/>}
            />)
        ||
        (model.anexoURL !== "" && model.anexoExt.includes("image") &&
            <PhotoDialog open={open} setOpen={setOpen} fotos={new Array(model.anexoURL)} />)
      }
    </MyCard>
  );
}