import React, { useState, useEffect, useContext } from 'react';
import { Button,  Dialog , Grid, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import { MyTextField } from "../../../assets/styles/styledComponents";
import api from '../../../api';
import { areaConhecimento, StoreContext } from '../../../utils';
import moment from "moment";
import "moment/locale/pt-br";

const initialFilter = {
  numeracao: "",
  disciplina: "",
  turmaID: "",
  topico: "",
  tipo: "",
  aluno:"",
}

// -- Ajuste de data para data das ADs
const DisplayDate = (item) => {
    var dataInicio = moment(item.dataInicio).format("DD");
    var dataFim = moment(item.dataFim).format("DD/MM");
    return (`${dataInicio}-${dataFim}`);
}

export default function CorrectionDialogFilter(props) {
  const { filter, setFilter, open, setOpen, setIsCleaned, essay, revision } = props;
  const [listaDisciplina, setListaDisciplina] = useState([]);
  const [mount, setMount] = useState(true);
  const [tempFilter, setTempFilter] = useState(initialFilter);
  const [listaTurmas, setListaTurma] = useState([]);
  const [listaDatas, setListaDatas] = useState([]);
  const [semanasAno, setSemanasAno] = useState([]);
  const { token } = useContext(StoreContext);

  // -- Carregamentos iniciais
  useEffect(() => {
    const abortController = new AbortController();

    // Verifica se filtro já está preenchido
    if (filter !== initialFilter) {
      setTempFilter(filter);
    } else if (tempFilter !== initialFilter) {
      setTempFilter(initialFilter);
    }

    // Monta disciplinas e ano letivo
    if (mount) {
      async function fetchTurmaAPI() {
        const response = await api.listarTurma();
        setListaTurma(response.data.data);
      }

      async function fetchDisciplinaAPI() {
        const response = await api.listarDisciplinas();
        var value = response.data.data;
        if (token.accessType !== "Administrador" && token.disciplina.length) {
          value = response.data.data.filter(item => {
            return token.disciplina.includes(item._id);
          });
        }
        setListaDisciplina(value);
      }
  
      // Faz o fetch e desmonta
      fetchTurmaAPI();
      fetchDisciplinaAPI();
      setMount(false);
    }

    return abortController.abort();
    // eslint-disable-next-line
  }, [filter]);

  // -- Ajusta campos do filtro de caso sejam listadas redações
  useEffect(() => {
    const abortController = new AbortController();
    const field = essay ? "tipo" : "aluno";
    setTempFilter(preValue => ({
      ...preValue,
      [field]: ""
    }));

    return abortController.abort();
    // eslint-disable-next-line
  }, [essay]);

  useEffect(() => {
    const abortController = new AbortController();
    if (tempFilter?.turmaID !== "") {
      // Preenche constante de numeração de acordo com o número de semanas do ano letivo
      
      async function fetchNumeroSemanas() {
        try {
          const response = await api.encAnoLetivoPorTurma(tempFilter.turmaID);
          const numSemanas = response.data.data.numSemanas;
          var array = [];
          for (let i = 1; i <= numSemanas; ++i) { array.push(i) }
          setSemanasAno(array);
        } catch (error) { }
      }

      async function fetchDataADAPI() {
        const response = await api.listarDataAdPorTurma(tempFilter.turmaID);
        setListaDatas(response.data.data);
      }

      fetchNumeroSemanas();
      fetchDataADAPI();
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [tempFilter.turmaID]);

  // -- Salva modificações do filtro temporariamente
  const onFilterChange = (event) => {
    const { name, value } = event.target;
    setTempFilter(preValue => ({
      ...preValue,
      [name]: value
    }))
    setIsCleaned(false);
  }

  // -- Salva modificações no filtro permanentemente
  const onSubmit = (event) => {
    event.preventDefault();
    setFilter(tempFilter);
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Filtro</DialogTitle>

      <DialogContent>
        <Grid container={true} spacing={2}>

          <Grid hidden={!revision} item={true} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Area do Conhecimento"
              name="area"
              value={tempFilter.area ? tempFilter.area : ""}
              onChange={onFilterChange}>
              {
                areaConhecimento.map((item, index) => {
                    return <MenuItem key={index} value={item.value}>{item.value}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>

          <Grid item={true} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Turma"
              name="turmaID"
              value={tempFilter.turmaID ? tempFilter.turmaID : ""}
              onChange={onFilterChange}>
              {
                listaTurmas.map((item, index) => {
                  return <MenuItem key={index} value={item._id}>{item.nome}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>
          
          <Grid hidden={revision} item={true} xs={12}>
            <MyTextField
              select={true}
              label="Numeração"
              variant="outlined"
              name="numeracao"
              disabled={tempFilter.turmaID === ""}
              value={tempFilter.numeracao ? tempFilter.numeracao : ""}
              onChange={onFilterChange}>
                {
                  semanasAno.map((row, index) => {
                    return <MenuItem key={index} value={row}>{row}</MenuItem>
                  })
                }
            </MyTextField>
          </Grid>
          
          <Grid hidden={!revision} item={true} xs={12}>
            <MyTextField
              select={true}
              label="Data"
              variant="outlined"
              name="data"
              disabled={tempFilter.turmaID === ""}
              value={tempFilter.data ? tempFilter.data : ""}
              onChange={onFilterChange}>
                {
                  listaDatas.map(item => {
                      return <MenuItem key={item._id} value={item._id}>{DisplayDate(item)}</MenuItem>
                  })
                }
            </MyTextField>
          </Grid>

          <Grid item={true} xs={12} hidden={essay || revision ? true : false}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Tipo de Atividade"
              name="tipo"
              value={tempFilter.tipo ? tempFilter.tipo : ""}
              onChange={onFilterChange}>
                <MenuItem value="Fixação">Fixação</MenuItem>
                <MenuItem value="Retomada">Retomada</MenuItem>
                <MenuItem value="Aprofundamento">Aprofundamento</MenuItem>
                <MenuItem value="Avaliação Diagnóstica">Avaliação Diagnóstica</MenuItem>
            </MyTextField>
          </Grid>

          <Grid hidden={revision} item={true} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Disciplina"
              name="disciplina"
              value={tempFilter.disciplina ? tempFilter.disciplina : ""}
              onChange={onFilterChange}>
              {
                listaDisciplina.map((row, index) => {
                  return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>

          <Grid hidden={revision} item={true} xs={12}>
            <MyTextField
              variant="outlined"
              label="Tópico"
              name="topico"
              value={tempFilter.topico ? tempFilter.topico : ""}
              onChange={onFilterChange}/>
          </Grid>

          <Grid hidden={essay ? false : true} item={true} xs={12}>
            <MyTextField
              variant="outlined"
              label="Aluno"
              name="aluno"
              value={tempFilter.aluno ? tempFilter.aluno : ""}
              onChange={onFilterChange}/>
          </Grid>

        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Voltar
        </Button>
        <Button onClick={onSubmit} color="primary">
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}