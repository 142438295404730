import React, { useState, useEffect, memo } from 'react';
import { CircularProgress, Typography, Box, Fade } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import * as doneData from "../../assets/animations/checkedDone.json";
import Lottie from "react-lottie";

const defaultOptions2 = {
  loop: false,
  autoplay: true,
  animationData: doneData.default,
  rendererSettings: {
     preserveAspectRatio: "xMidYMid slice"
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  boxDiv: {
    border: 0,
    padding: 0
  },
  box: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  green: {
    color: "#4dbc8e !important"
  },
  yellow: {
    color: "#fbab18 !important"
  },
  red: {
    color: "#f15a22 !important"
  }
}));

const CircularStatic = React.memo((props) => {
  const { progresso, numTasks, wasLoaded, setWasLoaded } = props;
  const [done, setDone] = useState(false);
  const [result, setResult] = useState(0);
  const classes = useStyles();
  
  useEffect(() => {
    const value = (numTasks === 0) ? (progresso * 100) : (progresso * (100/numTasks));
    if (value !== result) setResult(Math.round(value));
    if (!done && (numTasks > 0) && (progresso >= numTasks)) setTimeout(() => { setDone(true) }, 600);
    if (!wasLoaded) setWasLoaded(true);
  }, [progresso, numTasks, result, wasLoaded, setWasLoaded, done]);

  return (<>{
    (done) 
      ? 
        <Fade in={wasLoaded} style={{animationDelay: "300ms"}}>
          <Box alignItems="center" justifyContent="center">
            <Lottie options={defaultOptions2} style={{margin: "-10px"}} height={60} width={60} />
          </Box>
        </Fade>
      : 
        <Fade in={wasLoaded} style={{animationDelay: "300ms"}}>
          <Box className={classes.boxDiv} position="relative" display="inline-flex">
            <CircularProgress className={result >= 75 ? classes.green : result >= 50 ? classes.yellow : classes.red} hidden={result > 100} variant="determinate" value={result} />
            <Box top={0} left={0} bottom={0} right={0} className={classes.box}>
              <Fade in={wasLoaded} style={{ transitionDelay: '100ms' }}>
                <Typography variant="caption" hidden={result > 100} component="div" color="textSecondary">{`${result}%`}</Typography>
              </Fade>
            </Box>
          </Box>
        </Fade>
  }</>);
});

export default memo(CircularStatic);