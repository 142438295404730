import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { CustomButton } from '../Accordions/Utils/Buttons';
import * as videoPlayer from "../../assets/animations/videoPlayer.json";
import Lottie from "react-lottie";
import { Fade } from '@material-ui/core';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: videoPlayer.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoLinkDialog(props) {
    const { open, setOpen, linkAula, linkAulaOpcional, classes, handleCheck } = props;
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 900);
    },[]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={true}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className={classes.iconTitle}>
                {
                    isLoaded &&
                    <Fade in={isLoaded} style={{transitionDelay: "100ms"}}>
                        <Lottie options={defaultOptions} height={160} width={160} isStopped={!isLoaded} isPaused={!isLoaded}/>
                    </Fade>
                }  
            </DialogTitle>

            <DialogContent>
                <CustomButton 
                    id="videoaula"
                    check={false}
                    classe={classes.videoButton}
                    handleClick={() => window.open(linkAula,'_blank')}
                    title="Videoaula"
                />
                
                <CustomButton 
                    id="videoaulaExtra"
                    check={false}
                    classe={classes.videoButton}
                    handleClick={() => window.open(linkAulaOpcional,'_blank')}
                    title="Videoaula Extra"
                />
            </DialogContent>
            <DialogActions>
                <Button className={classes.visualizedButton} onClick={handleCheck} color="primary">
                    Visto
                </Button>
            </DialogActions>
        </Dialog>
    );
}