import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function AccordionSkeleton(num) {
  const RetSkeletons = () => {
    var array = [];
    for (let index = 0; index < num; index++) {
      array.push( <Skeleton key={index} animation="wave" height={130} /> )
    }
    return array;
  }
  return (
    <div style={{padding: '0 0.5rem', width: '100%'}}>
      <RetSkeletons />
    </div>
  )
}