import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    textAlign: "center", 
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem'
    }
  },
  root: {
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.12)',
    padding: "1rem", 
    borderRadius: "0",
    transition: '0.3s',
      '&:hover': {
        transform: 'translateY(-3px)',
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.12)',
      },
    },
}));

export default useStyles;