import React, { useState, useEffect, useContext } from "react";
import { MyTextField } from "../../../../assets/styles/styledComponents";
import { SimpleSnackMessage, TagTable } from "../../..";
import { MenuItem, Grid, Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./styles";
import api from "../../../../api";
import { StoreContext } from "../../../../utils";

export default function ManageTag(props) {
  const { listaDisciplina, setListaDisciplina, mount, setMount } = props;
  const { token } = useContext(StoreContext);
  const classes = useStyles();
  const initialTagState = {
    nome: "",
    disciplinaID: "",
    dataModificacao: new Date(),
    autorModificacao: token.userID,
  };
  const [tag, setTag] = useState(initialTagState); // Constante para armazenamento da TAG
  const [listaTag, setListaTag] = useState([]); // Tags do Banco
  const [tagID, setEditTag] = useState(null); // Constante para verificar se há edição em TAG
  const [listaTurma, setListaTurma] = useState([]);
  const [turmaSelecionada, setTurmaSelecionada] = useState("");
  const [noError, setNoError] = useState(true);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [disciplinas, setDisciplinas] = useState([]);

  // Lista DISCIPLINAS em tela
  async function fetchDisciplinaAPI() {
    const response = await api.listarDisciplinas();
    setListaDisciplina(response.data.data);
  }

  // Lista TURMAS em tela
  async function fetchTurmaAPI() {
    const response = await api.listarTurma();
    setListaTurma(response.data.data);
  }

  // Lista TAGS em tela
  async function fetchTagAPI() {
    const response = await api.listarTags();
    setListaTag(response.data.data);
  }

  // Caso haja mudança em tag
  useEffect(() => {
    const abortController = new AbortController();
    // Caso haja mudança em disciplina
    if (mount.disciplina) {
      fetchDisciplinaAPI();
      setMount((preValue) => ({ ...preValue, disciplina: false }));
    }

    // Caso haja mudança em tag
    if (mount.tag) {
      fetchTagAPI();
      setMount((preValue) => ({ ...preValue, tag: false }));
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [mount]);

  useEffect(() => {
    const abortController = new AbortController();
    if (turmaSelecionada?._id !== undefined) {
      setDisciplinas(listaDisciplina.filter(item => {
        return item.turmaID === turmaSelecionada._id;
      }));
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [turmaSelecionada])

  // Carregamento Inicial
  const initialTagLoad = () => {
    if (mount.isMountedTag) {
      fetchTagAPI();
      fetchTurmaAPI();
      setMount((preValue) => ({ ...preValue, isMountedTag: false }));
      if (mount.isMountedSubject) {
        fetchDisciplinaAPI();
        setMount((preValue) => ({ ...preValue, isMountedSubject: false }));
      }
    }
  };

  // Função para pegar os valores do formulário - TAG
  const handleTagChange = (event) => {
    const { name, value } = event.target;
    setTag((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  // Função para pegar os valores de TURMA
  const handleChange = (event) => {
    const { value } = event.target;
    setTurmaSelecionada(listaTurma.find(item => item._id === value));
  };

  // Guarda nova tag no banco
  async function saveTagChange() {
    await api.inserirTag(tag)
    .then((res) => {
      setTag(initialTagState);
      setMount((preValue) => ({ ...preValue, tag: true }));
      setNoError(true);
      setMessage(res.data.message);
    })
    .catch(err => {
      setNoError(false);
      setMessage("Houve um erro ao inserir a tag.");
    });
    setOpen(true);
  }

  // Guarda disciplina atualizada no banco
  async function editTagChange() {
    var novaTag = tag;
    novaTag.autorModificacao = token.userID;
    novaTag.dataModificacao = new Date();

    await api.atualizarTag(tagID, novaTag)
      .then((res) => {
        setTag(initialTagState);
        setEditTag(null);
        setMount((preValue) => ({ ...preValue, tag: true }));
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao editar a tag.");
      });
    setOpen(true);
  }

  // Limpa formulário
  const cleanForm = () => {
    setTag(initialTagState);
    setTurmaSelecionada("");
  }

  return (
    <>
      <Accordion
        square
        elevation={2}
        style={{ borderBottom: `0.2rem solid #f15a22` }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => initialTagLoad()}
          aria-controls="panel1a-content"
          id="panel2-settings"
        >
          <h2 className="heading-page">Gerenciar Tags</h2>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container={true} className={classes.root} spacing={2}>
            <Grid item={true} xs={12} sm={6}>
              <MyTextField
                id="campoTagNome"
                variant="outlined"
                label="Nome"
                name="nome"
                type="text"
                value={tag.nome}
                onChange={handleTagChange}
              />

              <MyTextField
                variant="outlined"
                select={true}
                label="Turma"
                name="turmaID"
                onChange={handleChange}
                value={turmaSelecionada?._id ? turmaSelecionada._id : ""}
              >
                {
                  listaTurma.map((row, index) => {
                    return <MenuItem key={index} value={row._id}>{row.nome}</MenuItem>
                  })
                }
              </MyTextField>

              <MyTextField
                variant="outlined"
                select={true}
                label="Diciplina"
                name="disciplinaID"
                disabled={disciplinas.length === 0}
                value={tag.disciplinaID?._id !== undefined ? tag.disciplinaID._id : tag.disciplinaID}
                onChange={handleTagChange}
              >
                {disciplinas.map(item => {
                  var nomeFormatado = `${turmaSelecionada.sigla}-${item.nome}`;
                  return (
                    <MenuItem key={item._id} value={item._id}>
                      {nomeFormatado}
                    </MenuItem>
                  );
                })}
              </MyTextField>

              <div className={classes.group}>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  type="submit"
                  color="secondary"
                  onClick={() => cleanForm()}
                >
                  Limpar
                </Button>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  type="submit"
                  color="primary"
                  onClick={tagID ? editTagChange : saveTagChange}
                >
                  Salvar
                </Button>
              </div>
            </Grid>

            <Grid item={true} xs={12} sm={6}>
              <TagTable
                pushTag={setTag}
                setTagID={setEditTag}
                data={listaTag}
                setMount={setMount}
                turmas={listaTurma}
                disciplinas={listaDisciplina}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <SimpleSnackMessage 
        open={open}
        setOpen={setOpen}
        message={message}
        error={noError}/>
    </>
  );
}
