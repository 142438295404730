import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { useStyles } from './styles';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { useWindowDimensions } from "../../utils"

export default function PdfViewer (props) {
    const classes = useStyles();
    const heighScreen = useWindowDimensions();
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;    
    return (
        <div className={classes.material}>
            {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js"> */}
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/es5/build/pdf.worker.js">
                <div className={classes.viewContainer}>
                    <div className={classes.toolbar}>
                        <Toolbar style={{minWidth: '5rem'}}>
                            {
                                (ToolbarSlot) => {
                                    const { ZoomIn, ZoomOut, GoToPreviousPage, CurrentPageInput, NumberOfPages, GoToNextPage } = ToolbarSlot;
                                    return (
                                        <>
                                            <div style={{ padding: '0px 2px' }}>
                                                <ZoomOut />
                                            </div>
                                            <div style={{ padding: '0px 2px' }}>
                                                <ZoomIn />
                                            </div>
                                            <div style={{ padding: '0px 2px', margin: '0 auto' }}>
                                                <GoToPreviousPage />
                                            </div>
                                            <div style={{ padding: '0px 2px' }}>
                                                <CurrentPageInput /> / <NumberOfPages />
                                            </div>
                                            <div style={{ padding: '0px 2px', margin: '0 auto' }}>
                                                <GoToNextPage />
                                            </div>
                                        </>
                                    )
                                }
                            }
                        </Toolbar>
                    </div>
                    <div className={classes.viewer} style={{height: heighScreen.height - props.height}}>
                        <Viewer fileUrl={props.source} plugins={[toolbarPluginInstance]} />
                    </div>
                </div>
            </Worker>
        </div>
    )
};