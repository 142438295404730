import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PdfViewer from '../PDFViewer';
import GeneralDialog from '../GeneralComponents/GeneralDialog';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PhotoDialog from '../Dialogs/PhotoDialog';

export default function UploadAttachment({comunicado, onChange}) {
  const [open, setOpen] = useState(false);

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} sm={(comunicado.anexoURL === '') ? 12 : 6}>
        <input
          id="contained-button-file"
          accept="file_extension/*"
          style={{ display: 'none' }}
          name="anexo"
          single="true"
          type="file"
          onChange={onChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            fullWidth={true}
            startIcon={<AttachFileIcon />}
          > Anexar Arquivo </Button>
        </label>
      </Grid>

      <Grid item={true} xs={12} sm={6} hidden={comunicado.anexoURL === ''}>
        <Button 
          variant="contained"
          color="primary"
          component="span"
          fullWidth={true}
          startIcon={<VisibilityIcon />} 
          onClick={() => setOpen(!open) }
        > Visualizar Anexo </Button>

        {
          (comunicado.anexoURL !== "" && comunicado.anexoExt.includes("pdf") &&
              <GeneralDialog 
                open={open}
                setOpen={setOpen}
                dialogTitle={"Pré-Visualização"}
                dialogContent={<PdfViewer source={comunicado.anexoURL}  height={120}/>}
              />)
          ||
          (comunicado.anexoURL !== "" && comunicado.anexoExt.includes("image") &&
              <PhotoDialog open={open} setOpen={setOpen} fotos={new Array(comunicado.anexoURL)} />)
        }
      </Grid>
    </Grid>
  );
}