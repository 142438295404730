export default function validate (props) {
    const {atividade, classificacao} = props;
    const {disciplinaID, turmaID, topicoID, dataLiberacao, areaConhecimento, tipoAtividade, questoes} = atividade;
    let isRevision = tipoAtividade === "Avaliação Diagnóstica";
    let erros = {};

    // Validar disciplina
    if (disciplinaID === '' && !isRevision) {
        erros.disciplina = "É preciso escolher uma disciplina."
        erros.validated = false;
    }

    // Validar turma
    if (turmaID === '' && isRevision) {
        erros.turma = "É preciso escolher uma turma."
        erros.validated = false;
    }

    // Validar topico
    if (topicoID === '' && !isRevision) {
        erros.topico = "É preciso escolher um tópico."
        erros.validated = false;
    }

    // Validar areaConhecimento
    if (areaConhecimento === '' && isRevision) {
        erros.areaConhecimento = "É preciso escolher uma área do conhecimento."
        erros.validated = false;
    }

    // Validar dataLiberacao
    if (dataLiberacao === '' && isRevision) {
        erros.dataLiberacao = "É preciso escolher a data de liberação."
        erros.validated = false;
    }

    // Validar tipoAtividade
    if (tipoAtividade === '') {
        erros.tipoAtividade = "É preciso escolher um tipo de atividade."
        erros.validated = false;
    }

    // Validar questoes
    if (!questoes.length) {
        erros.questoes = "É preciso escolher as questões."
        erros.validated = false;
    } else if (tipoAtividade === "Fixação") {
        if(!classificacao) {
            erros.topico = "O tópico selecionado não possui classificação."
            erros.validated = false;
        } else if (classificacao.qtdQuestoes !== questoes.length) {
            erros.questoes = `A quantidade de questões deve respeitar ao preestabelecido pela classificação do tópico selecionado: ${classificacao.qtdQuestoes} questões.`
            erros.validated = false;
        }
    }

    if (erros.validated !== false) {
        erros.validated = true;
    }

    return erros;
}