import React, {useState, useEffect, useContext} from 'react'
import { MyContainer, GeneralTitle, GeneralText } from "../../assets/styles/styledComponents"
import api from '../../api'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { MuralCard, MuralForm, SimpleSnackMessage } from '../../components';
import { StoreContext } from '../../utils';
import moment from "moment";

// Styles
const useStyles = makeStyles((theme) => ({
  alignVertically: {
    margin: "auto"
  },
  cards: {
    padding: "1rem"
  },
  fab: {
    position: 'relative',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  }
}));

// Main Function
export default function AdminMural() {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const today = `${moment(new Date()).format("YYYY-MM-DD")}T03:00:00.000Z`;
  const [comunicadoSelecionado, setComunicadoSelecionado] = useState();
  const [listaComunicado, setListaComunicado] = useState([]);
  const [listaTurma, setListaTurma] = useState([]);
  const [open, setOpen] = useState(false);
  const [mount, setMount] = useState({
    isMounting: true,
    wasChanged: false
  });
  const [message, setMessage] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [noError, setNoError] = useState(true);

  // -- Função de edição
  const handleEdit = (id) => setComunicadoSelecionado(id);

  // -- Função de remoção
  const handleRemove = (id) => {
    api.removerComunicado(id);
    setMessage("Comunicado removido com sucesso.");
    setNoError(true);
    setSnackOpen(true);
    setMount(x => ({...x, wasChanged: true}));
  }

  useEffect(() => {
    async function fetchTurmaAPI() {
      await api.listarTurma()
        .then(res => setListaTurma(res.data.data))
        .catch(err => console.log(err));
    }
    fetchTurmaAPI();
  }, [])

  // Carrega comunicados
  useEffect(() => {
    if (mount.isMounting || mount.wasChanged) {
      async function fetchComunicadoAPI() {
        try {
          var arrayAux = [];

          // Usuário Professor
          if (token.accessType === "Professor") {
            token.turmaID.forEach(async x => {
              await api.listarComunicado(x.turmaID, today)
                .then(res => {
                  var value = res.data.data;
                  if (arrayAux.length) {
                    arrayAux = arrayAux.concat(value);
                  } else {
                    arrayAux = value;
                  }
                })
                .catch(err => console.log(err));
              setListaComunicado(arrayAux);
            });
          }
          
          // Usuário Administrador
          else {
            await api.listarComunicado(0, today)
              .then(res => arrayAux = res.data.data)
              .catch(err => console.log(err));
          }
          setListaComunicado(arrayAux);
        } catch (err) {
          console.log(err)
          setListaComunicado([]);
        }
      }
      fetchComunicadoAPI();
    }
  }, [mount, today, token])

  function ListaMurais() {
    // Havendo murais
    if (listaComunicado.length) {
      return listaComunicado.map(x => {
        // Caso haja algum mural em modo edição
        if (x?._id === comunicadoSelecionado && comunicadoSelecionado !== undefined) {
          return (
            <Grid key={x._id} item xs={12} sm={6}>
              <MuralForm key={x._id} 
                id={comunicadoSelecionado} 
                setOpen={setOpen} 
                setMount={setMount} 
                setComunicadoSelecionado={setComunicadoSelecionado} 
                setNoError={setNoError}
                setMessage={setMessage}
                setSnackOpen={setSnackOpen}/>
            </Grid>
          );
        } 
        else {
          var turma = listaTurma.find(t => t._id === x.turmaID);
          return (
            <Grid key={x._id} item xs={12} sm={6}>
              <MuralCard 
                key={x._id} 
                model={x} 
                turma={turma} 
                student={false} 
                handleEdit={handleEdit} 
                handleRemove={handleRemove} />
            </Grid>
          )
        }
      })
    } 
    // Caso não hajam murais
    else {
      return (
        <Grid item hidden={open} xs={12}>
          <GeneralText>Ainda nenhum comunicado criado.</GeneralText>
        </Grid>
      );
    }
  }

  return (
    <MyContainer>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={12} sm={10}>
          <GeneralTitle className={classes.alignVertically}>Meus Comunicados</GeneralTitle>
        </Grid>

        <Grid item xs={12} sm={2} className={classes.alignVertically}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpen(true)}
          >
            Novo Comunicado
          </Button>
        </Grid>

        {
          open && 
          <Grid item xs={12} sm={6}>
            <MuralForm setOpen={setOpen} 
              setMount={setMount} 
              setComunicadoSelecionado={setComunicadoSelecionado} 
              setNoError={setNoError}
              setMessage={setMessage}
              setSnackOpen={setSnackOpen}/>
          </Grid>
        }
      
        { ListaMurais() }

        <SimpleSnackMessage 
          open={snackOpen}
          setOpen={setSnackOpen}
          message={message}
          error={noError}/>

      </Grid>
    </MyContainer>
  );
};