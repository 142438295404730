import React, { useContext, useState } from "react";
import api from '../../api';
import { StoreContext } from "../../utils";
import validate from "../../components/Form/Validation/FormValidateContent";
import Axios from "axios";
import { SimpleSnackMessage, ContentForm } from "../../components";

const initialState = {
  disciplinaID: "", 
  turmaID: "", 
  topico: "",
  classificacao: "",
  numeracao: '',
  autor: "",
  videoAulaURL: '',
  videoAulaExtraURL: '',
  conteudo: {
    file: false,
    url: ''
  },
  conteudoURL: '',
  erros: []
}

export default function ContentInsert(props) {
  const {token} = useContext(StoreContext);
  const [material, setMaterial] = useState(initialState);
  const [conteudo, setConteudo] = useState(initialState.conteudo);
  const [message, setMessage] = useState("Houve um erro com a inserção do conteúdo, verifique se todas as informações estão corretas. Se o erro persistir, informe à equipe técnica.");
  const [noError, setNoError] = useState(true);
  const [open, setOpen] = useState(false);

  const onSubmit = async event => {
    const { disciplinaID, topico, classificacao, numeracao, videoAulaURL, videoAulaExtraURL, conteudoURL } = material;
    const error = validate(material);

    setMaterial(preValue => ({
      ...preValue,
      erros: error
    }))

    if (error.validated) {
      var novoConteudo = {
        autor: token.userID,
        disciplinaID,
        topico, 
        classificacao,
        numeracao,
        videoAulaURL,
        videoAulaExtraURL,
        conteudoURL,
        autorModificacao: token.userID,
      };

      // Guarda novo conteúdo no banco
      await api
        .inserirConteudo(novoConteudo)
        .then(async res => {
          setMessage("Conteúdo inserido com sucesso!");

          // Verifica se o usuário subiu algum conteúdo pdf
          if (conteudo.file) {
            
            // Salva o pdf na núvem
            const formData = new FormData();
            formData.append("conteudo", conteudo.file);
            const { id } = res.data;

            
            await Axios.post(`${process.env.REACT_APP_API_URL}upload-conteudo/${id}`, formData)
              .then(async res => {
                novoConteudo.conteudoURL = res.data.url;
                await api
                  .atualizarConteudo(id, novoConteudo)
                  .then(res => {console.log("Salvou arquivo!")})
                  .catch(err => {
                    setNoError(false);
                    setMessage("Houve um erro com a inserção do conteúdo, verifique se todas as informações estão corretas. Se o erro persistir, informe à equipe técnica.");
                  });
              })
              .catch(err => {
                console.log(err);
              });
          }            
          setOpen(true);

          // Limpa os campos
          setMaterial(initialState)
          setConteudo("");
        })
    }
  }

  return (
    <div>
      <ContentForm 
        material={material}
        setMaterial={setMaterial}
        conteudo={conteudo}
        setConteudo={setConteudo}
        onSubmit={onSubmit}
        titulo="Criar Conteúdo"
      />

      <SimpleSnackMessage 
        open={open}
        setOpen={setOpen}
        message={message}
        error={noError}
      />
    </div>
  );
};