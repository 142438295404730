import React, { useState } from "react";
import api from '../../api'

import { makeStyles, Button, Grid } from "@material-ui/core";
import { PhotoDialog, DiscreteSlider, CustomButton } from '..';
import IconButton from '@material-ui/core/IconButton';
import AddCommentIcon from '@material-ui/icons/AddComment';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { MyTextField } from "../../assets/styles/styledComponents";

// -- Estilos locais
const useStyles = makeStyles((theme) => ({
  
  comment: {
    marginTop: '1rem',
    marginBottom: '2rem',
    width: '100%'
  },
  
  commentChanged: {
    marginTop: '1rem',
    marginBottom: '2rem',
    width: '90%'
  },

  commentIcon: {
    marginTop: '1rem'
  },

  discursiveAnswer: {
    width: '100%'
  },
}));

export default function DiscursiveAnswer(props) {
  const classes = useStyles();
  const { defaultValue, resposta, id, comentario, fotos, index, value, 
      progresso, setProgresso, setRespostaAtividade, respostaAtividade } = props;

  const [comment, setComment] = useState(comentario);
  const [open, setOpen] = useState(false);
  const [nota, setNota] = useState(defaultValue);
  const [flag, setFlag] = useState(false);

  async function adicionandoComentario() {
    const response = await api.encRespostaQuestaoPorID(id);
    let novaResposta = response.data.data;
    novaResposta.comentario = comment;
    await api.atualizarRespostaQuestao(id, novaResposta);
    setFlag(false);
  }

  function handleChange(event) {
    const { value } = event.target;
    setComment(value);
    setFlag(true);
  }

  async function corrigir(event) {
    event.preventDefault();

    if (nota !== defaultValue) {
        const response = await api.encRespostaQuestaoPorID(id);
        let novaResposta = response.data.data;
        novaResposta.nota = nota;
        
        let novaRespostaAtividade = respostaAtividade;
        novaRespostaAtividade[value].respostaQuestaoIDs[index].nota = nota;
        
        if (respostaAtividade[value].respostaQuestaoIDs[index].corrigido === false) { 
            novaResposta.corrigido = true;
            novaRespostaAtividade[value].respostaQuestaoIDs[index].corrigido = true;
            setProgresso(progresso+1)
        }
    
        setRespostaAtividade(novaRespostaAtividade);
    
        await api.atualizarRespostaQuestao(id, novaResposta);
    }      
  }

  return (
    <Grid container> 
      <Grid item={true} align="center" xs={fotos.length > 0 ? 10 : 12} lg={fotos.length > 0 ? 10 : 12} sm={fotos.length > 0 ? 10 : 12}>
        <MyTextField 
          className={classes.discursiveAnswer}
          disabled={true}
          multiline
          value={resposta}
        />
      </Grid>

        {
          fotos.length > 0 ? 
            <Grid item={true} align="center" xs={2} lg={2} sm={2}>
              <Button onClick={() => setOpen(true)} color="primary" variant="contained">Fotos</Button>
              <PhotoDialog fotos={fotos} open={open} setOpen={setOpen}/>
            </Grid>
          : null
        }

      <Grid item align='left' xs={flag ? 11 : 12} lg={flag ? 11 : 12} sm={flag ? 11 : 12}>
        <MyTextField 
          className={flag ? classes.commentChanged : classes.comment}
          multiline
          label='Comentário'
          onChange={handleChange}
          value={comment}
        />
      </Grid>
      {
        flag ? 
          <Grid item align='left' xs={1} lg={1} sm={1}>
            {
              comentario ?
              <IconButton size='medium' className={classes.commentIcon} color='primary' onClick={adicionandoComentario}>
                <RateReviewIcon />
              </IconButton>
              :
              <IconButton size='medium' className={classes.commentIcon} color='primary' onClick={adicionandoComentario}>
                <AddCommentIcon />
              </IconButton>
            }
          </Grid>
        : null
      }
        
      <Grid item align='center' xs={12} lg={12} sm={12}>
        <DiscreteSlider 
          value={nota} 
          setValue={setNota}
        />
      </Grid>

      <CustomButton
        hidden={setProgresso === undefined}
        check={respostaAtividade[value].respostaQuestaoIDs[index]?.corrigido}
        id="correctionButton" 
        icon={null} 
        title={"Corrigir"} 
        handleClick={corrigir}
      />
    
    </Grid>  
  )
}