const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: '0',
        padding: '0',
        marginLeft: '1rem',
        [theme.breakpoints.down('sm')]: {
          marginLeft: '0.5rem',
        }
    },
    colorAction: {
      marginTop: '1.5rem',
      maginLeft: '1rem',
      color: '#fdc504',
    },
    slider: {
      [theme.breakpoints.down('sm')]: {
        width: "85%",
      }
    },
    sliderGrid: {
      textAlign: 'center',
    },
    grade: {
      textAlign: 'center',
      marginTop: '1.5rem'
    },
    container: {
      justifyContent: 'center',
      alignSelf: 'center',
      alignContent: 'center',
      alignItems: 'center'
    }
}));
