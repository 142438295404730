import React from "react";
import { withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    // '&$expanded': {
    //   margin: 'auto',
    // },
  },
}));

const Accordion = (MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function GeneralAccordion({ accordionDetails, accordionSummary, noClass, customClass, onClick, ...props }) {
  const defaultClass = useStyles();
  const accordionConfig = {
    ...props,
    className: !noClass ? defaultClass.root : customClass?.root
  }
  return (
    <Accordion {...accordionConfig}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={onClick ? onClick : null} aria-controls="panel1a-content">
        { accordionSummary }
      </AccordionSummary>
      <AccordionDetails>
        { accordionDetails }
      </AccordionDetails>
  </Accordion>
  );
}