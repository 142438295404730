import React, { useEffect, useState, useContext, memo } from 'react';
import { StoreContext, inicialEixos } from "../../../utils";
import api from '../../../api';
import { AccordionDetails, AccordionSummary, Grid, Slide, Typography, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import { useStyles } from './styles';
import { CircularStatic  } from '../..'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReturnTopics from '../Utils/ReturnTopics';
import ReturnADs from '../Utils/ReturnADs';
import ReturnEssays from '../Utils/ReturnEssays';
import FeatureDialog from '../../Dialogs/FeatureDialog';

const initialActivity = {
    retomada: [],
    fixacao: [],
    aprofundamento: []
}

function ContentAccordion(props) {
    const { topicoID, disciplinaNome, titulo, semana, linkAula, linkAulaOpcional, tipoAcordeao, essay, revision, materialURL } = props;
    const { token } = useContext(StoreContext)
    const alunoID = token.userID;
    const classes = useStyles();
    const [progresso, setProgresso] = useState(0);                              // Contagem do Progresso
    const [activity, setActivity] = useState(initialActivity);
    const [open, setOpen] = useState({
        materialEstudo: false,
        videoaula: false,
        exercicioFixacao: false,
        exercicioRetomada: false,
        exercicioAprofundamento: false,
    });
    const [check, setCheck] = useState({
        materialEstudo: materialURL !== "" ? false : undefined,
        videoaula: linkAula !== "" ? false : undefined,
        videoaula2: linkAulaOpcional !== "" ? false : undefined 
    });
    const [gridSize, setGridSize] = useState({
        exe: 0,
        cont: 12
    });
    const [topicProgress, setTopicProgress] = useState({
        topicoID: topicoID,
        alunoID: alunoID,
        progresso: {}
    });
    const [numTasks, setNumTasks] = useState(0);                                // Número de tarefas do tópico
    const [wasChecked, setWasChecked] = useState(false);                        // Flag de salvamento do Progresso                      
    const [wasLoaded, setWasLoaded] = useState(false);
    const [redacaoURL, setRedacaoURL] = useState("");
    const [progressLoad, setProgressLoad] = useState(false)
    const [feedMsg, setFeedMsg] = useState({title: '', message: ''});
    const [feedOpen, setFeedOpen] = useState(false);
    const [featureOpen, setFeatureOpen] = useState(false);

    // Acordeão personalizado
    const AccordionPersonalized = withStyles({
        root: { 
            borderLeft: `0.7rem solid ${!props.color ? '#fdc504' : props.color}`,
            width: '100%',
            boxShadow: '1px 3px 3px 1px #bbb',
            transition: '0.3s',
            '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 10px 10px 0 #bbb',
            },
        }
    })(MuiAccordion);

    //#region UseEffects
    //==================================
    // -- Carregamento inicial dos dados
    useEffect(() => {
        const ctrlAbort = new AbortController();
        //#region Carregamento dos Progressos
        //==================================
        // -- Carrega progresso do TÓPICO
        async function fetchProgressoTopicoAPI() {
            try {
                const response = await api.encProgressoPorTopico(alunoID, topicoID);
                const value = response.data.data;
                setTopicProgress(value);
                if (value.progresso) {
                    let auxProgress = value.progresso;
                    let count = 0;
                    setCheck(auxProgress);
                    auxProgress.videoaula && count++;
                    auxProgress.materialEstudo && count++;
                    auxProgress.exercicioAprofundamento && count++;
                    auxProgress.exercicioFixacao && count++;
                    auxProgress.exercicioRetomada && count++;
                    setProgresso(count);
                }
            } catch (error) {
            }
        }

        // -- Carrega progresso da REDAÇÃO
        async function fetchProgressoRedacaoAPI() {
            try {
                const response = await api.encProgressoPorRedacaoID(alunoID, essay._id);
                const value = response.data.data;
                setTopicProgress(value);
                setCheck({ 
                    redacao: value.progresso,
                    materialEstudo: value.progresso,
                });
                setProgresso(value.progresso ? 1 : 0);
                setNumTasks(1);
            } catch (error) {
            }
        }

        // -- Carrega progresso da REVISAO
        async function fetchProgressoRevisaoAPI() {
            try {
                const response = await api.encProgressoPorRevisaoID(alunoID, revision._id);
                setTopicProgress(response.data.data);
                setCheck({ avaliacaoDiagnostica: response.data.data.progresso });
                setProgresso(response.data.data.progresso ? 1 : 0);
            } catch (error) {
            }
        }
        //#endregion

        //#region Carregamento das Atividades
        //==================================
        // -- Carrega as atividades do banco
        async function fetchAtividadeAPI() {
            setActivity(initialActivity);

            if (linkAula !== "" && materialURL !== "") setGridSize({exe: 0, cont: 6});
            else setGridSize({exe: 0, cont: 12});

            try {
                // Conta número de tarefas iniciando com videoaula e material de estudo
                let baseTasks = 0;
                if (linkAula !== "") baseTasks += 1;
                if (materialURL !== "") baseTasks += 1;
                setNumTasks(baseTasks);
                
                // Carrega as atividades do tópico
                const response = await api.listarAtividadesPorTopico(topicoID);
                var value = response.data.data;
                var count = 0;
    
                // Salva as respectivas atividades em seus campos
                value.forEach(item => {
                    if (item.tipoAtividade === 'Retomada') {
                        count++;
                        return setActivity(x => ({...x, retomada: item}))
                    } else if (item.tipoAtividade === 'Fixação') {
                        count++;
                        return setActivity(x => ({...x, fixacao: item}))
                    } else if (item.tipoAtividade === 'Aprofundamento') {
                        count++;
                        return setActivity(x => ({...x, aprofundamento: item}))
                    }
                })
                
                // Número de botões no acordeão
                setNumTasks(count + baseTasks);

                // Grid com divisão dinâmica
                if (count && linkAula !== "" && token.plano !== "Free") {
                    count === 1 && setGridSize({ exe: 4, cont: 4 });
                    count === 2 && setGridSize({ exe: 3, cont: 3 });
                    count === 3 && setGridSize({ exe: 2, cont: 3 });
                } else if ((count && linkAula === "") ||( token.plano === "Free")) {
                    count === 1 && setGridSize({ exe: 6, cont: 6 });
                    count === 2 && setGridSize({ exe: 4, cont: 4 });
                    count === 3 && setGridSize({ exe: 3, cont: 3 });
                }
            } catch (error) {
            }
        }
        //#endregion

        if (topicoID && !progressLoad) {
            fetchAtividadeAPI();
            fetchProgressoTopicoAPI();
            setProgressLoad(true);
        }

        else if (essay && !progressLoad) {
            setCheck({ redacao: false });
            setOpen({ 
                redacao: false, 
                materialEstudo: false
            });
            fetchProgressoRedacaoAPI();
            setProgressLoad(true);
        }

        else if (revision && !progressLoad) {
            setCheck({ avaliacaoDiagnostica: false });
            setOpen({ avaliacaoDiagnostica: false });
            fetchProgressoRevisaoAPI();
            setProgressLoad(true);
        }
        return ctrlAbort.abort();
    // eslint-disable-next-line
    }, [essay, revision, topicoID])

    // -- Salva o progresso após cada alteração em Check
    useEffect(() => {
        const ctrlAbort = new AbortController();
        
        //#region Funções de Salvamentos
        //==================================
        // Salvamento do progresso do TÓPICO
        async function saveTopicProgress() {

            const novoProgresso = {
                alunoID: topicProgress.alunoID,
                topicoID: topicProgress.topicoID,
                progresso: check
            }

            if (!topicProgress._id) {
                await api
                    .inserirProgresso(novoProgresso)
                    .then(res => {
                        setTopicProgress(preValue => ({
                            ...preValue,
                            _id: res.data.id
                        }))
                    })
            } else {
                await api.atualizarProgresso(topicProgress._id, novoProgresso)
            }
        }

        // Salvamento do progresso da REDAÇÃO
        async function saveEssayProgress() {
            const novoProgresso = {
                alunoID: topicProgress.alunoID,
                redacaoID: essay._id,
                progresso: check.redacao,
                corrigido: false,
                redacaoURL,
                eixo1: inicialEixos.eixo1,
                eixo2: inicialEixos.eixo2,
                eixo3: inicialEixos.eixo3,
                competencia: inicialEixos.competencia,
            }
            
            if (!topicProgress._id) {
                await api
                    .inserirProgressoRedacao(novoProgresso)
                    .then(res => {
                        setTopicProgress(preValue => ({
                            ...preValue,
                            _id: res.data.id
                        }))
                    });
            } else {
                await api.atualizarProgressoRedacao(topicProgress._id, novoProgresso)
            }
        }

        // Salvamento do progresso da REVISÃO
        async function saveRevisionProgress() {
            const novoProgresso = {
                alunoID: topicProgress.alunoID,
                revisaoID: revision._id,
                progresso: check.avaliacaoDiagnostica,
            }

            if (!topicProgress._id) {
                await api
                    .inserirProgressoRevisao(novoProgresso)
                    .then(res => {
                        setTopicProgress(preValue => ({
                            ...preValue,
                            _id: res.data.id
                        }))
                    })
            } else {
                await api.atualizarProgressoRevisao(topicProgress._id, novoProgresso)
            }
        }
        //#endregion
        
        if (wasChecked) {
            setTopicProgress(x => ({...x, progresso: check}));
            topicoID && saveTopicProgress();
            revision && saveRevisionProgress();
            essay && saveEssayProgress();
            setWasChecked(false);
        } else {
            let fixTam = activity.fixacao.length !== 0;
            let retTam = activity.retomada.length !== 0;
            let aprTam = activity.aprofundamento.length !== 0;

            if (fixTam && check.exercicioFixacao === undefined) {
                setCheck(preValue => ({
                    ...preValue,
                    exercicioFixacao: topicProgress.progresso.exercicioFixacao
                }))
            }
            
            if (retTam && check.exercicioRetomada === undefined) {
                setCheck(preValue => ({
                    ...preValue,
                    exercicioRetomada: topicProgress.progresso.exercicioRetomada
                }))
            }
            
            if (aprTam && check.exercicioAprofundamento === undefined) {
                setCheck(preValue => ({
                    ...preValue,
                    exercicioAprofundamento: topicProgress.progresso.exercicioAprofundamento 
                }))
            }
        }
        return ctrlAbort.abort();
    // eslint-disable-next-line
    }, [wasChecked])

    //#endregion

    return (
        <div style={{display: ((!essay && !revision && numTasks === 0) || (revision && token.plano === "Free")) ? "none" : "block"}}>
            <Slide direction="up" in={wasLoaded}>
                <AccordionPersonalized square>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${topicoID}`}
                        id="cabecalhoAccordionLibrary">
                        <CircularStatic progresso={progresso} numTasks={numTasks} wasLoaded={wasLoaded} setWasLoaded={setWasLoaded} />
                        <Typography id="heading" className={classes.heading}>{ tipoAcordeao === 'biblioteca' ? titulo : disciplinaNome }</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container={true} className={classes.accordionDetails} spacing={2}>
                            {
                                (revision)
                                    ? <ReturnADs 
                                        check={check}
                                        setCheck={setCheck}
                                        progresso={progresso}
                                        setProgresso={setProgresso}
                                        setWasChecked={setWasChecked}
                                        revision={revision}
                                        classes={classes}
                                        open={open}
                                        setOpen={setOpen}
                                        topicProgress={topicProgress}
                                        plano={token.plano}
                                        setFeatureOpen={setFeatureOpen} />
                                    : (essay) 
                                        ? <ReturnEssays 
                                            check={check}
                                            setCheck={setCheck} 
                                            progresso={progresso}
                                            setProgresso={setProgresso} 
                                            setWasChecked={setWasChecked}
                                            feedMsg={feedMsg}
                                            setFeedMsg={setFeedMsg}
                                            feedOpen={feedOpen}
                                            setFeedOpen={setFeedOpen}
                                            essay={essay}
                                            alunoID={alunoID}
                                            titulo={titulo} 
                                            classes={classes}
                                            open={open} 
                                            setOpen={setOpen}
                                            topicProgress={topicProgress}
                                            materialURL={materialURL}
                                            redacaoURL={redacaoURL}
                                            setRedacaoURL={setRedacaoURL}
                                            plano={token.plano}
                                            setFeatureOpen={setFeatureOpen} /> 
                                        : <ReturnTopics 
                                            check={check}
                                            setCheck={setCheck}
                                            progresso={progresso}
                                            setProgresso={setProgresso}
                                            setWasChecked={setWasChecked}
                                            linkAula={linkAula}
                                            linkAulaOpcional={linkAulaOpcional}
                                            tipoAcordeao={tipoAcordeao}
                                            titulo={titulo}
                                            classes={classes}
                                            disciplinaNome={disciplinaNome}
                                            semana={semana}
                                            gridSize={gridSize}
                                            topicoID={topicoID}
                                            open={open}
                                            setOpen={setOpen}
                                            materialURL={materialURL}
                                            activity={activity}
                                            topicProgress={topicProgress}
                                            plano={token.plano}
                                            setFeatureOpen={setFeatureOpen}/>
                            }
                            <FeatureDialog open={featureOpen} setOpen={setFeatureOpen} />
                        </Grid>
                    </AccordionDetails>
                </AccordionPersonalized>
            </Slide>
        </div>
    );
}

export default memo(ContentAccordion);