import React, { memo, useEffect, useState } from "react";
import { Card, CardContent, Fab, Grid, makeStyles } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const useStyles = makeStyles(() => ({
  card: {
    margin: "0 0.5rem",
    width: "93vw",
    position: "relative",
    marginTop: "1rem",
    borderLeft: "3px solid #a283bc"
  }
}));

function DndCard(props) {
  const {setData, selected, setSelected, removeThisQuestion} = props;
  const [value, setValue] = useState([]);
  const classes = useStyles();

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(value);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelected(items);
    setData(preValue => ({
      ...preValue,
      questoes: items.map(i => i.id)
    }));
  }

  // Confirma alterações no array de questões selecionadas.
  useEffect(() => {
    setValue(selected);
  }, [selected]);

  return (
    <Grid container spacing={2} justifyContent="center">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="data">
          {(provided) => (
            <ul style={{ listStyleType: "none", padding: 0 }}
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {
                (selected.length && selected !== undefined) && 
                selected.map((item, index) => {
                    return (
                      <Draggable key={item?.id} draggableId={item?.id} index={index}>
                        {(provided) => (
                          <li ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                              <Grid item xs={12}>
                                <Card square elevation={2} className={classes.card}>
                                  <CardContent>
                                    <Grid container column="true">
                                      <Grid item xs={12}>
                                        <b style={{color: "#606161"}}>Questão {index+1}</b>
                                      </Grid>
                                      <Grid item xs={11}>
                                        <p>{ item?.enunciado?.replace(/<(.|\n)*?>/g, '').replace("&nbsp;", " ").substring(0,250) + "..." }</p>
                                      </Grid>
                                      <Grid item xs={1}>
                                        <Fab onClick={() => removeThisQuestion(item?.id)} size="small" color="secondary" aria-label="add">
                                          <ClearRoundedIcon />
                                        </Fab>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                              
                          </li>
                        )}
                      </Draggable>
                    );
                  })
                }
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
}

export default memo(DndCard);