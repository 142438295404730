import React, { useState, useEffect, useContext } from "react";
import { MyTextField } from "../../../../assets/styles/styledComponents";
import { ClassTable, SimpleSnackMessage } from "../../../../components";
import { Grid, Button, Accordion, AccordionSummary, AccordionDetails, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./styles";
import api from "../../../../api";
import { StoreContext } from "../../../../utils";

export default function ManageClass(props) {
  const { mount, setMount } = props;
  const { token } = useContext(StoreContext);
  const initialTurmaState = {
    nome: "",
    sigla: "",
    status: "",
    dataCriacao: new Date(),
    dataModificacao: new Date(),
    autor: token.userID,
    autorModificacao: token.userID,
  };
  const classes = useStyles();
  const [turma, setTurma] = useState(initialTurmaState);
  const [listaTurma, setListaTurma] = useState([]);
  const [turmaID, setTurmaID] = useState(null);
  const [noError, setNoError] = useState(true);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  // Lista Turmas em tela
  async function fetchTurmaAPI() {
    const response = await api.listarTurma();
    setListaTurma(response.data.data);
  }

  // Caso haja mudança em turma
  useEffect(() => {
    const abortController = new AbortController();
    if (mount.turma) {
      fetchTurmaAPI();
      setMount((preValue) => ({ ...preValue, turma: false }));
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [mount]);

  // Carregamento Inicial
  const initialLoad = () => {
    if (mount.isMountedTurma) {
      fetchTurmaAPI();
      setMount((preValue) => ({ ...preValue, isMountedTurma: false }));
    }
  };

  // Função para pegar os valores do formulário - TAG
  const handleChange = (event) => {
    const { name, value } = event.target;
    setTurma((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  // Salva Inserção
  async function handleSave() {
    await api.inserirTurma(turma)
      .then((res) => {
        setTurma(initialTurmaState);
        setMount((preValue) => ({ ...preValue, turma: true }));
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao inserir a turma.");
      });
    setOpen(true);
  }

  // Salva Atualização
  async function handleUpload() {
    var novaTurma = turma;
    novaTurma.autorModificacao = token.userID;
    novaTurma.dataModificacao = new Date();
    await api.atualizarTurma(turmaID, novaTurma)
      .then(res => {
        setTurma(initialTurmaState);
        setTurmaID(null);
        setMount((preValue) => ({ ...preValue, turma: true }));
        setNoError(true);
        setMessage(res.data.message);
      })
      .catch(err => {
        setNoError(false);
        setMessage("Houve um erro ao atualizar a turma.");
        console.log(err);
      });
    setOpen(true);
  }

  return (
    <>
      <Accordion
        square
        elevation={2}
        style={{ borderBottom: `0.2rem solid #0f0d59` }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => initialLoad()}
          aria-controls="panel1a-content"
          id="panel2-settings"
        >
          <h2 className="heading-page">Gerenciar Turmas</h2>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container={true} className={classes.root} spacing={2}>
            <Grid item={true} xs={12} sm={6}>
              <MyTextField
                variant="outlined"
                label="Nome"
                name="nome"
                type="text"
                value={turma.nome}
                onChange={handleChange}
              />

              <MyTextField
                variant="outlined"
                label="Sigla"
                name="sigla"
                type="text"
                value={turma.sigla}
                onChange={handleChange}
              />

            <MyTextField
              variant="outlined"
              select={true}
              label="Status"
              name="status"
              onChange={handleChange}
              value={turma.status}
            >
              <MenuItem value={true}>Ativa</MenuItem>
              <MenuItem value={false}>Inativa</MenuItem>
            </MyTextField>

              <div className={classes.group}>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  type="submit"
                  color="secondary"
                  onClick={() => setTurma(initialTurmaState)}
                >
                  Limpar
                </Button>
                <Button
                  className={classes.buttons}
                  variant="outlined"
                  type="submit"
                  color="primary"
                  onClick={turmaID ? handleUpload : handleSave}
                >
                  Salvar
                </Button>
              </div>
            </Grid>

            <Grid item={true} xs={12} sm={6}>
              <ClassTable
                setTurma={setTurma}
                setTurmaID={setTurmaID}
                data={listaTurma}
                setMount={setMount}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      
      <SimpleSnackMessage 
        open={open}
        setOpen={setOpen}
        message={message}
        error={noError}/>
    </>
  );
}
