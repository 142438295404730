import React, { useState, useEffect, useContext } from 'react';
import { Button,  Dialog , Grid, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import { MyTextField } from "../../../assets/styles/styledComponents";
import api from '../../../api';
import { areaConhecimento, StoreContext } from '../../../utils';

const initialFilter = {
  turmaID: "",
  area: "",
  disciplinaID: "",
  numeracao: ""
}

export default function FormDialog(props) {
  const { filter, setFilter, open, setOpen, setIsCleaned } = props;
  const { token } = useContext(StoreContext);
  const [listaDisciplina, setListaDisciplina] = useState([]);
  const [semanasAno, setSemanasAno] = useState([]);
  const [tempFilter, setTempFilter] = useState(initialFilter);
  const [listaTurmas, setListaTurma] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [mount, setMount] = useState(true);
  const [sigla, setSigla] = useState("");

  // -- Carrega as Disciplinas existentes no banco
  useEffect(() => {
    const abortController = new AbortController();
    
    // Verifica se filtro já está preenchido
    if (filter !== initialFilter) {
      setTempFilter(filter);
    } else if (tempFilter !== initialFilter) {
      setTempFilter(initialFilter);
    }

    // Montagem inicial
    if (mount) {
      async function fetchTurmaAPI() {
        const response = await api.listarTurma();
        setListaTurma(response.data.data);
      }
  
      async function fetchDisciplinaAPI() {
        const response = await api.listarDisciplinas();
        var value = response.data.data;
        if (token.accessType !== "Administrador" && token.disciplina.length) {
          value = response.data.data.filter(item => {
            return token.disciplina.includes(item._id);
          });
        }
        setListaDisciplina(value);
      }
      
      // Faz o fetch e desmonta
      fetchTurmaAPI();
      fetchDisciplinaAPI();
      setMount(false);
    }

    return abortController.abort();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    const abortController = new AbortController();
      if (tempFilter?.turmaID !== "") {
        setDisciplinas(listaDisciplina.filter(x => {return x.turmaID === tempFilter.turmaID}));
        // Preenche constante de numeração de acordo com o número de semanas do ano letivo
        async function fetchNumeroSemanas() {
          try {
            const response = await api.encAnoLetivoPorTurma(tempFilter.turmaID);
            const numSemanas = response.data.data.numSemanas;
            var array = [];
            for (let i = 1; i <= numSemanas; ++i) { array.push(i) }
            setSemanasAno(array);
          } catch (error) { }
        }
        fetchNumeroSemanas();
      }
    return abortController.abort();
    // eslint-disable-next-line
  }, [tempFilter.turmaID])

  // Salva modificações do filtro temporariamente
  const onFilterChange = (event) => {
    const { name, value } = event.target;
    setTempFilter(preValue => ({
      ...preValue,
      [name]: value
    }))
    setIsCleaned(false);
    if (name === "turmaID") {
      setSigla(listaTurmas.filter(x => {return (x._id === value)})[0].sigla)
    }
  }

  // Salva modificações no filtro permanentemente
  const onSubmit = () => {
    setFilter(tempFilter);
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Filtro</DialogTitle>

      <DialogContent>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <MyTextField
              variant="outlined"
              disabled={false}
              select={true}
              label="Área do Conhecimento"
              name="area"
              value={tempFilter.area ? tempFilter.area : ""}
              onChange={onFilterChange}>
                {
                  areaConhecimento.map((item, index) => {
                    return <MenuItem key={index} value={item.value}>{item.value}</MenuItem>
                  })
                }
            </MyTextField>
          </Grid>

          <Grid item={true} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Turma"
              name="turmaID"
              value={tempFilter.turmaID ? tempFilter.turmaID : ""}
              onChange={onFilterChange}>
              {
                listaTurmas.map((item, index) => {
                  return <MenuItem key={index} value={item._id}>{item.nome}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>

          <Grid item={true} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Disciplina"
              name="disciplinaID"
              disabled={disciplinas.length === 0}
              value={tempFilter.disciplinaID ? tempFilter.disciplinaID : ""}
              onChange={onFilterChange}>
              {
                disciplinas.map((item, index) => {
                  return <MenuItem key={index} value={item._id}>{sigla}-{item.nome}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>

          <Grid item={true} xs={12}>
          <MyTextField
              select={true}
              label="Numeração"
              variant="outlined"
              name="numeracao"
              disabled={tempFilter.turmaID === ""}
              value={tempFilter.numeracao ? tempFilter.numeracao : ""}
              onChange={onFilterChange}>
                {
                  semanasAno.map((row, index) => {
                    return <MenuItem key={index} value={row}>{row}</MenuItem>
                  })
                }
            </MyTextField>
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Voltar
        </Button>
        <Button onClick={onSubmit} color="primary">
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}