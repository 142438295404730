import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, Dialog, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { GeneralSubtitle } from '../../assets/styles/styledComponents';
import { eixo1, eixo2, eixo3, competencia } from '../../utils';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: "18.75rem",
    maxWidth: "31.25rem",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyle = makeStyles((theme) => ({
  items: {
    '&:hover': {
      background: "#ddd",
    },
    padding: "0.5rem"
  },
  divider: {
    marginTop: "0.5rem"
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, fullScreen, setFullScreen, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography variant="h6">{children}</Typography>
      {!fullScreen ? (
        <IconButton aria-label="fullScreen" className={classes.closeButton} onClick={() => setFullScreen(true)}>
          <FullscreenIcon />
        </IconButton>
      ) : 
        <IconButton aria-label="fullScreenExit" className={classes.closeButton} onClick={() => setFullScreen(false)}>
          <FullscreenExitIcon />
        </IconButton>
      }
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: "18.75rem"
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    alignContent: "flex-end",
    minWidth: "18.75rem"
  },
}))(MuiDialogActions);

const ListaEixos = (props) => {
  const {eixo, index, tam, value} = props;
  const classes = useStyle();

  return (
    <Grid container className={classes.items}>
      <Grid item xs={10}>
        <Typography>{eixo.item}</Typography>
      </Grid>
      <Grid item style={{textAlign: "right", paddingRight: "0.5rem"}} xs={2}>
        <Typography>{value}</Typography>
      </Grid>
      {
        (index !== tam) && <Divider className={classes.divider} width="100%" />
      }
    </Grid>
  )
}

export default function EssayResultDialog(props) {
  const {data, open, setOpen} = props;
  const [isFullScreen, setFullScreen] = useState(false); 

  return (
    <Dialog onClose={() => setOpen(false)} 
      disableEnforceFocus
      disableAutoFocus
      fullScreen={isFullScreen} 
      fullWidth={true} 
      maxWidth='sm'
      open={open}>

      <DialogTitle fullScreen={isFullScreen} setFullScreen={setFullScreen}>
        Redação
      </DialogTitle>

      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={isFullScreen ? 3 : 12}>
            <GeneralSubtitle>Eixo 1</GeneralSubtitle>
            {
              (data !== undefined) &&
              Object.entries(data.eixo1).sort((a,b) => b[1] - a[1]).map((eixo, index) => {
                return (
                  <ListaEixos 
                    key={index}
                    eixo={eixo1.find(x => x.label === eixo[0])}
                    index={index}
                    tam={eixo1.length-1}
                    value={eixo[1]}
                  />
                )
              })
            }
          </Grid>
          <Grid item xs={12} sm={isFullScreen ? 3 : 12}>
            <GeneralSubtitle>Eixo 2</GeneralSubtitle>
            {
              (data !== undefined) &&
              Object.entries(data.eixo2).sort((a,b) => b[1] - a[1]).map((eixo, index) => {
                return (
                  <ListaEixos 
                    key={index}
                    eixo={eixo2.find(x => x.label === eixo[0])}
                    index={index}
                    tam={eixo2.length-1}
                    value={eixo[1]}
                  />
                )
              })
            }
          </Grid>
          <Grid item xs={12} sm={isFullScreen ? 3 : 12}>
            <GeneralSubtitle>Eixo 3</GeneralSubtitle>
            {
              (data !== undefined) &&
              Object.entries(data.eixo3).sort((a,b) => b[1] - a[1]).map((eixo, index) => {
                return (
                  <ListaEixos 
                    key={index}
                    eixo={eixo3.find(x => x.label === eixo[0])}
                    index={index}
                    tam={eixo3.length-1}
                    value={eixo[1]}
                  />
                )
              })
            }
          </Grid>
          <Grid item xs={12} sm={isFullScreen ? 3 : 12}>
            <GeneralSubtitle>Competências</GeneralSubtitle>
            {
              (data !== undefined) &&
              Object.entries(data.competencia).sort((a,b) => b[1] - a[1]).map((eixo, index) => {
                return (
                  <ListaEixos 
                    key={index}
                    eixo={competencia.find(x => x.label === eixo[0])}
                    index={index}
                    tam={competencia.length-1}
                    value={eixo[1]}
                  />
                )
              })
            }
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
}