import React, { useState, useEffect, useContext, memo } from 'react';
import { Button,  Dialog , Grid, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import { MyTextField } from "../../../assets/styles/styledComponents";
import api from '../../../api';
import { StoreContext } from '../../../utils';

const initialFilter = {
  disciplinaID: "",
  topicoID: "",
  turmaID: "",
  tipo: "",
  tags: "",
}

function QuestionDialogFilter(props) {
  const { filter, setFilter, open, setOpen, activity, setIsCleaned } = props;
  const { token } = useContext(StoreContext);
  const [listaDisciplina, setListaDisciplina] = useState([]);
  const [listaTopico, setListaTopico] = useState([]);
  const [mount, setMount] = useState(true);
  const [tempFilter, setTempFilter] = useState(initialFilter);
  const [listaTurmas, setListaTurma] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);

  //--Fetchs dos tópicos e tags
  useEffect(() => {
    const abortController = new AbortController();
    if (tempFilter.disciplinaID !== "") {
      async function fetchTopicoAPI() {
        try {
          var params = {
            area: "0",
            turmaID: "0",
            disciplinaID: tempFilter.disciplinaID,
            numeracao: "0"
          }
          const response = await api.listarConteudoPorFiltroInterno(params);
          setListaTopico(response.data.data);
        } catch (error) {
          setListaTopico([]);
        }
      }

      fetchTopicoAPI();

      setTempFilter(preValue => ({
        ...preValue,
        topicoID: "",
        tags: ""
      }))
    }
    return abortController.abort();
  }, [tempFilter.disciplinaID])

  //--Carregamentos do filtro
  useEffect(() => {
    // Verifica se filtro já está preenchido
    if (filter !== initialFilter) {
      setTempFilter(filter);
    } else if (tempFilter !== initialFilter) {
      setTempFilter(initialFilter);
    }
    // eslint-disable-next-line
  }, [filter]);

  //--Carregamentos iniciais
  useEffect(() => {
    const abortController = new AbortController();
  
    // Montagem das listas
    if (mount) {
      async function fetchTurmaAPI() {
        await api.listarTurma()
          .then(res => {
            var value = res.data.data;
            if (token.accessType !== "Administrador" && token.turmaID.length) {
              var turmas = [];
              token.turmaID.map(x => { return turmas.push(x.turmaID) });
              value = res.data.data.filter(item => {
                return turmas.includes(item._id);
              });
            }
            setListaTurma(value);
          });
      }

      async function fetchDisciplinaAPI() {
        await api.listarDisciplinas()
          .then(res => {
            var value = res.data.data;
            if (token.accessType !== "Administrador" && token.disciplina.length) {
              value = res.data.data.filter(item => {
                return token.disciplina.includes(item._id);
              });
            }
            setListaDisciplina(value);
          });
      }

      // Faz o fetch e desmonta
      fetchTurmaAPI();
      fetchDisciplinaAPI();
      setMount(false);
    }

    return abortController.abort();
  }, [filter, mount, token]);

  useEffect(() => {
    const abortController = new AbortController();
      if (tempFilter?.turmaID !== "") {
        setDisciplinas(listaDisciplina.filter(x => {return x.turmaID === tempFilter.turmaID}));
      }
    return abortController.abort();
  }, [listaDisciplina, tempFilter.turmaID])

  //--Caso seja uma atividade, já preenche os dados pré-existentes
  useEffect(() => {
    const abortController = new AbortController();
    if (activity !== false) {
      setTempFilter(preValue => ({
        ...preValue,
        disciplinaID: activity.disciplinaID !== '' ? activity.disciplinaID : '',
        topicoID: activity.topicoID !== '' ? activity.topicoID : '',
      }))
    }

    return abortController.abort();
  }, [activity])

  //--Salva modificações do filtro temporariamente
  const onFilterChange = (event) => {
    const { name, value } = event.target;
    setTempFilter(preValue => ({
      ...preValue,
      [name]: value
    }))
    setIsCleaned(false);
  }

  //--Salva modificações no filtro permanentemente
  const onSubmit = () => {
    setFilter(tempFilter);
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Filtro</DialogTitle>

      <DialogContent>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <MyTextField
              id="campoArea"
              variant="outlined"
              disabled={false}
              select={true}
              label="Tipo de Questão"
              name="tipo"
              value={tempFilter.tipo}
              onChange={onFilterChange}>
                <MenuItem value="discursiva">Discursiva</MenuItem>
                <MenuItem value="multiplaEscolha">Múltipla Escolha</MenuItem>
            </MyTextField>
          </Grid>

          <Grid item={true} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Turma"
              name="turmaID"
              value={tempFilter.turmaID ? tempFilter.turmaID : ""}
              onChange={onFilterChange}>
              {
                listaTurmas.map((item, index) => {
                  return <MenuItem key={index} value={item._id}>{item.nome}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>

          <Grid item={true} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              label="Disciplina"
              name="disciplinaID"
              disabled={disciplinas.length === 0}
              value={tempFilter.disciplinaID ? tempFilter.disciplinaID : ""}
              onChange={onFilterChange}>
              {
                disciplinas.map((item, index) => {
                  return <MenuItem key={index} value={item._id}>{item.nome}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>

          <Grid item={true} xs={12}>
            <MyTextField
              variant="outlined"
              select={true}
              disabled={listaTopico.length === 0}
              label="Tópico"
              name="topicoID"
              value={tempFilter.topicoID ? tempFilter.topicoID : ""}
              onChange={onFilterChange}>
              {
                listaTopico.map((row, index) => {
                  return <MenuItem key={index} value={row._id}>{row.topico}</MenuItem>
                })
              }
            </MyTextField>
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Voltar
        </Button>
        <Button type="submit" onClick={onSubmit} color="primary">
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(QuestionDialogFilter);