import { Fade, Grid, makeStyles } from '@material-ui/core';
import {useState, useEffect, useContext} from 'react';
import apis from '../../api';
import { GeneralSubtitle, GeneralText, GeneralTitle } from '../../assets/styles/styledComponents';
import {StoreContext} from '../../utils';
import * as persistenceTime from "../../assets/animations/persistenceTime.json";
import Lottie from 'react-lottie';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: persistenceTime.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid"
    }
  };

// -- Estilos locais
const useStyles = makeStyles(() => ({
    gridTimer: {
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      color:"#0f0d59",
      width: "auto",
      height: "5rem"
    }
  }));
  

const PersistenceTimer = () => {
    const { token } = useContext(StoreContext);
    const classes = useStyles()

    const [ onBlur, setOnBlur ] = useState(false);
    const [ away, setAway ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);
    
    const [persistenceTime, setPersistenceTime] = useState({
        segundos: "00",
        minutos: "00",
        horas: "00"
    });

    const calculaContador = () => {
        const timestampAtual = new Date().getTime();
        const timestampGeral = new Date(JSON.parse(localStorage.getItem("TimestampGeral"))).getTime();
        let contador = Math.ceil((timestampAtual - timestampGeral)/1000);
        if(localStorage.getItem("Contador"))
            contador += JSON.parse(localStorage.getItem("Contador"));
        return contador;
    }

    useEffect(() => {
        
        const getContador = async () => {
            const { data } = await apis.encContadorPorID(token.userID);
            let contador = calculaContador();
            if(data.data) {
                contador += data.data;
            }
            setPersistenceTime({
                segundos: ("00" + (contador % 60)).slice(-2),
                minutos: ("00" + (Math.floor(contador / 60) % 60)).slice(-2),
                horas: ((contador / 3600) % 60) < 60 ? ("00" + (Math.floor(contador / 3600) % 60)).slice(-2) : ("" + (Math.floor(contador / 3600) % 60))
            });
        }

        if(!isLoaded) {
            const timeoutIsLoaded = setTimeout(() => setIsLoaded(true), 500)
            return () => clearTimeout(timeoutIsLoaded);
        }
        
        isLoaded && getContador();
        // eslint-disable-next-line
    }, [isLoaded])

    useEffect(() => {
        function updateRemainingTime() {
    
            let segundos = Number.parseInt(persistenceTime.segundos);
            let minutos = Number.parseInt(persistenceTime.minutos);
            let horas = Number.parseInt(persistenceTime.horas);
            
            segundos = (segundos + 1) % 60;
            if(!segundos) {
                minutos = (minutos + 1) % 60;
                if(!minutos) ++horas;
            }
    
            setPersistenceTime({
                segundos: ("00" + segundos).slice(-2),
                minutos: ("00" + minutos).slice(-2),
                horas: horas < 60 ? ("00" + horas).slice(-2) : ("" + horas)
            });
        }
        if(!away) {
            const intervalId = setInterval(() => {
                updateRemainingTime();
            }, 1000);
            return () => clearInterval(intervalId);
        }
        
    },[persistenceTime, away]);

    useEffect(() => {

        if(onBlur) {
            const intervalAwayId = setInterval(() => {
                setAway(true);
            }, 300000);
            return () => clearInterval(intervalAwayId);
        }
        
    },[onBlur]);

    window.addEventListener("blur", () => {
        !onBlur && setOnBlur(true);
    });

    window.addEventListener("focus", () => {
        onBlur && setOnBlur(false);
        away && setAway(false);
    });

    return(

        <Grid container justifyContent="center" alignItems='center' alignContent='center' spacing={0}>
            <Grid item xs={12} className={classes.gridTimer}>
                <Fade in={isLoaded} style={{transitionDelay: "300ms"}}>
                    <Lottie options={defaultOptions} height={150} width={150} isStopped={!isLoaded} isPaused={!isLoaded}/>
                </Fade>
            </Grid>
            <Grid item xs={3}>
                <GeneralTitle align='center'>
                    {persistenceTime.horas}
                </GeneralTitle>
                <GeneralText align='center'>HORAS</GeneralText>
            </Grid>
            <Grid item xs={1}>
                <GeneralSubtitle align='center'> : </GeneralSubtitle>
            </Grid>
            <Grid item xs={3}>
                <GeneralTitle align='center'>
                    {persistenceTime.minutos}
                </GeneralTitle>
                <GeneralText align='center'>MINUTOS</GeneralText>
            </Grid>
            <Grid item xs={1}>
                <GeneralSubtitle align='center'> : </GeneralSubtitle>
            </Grid>
            <Grid item xs={4}>
                <GeneralTitle align='center'>
                    {persistenceTime.segundos}
                </GeneralTitle>
                <GeneralText align='center'>SEGUNDOS</GeneralText>
            </Grid>
        </Grid>
    );
}

export default PersistenceTimer;