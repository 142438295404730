import React, { useEffect, useState } from "react";

import api from "../../api";

import { MyContainer, MyCard, MyCardContent, GeneralTitle, MyTypography } from "../../assets/styles/styledComponents"
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Avatar } from "@material-ui/core";
import { FullWidthTab, WirisIframe, CorrectionProgressBar, DiscursiveAnswer } from "../../components";
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from "react-router-dom";

// -- Estilos locais
const useStyles = makeStyles((theme) => ({
  
  accordion: {
    marginTop: '1rem',
    minHeight: "15rem",
  },

  accordionReturns: {
    padding: '0.5rem'
  },

  card: {
    marginTop: '1rem',
    minHeight: 400,
    padding: '1rem'
  },
  
  comment: {
    marginTop: '1rem',
    marginBottom: '2rem',
    width: '100%'
  },
  
  commentChanged: {
    marginTop: '1rem',
    marginBottom: '2rem',
    width: '90%'
  },

  commentIcon: {
    marginTop: '1rem'
  },

  content: {
    fontStyle: 'normal',
    fontWeight: `300`,
    fontSize: '1rem',
    marginTop: '1rem',
    color: '#606161',
  },

  discursiveAnswer: {
    width: '100%'
  },
  
  phrase: {
    fontSize: '1rem'
  },
  
  question: {
    padding: '1rem',
    paddingTop: '0rem'
  },
  
  questionProgress: {
    marginLeft: '2rem',
  },

  student: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingLeft: '1rem'
  },
  
  subTitle: {
    fontStyle: 'normal',
    fontWeight: `500`,
    fontSize: '1.25rem',
    marginTop: '1rem',
    color: '#606161',
  },

  tabs: {
    marginTop: '1rem',
  },

  title: {
    fontStyle: 'normal',
    fontWeight: `300`,
    fontSize: '1.25rem',
    color: '#606161',
  }
}));

export default function ActivityToCorrect (props) {
  const activityID = props.match.params.atividadeID;
  const tipo = props.match.params.tipo;
  const classes = useStyles();
  const [wasLoaded, setWasLoaded] = useState(false);
  const [indice, setIndice] = useState(0);
  const [value, setValue] = useState(0);
  const [listarPorAluno, setListarPorAluno] = useState(false);
  const [respostaAtividade, setRespostaAtividade] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [questoes, setQuestoes] = useState([]);
  const [progresso, setProgresso] = useState(0);
  const [progressoAluno, setProgressoAluno] = useState([]);
  const [numTasks, setNumTasks] = useState(0);
  const [aCorrigirQuestao, setACorrigirQuestao] = useState([]);
  const history = useHistory();
  
  async function pegarRespostasAluno(tipo, atividadeID) {
    if(tipo === "AD") {
      await api.listarRAPorRevisaoID(atividadeID)
      .then( res => {
        if (res.data.success) {
          res.data.data.sort(function(a,b) {
            if (a.alunoID.nome > b.alunoID.nome) return 1;
            else if (a.alunoID.nome < b.alunoID.nome) return -1;
            return 0;
          });
          setRespostaAtividade(res.data.data);
        };
      });
      setWasLoaded(true);
    } else {
      await api.listarRAPorAtividadeID(atividadeID)
      .then( res => {
        if (res.data.success) {
          res.data.data.sort(function(a,b) {
            if (a.alunoID.nome > b.alunoID.nome) return 1;
            else if (a.alunoID.nome < b.alunoID.nome) return -1;
            return 0;
          });
          setRespostaAtividade(res.data.data);
        };
      });
      setWasLoaded(true);
    }
  }
  
  async function corrigirAtividade (respostaAtividade) {
    var nota = 0;
    for (let index = 0; index < respostaAtividade.respostaQuestaoIDs.length; index++) {
      nota = nota + respostaAtividade.respostaQuestaoIDs[index].nota;
    }

    nota = (nota) / respostaAtividade.respostaQuestaoIDs.length;

    if (nota === respostaAtividade.nota) return null;

    let novaResposta = respostaAtividade;
    novaResposta.nota = nota;
    novaResposta.corrigido = true;
    await api.atualizarRespostaAtividade(respostaAtividade._id, novaResposta);
  }
  
  function calcularProgressoAluno () {
    var total = 0, corrigidos = 0;
    var auxiliar = [];
    var aCorrigir = [];
    var aux = [];
    var array;
    var questao;

    for (let i = 0; i < respostaAtividade.length; i++) {
      array = [];
      auxiliar = [];
      for (let j = 0; j < respostaAtividade[i].respostaQuestaoIDs.length; ++j) {
        questao = questoes.find(element => { return element._id === respostaAtividade[i].respostaQuestaoIDs[j].questaoID })
        if (questao && questao.tipoResposta === 'discursiva') {
          total = total + 1;
          array.push(respostaAtividade[i].respostaQuestaoIDs[j])
          if (respostaAtividade[i].respostaQuestaoIDs[j].corrigido === false) {
            auxiliar.push(true);
          } else {
            auxiliar.push(false);
            corrigidos = corrigidos + 1;
          }
        } else auxiliar.push(false);
      }
      aux.push(array);
      aCorrigir.push(auxiliar); 
    }
    
    setACorrigirQuestao(aCorrigir);
    setProgressoAluno(aux);
    setNumTasks(total);
    setProgresso(corrigidos);
  }
      
  useEffect(() => {
    if (!wasLoaded) {
      pegarRespostasAluno(tipo, activityID);
      function listarAlunos() {
        let aux = [];
        if (respostaAtividade.length !== 0) {
          respostaAtividade.map( async (row, index) => {
            const { _id, nome, url } = row.alunoID;
            const { corrigido } = row;
            if(!row.respostaQuestaoIDs.find(element => element.corrigido !== true)) {
              corrigirAtividade(row);
            };
            aux.push({_id, nome, url, corrigido});
          })
          setAlunos(aux);
        }
      }
      async function listarQuestoes() {
        let aux = [];
        if (respostaAtividade.length !== 0) {
          var questoesTemp;
          if (tipo === 'AD') questoesTemp = respostaAtividade[0].revisaoID.questoes;
          else questoesTemp = respostaAtividade[0].atividadeID.questoes;
          questoesTemp.map(async (row, index) => {
            if (row.questaoID.tipoResposta === 'discursiva') {
              const { _id, enunciado, resposta, padraoResposta, tipoResposta, corrigido, foto } = row.questaoID;
              aux.push({ _id, enunciado, resposta, padraoResposta, tipoResposta, corrigido, index, foto });
            }
          })
          if (aux.length) {
            setIndice(aux[0].index)
            setQuestoes(aux);
          }
        }
      }
      listarAlunos();
      listarQuestoes();
    }
  }, [activityID, tipo, history, respostaAtividade, wasLoaded])
  
  useEffect(() => {
    const abortController = new AbortController();
    calcularProgressoAluno();
    return abortController.abort();
    // eslint-disable-next-line
  }, [progresso || respostaAtividade])

  function ListarRAPorQuestao() {
    if (respostaAtividade.length !== 0 && questoes.length !== 0) {
      var questoesTemp = tipo === "AD" ? respostaAtividade[0].revisaoID.questoes : respostaAtividade[0].atividadeID.questoes;
      return (
        <>
          <Grid item={true} xs={12} sm={4}>
            <MyCard className={classes.card}>
              <MyCardContent className={classes.question}>
                <h2 className={classes.title}  id="questaoNumeracao">{`Questão ${indice+1}`}</h2>
                <Grid item={true} align="center">
                  <WirisIframe text={questoesTemp[indice].questaoID.enunciado}/>
                </Grid>
                <Grid align="center" item={true} xs={12} sm={12} lg={12}>
                    <MyTypography className={classes.answerField}>{ questoesTemp[indice].questaoID.padraoResposta !== '' ? "Padrão de Resposta" : "Não há um padrão de resposta para esta questão."}</MyTypography>
                </Grid>
                <Grid align="center" item={true} xs={12} sm={12} lg={12}>
                    <WirisIframe className={classes.answerField} text={ questoesTemp[indice].questaoID.padraoResposta } />
                </Grid>
              </MyCardContent>
            </MyCard>
          </Grid>
  
          <Grid item={true} xs={12} sm={5} className={classes.accordion}>
            {
              respostaAtividade.length !== 0 ? 
              respostaAtividade.map((row, index) => {
                return (
                  <Accordion key={index} className={classes.accordionReturns}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      <Avatar sizes="small" src={`${process.env.REACT_APP_API_URL}uploads/profile/${row.alunoID._id}.jpeg`} alt="Preview"/>
                      <Typography className={classes.student}>{row.alunoID.nome}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <DiscursiveAnswer  
                        defaultValue={row.respostaQuestaoIDs[indice]?.nota}
                        resposta={row.respostaQuestaoIDs[indice]?.resposta}
                        id={row.respostaQuestaoIDs[indice]?._id} 
                        comentario={row.respostaQuestaoIDs[indice]?.comentario} 
                        fotos={row.respostaQuestaoIDs[indice] ? row.respostaQuestaoIDs[indice].foto : []} 
                        index={indice}
                        value={index} 
                        progresso={progresso}
                        setProgresso={setProgresso}
                        setRespostaAtividade={setRespostaAtividade} 
                        respostaAtividade={respostaAtividade}
                      />
                    </AccordionDetails>
                  </Accordion>
                )
              }) : null
            }
          </Grid>
        </>
      )
    }
  }

  function ListarRAPorAluno() {
    return (
      <Grid item sm={9} className={classes.accordion}>
        {
          questoes.length !== 0 ? 
          questoes.map((row, index) => {
            return (
              <Accordion key={index} className={classes.accordionReturns}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography>{`Questão ${row.index+1}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={5}>
                    <Grid item sm={6}>
                      <Grid item={true} align="center">
                        <WirisIframe text={questoes[index].enunciado}/>
                      </Grid>
                      <Grid align="center" item={true} xs={12} sm={12} lg={12}>
                          <MyTypography className={classes.answerField}>{ questoes[index].padraoResposta !== '' ? "Padrão de Resposta" : "Não há um padrão de resposta para esta questão."}</MyTypography>
                      </Grid>
                      <Grid align="center" item={true} xs={12} sm={12} lg={12}>
                          <WirisIframe className={classes.answerField} text={ questoes[index].padraoResposta } />
                      </Grid>
                    </Grid>
                    <Grid item sm={6}>
                      <Grid item={true} align="center">
                        <DiscursiveAnswer  
                          defaultValue={respostaAtividade[indice].respostaQuestaoIDs[row.index]?.nota}
                          resposta={respostaAtividade[indice].respostaQuestaoIDs[row.index]?.resposta}
                          id={respostaAtividade[indice].respostaQuestaoIDs[row.index]?._id} 
                          comentario={respostaAtividade[indice].respostaQuestaoIDs[row.index]?.comentario} 
                          fotos={respostaAtividade[indice].respostaQuestaoIDs[row.index] ? respostaAtividade[indice].respostaQuestaoIDs[row.index].foto : []} 
                          index={row.index}
                          value={indice} 
                          progresso={progresso}
                          setProgresso={setProgresso}
                          setRespostaAtividade={setRespostaAtividade} 
                          respostaAtividade={respostaAtividade}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )
          }) : null
        }
      </Grid>
    )
  }

  return (
    <MyContainer id="activityContainer">

      <section id="cabecalhoAtividade">
      { 
        respostaAtividade.length !== 0 && respostaAtividade[0].atividadeID ?
        <GeneralTitle className="heading-page">{`Atividade de ${ respostaAtividade.length !== 0 ? respostaAtividade[0].atividadeID.tipoAtividade : 'Fixação'}`}</GeneralTitle> 
        :
        <GeneralTitle className="heading-page">Avaliação Diagnóstica</GeneralTitle>
      }

      </section>

      <section id="areaDeCorrecao">
        <Grid container={true} spacing={2}>

          <Grid item={true} xs={12} sm={12}>
            <CorrectionProgressBar 
              max={numTasks} 
              progresso={progresso} 
              wasLoaded={wasLoaded}
            />
          </Grid>

          <Grid item={true} xs={12} sm={3}>
            <MyCard className={classes.tabs}>
              <FullWidthTab 
                questoes={
                  respostaAtividade.length !== 0 ? 
                  questoes : 0
                }
                questaoACorrigir={aCorrigirQuestao}
                alunos={ alunos }
                listarPorAluno={listarPorAluno}
                setListarPorAluno={setListarPorAluno}
                value={value}
                setValue={setValue}
                setIndice={setIndice}
                progressoAluno={progressoAluno}
              />
            </MyCard>
          </Grid>

          { 
            listarPorAluno ? 
            ListarRAPorAluno() :
            ListarRAPorQuestao()
          }

        </Grid>
      </section>

    </MyContainer>
  );
};
