import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import apis from '../../../api';
import { BackButton, ExportButton, GeneralTitle, MyContainer } from '../../../assets/styles/styledComponents';
import { StudentTable } from '../../../components';
import { StoreContext } from '../../../utils';

function SubjectAnalysis() {
  const { dataProvider, setDataProvider } = useContext(StoreContext)
  const [alunos, setAlunos] = useState([]);
  const [filterDialog, setFilterDialog] = useState(false);
  const [filter, setFilter] = useState();
  const [csvData, setCsvData] = useState([]);
  
  // Verifica se DataProvider possui as informações necessárias
  const history = useHistory();
  if (!dataProvider) history.push("/analisar-desempenho")
  const { disciplinaID, turmaID, disciplinaNome, areaConhecimento } = dataProvider;
  const fileName = "Resultados - " + disciplinaNome;
  const tabName = "Resultados";

  // Carrega dados para amostragem em tela
  useEffect(() => {
    // Pega as informações de cada aluno
    async function fetchData() {
      // Pega alunos ativos da turma
      await apis.listarAlunosAtivos()
        .then(async resAlunos => {
          var arrayAux = [];

          // Pega o ID e nome de todos os alunos ativos para esta disciplina
          resAlunos.data.data.map(aluno => {
            if (aluno.turma.find(t => t.turmaID === turmaID)) {
              arrayAux.push({
                alunoID: aluno._id,
                nome: aluno.nome,
                url: aluno.url,
                atividades: 0,
                nota: 0
              })
            }
            return null;
          })

          var redacao = areaConhecimento === "Redação";
          setDataProvider(preValue => ({ ...preValue, redacao: redacao }));

          // Disciplina do tipo Redação
          var apiFunction = redacao ? apis.listarRedacoesPorDisciplina : apis.listaRespostaAtividadePorDisciplina;

          // Pega os resultados dos alunos (número de atividades e nota média)
          await apiFunction(disciplinaID)
            .then(ras => {
              ras.data.data.map(x => {
                
                try {
                  // Índice da posição no array do aluno
                  var index = arrayAux.findIndex(a => a.alunoID === x.alunoID._id);
  
                  // Caso informações do aluno já estejam no array
                  if (index >= 0) {
                    var aluno = arrayAux[index];
                    var notaFinal = redacao ? (x?.nota ? x.nota/10 : 0) : x.nota;
                    
                    arrayAux[index] = {
                      alunoID: aluno.alunoID,
                      nome: aluno.nome,
                      url: aluno.url,
                      atividades: ++aluno.atividades,
                      nota: aluno.nota + notaFinal
                    }
                  }
                  else {
                    // console.log("Aluno não encontrado: ", x.alunoID);
                  }
                } catch (error) {
                  // console.log("Aluno não encontrado: ", x);
                }
                return null;
              })

              // Salva dados dos alunos
              setAlunos(arrayAux);
            })
            .catch(err => console.log("Erro - apiFunction: ", err))
        })
        .catch(err => console.log("Erro listarAlunosAtivos: ", err))
    }
    fetchData();
  }, [areaConhecimento, disciplinaID, setDataProvider, turmaID])

  // Carrega dados para exportação de resultados
  useEffect(() => {
    async function CarregaDadosExportacao() {
      var params = {
        area: "0",
        turmaID: turmaID,
        disciplinaID: disciplinaID,
        numeracao: "0"
      }

      // Carrega alunos ativos na turma
      await apis.listarAlunosAtivos()
        .then(async alunos => {
          var alunosDaTurma = alunos.data.data.map(aluno => {
            if (aluno.turma.find(t => t.turmaID === turmaID)) {
              return aluno;
            }
            return null;
          })
          alunos = alunosDaTurma.filter(x => { return x });

          // Recupera tópicos da disciplina
          await apis.listarConteudoPorFiltroInterno(params)
            .then(async res => {
              let topicosDaDisciplina = res.data.data;
      
              // Define cabeçalho da planilha
              let header = ["AlunoID", "Alunos"];
              res.data.data.map(obj => { return header.push(`${obj.topico}`) })
              header.push("Média");

              // Preenche array com dados iniciais dos alunos
              var csvDataArray = [];
              alunos.map(aluno => {
                return csvDataArray.push({
                  [header[0]]: `${aluno._id}`,
                  [header[1]]: `${aluno.nome}`
                })
              });
              
              // Caso atividade não seja do tipo redação
              if (areaConhecimento !== "Redação") {
                let progresso = [];
                
                // Recupera dados de cada aluno
                alunos.map(({_id}) => {
                  // Define variável que vai conter os dados das redações
                  let progressoDoTopico = [];
                  
                  // Recupera progressos de cada tópico
                  topicosDaDisciplina.map(async item => {
                    await apis.encProgressoPorTopico(_id, item._id)
                      .then(progEncontrado => {
                        progressoDoTopico = progressoDoTopico.length ? progressoDoTopico.concat(progEncontrado.data.data) : progEncontrado.data.data
                      })
                      .catch(err => console.log("Erro em carregar progressos: ", err))
                  })
                  return progresso.push(progressoDoTopico);
                })
              }
      
              // Encontra progresso das redações
              else {
                params = {
                  tipo: "0",
                  turmaID: turmaID,
                  disciplinaID: disciplinaID,
                  topico: "0",
                  numeracao: "0",
                  area: "0"
                }
                
                // Encontra as redações por disciplina
                await apis.listarAtividadePorFiltro(params)
                  .then(async res1 => {
                    const redacoesEncontradas = res1.data.data;

                    // Separa as atividades por tópico
                    var redacaoPorTopico = [];
                    redacoesEncontradas.forEach(re => {
                      let tmp = topicosDaDisciplina.find(x => x._id === re.topicoID._id);
                      redacaoPorTopico.push({
                        topicoID: tmp._id,
                        topicoNome: tmp.topico,
                        redacaoID: re._id
                      })
                    })

                    // Encontra progressos das redações
                    await apis.listarRedacoesPorDisciplina(disciplinaID)
                      .then(res2 => {
                        const redacoesRealizadas = res2.data.data;

                        // Carrega  informações por aluno
                        alunos.map(({_id}) => {

                          // Identifica as redações realizadas de cada aluno
                          let redacoesDoAluno = redacoesRealizadas.filter(rr => {
                            return rr?.alunoID?._id === _id;
                          })

                          // Identifica informações das Redações realizadas pelo aluno
                          let rfa = redacoesEncontradas.map(re => {
                            return redacoesDoAluno.filter(ra => {
                              return ra.redacaoID._id === re._id;
                            })
                          }).filter(x => {return x.length > 0})

                          // Identifica o índice do aluno no array de dados para exportação
                          let index = csvDataArray.map(x => { return x.AlunoID }).indexOf(_id);

                          // Faz o carregamento dos dados por aluno no array de informações para exportação
                          try {
                            for (let i = 0; i < header.length; i++) {
                              if (header[i] === "Média") {
                                let nota = 0;
                                redacoesDoAluno.map(x => {
                                  return nota += x?.nota ? x.nota : 0;
                                })
                                if (redacoesDoAluno.length !== 0) {
                                  nota = Math.round(nota/(redacoesDoAluno.length*10), 2);
                                }
                                csvDataArray[index][header[i]] = `${nota}`;
                              }
                              else {
                                if (redacaoPorTopico.find(x => x.topicoNome === header[i+2])) {
                                  // Encontra progresso do aluno para esse tema
                                  let progressoAluno = rfa.find(x => x[0]?.redacaoID?._id === redacaoPorTopico[i]?.redacaoID);
                                  // Aluno fez redação para esse tema
                                  if (progressoAluno) {
                                    csvDataArray[index][header[i+2]] = progressoAluno[0]?.corrigido ? `${progressoAluno[0]?.nota/10}` : "Não corrigido";
                                  }
                                  // Aluno não fez redação para esse tema
                                  else {
                                    csvDataArray[index][header[i+2]] = "Não realizado";
                                  }
                                }
                              }
                            }                            
                          } catch (error) {
                            console.log(error);
                          }
                          return null;
                        })

                        for (let i = 0; i < csvDataArray.length; i++) {
                          delete csvDataArray[i].AlunoID;
                        }
                        setCsvData(csvDataArray)
                      })
                  })
              }
            })
            .catch(err => console.log("Erro em carregar tópicos: ", err));
        })
    }
    CarregaDadosExportacao();
  }, [areaConhecimento, disciplinaID, turmaID])

  return (
    <MyContainer>
      <GeneralTitle>
        {disciplinaNome ? disciplinaNome : ""}
      </GeneralTitle>

      <StudentTable 
        data={alunos}
        filterDialog={filterDialog}
        setFilterDialog={setFilterDialog}
        filter={filter}
        setFilter={setFilter}
      />

        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <BackButton url={"/analisar-desempenho"} />
          </Grid>
          <Grid item>
            <ExportButton csvData={csvData} fileName={fileName} tabName={tabName} />
          </Grid>
        </Grid>
    </MyContainer>
  );
}

export default SubjectAnalysis;