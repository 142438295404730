import React, { memo, useContext, useState } from 'react';
import { PDFViewer, FullScreenDialog } from '../../';
import { CustomTopicButton, CustomVideoButton } from './Buttons';
import { Grid, Typography } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import AulaIcon from '@material-ui/icons/OndemandVideo';
import FixacaoIcon from '@material-ui/icons/LocalLibrary';
import RetomadaIcon from '@material-ui/icons/AssignmentReturn';
import AprofundamentoIcon from '@material-ui/icons/FindInPage';
import VideoLinkDialog from '../../Dialogs/VideoLinkDialog';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../../utils';

function SubtituloAcordeao(tipoAcordeao, titulo, disciplinaNome, semana, classes) {
    if (tipoAcordeao === 'biblioteca') {
        return (
            <Grid item={true} xs={12} sm={12}>
                <Grid container={true} style={{padding: "0 1rem 0"}}>
                    <Grid item={true} xs={6} sm={6}>
                        <Typography className={classes.secondaryHeading}>{disciplinaNome}</Typography>
                    </Grid>
                    <Grid item={true} xs={6} sm={6}>
                        <Typography className={classes.subtitleLibrary}>Semana {semana}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid item={true} xs={12} sm={12}>
                <Typography id="secondaryHeading" className={classes.secondaryHeading}>{titulo}</Typography>
            </Grid>
        )
    }
}

function ReturnTopics(props) {
    const { 
        check, setCheck, progresso, setProgresso, setWasChecked, linkAula, linkAulaOpcional, tipoAcordeao, titulo, 
        disciplinaNome, classes, semana, gridSize, open, setOpen, materialURL, activity, topicProgress, plano
    } = props;
    
    const [videoOpen, setVideoOpen] = useState(false);
    const { setDataProvider } = useContext(StoreContext);

    // Função de abertura dos diálogos
    const handleClickOpen = (name) => {
        setOpen(preValue => ({
            ...preValue,
            [name]: true
        }));
    };
    
    // Ao clicar no botão de conteudo do essa
    const handleClickConteudo = () => {
        if (!check.materialEstudo) {
            setCheck(prevValue => ({
                ...prevValue,
                materialEstudo: true
            }));
            setProgresso(progresso+1);
            setWasChecked(true);
        }
        window.open(materialURL,'_blank');
    }

    // Ao clicar no botão de videoaula
    const handleClickVideo = () => {
        if (!check.videoaula) {
            setCheck(preValue => ({
                ...preValue,
                videoaula: true
            }));
            setProgresso(progresso + 1);
            setWasChecked(true);
        }
        window.open(linkAula,'_blank');
    }

    // Ao clicar no botão de visto
    const handleCheckVideo = () => {
        if (!check.videoaula) {
            setCheck(preValue => ({
                ...preValue,
                videoaula: true
            }));
            setProgresso(progresso + 1);
            setWasChecked(true);
        }
        setVideoOpen(false);
    }

    // Encerra o diálogo de visualização 
    const handleCloseContentDialog = () => {
        setOpen(preValue => ({ 
            ...preValue, 
            'materialEstudo': false 
        }));
    }

    // Marca material de estudo como visto/estudado
    const markAsViewed = () => {
        if (!check.materialEstudo) {
            setCheck(preValue => ({
                ...preValue,
                materialEstudo: true
            }));
            setProgresso(progresso + 1);
            setWasChecked(true);
        }
        handleCloseContentDialog();
    }

  return (
      <>
        { SubtituloAcordeao(tipoAcordeao, titulo, disciplinaNome, semana, classes) }
            
            { materialURL !== "" &&
                <Grid item={true} xs={12} sm={gridSize.cont ? gridSize.cont : 'auto'}>
                    <CustomTopicButton 
                        id="materialEstudo" 
                        icon={<SchoolIcon />}
                        check={check.materialEstudo }
                        classes={classes}
                        handleClick={String(materialURL).includes("odisseia-bucket") ? handleClickOpen : handleClickConteudo}
                        title1="Conteúdo"
                        title2="Conteúdo"
                    />

                    <FullScreenDialog 
                        dialogContent={<PDFViewer height={70} source={materialURL}/>}
                        open={open.materialEstudo}
                        functionText='Marcar como Visto'
                        handleClick={markAsViewed}
                        handleClose={handleCloseContentDialog}
                    />
                </Grid>
            }
        
            { linkAula !== '' && plano !== "Free" &&
                <Grid item={true} xs={12} sm={gridSize.cont ? gridSize.cont : 'auto'}>
                    <CustomVideoButton 
                        id="videoaula" 
                        icon={<AulaIcon />}
                        check={check.videoaula }
                        classes={classes}
                        handleClick={ linkAulaOpcional !== '' ? () => setVideoOpen(true) : handleClickVideo }
                        title={"Vídeoaula"}
                    />
                
                    <VideoLinkDialog
                        open={videoOpen}
                        setOpen={setVideoOpen}
                        linkAula={linkAula}
                        linkAulaOpcional={linkAulaOpcional}
                        check={check}
                        classes={classes}
                        handleCheck={handleCheckVideo}
                        title={<AulaIcon fontSize="large" color="primary"/>}
                        />
                </Grid>
            }

            { activity.fixacao.length !== 0 &&
                <Grid item={true} xs={12} sm={gridSize.exe ? gridSize.exe : 'auto'}>
                    <Link to={`/atividade/`} style={{ textDecoration: 'none' }}>
                        <CustomTopicButton 
                            id="exercicioFixacao" 
                            handleClick={() => { setDataProvider( prevValue => ({ ...prevValue, activity: activity.fixacao, progress: topicProgress, type: "exercicioFixacao"}))}} 
                            check={check.exercicioFixacao }
                            classes={classes}
                            icon={<FixacaoIcon/>}
                            title1="Fixação"
                            title2="Fixação"
                        />
                    </Link>
                </Grid>
            }

            { activity.retomada.length !== 0 &&
                <Grid item={true} xs={12} sm={gridSize.exe ? gridSize.exe : 'auto'}>
                    <Link to={`/atividade/`} style={{ textDecoration: 'none' }}>
                        <CustomTopicButton 
                            id="exercicioRetomada" 
                            handleClick={() => { setDataProvider( prevValue => ({ ...prevValue, activity: activity.retomada, progress: topicProgress, type: "exercicioRetomada"}))}} 
                            check={check.exercicioRetomada }
                            classes={classes}
                            icon={<RetomadaIcon/>}
                            title1="Retomada"
                            title2="Retomada"
                        />
                    </Link>
                </Grid>
            }

            { activity.aprofundamento.length !== 0 &&
                <Grid item={true} xs={12} sm={gridSize.exe ? gridSize.exe : 'auto'}>
                    <Link to={`/atividade/`} style={{ textDecoration: 'none' }}>
                        <CustomTopicButton 
                            id="exercicioAprofundamento" 
                            handleClick={() => { setDataProvider( prevValue => ({ ...prevValue, activity: activity.aprofundamento, progress: topicProgress, type: "exercicioAprofundamento"}))}} 
                            check={check.exercicioAprofundamento }
                            classes={classes}
                            icon={<AprofundamentoIcon/>}
                            title1="Aprofundamento"
                            title2="Aprofundamento"
                        />
                    </Link>
                </Grid>
            }
      </>
  )
}

export default memo(ReturnTopics)