import axios from "axios";

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// const api = axios.create({ baseURL: "https://odisseia-server-teste.herokuapp.com/api" });

//#region Funções relacionadas ao Usuário
export const inserirUsuario = novoUsuario => api.post("/controle-usuario", novoUsuario);
export const listarUsuarios = () => api.get("/controle-usuario");
export const listarAlunosAtivos = () => api.get("/listar-alunos");
export const atualizarUsuario = (id, usuarioAtualizado) => api.put(`/controle-usuario/${id}`, usuarioAtualizado);
export const atualizarContador = (id, contadorAtualizado) => api.put(`/controle-usuario/contador/${id}`, contadorAtualizado);
export const encContadorPorID = id => api.get(`/controle-usuario/contador/${id}`);
export const removerUsuario = id => api.delete(`/controle-usuario/${id}`);
export const encUsuarioPorID = id => api.get(`/controle-usuario/${id}`);
export const encUsuarioPorEmail = email => api.get(`/login/${email}`);
export const confirmarUsuario = usuario => api.post("/confirmar-usuario", usuario);
export const enviarEmail = email => api.post(`/enviar-email/${email}`);
export const verificarToken = token => api.post(`/verificar-token/${token}`);
export const redefinirSenha = (token, senha) => api.post(`/redefinir-senha/${token}/${senha}`);
export const concluirTutorial = id => api.put(`/concluir-tutorial/${id}`);
//#endregion

//#region Funções relacionadas ao Comunicado
export const inserirComunicado = novoComunicado => api.post("/comunicado", novoComunicado);
export const listarComunicado = (turmaID, dataAtual) => api.get(`/comunicado/filtro/${turmaID}&${dataAtual}`);
export const atualizarComunicado = (id, comunicadoAtualizado) => api.put(`/comunicado/${id}`, comunicadoAtualizado);
export const removerComunicado = id => api.delete(`/comunicado/${id}`);
export const encComunicadoPorID = id => api.get(`/comunicado/${id}`);
//#endregion

//#region Funções relacionadas ao Conteúdo
export const inserirConteudo = novoConteudo => api.post("/controle-conteudo", novoConteudo);
export const listarConteudos = () => api.get("/controle-conteudo");
export const atualizarConteudo = (id, conteudoAtualizado) => api.put(`/controle-conteudo/${id}`, conteudoAtualizado);
export const removerConteudo = id => api.delete(`/controle-conteudo/${id}`);
export const encConteudoPorID = id => api.get(`/controle-conteudo/${id}`);
export const listarConteudoPorFiltro = ({numeracao, disciplinaID, topicoID, turmaID}) => api.get(`/controle-conteudo/filtro/disciplina=${disciplinaID}&numeracao=${numeracao}&topicoID=${topicoID}&turma=${turmaID}`);
export const listarConteudoPorFiltroInterno = ({area, turmaID, disciplinaID, numeracao}) => api.get(`/controle-conteudo/interno/disciplina=${disciplinaID}&area=${area}&turma=${turmaID}&numeracao=${numeracao}`);
export const encConteudoPersonalizado = (id, numeracao) => api.get(`/controle-conteudo/topico/${id}/${numeracao}`);
export const listarConteudoCorrente = (numeracao, diaSemana, turmaID) => api.get(`/controle-conteudo/corrente/numeracao=${numeracao}&diaSemana=${diaSemana}&turma=${turmaID}`);
//#endregion

//#region Funções relacionadas à Disciplina
export const inserirDisciplina = novaDisciplina => api.post("/configuracoes/disciplina", novaDisciplina);
export const listarDisciplinas = () => api.get("/configuracoes/disciplina");
export const atualizarDisciplina = (id, disciplinaAtualizada) => api.put(`/configuracoes/disciplina/${id}`, disciplinaAtualizada);
export const removerDisciplina = id => api.delete(`/configuracoes/disciplina/${id}`);
export const encDisciplinaPorID = id => api.get(`/configuracoes/disciplina/${id}`);
export const listarDisciplinasPorDiaDaSemana = (dia) => api.get(`/plano-estudo/disciplina/${dia}`);
export const listarDisciplinasPorTurma = (turmaID) => api.get(`/biblioteca/disciplina/${turmaID}`);
//#endregion

//#region Funções relacionadas à Log
export const inserirLog = novoLog => api.post("/set-log", novoLog);
export const loginDiario = id => api.get(`/loginDiario/${id}`);
//#endregion

//#region Funções relacionadas à Tag
export const inserirTag = novaTag => api.post("/configuracoes/tag", novaTag);
export const listarTags = () => api.get("/configuracoes/tag");
export const atualizarTag = (id, tagAtualizada) => api.put(`/configuracoes/tag/${id}`, tagAtualizada);
export const removerTag = id => api.delete(`/configuracoes/tag/${id}`);
export const encTagPorID = id => api.get(`/configuracoes/tag/${id}`);
export const encTagPorNome = nome => api.get(`/configuracoes/tag-nome/${nome}`);
export const listarTagsPorDisciplina = id => api.get(`/configuracoes/tags/${id}`);
//#endregion

//#region Funções relacionadas à Classificacao
export const inserirClassificacao = novaClassificacao => api.post("/configuracoes/classificacao", novaClassificacao);
export const listarClassificacoes = () => api.get("/configuracoes/classificacao");
export const atualizarClassificacao = (id, classificacaoAtualizada) => api.put(`/configuracoes/classificacao/${id}`, classificacaoAtualizada);
export const removerClassificacao = id => api.delete(`/configuracoes/classificacao/${id}`);
export const encClassificacaoPorID = id => api.get(`/configuracoes/classificacao/${id}`);
export const encClassificacaoPorNome = nome => api.get(`/configuracoes/classificacao-nome/${nome}`);
//#endregion

//#region Funções relacionadas à RespostaQuestao
export const inserirRespostaQuestao = novaRespostaQuestao => api.post("/resposta-questao", novaRespostaQuestao);
export const atualizarRespostaQuestao = (id, respostaQuestaoAtualizada, fotos) => api.put(`/resposta-questao/${id}`, respostaQuestaoAtualizada, fotos)
export const removerRespostaQuestao = id => api.delete(`/resposta-questao/${id}`);
export const encRespostaQuestaoPorID = id => api.get(`/resposta-questao/${id}`);
export const encRespostaQuestaoPorAtividade = (atividadeID, alunoID, questaoID) => api.get(`/resposta-questao/atividade/${atividadeID}/${alunoID}/${questaoID}`);
export const encRespostaQuestaoPorRevisao = (revisaoID, alunoID, questaoID) => api.get(`/resposta-questao/revisao/${revisaoID}/${alunoID}/${questaoID}`);
export const listarRespostaQuestao = () => api.get(`/resposta-questao`);
export const listarRQPorQuestaoID = questaoID => api.get(`/resposta-questao/questao/${questaoID}`);
export const listarRQPorAtividadeID = (alunoID, atividadeID) => api.get(`/resposta-questao/atividade/${alunoID}/${atividadeID}`);
export const listarRQPorRevisaoID = (alunoID, revisaoID) => api.get(`/resposta-questao/revisao/${alunoID}/${revisaoID}`);
export const listarRQPorAlunoID = alunoID => api.get(`/resposta-questao/user/${alunoID}`);
export const pegarArquivoAWS = (folder, key) => api.get(`/download-arquivo/folder=${folder}&key=${key}`);
//#endregion

//#region Funções relacionadas à RespostaAtividade
export const inserirRespostaAtividade = novaRespostaAtividade => api.post("/resposta-aluno", novaRespostaAtividade);
export const atualizarRespostaAtividade = (id, respostaAtividadeAtualizada) => api.put(`/resposta-aluno/${id}`, respostaAtividadeAtualizada)
export const removerRespostaAtividade = id => api.delete(`/resposta-aluno/${id}`);
export const encRespostaAtividadePorID = id => api.get(`/resposta-aluno/${id}`);
export const encRespostaAtividade = (alunoID, revisaoID) => api.get(`/resposta-aluno/${alunoID}/${revisaoID}`);
export const listarRespostaAtividade = () => api.get(`/resposta-aluno`);
export const listarRAPorRespostaQuestaoID = respostaQuestaoID => api.get(`/resposta-aluno/respostaquestao/${respostaQuestaoID}`);
export const listarRAPorAlunoID = alunoID => api.get(`/resposta-aluno/user/${alunoID}`);
export const listarRAPorAtividadeID = atividadeID => api.get(`/resposta-aluno/atividade/${atividadeID}`);
export const listarRespostaAtividadePorDisciplina = disParams => api.get(`/resposta-aluno/correcoes/${disParams}`);
export const listarRespostaAtividadePorArea = area => api.get(`/resposta-aluno/correcoes-ads/${area}`);
export const contarRAsNaoCorrigidas = disParams => api.get(`/resposta-aluno/contagem/${disParams}`);
export const contarADsNaoCorrigidas = area => api.get(`/resposta-aluno/contagem-ad/${area}`);
export const listarRAPorRevisaoID = revisaoID => api.get(`/resposta-aluno/avaliacao-diagnostica/${revisaoID}`);
export const listaRespostaAtividadePorDisciplina = disciplinaID => api.get(`/resposta-aluno/disciplina/${disciplinaID}`);
//#endregion

//#region Funções relacionadas à AnoLetivo
export const inserirAnoLetivo = novaAnoLetivo => api.post("/configuracoes/ano-letivo", novaAnoLetivo);
export const listarAnoLetivo = () => api.get("/configuracoes/ano-letivo");
export const atualizarAnoLetivo = (id, anoLetivoAtualizado) => api.put(`/configuracoes/ano-letivo/${id}`, anoLetivoAtualizado);
export const removerAnoLetivo = id => api.delete(`/configuracoes/ano-letivo/${id}`);
export const encAnoLetivoPorID = id => api.get(`/configuracoes/ano-letivo/id/${id}`);
export const encAnoLetivoPorTurma = turmaID => api.get(`/configuracoes/ano-letivo/filtro/${turmaID}`);
//#endregion

//#region Funções relacionadas à Questão
export const inserirQuestao = novaQuestao => api.post("/controle-questao", novaQuestao);
export const listarQuestao = () => api.get("/controle-questao");
export const atualizarQuestao = (id, questaoAtualizada) => api.put(`/controle-questao/${id}`, questaoAtualizada);
export const removerQuestao = id => api.delete(`/controle-questao/${id}`);
export const encQuestaoPorID = id => api.get(`/controle-questao/${id}`);
export const listarQuestaoPorTopico = id => api.get(`/controle-questao/topico/${id}`);
export const listarQuestaoPorArea = area => api.get(`/controle-questao/area/${area}`);
export const listarTQPorQuestaoID = questaoID => api.get(`/controle-questao/tags/${questaoID}`);
export const listarTopQuestPorQuestaoID = questaoID => api.get(`/controle-questao/topicos/${questaoID}`);
export const listarQuestaoPorFiltro = ({disciplinaID, topicoID, turmaID, tipo, area}) => api.get(`/controle-questao/filtro/disciplina=${disciplinaID}&topico=${topicoID}&turma=${turmaID}&tipo=${tipo}&area=${area}`);
export const listarQuestaoPorUserID = userID => api.get(`/controle-questao/filtro/userID=${userID}`);
//#endregion

//#region Funções relacionadas à Atividade
export const inserirAtividade = novaAtividade => api.post("/controle-atividade", novaAtividade);
export const listarAtividades = () => api.get("/controle-atividade");
export const atualizarAtividade = (id, atividadeAtualizada) => api.put(`/controle-atividade/${id}`, atividadeAtualizada);
export const removerAtividade = id => api.delete(`/controle-atividade/${id}`);
export const encAtividadePorID = id => api.get(`/controle-atividade/${id}`);
export const encQuestoesDaAtividadeID = id => api.get(`/controle-atividade/questoes/${id}`);
export const encRedacaoDaSemana = (numeracao, turmaID) => api.get(`/controle-atividade/redacao/${numeracao}&${turmaID}`);
export const listarAtividadesPorTopico = (id) => api.get(`/controle-atividade/topico/${id}`);
export const listarAtividadePorFiltro = ({disciplinaID, area, turmaID, tipo, topico}) => api.get(`/controle-atividade/filtro/disciplina=${disciplinaID}&area=${area}&turma=${turmaID}&tipo=${tipo}&topico=${topico}`);
export const encPropostaRedacao = (id) => api.get(`/controle-atividade/proposta-redacao/${id}`);
export const verificarQuestao = (disciplinaID, topicoID, area, atividade) => api.get(`/verificando/${disciplinaID}/${topicoID}/${area}/${atividade}`);
//#endregion

//#region Funções relacionadas à Revisao
export const inserirRevisao = novaRevisao => api.post("/controle-revisao", novaRevisao);
export const listarRevisao = () => api.get("/controle-revisao");
export const atualizarRevisao = (id, revisaoAtualizada) => api.put(`/controle-revisao/${id}`, revisaoAtualizada);
export const removerRevisao = id => api.delete(`/controle-revisao/${id}`);
export const encRevisaoPorID = id => api.get(`/controle-revisao/${id}`);
export const encRevisaoPorFiltroInterno = (turmaID, dataLiberacao, area) => api.get(`/controle-revisao/filtro-interno/turma=${turmaID}&dataLiberacao=${dataLiberacao}&area=${area}`);
export const encRevisaoPorFiltro = (turmaID, dataAtual) => api.get(`/controle-revisao/filtro/turma=${turmaID}&dataAtual=${dataAtual}`);
export const encRevisaoPorPeriodo = (turmaID, dataInicio, dataFim) => api.get(`/controle-revisao/filtro-periodo/turma=${turmaID}&dataInicio=${dataInicio}&dataFim=${dataFim}`);
//#endregion

//#region Funções relacionadas à ClassLink
export const inserirClassLink = novoLink => api.post("/aula-link", novoLink);
export const listarClassLink = () => api.get("/aula-link");
export const atualizarClassLink = (id, linkAtualizado) => api.put(`/aula-link/${id}`, linkAtualizado);
export const removerClassLink = id => api.delete(`/aula-link/${id}`);
export const encClassLinkPorID = id => api.get(`/aula-link/${id}`);
export const encClassLinkPorTurma = turmaID => api.get(`/aula-link/turma/${turmaID}`);
//#endregion

//#region Funções relacionadas à Turma
export const inserirTurma = novaTurma => api.post("/turma", novaTurma);
export const clonarTurma = (id, userID) => api.post(`/turma/${id}/${userID}`);
export const listarTurma = () => api.get("/turma");
export const atualizarTurma = (id, turmaAtualizada) => api.put(`/turma/${id}`, turmaAtualizada);
export const removerTurma = id => api.delete(`/turma/${id}`);
export const encTurmaPorID = id => api.get(`/turma/${id}`);
export const listarTurmasAtivas = () => api.get("/turmas-ativas");
//#endregion

//#region Funções relacionadas à Data Ads
export const inserirDataAd = novaDataAd => api.post("/data-ads", novaDataAd);
export const listarDataAd = () => api.get("/data-ads");
export const atualizarDataAd = (id, dataAdAtualizada) => api.put(`/data-ads/${id}`, dataAdAtualizada);
export const removerDataAd = id => api.delete(`/data-ads/${id}`);
export const encDataAdPorID = id => api.get(`/data-ads/id/${id}`);
export const listarDataAdPorTurma = turmaID => api.get(`/data-ads/turma/${turmaID}`);
//#endregion

//#region Funções relacionadas à Progresso do Tópico
export const inserirProgresso = novoProgresso => api.post("/progresso-conteudo", novoProgresso);
export const atualizarProgresso = (id, progressoAtualizado) => api.put(`/progresso-conteudo/${id}`, progressoAtualizado);
export const removerProgresso = id => api.delete(`/progresso-conteudo/${id}`);
export const encProgressoPorID = id => api.get(`/progresso-conteudo/${id}`);
export const encProgressoPorTopico = (alunoID, topicoID) => api.get(`/progresso-conteudo/topico/${alunoID}/${topicoID}`);
export const listarProgressoPorAluno = id => api.get(`/progresso-conteudo/aluno/${id}`);
//#endregion

//#region Funções relacionadas à Progresso da Redação
export const inserirProgressoRedacao = novoProgresso => api.post("/progresso-redacao", novoProgresso);
export const atualizarProgressoRedacao = (id, progressoAtualizado) => api.put(`/progresso-redacao/${id}`, progressoAtualizado);
export const removerProgressoRedacao = id => api.delete(`/progresso-redacao/${id}`);
export const encProgressoRedacaoPorID = id => api.get(`/progresso-redacao/${id}`);
export const encProgressoPorRedacaoID = (alunoID, redacaoID) => api.get(`/progresso-redacao/lista/${alunoID}/${redacaoID}`);
export const listarRedacoesNaoCorrigidas = disParams => api.get(`/progresso-redacao/correcoes/${disParams}`);
export const listarRedacoesRealizadasPorRedacaoID = redacaoID => api.get(`/progresso-redacao/redacaoID/${redacaoID}`);
export const contarRedacoesNaoCorrigidas = disParams => api.get(`/progresso-redacao/contagem/${disParams}`);
export const listarRedacoesCorrigidasPorAlunoID = alunoID => api.get(`/resultado-redacao/${alunoID}`);
export const listarRedacoesPorDisciplina = disciplina => api.get(`/progresso-redacao/disciplina/${disciplina}`);
//#endregion

//#region Funções relacionadas à Progresso da Revisão
export const inserirProgressoRevisao = novoProgresso => api.post("/progresso-revisao", novoProgresso);
export const atualizarProgressoRevisao = (id, progressoAtualizado) => api.put(`/progresso-revisao/${id}`, progressoAtualizado);
export const removerProgressoRevisao = id => api.delete(`/progresso-revisao/${id}`);
export const encProgressoRevisaoPorID = id => api.get(`/progresso-revisao/${id}`);
export const encProgressoPorRevisaoID = (alunoID, revisaoID) => api.get(`/progresso-revisao/${alunoID}/${revisaoID}`);
export const encProgressoPorAlunoID = (alunoID) => api.get(`/resultado-ads/${alunoID}`);
//#endregion

//#region Funções relacionadas à Análises
export const gerarAnaliseAluno = (id, filtro) => api.get(`analises/${id}/${filtro}`);
export const gerarProgressoDiario = (alunoID, dia, semana, turmaID) => api.get(`analises/${alunoID}/${dia}/${semana}/${turmaID}`);
export const gerarAnaliseProfessor = id => api.get(`analises-professor/${id}`);
export const gerarAnaliseAdministrador = (turmaID) => api.get(`analises-administrador/${turmaID}`);
export const calcularProgresso = (id, tmID, tpID) => api.get(`analises-frequencia/${id}/${tmID}/${tpID}`);
export const calcularFrequenciaGeral = (id, totalAlunos) => api.get(`analises-frequencia-geral/${id}/${totalAlunos}`);
//#endregion

const apis = {
    // Usuário
    inserirUsuario,
    listarUsuarios,
    listarAlunosAtivos,
    atualizarUsuario,
    atualizarContador,
    removerUsuario,
    encUsuarioPorID,
    encUsuarioPorEmail,
    confirmarUsuario,
    enviarEmail,
    verificarToken,
    redefinirSenha,
    concluirTutorial,
    encContadorPorID,
    // Comunicado
    inserirComunicado,
    listarComunicado,
    atualizarComunicado,
    removerComunicado,
    encComunicadoPorID,
    // Conteúdo
    inserirConteudo,
    listarConteudos,
    atualizarConteudo,
    removerConteudo,
    encConteudoPorID,
    listarConteudoPorFiltro,
    listarConteudoPorFiltroInterno,
    encConteudoPersonalizado,
    listarConteudoCorrente,
    // Disciplina
    inserirDisciplina,
    listarDisciplinas,
    atualizarDisciplina,
    removerDisciplina,
    encDisciplinaPorID,
    listarDisciplinasPorDiaDaSemana,
    listarDisciplinasPorTurma,
    // Log
    inserirLog,
    loginDiario,
    // Tag
    inserirTag,
    listarTags,
    atualizarTag,
    removerTag,
    encTagPorID,
    encTagPorNome,
    listarTagsPorDisciplina,
    // Classificacao
    inserirClassificacao,
    listarClassificacoes,
    atualizarClassificacao,
    removerClassificacao,
    encClassificacaoPorID,
    encClassificacaoPorNome,
    // Resposta-Questão
    inserirRespostaQuestao,
    atualizarRespostaQuestao,
    removerRespostaQuestao,
    encRespostaQuestaoPorID,
    encRespostaQuestaoPorAtividade,
    encRespostaQuestaoPorRevisao,
    listarRespostaQuestao,
    listarRQPorQuestaoID,
    listarRQPorAtividadeID,
    listarRQPorRevisaoID,
    listarRQPorAlunoID,
    pegarArquivoAWS,
    //Resposta-Aluno
    inserirRespostaAtividade,
    atualizarRespostaAtividade,
    removerRespostaAtividade,
    encRespostaAtividadePorID,
    encRespostaAtividade,
    listarRespostaAtividade,
    listarRAPorRespostaQuestaoID,
    listarRAPorAlunoID,
    listarRAPorAtividadeID,
    listarRespostaAtividadePorDisciplina,
    listarRespostaAtividadePorArea,
    contarRAsNaoCorrigidas,
    contarADsNaoCorrigidas,
    listarRAPorRevisaoID,
    listaRespostaAtividadePorDisciplina,
    // Ano Letivo
    inserirAnoLetivo,
    listarAnoLetivo,
    atualizarAnoLetivo,
    removerAnoLetivo,
    encAnoLetivoPorID,
    encAnoLetivoPorTurma,
    // Questão
    inserirQuestao,
    listarQuestao,
    atualizarQuestao,
    removerQuestao,
    encQuestaoPorID,
    listarQuestaoPorTopico,
    listarQuestaoPorArea,
    listarTQPorQuestaoID,
    listarTopQuestPorQuestaoID,
    listarQuestaoPorFiltro,
    listarQuestaoPorUserID,
    // preencherTopicoQuestao,
    // Atividade
    inserirAtividade,
    listarAtividades,
    atualizarAtividade,
    removerAtividade,
    encAtividadePorID,
    encQuestoesDaAtividadeID,
    encRedacaoDaSemana,
    listarAtividadesPorTopico,
    listarAtividadePorFiltro,
    encPropostaRedacao,
    verificarQuestao,
    // Revisão
    inserirRevisao,
    listarRevisao,
    atualizarRevisao,
    removerRevisao,
    encRevisaoPorID,
    encRevisaoPorFiltroInterno,
    encRevisaoPorPeriodo,
    encRevisaoPorFiltro,
    // ClassLink
    inserirClassLink,
    listarClassLink,
    atualizarClassLink,
    removerClassLink,
    encClassLinkPorID,
    encClassLinkPorTurma,
    // Turma
    inserirTurma,
    clonarTurma,
    listarTurma,
    atualizarTurma,
    removerTurma,
    encTurmaPorID,
    listarTurmasAtivas,
    // Data Ads
    inserirDataAd,
    listarDataAd,
    atualizarDataAd,
    removerDataAd,
    encDataAdPorID,
    listarDataAdPorTurma,
    // ProgressoTopico
    inserirProgresso,
    atualizarProgresso,
    removerProgresso,
    encProgressoPorID,
    encProgressoPorTopico,
    listarProgressoPorAluno,
    // ProgressoRedacao
    inserirProgressoRedacao,
    atualizarProgressoRedacao,
    removerProgressoRedacao,
    encProgressoRedacaoPorID,
    encProgressoPorRedacaoID,
    listarRedacoesNaoCorrigidas,
    listarRedacoesRealizadasPorRedacaoID,
    contarRedacoesNaoCorrigidas,
    listarRedacoesCorrigidasPorAlunoID,
    listarRedacoesPorDisciplina,
    // ProgressoRevisao
    inserirProgressoRevisao,
    atualizarProgressoRevisao,
    removerProgressoRevisao,
    encProgressoRevisaoPorID,
    encProgressoPorRevisaoID,
    encProgressoPorAlunoID,
    //Analise
    gerarAnaliseAluno,
    gerarProgressoDiario,
    gerarAnaliseProfessor,
    calcularProgresso,
    gerarAnaliseAdministrador,
    calcularFrequenciaGeral
}

export default apis;
