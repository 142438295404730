import React, { useEffect, useState } from 'react';
import api from '../../api'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { IconButton, Button, Dialog, Grid, Typography, Divider, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { MyTypography, QuestionNumeration } from '../../assets/styles/styledComponents';
import { WirisIframe } from '..';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: "18.75rem",
    maxWidth: "31.25rem",
  }
});

const useStyle = makeStyles(() => ({
  firstQuestionNumeration: {
    marginTop: '0'
  }, 
  divider: {
    marginTop: '1rem'
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: "18.75rem"
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    alignContent: "flex-end",
    minWidth: "18.75rem"
  },
}))(MuiDialogActions);

function genCharArray(charA, charZ) {
  var letters = [];
  for (var i = charA.charCodeAt(0); i <= charZ.charCodeAt(0); ++i) {
    letters.push(String.fromCharCode(i).toUpperCase()+")");
  }
  return letters;
}

export default function ActivityDialog(props) {
  const classes = useStyle();
  const {atividadeID, open, setOpen} = props;
  const [questoes, setQuestoes] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const optionsLetter = genCharArray('a', 'z');

  useEffect(() => {
    const abortController = new AbortController();
    setShowAnswer(false);
    if (atividadeID !== '') {
      async function fetchQuestoesAPI() {
        const response = await api.encQuestoesDaAtividadeID(atividadeID);
        setQuestoes(response.data.data);
      }
      fetchQuestoesAPI()
    }
    return abortController.abort();
    // eslint-disable-next-line
  }, [atividadeID])

  const DisplayQuestion = (props) => {
    const { index } = props;
    const { enunciado, resposta, tipoResposta, padraoResposta } = props.questao;
    return (
      <div>
        <QuestionNumeration className={ index === 0 ? classes.firstQuestionNumeration : 'none' }>Questão {index + 1}</QuestionNumeration>
        <WirisIframe text={enunciado} />
        {
          tipoResposta === "multiplaEscolha" && resposta.map((item, index) => {
            return (
              <div key={index} className={item.gabarito && showAnswer ? "correctOption" : "optionSection"}>
                <Grid key={index} container={true} spacing={2} justifyContent="center">
                  <Grid item={true} xs={1} sm={1} lg={1}>
                    <p className="optionsLetter">{optionsLetter[index]}</p>
                  </Grid>
                  <Grid item={true} xs={11} sm={11} lg={11}>
                    <WirisIframe text={item.opcao} />
                  </Grid>
                </Grid>
              </div>
            );
          })
        }
        {
          (tipoResposta === "discursiva" && showAnswer) && 
            <Grid container={true} justifyContent="center">
              <Grid item={true} xs={12} sm={12} lg={12}>
                <MyTypography className="optionSection">{padraoResposta !== '' ? "Padrão de Resposta" : "Não há padrão de resposta para esta questão."}</MyTypography>
              </Grid>
              <Grid item={true} xs={12} sm={12} lg={12}>
                <WirisIframe text={padraoResposta} />
              </Grid>
            </Grid>
        }
        <Divider className={classes.divider} hidden={(index + 1) === questoes.length}/>
      </div>    
    )
  }

  return (
    <Dialog onClose={() => setOpen(false)} fullWidth={true} maxWidth='sm' aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        Visualizar Questões
      </DialogTitle>
      <DialogContent dividers={true}>
        {
          questoes.map(({ questaoID }, index) => {
            return (
              <div key={index}>
                <DisplayQuestion questao={questaoID} index={index} />
              </div>
            )
          })
        }
      </DialogContent>
      <DialogActions>
       <Tooltip title={showAnswer ? "Esconder gabarito" : "Mostrar gabarito"}>
          <IconButton 
            size="medium"
            onClick={() => {setShowAnswer(!showAnswer)}} 
            color="primary">
            {showAnswer ? <VisibilityOffIcon size='large'/> : <VisibilityIcon size='large'/>}
          </IconButton>
        </Tooltip>
        <Button autoFocus onClick={() => setOpen(false)} color="primary">
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
}