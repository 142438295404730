import React, { useContext, useState, useEffect } from "react";
import api from '../../api'
import { StoreContext } from "../../utils";
import { QuestionForm, SimpleSnackMessage, QuestionValidater } from "../../components";

// -- Função Principal
export default function QuestionInsert() {
    // -- Dados iniciais da constante Questão
    const initialQuestionState = {
        turmaID: "",
        disciplinaID: "",
        topicoID: "",
        enunciado: "",
        resposta: [],
        tipoResposta: "multiplaEscolha",
        dataCriacao: new Date(),
        dataEdicao: new Date(),
        padraoResposta: "",
        tags: [],
        erros: []
    }

    // -- Dados iniciais da constante Opções
    const initialOptionState = {
        opcao: "", 
        gabarito: false
    }

    const [questao, setQuestao] = useState(initialQuestionState);
    const [opcoes, setOpcoes] = useState([initialOptionState]);
    const [selectedTag, setSelectedTag] = useState([]);
    const {token} = useContext(StoreContext);
    const [message, setMessage] = useState("Houve um erro ao inserir a questão.");
    const [noError, setNoError] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState([]);

    // -- Confirma mudanças realizadas em opcoes
    useEffect(() => {
        const abortController = new AbortController();
        setOpcoes(opcoes);
        return abortController.abort();
    }, [opcoes]);

    // -- Confirma mudanças realizadas em questao
    useEffect(() => {
        const abortController = new AbortController();
        setQuestao(questao);
        return abortController.abort();
    }, [questao]);

    // -- Salva questão no banco de dados
    async function saveQuestion() {
        // Valida se há algum erro no preenchimento dos campos
        const error = QuestionValidater(questao);
        setQuestao(preValue => ({
            ...preValue,
            erros: error
        }))
        
        // Verifica se há erro
        if (error.validated) {
            const { topicos, enunciado, resposta, tipoResposta, padraoResposta, dataCriacao, dataEdicao, tags } = questao;

            if (tipoResposta === "multiplaEscolha") {
                var respostaValidada = resposta.filter(item => {
                    return item.opcao !== "";
                })
            }

            const novaQuestao = {
                topicos,
                enunciado,
                resposta: respostaValidada,
                tipoResposta,
                dataCriacao,
                dataEdicao,
                padraoResposta,
                tags,
                autor: token.userID
            }

            // Inserção pela API
            await api
                .inserirQuestao(novaQuestao)
                .then(res => {
                    setNoError(true);
                    setMessage(res.data.message);
                    setSelectedTag([]);
                    setQuestao(preValue => ({
                        ...preValue,
                        enunciado: "",
                        resposta: [],
                        tipoResposta: "multiplaEscolha",
                        dataCriacao: new Date(),
                        dataEdicao: new Date(),
                        padraoResposta: "",
                        tags: [],
                        erros: []
                    }));
                    setOpcoes([initialOptionState]);
                })
                .catch(err => {
                    setNoError(false);
                });
            setOpen(true);
        }
    }

    return (
        <div>
            <QuestionForm 
                title="Criar Questão"
                questao={questao}
                setQuestao={setQuestao}
                saveQuestion={saveQuestion}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
                opcoes={opcoes}
                setOpcoes={setOpcoes}
                initialOptionState={initialOptionState}
                topicoSelecionado={selectedTopic}
                setTopicoSelecionado={setSelectedTopic}
            />

            <SimpleSnackMessage 
                open={open}
                setOpen={setOpen}
                message={message}
                error={noError}
            />
        </div>
    );
};