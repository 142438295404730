import React, { useState, useEffect } from 'react';
import apis from '../../../api';
import { GeneralSubtitle, GeneralTitle, MyCard, MyContainer } from '../../../assets/styles/styledComponents';
import { Grid, makeStyles } from "@material-ui/core";
import { FullWidthActivityTab, CardRAbyQuestion, CardRAbyStudent } from "../../../components";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: '1rem',
    padding: '1rem'
  },
  cardStudent: {
    marginTop: '1rem',
    padding: '1rem'
  },
  centering: {
    textAlign: "center", 
  },
  circularStatic: {
    margin: "auto", 
    textAlign: "center", 
    [theme.breakpoints.up('sm')]: {
      borderLeft: "solid 1px #aaa",
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "1rem",
    }
  },
  correct: {
    color: "#00af2c",
    fontWeight: 'bold',
    fontSize: '1rem',
    marginTop: '1rem',
  },
  correctOption: {
    color: "#00af2c",
    transition: "0s color background-color",
    '&:hover': {
      color: "#017227",
      backgroundColor: "#baf3d0",
      transition: "0.2s",
    }
  },
  incorrect: {
    color: "#af0000",
  },
  optionsLetter: {
    paddingLeft: "0.625rem"
  },
  optionSection: {
    transition: "0s background-color",
    '&:hover': {
      backgroundColor: "#eeeeee",
      transition: "0.2s",
    },
  },
  question: {
    padding: '1rem',
    paddingTop: '0rem'
  },
  subTitle: {
    fontStyle: 'normal',
    fontWeight: `500`,
    fontSize: '1.25rem',
    marginTop: '1rem',
    color: '#606161',
  },
  textStudent: {
    fontSize: '1rem',
    marginTop: '1rem',
  },
  title: {
    textAlign: "center", 
    fontStyle: 'normal',
    fontWeight: `300`,
    fontSize: '1.5rem',
    color: '#606161',
  },
  wrongAnswer: {
    color: "#af0000",
    transition: "0s color background-color",
    '&:hover': {
      color: "#720101",
      backgroundColor: "#f3baba",
      transition: "0.2s",
    }
  }
}));

const noAnswer = "Nenhum aluno respondeu a essa Avaliação Diagnóstica até o momento, portanto não há nenhuma estatística disponível.";

const RemoveDuplicata = (array) => {
  var tmp = [];
  var result = [];

  array.map(x => {
    if (!tmp.length) {
      tmp.push(x.alunoID._id);
      result.push(x);
    }

    if (!tmp.includes(x.alunoID._id)) {
      tmp.push(x.alunoID._id);
      result.push(x);
    }
    return null;
  })

  return result;
}

export default function RevisionAnalysis(props) {
  const { id } = props.match.params;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [listarPorAluno, setListarPorAluno] = useState(false);
  const [value, setValue] = useState(0);
  const [indice, setIndice] = useState(0);

  useEffect(() => {
    async function fetchAtividadeAPI() {
      var array = {};
      await apis.encRevisaoPorID(id)
      .then(async res1 => {
        array.atividade = res1.data.data;

        await apis.listarRAPorRevisaoID(id)
          .then(res2 => {
            array.respostaAtividade = RemoveDuplicata(res2.data.data);

            var questoes = [];
            var alunos = [];

            // Carrega alunos e questões por RA
            if (array.respostaAtividade.length) {
              array.respostaAtividade.map((row, index) => {
                // Pega questões da atividade
                if (index === 0) {
                  row.revisaoID.questoes.map((x, i) => {
                    const { _id, enunciado, resposta, padraoResposta, tipoResposta, corrigido, foto } = x.questaoID;
                    return questoes.push({ _id, enunciado, resposta, padraoResposta, tipoResposta, corrigido, index: i, foto });
                  })
                }
  
                // Pega alunos que realizaram a atividade
                const { _id, nome, url } = row.alunoID;
                return alunos.push({_id, nome, url, corrigido: row.corrigido});
              })
            }
            // Caso nenhum aluno tenha respondido a AD, pega questões da AD diretamente
            else {
              array.atividade.questoes.map((x, i) => {
                // Pega questões da atividade
                const { _id, enunciado, resposta, padraoResposta, tipoResposta } = x.questaoID;
                return questoes.push({ _id, enunciado, resposta, padraoResposta, tipoResposta, index: i });
              })
            }

            array.questoes = questoes;
            array.alunos = alunos;

            setIndice(questoes[0].i);
            setData(array);
          })
      })
    }
    fetchAtividadeAPI();
  }, [id])

  function EncontraRespostasValidas(respostaQuestaoIDs, questaoID) {
    var resEncontrada = [];
    var notaFinal = 0;

    // Filtra mensagens repetidas ou vazias
    var resQuestao = respostaQuestaoIDs.filter(x => { return x.questaoID === questaoID });

    // Caso haja mais de uma resposta para a mesma questão
    if (resQuestao.length) {
      var resNaoVazia = resQuestao.filter(x => { return x.resposta !== "" });

      // Caso haja mais de uma resposta não vazia para a questão
      // pega a última resposta enviada pelo aluno
      if (resNaoVazia.length) {
        resQuestao = [];
        var res = resNaoVazia[resNaoVazia.length-1];
        resQuestao.push(res);
        notaFinal += res?.nota === 1 ? 100 : res.nota;
      }
      // Do contrário, salva a única resposta não vazia que houver
      else {
        resQuestao = resQuestao[0];
        if (resQuestao[0]) {
          notaFinal += resQuestao[0]?.nota === 1 ? 100 : resQuestao[0].nota;
        }
        else {
          notaFinal += 0;
        }
      }
    }

    // Retorna resposta encontrada
    resEncontrada = resEncontrada.length ? resEncontrada.concat(resQuestao) : resQuestao;

    return {
      resEncontrada,
      notaFinal
    }
  }

  // Retorna RespostaAtividade por Questão
  function ListarRAPorQuestao() {
    var { questoes, respostaAtividade } = data;

    if (questoes?.length > 0) {
      // Verifica o tipo da questão
      var tipoQuestao = questoes[indice ? indice : 0].tipoResposta === "multiplaEscolha" ? "Múltipla Escolha" : "Discursiva";

      // Pega dados das questões
      var questaoID = questoes[indice ? indice : 0]._id;
      var alternativas = questoes[indice ? indice : 0].resposta;
      var soma = 0, erros = 0, acertos = 0, media = 0;
      var respostas = [];

      // Caso possua respostaAtividade, calcula estatísticas
      if (respostaAtividade.length) {
        respostaAtividade.map(ra => {
          // Filtra informações duplicadas
          var { resEncontrada, notaFinal } = EncontraRespostasValidas(ra.respostaQuestaoIDs, questaoID)
          var nota = (notaFinal === 1) ? 100 : notaFinal;

          // Calcula nota da atividade
          soma += nota;
          (nota === 100) && acertos++;

          // Pega resposta de questão discursiva
          if (tipoQuestao !== "Discursiva") {
            if (resEncontrada.length) {
              respostas.push(resEncontrada[0].resposta);
            } else {
              respostas.push(resEncontrada.resposta);
            }
          }

          return null;
        })

        // Salva erros, acertos e média da questão
        erros = respostaAtividade.length - acertos;
        media = soma/respostaAtividade.length;
      }

      return ( 
        <CardRAbyQuestion 
          classes={classes}
          discursiva={tipoQuestao === "Discursiva"}
          data={data}
          tipo={tipoQuestao}
          questoes={questoes}
          acertos={acertos}
          erros={erros}
          media={media}
          noAnswer={noAnswer}
          alternativas={alternativas}
          respostas={respostas}
          indice={indice}
        /> 
      )
    }
  }

  // Retorna RespostaAtividade por Aluno
  function ListarRAPorAluno() {
    const { questoes, respostaAtividade } = data;

    if (respostaAtividade[indice]?.alunoID?.nome !== undefined) {
      var { alunoID, corrigido, respostaQuestaoIDs } = respostaAtividade[indice];

      return (
        <CardRAbyStudent 
          classes={classes}
          alunoID={alunoID}
          corrigido={corrigido}
          questoes={questoes}
          respostaQuestaoIDs={respostaQuestaoIDs}
          isEssay={false}
        />
      )
    }
  }

  return (
    <MyContainer>
      <GeneralTitle>Análise de Avaliação Diagnóstica</GeneralTitle>
      <GeneralSubtitle>{data?.atividade?.areaConhecimento}</GeneralSubtitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <MyCard style={{marginTop: '1rem'}}>
            <FullWidthActivityTab 
              questoes={ data?.questoes }
              alunos={ data.alunos }
              listarPorAluno={listarPorAluno}
              setListarPorAluno={setListarPorAluno}
              value={value}
              setValue={setValue}
              setIndice={setIndice}
            />
          </MyCard>
        </Grid>
        { 
          listarPorAluno ? 
          ListarRAPorAluno() :
          ListarRAPorQuestao()
        }
      </Grid>

    </MyContainer>
  );
}