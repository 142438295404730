const tipoAtividade = [
  {
    value: "Nenhum",
  }, {
    value: "Fixação",
  }, {
    value: "Retomada",
  }, {
    value: "Aprofundamento",
  }, {
    value: "Avaliação Diagnóstica",
  }, {
    value: "Redação",
  }
]

export {
  tipoAtividade
}