import React, { memo, useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { GreenButton } from '../../assets/styles/styledComponents'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from "axios";
import { ProgressDialog } from '..';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute"
  },
}));

const messages = [
  {
    title: 'Ops! Houve um erro ao enviar sua redação.',
    message: 'Verifique se o arquivo que nos enviou não está corrompido ou se possui um dos seguintes formatos permitidos: .jpg, .png, .jpeg, .pdf.'
  },
  {
    title: 'Redação enviada!',
    message: 'Aí sim! Agora é só aguardar a correção de nossos professores. Em breve você estará recebendo sua correção!'
  },
  {
    title: 'Houve um erro ao enviar sua correção!',
    message: 'Verifique se o arquivo que enviou não está corrompido ou se possui um dos seguintes formatos permitidos: .jpg, .png, .jpeg, .pdf.'
  },
  {
    title: 'Correção enviada com sucesso!',
    message: 'Sua correção foi enviada com sucesso e já está disponível para o aluno'
  }
]

function UploadEssay(props) {
  const classes = useStyles();
  const { 
    uploadLink, checked, primaryTitle, secondaryTitle, correction, setRedacaoURL, setFeedMsg, 
    setFeedOpen, setCheck, setProgresso, setWasChecked, setEssayUploaded, setUploadError, plano, setFeatureOpen
  } = props;

  const [loading, setLoading]= useState(false);
  const [percentage, setPercentage]= useState(0);

  const handleUpload = async ({ target: { files } }) => {
    const file = files[0];

    // Filtra tipo de arquivo
    if (file && uploadLink && (file?.type === "application/pdf" || file?.type.includes("image"))) {
      const formData = new FormData();
      formData.append("redacao", file);
      
      async function postContent() {
        setLoading(true);
        const options = {
          onUploadProgress: ({loaded, total}) => setPercentage(Math.floor((loaded * 100) / total))
        }
        await axios.post(uploadLink, formData, options)
          .then(res => {
            if (res.data.success && res.data?.url !== "") {
              setFeedMsg(messages[correction ? 3 : 1]);
              setRedacaoURL(res.data.url);
              if (correction) {
                setEssayUploaded(true);
                setUploadError(true);
              } else {
                setCheck({ redacao: true });
                setProgresso(1);
                setWasChecked(true);
              }
            } else setFeedMsg(messages[correction ? 2 : 0]);
            setLoading(false);
            setFeedOpen(true);
          })
          .catch(() => setFeedMsg(messages[correction ? 2 : 0]));
        }
        
      postContent();
    }
    else {
      setFeedMsg(messages[correction ? 2 : 0])
      setFeedOpen(true);
    }
  }

  const verifyFeatureAccess = () => {
    plano === "Free" && setFeatureOpen(true);
  }

  return (
    <>
      <input
        id="contained-button-essay"
        accept="image/*, application/*"
        className={classes.input}
        single="true"
        type="file"
        onChange={handleUpload}
        />
      <label htmlFor="contained-button-essay">
        {
          checked
            ?   <GreenButton 
                  fullWidth={true} 
                  variant="contained" 
                  color="primary" 
                  component="span"
                  startIcon={<CloudUploadIcon />}>{secondaryTitle}</GreenButton>
            :   <Button 
                  fullWidth={true} 
                  variant="outlined" 
                  color="primary" 
                  component="span"
                  startIcon={<CloudUploadIcon />}
                  onClick={verifyFeatureAccess}>{primaryTitle}</Button>
        }
      </label>

        <ProgressDialog progress={percentage} open={loading} setOpen={setLoading}/>
    </>
  );
}

export default memo(UploadEssay);