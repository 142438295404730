import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../api";

// -- Styles / Componentes gráficos
import { Backdrop, CircularProgress, makeStyles, Grid, Card, Zoom, Button } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { MyTextField, MyTypography } from "../../assets/styles/styledComponents"
import logo from "../../assets/images/odisseia-color.png"
import "./styles.css"
import { SimpleSnackMessage } from "../../components";

// -- Style Classes
const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    logo: {
        alignSelf: "center",
        height: "100%",
        width: "100%"
    },
    loginCard: {
        alignContent: "center", 
        textAlign: "center",
        backgroundColor: "#fff",
        borderRadius: "1rem",
        boxShadow: "0.5rem 1rem 2rem black",
        margin: "1rem 0",
        padding: "0 2rem 2rem 2rem"
    },
    loginContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        justifySelf: "center",
        minWidth: "18.75rem",
        padding: "0 2rem",
        width: "100vw",
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        [theme.breakpoints.down('sm')]: {
            padding: "0 1rem",
            backgroundSize: "cover",
        }
    },
    loginHeader: {
        alignContent: "center",
        alignItems: "center"
    },
    forgotButton: {
        color: red[500],
        fontSize: "1rem",
        marginTop: "1rem",
        marginBottom: "-0.5rem"
    },
    loginForm: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "2rem"
        }
    },
    marginFields: {
        marginTop: "1.5rem"
    },
    smLoginForm: {
        marginTop: "3rem",
        marginBottom: "2rem",
    },
    redefinirSenha: {
        margin: "1rem"
    }
}));

const schema = Yup.object().shape({
    senha: Yup.string("").required("Senha necessária.").min(6, "A senha deve conter no mínimo 6 dígitos."),
    confirmacaoSenha: Yup.string("").oneOf([Yup.ref('senha')], 'As senhas têm que ser iguais.').required("As senhas têm que ser iguais."),
});

function ResetPassword(props) {
    const classes = useStyles();
    const history = useHistory();
    const [loadingReset, setLoadingReset] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState(false);
    const token = props.match.params.token;

    const formik = useFormik({
        initialValues: {senha: '', confirmacaoSenha: ''},
        validationSchema: schema,
        onSubmit: async (values) => {
            if (values.senha && values.confirmacaoSenha) {
                setLoadingReset(true);
                try {
                    // const res = await api.redefinirSenha(token, values.senha, values.confirmacaoSenha);
                    await api.redefinirSenha(token, values.senha);
                    return history.push('/');
                } catch (error) {
                    setLoadingMsg(true);
                }
                setLoadingReset(false);
            } else {
                setLoadingMsg(true);
            }
        }
    })

    return (
        <Grid id="resetScreen" container={true} className={classes.loginContainer}>
            <Grid item={true} xl={5} lg={5} md={6} sm={9} xs={12}>
                <Zoom in={true} style={{ transitionDelay: '250ms' }}>
                    <Card className={classes.loginCard}>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <div className={classes.loginHeader}>
                                <Zoom in={true} style={{ transitionDelay: '300ms' }}>
                                    <img className={classes.logo} src={logo} alt="Logo"/>
                                </Zoom>
                                <Zoom in={true} style={{ transitionDelay: '300ms' }}>
                                    <MyTypography align="center" variant="h5">Redefinição de Senha</MyTypography>
                                </Zoom>
                            </div>

                            <div className={classes.loginForm}>
                                <Zoom in={true} style={{ transitionDelay: '350ms' }}>
                                    <MyTextField
                                        className={classes.marginFields}
                                        label="Senha"
                                        variant="outlined"
                                        name="senha"
                                        type="password"
                                        error={formik.touched.senha && Boolean(formik.errors.senha)}
                                        helperText={formik.touched.senha && formik.errors.senha}
                                        autoComplete="false"
                                        autoFocus
                                        value={formik.values.senha}
                                        onChange={formik.handleChange}/>
                                </Zoom>

                                <Zoom in={true} style={{ transitionDelay: '400ms' }}>
                                    <MyTextField
                                        className={classes.marginFields}
                                        label="Confirmar senha"
                                        variant="outlined"
                                        name="confirmacaoSenha"
                                        type="password"
                                        error={formik.touched.confirmacaoSenha && Boolean(formik.errors.confirmacaoSenha)}
                                        helperText={formik.touched.confirmacaoSenha && formik.errors.confirmacaoSenha}
                                        autoComplete="false"
                                        value={formik.values.confirmacaoSenha}
                                        onChange={formik.handleChange}/>
                                </Zoom>
                            </div>

                            <div className={classes.marginFields}>
                                <Zoom in={true} style={{ transitionDelay: '450ms' }}>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        disabled={!(formik.values.senha !== '' && formik.isValid)}
                                        type="submit">Redefinir Senha</Button>
                                </Zoom>
                            </div>
                        </form>
                    </Card>
                </Zoom>
            </Grid>

            <SimpleSnackMessage open={loadingMsg} setOpen={setLoadingMsg} message='Usuário ou senha inválido' error={false}/>

            <Backdrop className={classes.backdrop} open={loadingReset}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
}

export default withRouter(ResetPassword);