const eixo1 = [
  {
    label: 'a1',
    item: 'Desrespeito ao gênero'
  },
  {
    label: 'a2',
    item: 'Desrespeito ao tema'
  },
  {
    label: 'a3',
    item: 'Parte estrutural inadequada'
  },
  {
    label: 'a4',
    item: 'Falta de profundidade temática'
  },
  {
    label: 'a5',
    item: 'Não compreensão da proposta'
  },
  {
    label: 'a6',
    item: 'Paráfrase do texto motivador'
  },
  {
    label: 'a7',
    item: 'Ausência de proposta de solução'
  },
  {
    label: 'a8',
    item: 'Proposta sem os cinco quesitos'
  },
  {
    label: 'a9',
    item: 'Proposta inviável'
  }
]

const eixo2 = [
  {
    label: 'b1',
    item: 'Argumentação restrita ao senso comum'
  },
  {
    label: 'b2',
    item: 'Falta de relação argumentativa'
  },
  {
    label: 'b3',
    item: 'Falta de encadeamento argumentativo'
  },
  {
    label: 'b4',
    item: 'Erro no uso das estratégias persuasivas'
  },
  {
    label: 'b5',
    item: 'Incoerência'
  },
  {
    label: 'b6',
    item: 'Ausência de vocábulo'
  },
  {
    label: 'b7',
    item: 'Repetição de vocábulo'
  },
  {
    label: 'b8',
    item: 'Conectivo inadequado'
  },
  {
    label: 'b9',
    item: 'Problema na construção frasal'
  },
  {
    label: 'b10',
    item: 'Parágrafo com período único'
  },
  {
    label: 'b11',
    item: 'Problema na paragrafação'
  },
  {
    label: 'b12',
    item: 'Erro na referenciação'
  }
]

const eixo3 = [
  {
    label: 'c1',
    item: 'Desvio de Ortografia'
  },
  {
    label: 'c2',
    item: 'Desvio de acentuação'
  },
  {
    label: 'c3',
    item: 'Inadequação de pontuação'
  },
  {
    label: 'c4',
    item: 'Erro de sintaxe'
  },
  {
    label: 'c5',
    item: 'Problema de concordância'
  },
  {
    label: 'c6',
    item: 'Problema de regência'
  },
  {
    label: 'c7',
    item: 'Inadequação vocabular'
  },
  {
    label: 'c8',
    item: 'Uso de clichês e frases feitas'
  }
]

const competencia = [
  {
    label: 'd1',
    item: 'Demonstrar domínio da modalidade escrita formal da Língua Portuguesa.'
  },
  {
    label: 'd2',
    item: 'Compreender a proposta de redação e aplicar conceitos das várias áreas de conhecimento para desenvolver o tema, dentro dos limites estruturais do texto dissertativo-argumentativo em prosa.'
  },
  {
    label: 'd3',
    item: 'Selecionar, relacionar, organizar e interpretar informações, fatos, opiniões e argumentos em defesa de um ponto de vista.'
  },
  {
    label: 'd4',
    item: 'Demonstrar conhecimento dos mecanismos linguísticos necessários para construção da argumentação.'
  },
  {
    label: 'd5',
    item: 'Elaborar proposta de intervenção para o problema abordado, respeitando os direitos humanos.'
  }
]

const inicialEixos = {
  eixo1: {
    a1: 0,
    a2: 0,
    a3: 0,
    a4: 0,
    a5: 0,
    a6: 0,
    a7: 0,
    a8: 0,
    a9: 0,
  },
  eixo2: {
    b1: 0,
    b2: 0,
    b3: 0,
    b4: 0,
    b5: 0,
    b6: 0,
    b7: 0,
    b8: 0,
    b9: 0,
    b10: 0,
    b11: 0,
    b12: 0,
  },
  eixo3: {
    c1: 0,
    c2: 0,
    c3: 0,
    c4: 0,
    c5: 0,
    c6: 0,
    c7: 0,
    c8: 0,
  },
  competencia: {
    d1: 0,
    d2: 0,
    d3: 0,
    d4: 0,
    d5: 0,
  }
}

export {
  eixo1,
  eixo2,
  eixo3,
  competencia,
  inicialEixos
}