import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from "../../utils";
import api from '../../api'
import { GeneralSubtitle, GeneralText, GeneralTitle, MyContainer } from "../../assets/styles/styledComponents"
import { makeStyles, ButtonGroup, Tooltip, Button, Grid, Fade } from "@material-ui/core";
import { CorrectionTable, CorrectionDialog } from '../../components'

const useStyles = makeStyles((theme) => ({
    groupButtons: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
        }
    }
})); 

export default function Correction() {
    const classes = useStyles();
    const { token } = useContext(StoreContext);
    const disciplinas = token.disciplina;
    const [atividade, setAtividade] = useState([]);
    const [revisao, setRevisao] = useState([]);
    const [redacao, setRedacao] = useState([]);
    const [isEssay, setIsEssay] = useState(false);
    const [isRevision, setIsRevision] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [filterDialog, setFilterDialog] = useState(false);
    const [wasLoaded, setWasLoaded] = useState({
        atividade: false,
        redacao: false,
        revisao: false
    })
    const [toLoad, setToLoad] = useState({
        atividade: true,
        redacao: false,
        revisao: false
    })
    const [filter, setFilter] = useState({
        numeracao: "",
        disciplina: "",
        turmaID: "",
        topico: "",
        tipo: "",
        aluno:"",
        area: "",
        data: ""
    });

    // -- Carregamento inicial dos dados para preenchimento das tabelas
    useEffect(() => {

        // -- Carrega Tudo
        async function fetchRevisoes() {
            var arrayAux = [];
            var area = [];

            disciplinas.map(async id => {
                const res = await api.encDisciplinaPorID(id);
                if(!area.includes(res.data.data.areaConhecimento)) {
                    area.push(res.data.data.areaConhecimento);
                    await api.listarRespostaAtividadePorArea(res.data.data.areaConhecimento)
                    .then(response => {
                        arrayAux = arrayAux.length ? arrayAux.concat(response.data.data) : response.data.data;                        
                        setRevisao(arrayAux.filter(x => {
                            let valido = false;
                            x.respostaQuestaoIDs.forEach(rq => {
                                if (!rq.corrigido) {
                                    valido = true;
                                }
                            })
                            return valido;
                        }));
                    })
                    .catch(err => console.log(err));
                }
                
            });
                
            setWasLoaded(x => ({ ...x, revisao: true }));
            setToLoad(x => ({ ...x, revisao: false }));
        }

        // -- Carrega Atividades
        async function fetchAtividades() {
            var arrayAux = [];

            disciplinas.forEach(async item => {
                await api.listarRespostaAtividadePorDisciplina(item)
                .then(res => {
                    arrayAux = arrayAux.length ? arrayAux.concat(res.data.data) : res.data.data;

                    setAtividade(arrayAux.filter(x => {
                        let valido = false;
                        x.respostaQuestaoIDs.forEach(rq => {
                            if (!rq.corrigido) {
                                valido = true;
                            }
                        })
                        return valido;
                    }));
                })
                .catch(err => console.log(err));
            });

            setWasLoaded(x => ({ ...x, atividade: true }));
            setToLoad(x => ({ ...x, atividade: false }));
        }

        // -- Carrega Redacoes
        async function fetchRedacoes() {
            var arrayAux = [];
            var redacoesVazias = [];
            
            disciplinas.forEach(async item => {
                await api.listarRedacoesNaoCorrigidas(item)
                .then(res => {
                    // Filtro temporário até que o upload de redações esteja sem erro
                    var redacoesNaoVazias = [];
                    res.data.data.map(x => {
                        if (x.redacaoURL !== "") redacoesNaoVazias.push(x);
                        else redacoesVazias = redacoesVazias.concat(x);
                        return null;
                    });

                    arrayAux = arrayAux.length ? arrayAux.concat(redacoesNaoVazias) : redacoesNaoVazias;
                    setRedacao(arrayAux);
                })
                .catch(err => console.log(err));

                if (redacoesVazias.length) {
                    console.log("Redações sem conteúdo identificadas.")
                    // var aux = redacoesVazias.map(x => {
                    //     return {
                    //         alunoNome: x.alunoID?.nome,
                    //         alunoEmail: x.alunoID?.email,
                    //         alunoID: x.alunoID?._id,
                    //         topico: x.redacaoID.topicoID.topico,
                    //         turmaID: x.redacaoID.topicoID.disciplinaID.turmaID,
                    //         numeracao: x.redacaoID.topicoID.numeracao,
                    //         progressoID: x._id,
                    //         redacaoURL: x.redacaoURL
                    //     };
                    // });
                }
            });

            if (!arrayAux.length) setRedacao([]);
            setWasLoaded(x => ({...x, redacao: true }));
            setToLoad(x => ({ ...x, redacao: false }));
        }

        // -- Carrega atividades não corrigidas
        if (toLoad.atividade) {
            setIsEssay(false);
            setIsRevision(false);
            (!wasLoaded.atividade && !atividade.length) 
                ?   fetchAtividades()
                :   setToLoad(x => ({ ...x, atividade: false }));
        }

        // -- Carrega revisões não corrigidas
        if (toLoad.revisao) {
            setIsRevision(true);
            setIsEssay(false);
            (!wasLoaded.revisao && !revisao.length) 
                ?   fetchRevisoes()
                    :   setToLoad(x => ({ ...x, revisao: false }));
        }

        // -- Carrega redações não corrigidas
        if (toLoad.redacao) {
            setIsEssay(true);
            setIsRevision(false);
            (!wasLoaded.redacao && !redacao.length) 
                ?   fetchRedacoes()
                    :   setToLoad(x => ({ ...x, redacao: false }));
        }

        // -- Carregamento inicial das redações
        if (isEssay && !wasLoaded.redacao && !toLoad.redacao) {
            fetchRedacoes();
        }

        // -- Carregamento inicial das revisões
        if (isRevision && !wasLoaded.revisao && !toLoad.revisao) {
            fetchRevisoes();
        }

    }, [atividade, disciplinas, isEssay, redacao, isRevision, revisao, toLoad, wasLoaded]);

    // -- Carrega tabela com dados ou mensagem caso não haja dados
    const ReturnCorrectionTable = () => {

        const data = isEssay ? redacao : isRevision ? revisao : atividade;

        if (data.length > 0) {
            return (
                <Fade in={true} style={{transitionDelay: '100ms'}}>
                    <CorrectionTable 
                        data={data} 
                        essay={isEssay}
                        revision={isRevision} 
                        filterDialog={filterDialog}
                        setFilterDialog={setFilterDialog}
                        setSelectedRow={setSelectedRow}
                        setDialogOpen={setDialogOpen}
                        filter={filter}
                        setFilter={setFilter}/>
                </Fade>
            )
        } else {
            return (
                <Fade in={true} style={{transitionDelay: '250ms'}}>
                    <GeneralSubtitle>Pode relaxar que não há {isEssay ? "redações" : "atividades"} pendentes para correção aqui!</GeneralSubtitle>
                </Fade>
            )
        }
    }

    return (
        <MyContainer>
            <Grid>
                <Grid container={true} spacing={1}>
                    <Grid item={true} xs={12} sm={12}>
                        <Fade in={true} style={{transitionDelay: '100ms'}}>
                            <GeneralTitle>Correções Pendentes</GeneralTitle>
                        </Fade>
                        { 
                            isEssay 
                                ?
                                    (redacao.length > 0) &&
                                    <Fade in={true} style={{transitionDelay: '100ms'}}>
                                        <GeneralText variant="h6">Correções pendentes: {redacao.length}</GeneralText>
                                    </Fade>
                                :   isRevision 
                                    ?
                                        (revisao.length > 0) &&
                                        <Fade in={true} style={{transitionDelay: '100ms'}}>
                                            <GeneralText variant="h6">Correções pendentes: {revisao.length}</GeneralText>
                                        </Fade>
                                    :    
                                        (atividade.length > 0) &&
                                        <Fade in={true} style={{transitionDelay: '100ms'}}>
                                            <GeneralText variant="h6">Correções pendentes: {atividade.length}</GeneralText>
                                        </Fade>
                        }
                    </Grid>

                    <Grid align="right" item={true} xs={12} sm={12} className={classes.groupButtons}>
                        <Fade in={true} style={{transitionDelay: '100ms'}}>
                            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                                <Tooltip title="Visualizar Redações">
                                    <Button onClick={() => setToLoad(preValue => ({ ...preValue, redacao: true}))}>Redações</Button>
                                </Tooltip>

                                <Tooltip title="Visualizar Atividades">
                                    <Button onClick={() => setToLoad(preValue => ({ ...preValue, atividade: true}))}>Atividades</Button>
                                </Tooltip>

                                <Tooltip title="Visualizar ADs">
                                    <Button onClick={() => setToLoad(preValue => ({ ...preValue, revisao: true}))}>ADs</Button>
                                </Tooltip>
                            </ButtonGroup>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
            { ReturnCorrectionTable() }
            {
                selectedRow && 
                    <CorrectionDialog 
                        redacaoID={selectedRow.redacaoID._id}
                        data={selectedRow} 
                        aluno={selectedRow.alunoID} 
                        open={dialogOpen} 
                        setOpen={setDialogOpen}
                        setWasChanged={setWasLoaded}
                    /> 
            }
        </MyContainer>
    )
}