import React, { useState, useContext, useEffect } from "react";
import apis from '../../api';
import { StoreContext } from "../../utils";

import { MyContainer, MyCard, GeneralTitle, GeneralSubtitle, GeneralText, MyTypography } from "../../assets/styles/styledComponents"
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemText, ListItemAvatar, Zoom, ListItemIcon, Tooltip, Fade, useMediaQuery, useTheme } from '@material-ui/core';

import LinearProgressBar from "../../components/ProgressBar/LinearProgressBar";
import CircularStatic from "../../components/ProgressBar/CircularStatic";
import { currentWeek } from '../../utils';

import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import { meses } from "../../utils/Data/auxFunctions";

import HelpIcon from '@material-ui/icons/Help';
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import * as questionTime from "../../assets/animations/questionTime.json";
import * as questionsDone from "../../assets/animations/questionsDone.json";
import Lottie from 'react-lottie';
import { PersistenceTimer } from "../../components";

const questionTimeOptions = {
  loop: true,
  autoplay: true,
  animationData: questionTime.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid"
  }
};

const questionsDoneOptions = {
  loop: false,
  autoplay: true,
  animationData: questionsDone.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid"
  }
};

// -- Estilos locais
const useStyles = makeStyles(() => ({
  calendarCard: {
    minHeight: "18.5rem",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row",
    padding: "1rem"
  },
  calendarNumber: {
    fontStyle: 'normal',
    fontWeight: `300`,
    fontSize: '1rem',
    color: '#fff',
    textAlign: "center",
    justifyContent: "center"
  },
  content: {
    fontStyle: 'normal',
    fontWeight: `300`,
    fontSize: '1rem',
    marginTop: '1rem',
    color: '#606161',
  },
  dayCard: {
    minHeight: "1rem",
    minWidth: "1rem",
    backgroundColor: "#606161",
    height: "2rem",
    width: "2rem"
  },
  generalCard: {
    height: "18.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  hourglass: {
    display: "flex",
    justifyContent: "center",
    color:"#4dbc8e"
  },
  icone: {
    width: "auto",
    height: "5rem"
  },
  legenda: {
    width: "0.75rem",
    height: "0.75rem"
  },
  noContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
  },
  phrase: {
    fontSize: '1rem'
  },
  questionProgress: {
    marginLeft: '2rem',
  },
  rankingCard: {
    minHeight: "18.5rem",
    maxHeight: "18.5rem"
  },
  semana: {
    margin: '0.25rem'
  },
  subTitle: {
    fontStyle: 'normal',
    fontWeight: `500`,
    fontSize: '1.25rem',
    marginTop: '1rem',
    color: '#606161',
  },
  tarefaDia: {
    fontStyle: 'normal',
    fontWeight: `300`,
    fontSize: '1rem',
    marginTop: '1rem',
    color: '#606161',
    padding: '0 1rem',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: `300`,
    fontSize: '1.25rem',
    marginTop: '1rem',
    color: '#606161',
  },
  trophy: {
    display: "flex",
    justifyContent: "center",
    color:"#fbab18"
  }
}));

export default function Dashboard () {

  const classes = useStyles();
  
  const [wasLoaded, setWasLoaded] = useState(false);
  const [analiseAluno, setAnaliseAluno] = useState([]);
  const [progressoDiario, setProgressoDiario] = useState([]);
  const [isLoaded, setIsLoaded] = useState({tasks: false, analysis: false});
  const [loginDiario, setLoginDiario] = useState([]);
  const { token } = useContext(StoreContext)
  const alunoID = token.userID;
  const turmaID = token.turmaID;
  
  // -- Armazena informações sobre dia e semana corrente
  const [thisWeek, setThisWeek] = useState(0);
  const [currentDay, setCurrentDay] = useState(0);
  
  const [filter, setFilter] = useState('1MES');

  const theme = useTheme();
	const smScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const activeStyle = (value) => {
    if(filter === value)
      return {
        backgroundColor: "#3300ff", 
        color: "white"
      }
  }

  // -- Carrega análises ao iniciar página
  useEffect(() => {
    // -- Carrega dia e semana corrente
    const pegaSemanaDiaCorrentes = async () => {
      try {
        const auxWeek = await currentWeek(turmaID);
        const auxDay = new Date().getDay();

        setThisWeek(auxWeek);
        setCurrentDay(auxDay);
      } catch (error) {
        console.log(error);
      }
    }

    // -- Carrega análises do aluno
    const gerarAnalise = async () => {
      try {
        await apis
          .gerarAnaliseAluno(alunoID, filter)
          .then(res => {
            setAnaliseAluno(res.data.data);
            setIsLoaded(x => ({...x, analysis: true}));
          })

      } catch (error) {
        console.log(error);
      }
    }

    if (thisWeek === 0) pegaSemanaDiaCorrentes();
    if (!isLoaded.analysis) gerarAnalise();

    return () => {};
  }, [alunoID, currentDay, isLoaded.analysis, thisWeek, turmaID, filter]);

  useEffect(() => {
    if (currentDay !== 0 && thisWeek !== 0) {
      // -- Carrega progressos do aluno
      const gerarProgresso = async () => {
        try {
          const res = await apis.gerarProgressoDiario(alunoID, currentDay, thisWeek, turmaID);
          const data = res.data;
          setProgressoDiario(data.progressos);
        } catch (error) {
          console.log(error);
        }
      }
      gerarProgresso();
    }
    return () => {};
  }, [alunoID, currentDay, thisWeek, turmaID])

  useEffect(() => {
    if(!loginDiario.length) {
      const getLoginDiario = async () => {
        try {
          const { data } = await apis.loginDiario(alunoID);
          setLoginDiario(data.logs);
        } catch (error) {
          console.log(error);
        }
      }
      getLoginDiario();
    }
  }, [loginDiario, alunoID, isLoaded.daily])

  const formataTempo = (tempo, minuto) => {
    if(minuto)
      return ("00" + ( Math.ceil(( tempo / 60 ) - 1 ) % 60 ) ).slice(-2)
    return ("00" + ( Math.ceil( tempo - 1 ) % 60 ) ).slice(-2)
  }

  const handleFilter = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
      setIsLoaded(prev => ({...prev, analysis: false}))
    }
  };

  return (
    <MyContainer>


      <section>
        <GeneralTitle className="heading-page">Dashboard</GeneralTitle>
      </section>

          <section>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} sm={12}>
                <LinearProgressBar titulo="Progresso do Curso" />
              </Grid>

              <Grid item xs={12} container justifyContent={ smScreen ? "center" : "flex-end" }>
                <ToggleButtonGroup
                  value={filter}
                  exclusive
                  onChange={handleFilter}
                  aria-label="text alignment"
                  >
                  <ToggleButton value="1MES" style={activeStyle("1MES")} aria-label="30 dias">30 DIAS</ToggleButton>
                  <ToggleButton value="3MESES" style={activeStyle("3MESES")} aria-label="3 meses">3 MESES</ToggleButton>
                  <ToggleButton value="6MESES" style={activeStyle("6MESES")} aria-label="6 meses">6 MESES</ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              <Grid item={true} xs={12} sm={3} justifyContent="center">
                <GeneralSubtitle className={classes.title}>{meses[new Date().getMonth()]}</GeneralSubtitle>
                <MyCard className={classes.calendarCard}>
                  <Grid item xs={12} container justifyContent="space-between">
                    {
                      ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"].map((element, i) => {
                          return (
                            <Grid item xs={1} sm={1} container className={classes.semana}>
                                <GeneralText>{element}</GeneralText>
                            </Grid>
                          )
                      })
                    }
                  </Grid>
                  {
                    loginDiario.length > 0 && loginDiario.map((element, i) => {
                      if(element.dia === 0) {

                        const inicio = i;
                        const fim = inicio + 7;

                        return (
                          <Grid item xs={12} container justifyContent="space-between">
                            {
                              loginDiario.slice(inicio, fim).map((item, index) => {
                                return (
                                  <Grid item xs={1} sm={1} container justifyContent="space-between" className={classes.semana}>
                                    {item.data !== -1 ?
                                    <MyCard className={classes.dayCard} style={{backgroundColor: item.login ? "#4dbc8e" : new Date().getDate() < item.data ? "#606161" : "#fbab18"}}>
                                      <GeneralSubtitle className={classes.calendarNumber}>{item.data}</GeneralSubtitle>
                                    </MyCard>
                                    : null}
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                        )
                      }
                      return null
                    })
                  }
                  <Grid item xs={12} container>
                      <Grid item xs={6} sm={6} container direction="row" justifyContent="space-evenly" alignItems="center">
                          <MyCard className={classes.legenda} style={{backgroundColor: "#4dbc8e"}} />
                          <MyTypography>Presente</MyTypography>
                      </Grid>
                      <Grid item xs={6} sm={6} container direction="row" justifyContent="space-evenly" alignItems="center">
                          <MyCard className={classes.legenda} style={{backgroundColor: "#fbab18"}} />
                          <MyTypography>Ausente</MyTypography>
                      </Grid>
                    </Grid>
                </MyCard>
              </Grid>

              <Grid item={true} xs={12} sm={3}>
                <GeneralSubtitle className={classes.title}>Tempo no Odisseia</GeneralSubtitle>
                <MyCard className={classes.generalCard} style={{display: "flex", padding: "1rem"}}>
                  <PersistenceTimer/>
                </MyCard>
              </Grid>

              <Grid item={true} xs={12} sm={3}>
                <GeneralSubtitle className={classes.title}>Tarefas do Dia</GeneralSubtitle>
                <MyCard className={classes.rankingCard} style={{display: "flex", alignContent: "center"}}>
                    {
                      (progressoDiario.length > 0)
                      ?
                        <List>
                          {
                            progressoDiario.map((row, index) => {
                              if (row.topico !== "") {
                                return (
                                  <ListItem key={index}>
                                    <ListItemAvatar>
                                      <CircularStatic
                                        wasLoaded={wasLoaded}
                                        setWasLoaded={setWasLoaded}
                                        numTasks={row.numTask}
                                        progresso={row.progresso}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={row.topico} secondary={row.nome} />
                                  </ListItem>
                                )
                              }
                              // eslint-disable-next-line array-callback-return
                              return;
                            })
                          }
                        </List>
                      :
                      <div className={classes.noContent}>
                        <GeneralSubtitle className={classes.title}>Pode relaxar!</GeneralSubtitle>
                          <Zoom in={true}>
                            <BeachAccessIcon color="action" fontSize="large"/>
                          </Zoom>
                        <GeneralSubtitle className={classes.tarefaDia}>Não há tarefas programadas para hoje.</GeneralSubtitle>
                        <GeneralSubtitle className={classes.tarefaDia}>Aproveite o descanso!</GeneralSubtitle>
                      </div>
                    }
                </MyCard>
              </Grid>

              <Grid item={true} xs={12} sm={3}>
                <GeneralSubtitle className={classes.title}>Questões realizadas</GeneralSubtitle>
                <MyCard className={classes.generalCard}>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Fade in={isLoaded} style={{transitionDelay: "300ms"}}>
                          <Lottie options={questionsDoneOptions} height={160} width={160} isStopped={!isLoaded} isPaused={!isLoaded}/>
                      </Fade>
                    </Grid>
                    <Grid xs={12}>
                      <GeneralTitle align='center'>
                        {analiseAluno ? analiseAluno.questoesRealizadas : "0"}
                      </GeneralTitle>
                    </Grid>
                    <Grid xs={12}>
                      <GeneralText align='center'>
                        Questões Realizadas
                      </GeneralText>
                    </Grid>
                  </Grid>
                </MyCard>
              </Grid>

              <Grid item={true} xs={12} sm={3}>
                <GeneralSubtitle className={classes.title}>Melhor Desempenho</GeneralSubtitle>
                <MyCard className={classes.generalCard} style={{display: "flex", flexDirection:"column", alignItems: "center"}}>
                  {
                    (analiseAluno && analiseAluno.topicos?.length > 0) ?
                      <List>
                        {
                          analiseAluno.topicos.slice(0, 3).map((row, index) => {
                            return (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  <GeneralText style={{
                                    color: index === 0
                                      ? "#fbab18"
                                      : index === 1
                                        ? "#eae1d7"
                                        : index === 2
                                          ? "#cd7f32"
                                          : ""
                                  }}>{index + 1} º</GeneralText>
                                </ListItemIcon>
                                <ListItemAvatar>
                                  <CircularStatic
                                    wasLoaded={wasLoaded}
                                    setWasLoaded={setWasLoaded}
                                    numTasks={row.acerto + row.erro}
                                    progresso={row.acerto}/>
                                </ListItemAvatar>
                                <ListItemText primary={row.nome} secondary={`${row.acerto/100} / ${(row.acerto + row.erro)/100} pontos`} />
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    :
                      <div className={classes.noContent}>
                        <GeneralSubtitle className={classes.title}>Sem questões realizadas ainda!</GeneralSubtitle>
                          <Zoom in={true}>
                            <FormatListBulletedIcon color="secondary" fontSize="large"/>
                          </ Zoom>
                        <GeneralSubtitle className={classes.tarefaDia}>Aqui ficarão os três tópicos com melhor desempenho.</GeneralSubtitle>
                      </div>
                  }
                </MyCard>
              </Grid>

              <Grid item={true} xs={12} sm={3}>
                <div style={{display:"flex", alignItems: "center"}}>
                    <GeneralSubtitle className={classes.title}>Ranking</GeneralSubtitle>
                    <Tooltip title="Percentual de acerto de questões por disciplina.">
                      <HelpIcon fontSize="small"/>
                    </Tooltip>
                </div>
                <MyCard 
                  className={classes.rankingCard} 
                  style={{
                    overflowY: analiseAluno.disciplinas?.length < 5 ? "hidden" : "scroll",
                    display: "flex",
                    alignItems: (analiseAluno && analiseAluno.disciplinas?.length > 0) ? "flex-start" : "center"
                  }}
                >
                  {
                    (analiseAluno && analiseAluno.disciplinas?.length > 0) ?
                      <List>
                        {
                          analiseAluno.disciplinas.map((row, index) => {
                            return (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  <GeneralText style={{
                                    color: index === 0
                                      ? "#fbab18"
                                      : index === 1
                                        ? "#eae1d7"
                                        : index === 2
                                          ? "#cd7f32"
                                          : ""
                                  }}>{index + 1} º</GeneralText>
                                </ListItemIcon>
                                <ListItemAvatar>
                                  <CircularStatic
                                    wasLoaded={wasLoaded}
                                    setWasLoaded={setWasLoaded}
                                    numTasks={row.acerto + row.erro}
                                    progresso={row.acerto}/>
                                </ListItemAvatar>
                                <ListItemText primary={row.nome} />
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    :
                      <div className={classes.noContent}>
                        <GeneralSubtitle className={classes.title}>Nada feito por enquanto!</GeneralSubtitle>
                          <Zoom in={true}>
                            <MenuBookIcon color="secondary" fontSize="large"/>
                          </ Zoom>
                        <GeneralSubtitle className={classes.tarefaDia}>Você ainda não tem nenhuma tarefa concluída.</GeneralSubtitle>
                        <GeneralSubtitle className={classes.tarefaDia}>Já checou se tem atividades pendentes?</GeneralSubtitle>
                      </div>
                  }
                </MyCard>
              </Grid>

              <Grid item={true} xs={12} sm={3}>
                <GeneralSubtitle className={classes.title}>Precisa de Atenção</GeneralSubtitle>
                <MyCard 
                  className={classes.rankingCard} 
                  style={{
                    overflowY: analiseAluno.disciplinas?.filter(x => (x.acerto)/(x.acerto + x.erro) < 0.5).length < 5 ? "hidden" : "scroll",
                    display: "flex",
                    alignItems: (analiseAluno && analiseAluno.disciplinas?.length > 0) ? "flex-start" : "center"
                  }}
                >
                  {
                    (analiseAluno && analiseAluno.disciplinas?.length > 0) ?
                      <List>
                        {
                          analiseAluno.disciplinas.filter(x => (x.acerto)/(x.acerto + x.erro) < 0.5).reverse().map((row, index) => {
                            return (
                              <ListItem key={index}>
                                <ListItemAvatar>
                                  <CircularStatic
                                    wasLoaded={wasLoaded}
                                    setWasLoaded={setWasLoaded}
                                    numTasks={row.acerto + row.erro}
                                    progresso={row.acerto}/>
                                </ListItemAvatar>
                                <ListItemText primary={row.nome} />
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    :
                      <div className={classes.noContent}>
                        <GeneralSubtitle className={classes.title}>Sem nada a melhorar!</GeneralSubtitle>
                          <Zoom in={true}>
                            <BookmarksIcon color="secondary" fontSize="large"/>
                          </ Zoom>
                        <GeneralSubtitle className={classes.tarefaDia}>Nenhuma tarefa concluída ainda.</GeneralSubtitle>
                        <GeneralSubtitle className={classes.tarefaDia}>Mas não queira ver nenhuma disciplina por aqui!</GeneralSubtitle>
                      </div>
                  }
                </MyCard>
              </Grid>

              {
                (analiseAluno && analiseAluno.tempoMedioPorQuestao > 0) &&
                <Grid item={true} xs={12} sm={3}>
                  <GeneralSubtitle className={classes.title}>Tempo Médio por Questão</GeneralSubtitle>
                  <MyCard className={classes.generalCard}>
                    <Grid container spacing={0} justifyContent="center">
                      <Grid item xs={12} className={classes.hourglass}>
                        <Fade in={isLoaded} style={{transitionDelay: "300ms"}}>
                            <Lottie options={questionTimeOptions} height={160} width={160} isStopped={!isLoaded} isPaused={!isLoaded}/>
                        </Fade>
                      </Grid>
                      <Grid item xs={4}>
                        <GeneralTitle align='center'>
                          {formataTempo(analiseAluno.tempoMedioPorQuestao, true)}
                        </GeneralTitle>
                        <GeneralText align='center'>MINUTOS</GeneralText>
                      </Grid>
                      <Grid item xs={1}>
                        <GeneralSubtitle align='center'> : </GeneralSubtitle>
                      </Grid>
                      <Grid item xs={4}>
                        <GeneralTitle align='center'>
                          {formataTempo(analiseAluno.tempoMedioPorQuestao, false)}
                        </GeneralTitle>
                        <GeneralText align='center'>SEGUNDOS</GeneralText>
                      </Grid>
                    </Grid>
                  </MyCard>
                </Grid>
              }

            </Grid>
          </section>

    </MyContainer>
  );
};