import React, { useState, useEffect } from 'react';
import { CircularProgress, Typography, Box, Fade } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import * as doneData from "../../assets/animations/checkedDone.json";
import Lottie from "react-lottie";

const defaultOptions2 = {
  loop: false,
  autoplay: true,
  animationData: doneData.default,
  rendererSettings: {
     preserveAspectRatio: "xMidYMid slice"
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  boxDiv: {
    border: 0,
    padding: 0
  },
  box: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default function CircularStatic(props) {
  const { result } = props;
  const classes = useStyles();
  const [done, setDone] = useState(false);
  
  useEffect(() => {
    if (!done && result >= 100) setTimeout(() => { setDone(true) }, 600);
  }, [done, result]);

  return (<>{
    (done) 
      ? 
        <Fade in={true} style={{animationDelay: "300ms"}}>
          <Box alignItems="center" justifyContent="center">
            <Lottie options={defaultOptions2} style={{margin: "-10px"}} height={60} width={60} />
          </Box>
        </Fade>
      : 
        <Fade in={true} style={{animationDelay: "300ms"}}>
          <Box className={classes.boxDiv} position="relative" display="inline-flex">
            <CircularProgress hidden={result > 100} variant="determinate" value={result} />
            <Box top={0} left={0} bottom={0} right={0} className={classes.box}>
              <Fade in={true} style={{ transitionDelay: '100ms' }}>
                <Typography variant="caption" hidden={result > 100} component="div" color="textSecondary">{`${result}%`}</Typography>
              </Fade>
            </Box>
          </Box>
        </Fade>
  }</>);
}
