import React, { useState, useEffect, useContext } from 'react';
import { GeneralSubtitle, GeneralText, MyCard } from '../../../assets/styles/styledComponents';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import { Button, Grid, Slide, Typography, Divider } from '@material-ui/core';
import CircularStatic from '../../ProgressBar/CircularStatic';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import api from '../../../api';
import { EssayResultDialog } from '../..';
import { StoreContext } from '../../../utils';
import useStyles from './styles';
import { eixo1, eixo2, eixo3, competencia } from '../../../utils';

export default function StudentResultsEssay(props) {
  const classes = useStyles();
  const { msg1, msg2, msg3, listagem, mediaAcumulada, title1, title2, title3 } = props;
  const { token } = useContext(StoreContext);
  const [wasLoaded, setWasLoaded] = useState(false);
  const [dataRealizacao, setDataRealizacao] = useState('');
  const [notaAluno, setNotaAluno] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [atividadeSelecionada, setAtividadeSelectionada] = useState(listagem[0]);
  const [numTotal, setNumTotal] = useState(0);
  const [numRealizado, setNumRealizado] = useState(0);
  const [open, setOpen] = useState(false);

  // -- Função para visualizar redação
  const VisualizarCorrecao = () => {
    window.open(atividadeSelecionada?.correcaoURL);
  }

  // -- Carrega número total de redações
  const CarregaDadosGeraisRedacao = async () => {
    try {
      await api.listarAtividades().then(res => {
        var atividades = res.data.data.filter(item => {
          return item.tipoAtividade === "Redação" && item.disciplinaID.turmaID === token.turmaID;
        })
        setNumTotal(atividades.length);
      });
    } catch (error) {
      console.log(error);
    }
  }

  // -- Renderiza linhas da tabela virtualizada
  const RenderizaLinhasTabela = (props) => {
    const { index, style } = props;
    const dataFormatada = new Intl.DateTimeFormat('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(listagem[index].dataConclusao));
    const titleList = `Redação ${index+1} - ${dataFormatada}`;

    function MostraAtividade(index) {
      // Salva atividade selecionada
      setAtividadeSelectionada(listagem[index]);

      // Atualiza data de realização da atividade
      var dataConclusao = new Intl.DateTimeFormat('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(listagem[index].dataConclusao));
      setDataRealizacao(dataConclusao);

      // Pega nota do aluno
      setNotaAluno(listagem[index].nota);
    }

    return (
      <ListItem button style={style} key={index} onClick={() => MostraAtividade(index)}>
        <ListItemText primary={titleList}  />
      </ListItem>
    );
  }

  // -- Carregamento inicial
  useEffect(() => {
    if (!isLoaded && listagem.length) {
      // Carrega data inicial da redação ou AD
      var dataConclusao = new Intl.DateTimeFormat('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(listagem[0].dataConclusao));
      setDataRealizacao(dataConclusao);

      // Carrega notas e total de ADs/redações
      setNotaAluno(listagem[0].nota);
      CarregaDadosGeraisRedacao();

      setAtividadeSelectionada(listagem[0]);
      setNumRealizado(listagem.length);

      // Marca como já carregado
      setIsLoaded(true);
    }
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listagem]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '250ms'}}>
          <MyCard className={classes.contentCard}>
            <GeneralSubtitle>{title1}</GeneralSubtitle>
            <Grid container spacing={2}>
              <Grid item xs={7} sm={8}>
                <GeneralText className={classes.subtitle}>{msg1}</GeneralText>
                <GeneralText className={classes.subtitle}>{msg2}</GeneralText>
              </Grid>

              <Grid item xs={5} sm={4}>
                <div className={classes.caixaRedacoes}>
                  <GeneralText className={classes.rightText}>{dataRealizacao}</GeneralText>
                  <GeneralText className={classes.rightSubtitle}>Data Realização</GeneralText>
                  <GeneralText className={classes.rightText} style={{paddingTop: "0.7rem"}}>{notaAluno}</GeneralText>
                  <GeneralText className={classes.rightSubtitle}>Resultado Obtido</GeneralText>
                </div>
              </Grid>

              <Grid item xs={12}style={{paddingTop: "0.5rem"}}>
                <Button 
                  fullWidth={true} 
                  variant="contained" 
                  color="primary" 
                  component="span"
                  onClick={VisualizarCorrecao}
                  startIcon={<DescriptionIcon />}>Visualizar Correção</Button>

                <Button 
                  fullWidth={true} 
                  variant="contained" 
                  color="primary" 
                  component="span"
                  style={{marginTop: "0.5rem"}}
                  onClick={() => setOpen(true)}
                  startIcon={<InfoIcon />}>Mais Detalhes</Button>
                
                <EssayResultDialog 
                  data={atividadeSelecionada}
                  open={open}
                  setOpen={setOpen}
                />

              </Grid>
            </Grid>
          </MyCard>
        </Slide>
      </Grid>

      <Grid item xs={12} sm={4}>
        
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '300ms'}}>
          <MyCard className={classes.contentCard}>
            <GeneralSubtitle>{title2}</GeneralSubtitle>
            <Grid container spacing={1} justifyContent="center">
              <Grid id='nota' item xs={6} sm={6} className={classes.sumario}>
                <div className={classes.caixaNota}>
                  <GeneralText className={classes.resultadoNota}>{mediaAcumulada}</GeneralText>
                  <GeneralText className={classes.legenda}>Resultado Médio</GeneralText>
                </div>
              </Grid>
              
              <Grid id='progresso' item xs={6} sm={6} className={classes.sumario}>
                <div className={classes.caixa}>
                  <div className={numTotal !== numRealizado ? "none" : classes.success}>
                    <CircularStatic 
                      wasLoaded={wasLoaded} 
                      setWasLoaded={setWasLoaded} 
                      numTasks={numTotal} 
                      progresso={numRealizado}/>
                  </div>
                  <GeneralText className={classes.resultado}>{`${numRealizado}/${numTotal}`}</GeneralText>
                  <GeneralText className={classes.legenda}>Realizadas/Total</GeneralText>
                </div>
              </Grid>
              
              <Grid id='observacao' item xs={12}>
                <GeneralText className={classes.observacao}>{msg3}</GeneralText>
              </Grid>
            </Grid>
          </MyCard>
        </Slide>
      </Grid>
      
      <Grid item xs={12} sm={4}>
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '350ms'}}>
          <MyCard className={classes.listCard}>
            <GeneralSubtitle>{title3}</GeneralSubtitle>
            <FixedSizeList height={200} itemSize={46} itemCount={listagem.length}>
              { RenderizaLinhasTabela }
            </FixedSizeList>
          </MyCard>
        </Slide>
      </Grid>
      
      <Grid item xs={12} sm={6}>
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '350ms'}}>
          <MyCard className={classes.listCard}>
            <GeneralSubtitle>Competências</GeneralSubtitle>
            {
              (atividadeSelecionada !== undefined) &&
              Object.entries(atividadeSelecionada.competencia).sort((a,b) => b[1] - a[1]).slice(0, 3).map((comp, index) => {
                return (
                  <Grid key={index} container className={classes.items} alignItems='center'>
                    <Grid item xs={10} className={classes.description}>
                      <Typography>{competencia.find(x => x.label === comp[0]).item}</Typography>
                    </Grid>
                    <Grid item className={classes.value} xs={2}>
                      <Typography>{comp[1]}</Typography>
                    </Grid>
                    {
                      (index !== 2) && <Divider className={classes.divider} width="100%" />
                    }
                  </Grid>
                )
              })
            }
          </MyCard>
        </Slide>
      </Grid>
      
      <Grid item xs={12} sm={2}>
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '350ms'}}>
          <MyCard className={classes.listCard}>
            <GeneralSubtitle>Eixo 1</GeneralSubtitle>
            {
              (atividadeSelecionada !== undefined) &&
              Object.entries(atividadeSelecionada.eixo1).sort((a,b) => b[1] - a[1]).slice(0, 3).map((eixo, index) => {
                return (
                  <Grid key={index} container className={classes.items} alignItems='center'>
                    <Grid item xs={10} className={classes.description}>
                      <Typography>{eixo1.find(x => x.label === eixo[0]).item}</Typography>
                    </Grid>
                    <Grid item className={classes.value} xs={2}>
                      <Typography>{eixo[1]}</Typography>
                    </Grid>
                    {
                      (index !== 2) && <Divider className={classes.divider} width="100%" />
                    }
                  </Grid>
                )
              })
            }
          </MyCard>
        </Slide>
      </Grid>
      
      <Grid item xs={12} sm={2}>
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '350ms'}}>
          <MyCard className={classes.listCard}>
            <GeneralSubtitle>Eixo 2</GeneralSubtitle>
            {
              (atividadeSelecionada !== undefined) &&
              Object.entries(atividadeSelecionada.eixo2).sort((a,b) => b[1] - a[1]).slice(0, 3).map((eixo, index) => {
                return (
                  <Grid key={index} container className={classes.items} alignItems='center'>
                    <Grid item xs={10} className={classes.description}>
                      <Typography>{eixo2.find(x => x.label === eixo[0]).item}</Typography>
                    </Grid>
                    <Grid item className={classes.value} xs={2}>
                      <Typography>{eixo[1]}</Typography>
                    </Grid>
                    {
                      (index !== 2) && <Divider className={classes.divider} width="100%" />
                    }
                  </Grid>
                )
              })
            }
          </MyCard>
        </Slide>
      </Grid>
      
      <Grid item xs={12} sm={2}>
        <Slide in={wasLoaded} direction="up" style={{transitionDelay: '350ms'}}>
          <MyCard className={classes.listCard}>
            <GeneralSubtitle>Eixo 3</GeneralSubtitle>
            {
              (atividadeSelecionada !== undefined) &&
              Object.entries(atividadeSelecionada.eixo3).sort((a,b) => b[1] - a[1]).slice(0, 3).map((eixo, index) => {
                return (
                  <Grid key={index} container className={classes.items} alignItems='center'>
                    <Grid item xs={10} className={classes.description}>
                      <Typography>{eixo3.find(x => x.label === eixo[0]).item}</Typography>
                    </Grid>
                    <Grid item className={classes.value} xs={2}>
                      <Typography>{eixo[1]}</Typography>
                    </Grid>
                    {
                      (index !== 2) && <Divider className={classes.divider} width="100%" />
                    }
                  </Grid>
                )
              })
            }
          </MyCard>
        </Slide>
      </Grid>
    </Grid>
  );
}
