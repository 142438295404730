import React, { memo } from 'react';
import { GeneralSubtitle } from '../../../assets/styles/styledComponents';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Slide, List, ListItem, ListItemText } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Link as RouterLink} from 'react-router-dom';
import { useStyles } from "./Styles"
import moment from "moment";
import "moment/locale/pt-br";

const DisplayDate = (item) => {
    var dataInicio = moment(item.dataInicio).format("DD/MM");
    var dataFim = moment(item.dataFim).format("DD/MM");
    return (`Disponível de ${dataInicio} a ${dataFim}`);
}

function RevisionAccordion (props) {
    const classes = useStyles();
    const { color, turma, data } = props;

    function RetornaADs() {
        if (data.length > 0) {
            return data.slice(0).reverse().map((item, index) => {
                var text = `${index+1}. ${item.areaConhecimento} - ${DisplayDate(item.dataLiberacao)} `
                var ind = Math.random();
                return (
                    <ListItem key={ind + item._id} className={classes.listItem} button>
                        <RouterLink to={{pathname: "/analise-ad/" + item._id, /*state: {dataAtual: 'bar'}*/}} className={classes.routerLink}>
                            <ListItemText primary={text} />
                        </RouterLink>
                    </ListItem>
                )
            })
        }
        return;
    }
  
    return (
        <Slide in={true} direction="up" style={{transitionDelay: '250ms'}}>
            <Accordion square style={{borderBottom: `0.2rem solid ${color}`}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel3-settings">
                    <GeneralSubtitle style={{paddingLeft: "0.4rem"}}>{turma ? (turma + " -") : ""} Avaliação Diagnóstica</GeneralSubtitle>
                </AccordionSummary>
        
                <AccordionDetails>
                    <Grid container className={classes.accordionContainer}>
                        <Grid item xs={12} className={classes.topDivider} style={{margin: "1rem"}}>
                            <List className={classes.list} component="nav" aria-label="contacts">
                                { RetornaADs() }
                            </List>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
      </Slide>
    );
}

export default memo(RevisionAccordion);