import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { GeneralDialog } from '../..';
import { GeneralText } from '../../../assets/styles/styledComponents';
import PropTypes from 'prop-types';
import * as uploadFile from "../../../assets/animations/uploadFile.json";
import Lottie from "react-lottie";
import LinearProgress from '@material-ui/core/LinearProgress';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: uploadFile.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function LinearProgressWithLabel(props) {
  return (
    <div style={{width: "85%", margin: "auto"}}>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={25}>
          <GeneralText variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</GeneralText>
        </Box>
      </Box>
    </div>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function ProgressDialog(props) {
  const {open, setOpen, progress} = props;

  return (
    <GeneralDialog
      style={{width: "10rem"}}
      noClose={true}
      open={open}
      setOpen={setOpen}
      maxWidth="xs"
      dialogTitle={
        <div style={{textAlign: "center"}}>
          <GeneralText variant="h5" style={{margin: "auto"}}>Fazendo upload do Arquivo</GeneralText>
        </div>
      }
      dialogContent={
        <Grid container spacing={1} style={{textAlign: "center", margin: "auto"}}>
          <Grid item xs={12}>
            <Lottie options={defaultOptions} height={200} width={200} isStopped={!open} isPaused={!open}/>
          </Grid>
          <Grid item xs={12}>
            <LinearProgressWithLabel value={progress} />
          </Grid>
        </Grid>
      }
    />
  );
}