import React, { useContext, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { StoreContext } from "../../utils";
import * as Yup from "yup";
import { useFormik } from "formik";
import api from "../../api";
import { Backdrop, CircularProgress, makeStyles, Grid, Card, Zoom, Button, Slide, Dialog, DialogContent } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { DeleteButton, GeneralText, GeneralTitle, MyTextField, MyTypography } from "../../assets/styles/styledComponents"
import logo from "../../assets/images/odisseia-color.png"
import "./styles.css"
import { SimpleSnackMessage } from "../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    button: {
        textAlign: "center"
    },
    card: {
        maxWidth: "20rem",
        paddingBottom: "1rem"
    },
    logo: {
        alignSelf: "center",
        height: "100%",
        width: "100%"
    },
    loginCard: {
        alignContent: "center", 
        textAlign: "center",
        backgroundColor: "#fff",
        borderRadius: "1rem",
        boxShadow: "0.5rem 1rem 2rem black",
        margin: "1rem 0",
        padding: "0 1rem 1.5rem 1rem"
    },
    loginContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        justifySelf: "center",
        minWidth: "18.75rem",
        padding: "0 2rem",
        width: "100vw",
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        [theme.breakpoints.down('sm')]: {
            padding: "0 1rem",
            backgroundSize: "cover",
        }
    },
    loginHeader: {
        alignContent: "center",
        alignItems: "center"
    },
    forgotButton: {
        color: red[500],
        fontSize: "1rem",
        marginTop: "1rem",
        marginBottom: "-0.5rem",
        cursor: 'pointer'
    },
    loginForm: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "2rem"
        }
    },
    marginFields: {
        marginTop: "1.5rem"
    },
    smLoginForm: {
        marginTop: "3rem",
        marginBottom: "2rem",
    },
    redefinirSenha: {
        margin: "1rem"
    },
    text: {
        textAlign: "center",
        fontSize: "1.2rem",
        padding: "0.5rem 0"
    },
    title: {
        padding: "1rem 0 0 1rem",
        marginBottom: "-0.3rem",
        textAlign: "center"
    }
}));

const schema = Yup.object().shape({
    email: Yup.string("Insira seu e-mail de acesso.").email("Insira um e-mail válido.").required("E-mail necessário."),
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const userName = (nomeAluno) => {
    var name = nomeAluno.split(' ').map(name => {
        return name;
    })
    return name[0] + ' ' + name[1];
}

function Reset() {
    const classes = useStyles();
    const history = useHistory();
    const { token, setToken } = useContext(StoreContext)
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const formik = useFormik({
        initialValues: {email: ''},
        validationSchema: schema,
        onSubmit: async (values) => {
            if (values.email) {
                setLoadingUser(true);
                try {
                    console.log(values);
                  
                    setToken((await api.encUsuarioPorEmail(values.email)).data.data);
                    await api.enviarEmail(values.email).then(() => {
                        setEmailSent(true);
                    });
                } catch (error) {
                    setLoadingMsg(true);
                }
                setLoadingUser(false);
            } else {
                setLoadingMsg(true);
            }
        }
    })

    return (
        <Grid id="loginScreen" container={true} className={classes.loginContainer}>
            <Grid item={true} xl={5} lg={5} md={6} sm={9} xs={12}>
                <Zoom in={true} style={{ transitionDelay: '250ms' }}>
                    <Card className={classes.loginCard}>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <div className={classes.loginHeader}>
                                <Zoom in={true} style={{ transitionDelay: '300ms' }}>
                                    <img className={classes.logo} src={logo} alt="Logo"/>
                                </Zoom>
                                <Zoom in={true} style={{ transitionDelay: '300ms' }}>
                                    <MyTypography align="center" variant="h5">Recuperar Senha</MyTypography>
                                </Zoom>
                            </div>

                            <div className={classes.loginForm}>
                                <Zoom in={true} style={{ transitionDelay: '350ms' }}>
                                    <MyTextField
                                        className={classes.marginFields}
                                        label="E-mail"
                                        variant="outlined"
                                        name="email"
                                        type="email"
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        autoComplete="true"
                                        autoFocus
                                        value={formik.values.email}
                                        onChange={formik.handleChange}/>
                                </Zoom>
                            </div>

                            <div className={classes.marginFields}>
                                <Zoom in={true} style={{ transitionDelay: '450ms' }}>
                                    <>
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        type="submit">Redefinir Senha</Button>
                                    <Link to="/login" style={{textDecoration: "none"}}>
                                        <DeleteButton
                                            className={classes.redefinirSenha}
                                            size="large"
                                            variant="contained">Voltar</DeleteButton> 
                                    </Link>
                                    </> 
                                </Zoom>
                            </div>
                        </form>
                    </Card>
                </Zoom>
            </Grid>

            <SimpleSnackMessage open={loadingMsg} setOpen={setLoadingMsg} message='E-mail inválido' error={false}/>

            <Dialog
                open={emailSent}
                TransitionComponent={Transition}
                keepMounted={true}>
                <GeneralTitle className={classes.title}>{`Olá, ${token ? userName(token.nome) : null}!`}</GeneralTitle>
                <DialogContent className={classes.card} style={{height: 300, width: 600}}>
                    <Grid container spacing={2} justifyContent="center">
    
                        <Grid item xs={12}>
                            <GeneralText className={classes.text}>Um link para redefinição de senha foi enviado para o email informado. Verifique sua caixa de entrada e acesse o link para alterar sua senha</GeneralText>
                        </Grid>
    
                        <Grid item xs={12} className={classes.button}>
                            <Button variant="contained" onClick={() => history.push("/login")} color="primary">
                                Voltar
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Backdrop className={classes.backdrop} open={loadingUser}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    );
};

export default withRouter(Reset);