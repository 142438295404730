import React, { useEffect, useState } from "react";
import { GeneralText } from "../../assets/styles/styledComponents"
import { Avatar, Badge, Button, Divider, Grid, makeStyles } from "@material-ui/core";
import { GeneralDialog, GeneralAccordion } from "../"
import apis from "../../api";
import { useContext } from "react";
import { StoreContext } from "../../utils";
import { useHistory } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    items: {
        '&:hover': {
            background: "#ddd",
        },
        padding: "0.5rem"
    },
    noEssay: {
        color: "#c42828",
    },
    withEssay: {
        color: "#017227",
    },
    userName: {
        paddingTop: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '-1.5rem',
        },
        color: "#606161",
        fontWeight: 'semi-bold'
    }
}));

export default function SubjectAnalysisDialog(props) {
    const { open, setOpen, data } = props;
    const classes = useStyles();
    const [topico, setTopicos] = useState([]);
    const [redacoes, setRedacoes] = useState([]);
    const [progress, setProgress] = useState([]);
    
    // Verifica se DataProvider possui as informações necessárias
    const history = useHistory();
    const { dataProvider } = useContext(StoreContext)
    if (dataProvider === null) history.push("/analisar-desempenho");
    const {turmaID, disciplinaID, redacao} = dataProvider;

    useEffect(() => {
        async function fetchTopics() {
            var params = {
                area: "0",
                turmaID: turmaID,
                disciplinaID: disciplinaID,
                numeracao: "0"
            }

            // Recupera tópicos da disciplina
            await apis.listarConteudoPorFiltroInterno(params)
                .then(async res => {
                    var topicosDaDisciplina = res.data.data;
                    setTopicos(topicosDaDisciplina);

                    var progressoDoTopico = [];

                    // Caso atividade não seja do tipo redação
                    if (!redacao) {
                        // Recupera progressos de cada tópico
                        topicosDaDisciplina.map(async item => {
                            await apis.encProgressoPorTopico(data.alunoID, item._id)
                                .then(progEncontrado => {
                                    progressoDoTopico = progressoDoTopico.length ? progressoDoTopico.concat(progEncontrado.data.data) : progEncontrado.data.data
                                })
                                .catch(err => console.log("Erro em carregar progressos: ", err))
                        })
                        setProgress(progressoDoTopico);                  
                    }

                    // Encontra progresso das redações
                    else {
                        params = {
                            tipo: "0",
                            turmaID: turmaID,
                            disciplinaID: disciplinaID,
                            topico: "0",
                            numeracao: "0",
                            area: "0"
                        }
                        
                        await apis.listarAtividadePorFiltro(params)
                            .then(async res1 => {
                                const redacoesEncontradas = res1.data.data;
                                setRedacoes(redacoesEncontradas);
            
                                const promises = redacoesEncontradas.map(async redacao => {
                                    return await apis.encProgressoPorRedacaoID(data.alunoID, redacao._id)
                                        .catch(err => console.log("Erro em carregar progressos: ", err));
                                });
                                
                                Promise
                                    .all(promises)
                                    .then(res => {
                                        res.filter(x => { return x && progressoDoTopico.push(x.data.data) });
                                        setProgress(progressoDoTopico);
                                    })
                            })
                    }
                })
                .catch(err => console.log("Erro em carregar tópicos: ", err));
        }
        fetchTopics();
    }, [data, disciplinaID, redacao, turmaID])

    const DialogTitle = () => {
        return (
            <Grid container={true}>
                <Grid item={true} xs={2} sm={1}>
                    <Badge overlap="circle">
                        <Avatar sizes="small" src={data.url} alt="Preview"/>
                    </Badge>
                </Grid>
                <Grid item={true} xs={10} sm={11}>
                    <GeneralText variant="h6" className={classes.userName}>{data.nome}</GeneralText>
                </Grid>
            </Grid>
        );
    }

    const DialogContent = () => {
        const MappingTopics = () => {
            const SimpleItem = (props) => {
                let { title, content, divider } = props;
                return (
                    <Grid item xs={12}>
                        <Grid container className={classes.items}>
                            <Grid item xs={10}>
                                <GeneralText>{title}</GeneralText>
                            </Grid>
                            <Grid item style={{textAlign: "right", paddingRight: "0.5rem"}} xs={2}>
                                <GeneralText>{content}</GeneralText>
                            </Grid>
                        </Grid>
                        {
                            divider && 
                            <Grid item xs={12}>
                                <Divider width="100%" />
                            </Grid>
                        }
                    </Grid>
                )
            }
    
            const TopicProgress = (foundProgress) => {
                return (
                    <Grid container>
                        <SimpleItem 
                            title="Material de Estudo"
                            content={foundProgress?.materialEstudo ? "Estudado" : "Não estudado"}
                            divider={true}
                        />
    
                        <SimpleItem 
                            title="Videoaula"
                            content={foundProgress?.videoaula ? "Visto" : "Não visto"}
                            divider={true}
                        />
    
                        <SimpleItem 
                            title="Atividade de Aprofundamento"
                            content={foundProgress?.exercioAprofundamento ? "Realizado" : "Não realizado"}
                            divider={true}
                        />
    
                        <SimpleItem 
                            title="Atividade de Retomada"
                            content={foundProgress?.exercioRetomada ? "Realizado" : "Não realizado"}
                            divider={true}
                        />
    
                        <SimpleItem 
                            title="Atividade de Retomada"
                            content={foundProgress?.exercioRetomada ? "Realizado" : "Não realizado"}
                            divider={true}
                        />
    
                        <SimpleItem 
                            title="Atividade de Fixação"
                            content={foundProgress?.exercioFixacao ? "Realizado" : "Não realizado"}
                            divider={false}
                        />
                    </Grid>
                );
            }

            const EssayProgress = ({foundProgress}) => {
                if (foundProgress) {
                    const {nota, corrigido, dataConclusao} = foundProgress;
                    return (
                        <Grid container>
                            <SimpleItem 
                                title="Status Correção"
                                content={corrigido ? "Corrigido" : "Não corrigido"}
                                divider={true}
                            />
        
                            <SimpleItem 
                                title="Resultado"
                                content={(nota > 100) ? (nota/10) : nota}
                                divider={true}
                            />

                            <SimpleItem 
                                title="Data de Realização"
                                content={`${moment(dataConclusao).format("DD/MM/YYYY")}`}
                                divider={true}
                            />
                        </Grid>
                    );
                }

                return (
                    <Grid container>
                        <SimpleItem 
                            title="Status da Redação"
                            content="Não realizada"
                            divider={true}
                        />
                    </Grid>
                );
            }

            if (redacao) {
                return topico.map((item, index) => {
                    var redacaoDoTopico = redacoes.find(x => x?.topicoID?._id === item._id);
                    var progressoDaRecadao = redacaoDoTopico && progress.find(x => x.redacaoID === redacaoDoTopico._id);
                    return (
                        <Grid item xs={12}>
                            <GeneralAccordion 
                                accordionSummary={<GeneralText className={progressoDaRecadao ? classes.withEssay : classes.noEssay}>{index+1} - {item.topico}</GeneralText>}
                                accordionDetails={<EssayProgress foundProgress={progressoDaRecadao}/>}
                            />
                        </Grid>
                    );
                })
            }
            else {
                return topico.map((item, index) => {
                    var foundProgress = progress.find(x => x.topicoID === item._id);
                    return (
                        <Grid item xs={12}>
                            <GeneralAccordion 
                                accordionSummary={<GeneralText>{index+1} - {item.topico}</GeneralText>}
                                accordionDetails={<TopicProgress foundProgress={foundProgress}/>}
                            />
                        </Grid>
                    );
                })
            }
        }


        return (
            <Grid container spacing={2}>
                { MappingTopics() }
            </Grid>
        );
    }

    const DialogActions = () => {
        return (
            <Button autoFocus onClick={() => setOpen(false)} color="primary">
                Voltar
            </Button>
        );
    }

    return (
        <GeneralDialog 
            open={open}
            setOpen={setOpen}
            dialogTitle={<DialogTitle/>}
            dialogContent={<DialogContent/>}
            dialogActions={<DialogActions/>}
        />
    );
}
