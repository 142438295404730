import React, { useState, useEffect } from "react";
import api from '../../api'
import { QuestionForm, SimpleSnackMessage, QuestionValidater } from "../../components";

// -- Função Principal
export default function QuestionUpdate(props) {

    // -- Dados iniciais da constante Opções
    const initialOptionState = {
        opcao: "", 
        gabarito: false
    }

    const [questao, setQuestao] = useState(props.location.state);
    const [selectedTag, setSelectedTag] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState([]);
    const [opcoes, setOpcoes] = useState([initialOptionState]);
    const questaoID = props.match.params.id;
    const [message, setMessage] = useState("Houve um erro ao atualizar a questão.");
    const [noError, setNoError] = useState(true);
    const [open, setOpen] = useState(false);
    
    // -- Carrega as alternativas e os tópicos vinculados a questão selecionada pelo usuário
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchTopicosAPI() {
            let topics = [];
            await api.listarTopQuestPorQuestaoID(questaoID).then(res => res.data.data.forEach(row => topics.push( row.topicoID )));
            setSelectedTopic(topics);
            if (questao.tipoResposta === "multiplaEscolha") {
                setOpcoes(questao.resposta)
            }
            setSelectedTag(questao.tags);
        }
        fetchTopicosAPI()
        return abortController.abort();
    }, [questaoID, questao.tipoResposta, questao.resposta, questao.tags]);

    // -- Confirma mudanças realizadas em opcoes
    useEffect(() => {
        const abortController = new AbortController();
        setOpcoes(opcoes);
        return abortController.abort();
    }, [opcoes]);

    // -- Confirma mudanças realizadas em questao
    useEffect(() => {
        const abortController = new AbortController();
        setQuestao(questao);
        return abortController.abort();
    }, [questao]);

    // -- Salva questão no banco de dados
    async function updateQuestion() {
        // Valida se há algum erro no preenchimento dos campos
        const error = QuestionValidater(questao);
        setQuestao(preValue => ({
            ...preValue,
            erros: error
        }))
        
        // Verifica se há erro
        if (error.validated) {
            const { topicos, enunciado, resposta, tipoResposta, padraoResposta, tags, autor } = questao;

            if (tipoResposta === "multiplaEscolha") {
                var respostaValidada = resposta.filter(item => {
                    return item.opcao !== "";
                })
            }

            const questaoAtualizada = {
                topicos,
                enunciado,
                resposta: respostaValidada,
                tipoResposta,
                dataEdicao: new Date(),
                padraoResposta,
                tags,
                autor
            }

            // Inserção pela API
            await api
                .atualizarQuestao(props.match.params.id, questaoAtualizada)
                .then(res => {
                    setNoError(true);
                    setMessage(res.data.message);
                })
                .catch(err => {
                    setNoError(false);
                });
            setOpen(true);
        }
    }

    return (
        <div>
            <QuestionForm 
                title="Atualizar Questão"
                questao={questao}
                setQuestao={setQuestao}
                saveQuestion={updateQuestion}
                opcoes={opcoes}
                setOpcoes={setOpcoes}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
                initialOptionState={initialOptionState}
                topicoSelecionado={selectedTopic}
                setTopicoSelecionado={setSelectedTopic}
            />
        
            <SimpleSnackMessage 
                open={open}
                setOpen={setOpen}
                message={message}
                error={noError}/>
        </div>
    );
};