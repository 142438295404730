import React from "react";
import {Link as RouterLink} from 'react-router-dom';
import api from '../../api'
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import "moment/locale/pt-br";

const useStyles = makeStyles({
    table: {
        color: "#606161"
    },
    row: {
        color: "#606161"
    },
    head: {
        color: "#606161",
        fontWeight: "bold"
    }
});

// -- Atualização
function UpdateYear(props) {
    const {setAno, ano, setAnoID, setMount} = props;

    function AtualizandoAno() {
        setAnoID(ano._id)

        var anoSelecionado = ano;
        anoSelecionado.turmaID = ano.turmaID?._id;
        setAno(anoSelecionado);
        
        setMount(preValue => ({...preValue, anoLetivo: true}));
    }

    return (
        <IconButton aria-label="update" color="primary" size="small" onClick={AtualizandoAno}>
            <EditIcon/>
        </IconButton>
    )
}

// -- Remoção
function DeleteYear(props) {
    const {id, setMount} = props;

    function RemovendoAno() {
        if (window.confirm(`Quer remover este ano letivo permanentemente?`)) {
            api.removerAnoLetivo(id)
            setMount(preValue => ({...preValue, anoLetivo: true}));
        }
    }

    return (
        <RouterLink to={"/configuracoes"}>
            <IconButton
                aria-label="delete"
                color="secondary"
                size="small"
                onClick={RemovendoAno}>
                <DeleteIcon/>
            </IconButton>
        </RouterLink>
    )
}

export default function YearTable(props) {
    const {data, setAno, setAnoID, setMount} = props;
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.head}>Turma</TableCell>
                        <TableCell className={classes.head}>Início</TableCell>
                        <TableCell className={classes.head}>Fim</TableCell>
                        <TableCell className={classes.head}>Semanas</TableCell>
                        <TableCell className={classes.head}>Funções</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((row, index) => {
                            const {turmaID, dataInicio, dataFim, numSemanas} = row;
                            return (
                                <TableRow key={index}>
                                    <TableCell className={classes.row} component="th" scope="row">{turmaID?.nome}</TableCell>
                                    <TableCell className={classes.row} component="th" scope="row">{moment(dataInicio).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell className={classes.row} component="th" scope="row">{moment(dataFim).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell className={classes.row} component="th" scope="row">{numSemanas}</TableCell>
                                    <TableCell className={classes.row} component="th" scope="row">
                                        <UpdateYear ano={row} setAno={setAno} setMount={setMount} setAnoID={setAnoID}/>
                                        <DeleteYear id={row._id} setMount={setMount}/>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}