import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Grid, Tooltip, Typography, Zoom } from "@material-ui/core";
import { GeneralText } from "../../assets/styles/styledComponents";
import { StoreContext } from '../../utils';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    backButton: {
        margin: '0 0.5rem',
        [theme.breakpoints.down('sm')]: {
          marginTop: '-1rem'
        }
    },
    buttons: {
        margin: '0 0.5rem',
        [theme.breakpoints.down('sm')]: {
          marginTop: '-1rem',
        }
    },
    foco: {
        zIndex: 2000,
        position: 'relative',
        backgroundColor: "#3300ff",
        borderRadius: '7px'
    },
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        zIndex: 3000,
        borderRadius: '7px',
        pointerEvents: "auto",
        position: "static",
        width: '300px',
        [theme.breakpoints.down("sm")]: {
            position: "relative"
        }
    }
}));

export default function OnboardingTooltip(props) {
    
    const { lastStep, nextStep, tip, title, step, currentStep, setCurrentStep, placement, url } = props;
    const { setToken } = useContext(StoreContext);
    const classes = useStyles();

    const skipTutorial = () => {
        setCurrentStep(0);
        setToken((prevValue) => ({
            ...prevValue,
            firstTime: false
        }));
    }

    const returnBodyTooltip = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} className={classes.tooltip}>
                <Grid item xs={12}>
                    <Typography align="center" variant="h6">{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <GeneralText align="center" variant="body2">{tip}</GeneralText>
                </Grid>
                <Grid item xs={lastStep ? 12 : 6} container justifyContent='center'>
                    <Button className={classes.backButton} color="secondary" onClick={skipTutorial}>Fechar</Button>
                </Grid>
                <Grid item xs={6} hidden={lastStep}>
                    { 
                        url ?   
                            <Link to={url} style={{ textDecoration: 'none' }}>
                                <Button className={classes.buttons} color="primary" onClick={() => setCurrentStep(nextStep)}>Próxima</Button>
                            </Link> :
                            !lastStep && <Button className={classes.buttons} color="primary" onClick={() => setCurrentStep(nextStep)}>Próxima</Button>
                    }
                    
                </Grid>
            </Grid>
        )
    }
    
    return (
        <Tooltip
            title={returnBodyTooltip()}
            open={currentStep === step ? true : false}
            placement={placement ? placement : "bottom"}
            className={currentStep === step ? classes.foco : ""}
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 600 }}
            arrow
        >
            {props.children}
        </Tooltip>
    );
}
